import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {BaseInput} from "../../components/Input/BaseInput";
import HomePotentialPopup from "../../components/HomeHealthCare/HomePotentialPopup"
import {InputTypeEnum} from "edah_utils/dist"
import { TextArea, SizeEnum, Field } from 'edah-component/dist'



/**
 * 居家護理=>居家潛在個案維護(S1)
 * @return {JSX.Element}
 */
const HomePatentialCase = () => {
    // 欄位名稱CSS
    const title = "text-right flex items-center justify-end"
    // 欄位input CSS
    const inputCss = "h-full px-2 bg-white text-black rounded-[6px] border border-gray-300 w-[166px]"

    // 看診資料查詢popup
    const [showPopup, setShowPopup]=useState(false)

    // 關係test arr
    const relationship =[
        {
            name:"",
            value:""
        },
        {
            name:"父子",
            value:"DadSon"
        },
        {
            name:"父女",
            value:"DadDaughter"
        },
        {
            name:"母女",
            value:"MonDaughter"
        },
        {
            name:"母子",
            value:"MonSon"
        },
        {
            name:"其他",
            value:"Other"
        },
    ]

    // 職業test arr
    const job =[
        {
            name:"",
            value:""
        },
        {
            name:"醫生",
            value:"doctor"
        },
        {
            name:"老師",
            value:"teacher"
        },
        {
            name:"工程師",
            value:"engineer"
        },
        {
            name:"律師",
            value:"loyer"
        },
        {
            name:"其他",
            value:"Other"
        },
    ]

    // 教育程度test arr
    const education=[
        {
            name:"",
            value:""
        },
        {
            name:"博士",
            value:"phd"
        },
        {
            name:"碩士",
            value:"master"
        },
        {
            name:"大學",
            value:"Bachelor"
        },
        {
            name:"高中",
            value:"senior"
        },
        {
            name:"國中",
            value:"junior"
        },
        {
            name:"國小",
            value:"elementary"
        }
    ]

    // 溝通語言test arr
    const lauguage=[
        {
            name:"",
            value:""
        },
        {
            name:"中文",
            value:"chinese"
        },
        {
            name:"台語",
            value:"taiwanese"
        },
        {
            name:"英文",
            value:"english"
        }  
    ]

    // 看診資料查詢Popup show
    const handleShowPopup =()=>{
        setShowPopup(true)
    }

    // 看診資料查詢Popup show
    const handleCloseShowPopup =()=>{
        setShowPopup(false)
    }

    return (
        <>
        <div className="w-full h-full px-4 bg-[#FAFAFA]">
            {/* 右側功能btn */}
            <div className="pt-2 flex flex-row flex-wrap items-center justify-end">

                <div className="flex flex-row items-center justify-start mb-2 mr-4">
                    {/* 看診資料查詢 */}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] border-[#D4D4D8] rounded-[6px]"
                        onClick={handleShowPopup}
                    >
                        看診資料查詢
                    </button>
                </div>

                <div className="flex flex-row items-center justify-end mb-2 mr-4">
                    {/* 列印 */}
                    <button
                        className="flex items-center justify-center h-10 px-4 border-[2px] bg-[#FAFAFA] text-black font-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                </div>

                <div className="flex flex-row items-center justify-end mb-2 mr-4">
                    {/* 回收案清單(S1) */}
                    <button
                        className="flex items-center justify-center h-10 px-4 border-[2px] bg-[#FAFAFA] text-black font-black border-[#D4D4D8] rounded-[6px]">
                        回收案清單(S1)
                    </button>
                </div>
                <div className="flex flex-row items-center justify-end mb-2 mr-4">
                    {/* 存檔 */}
                    <button
                        className=" w-[128px] bg-[#2B6CB0] text-white flex items-center justify-center h-10 px-4 border-[2px]border-[#2B6CB0] rounded-[6px]">
                        {t('general.saveFile')}
                    </button>
                </div>
            </div>


            {/*欄位區塊*/}
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-full border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="flex flex-row py-2">
                        <div className="w-[8px] h-[25px] bg-[#2B6CB0] mx-2"></div>
                        <div className="text-[#2B6CB0] text-xl ">資料維護</div>
                    </div>
                    <div className="grid grid-cols-5 gap-4 h-[42px]">
                        <div className="grid grid-cols-2 gap-4 h-full">
                            {/* 個案姓名 */}
                            <span className={title}>個案姓名</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 病歷號 */}
                            <span className={title}>病歷號</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 身分證號 */}
                            <span className={title}>{t('general.idNumber')}</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 性別*/}
                            <span className={title}>{t('general.gender.name')}</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className="grid grid-cols-2 gap-4">
                            {/* 出生日期 */}
                            <span className={title}>{t('general.dateOfBirth')}</span>
                            <BaseInput type={InputTypeEnum.date} inputMode={'date'} className={inputCss}/>
                        </div>
                        <div className="flex flex-row col-span-2">
                            {/* 電話 */}
                            <span className={`w-[23%] ${title}`}>電話</span>
                            <BaseInput type={InputTypeEnum.text} className={`mx-4 ${inputCss}`}/>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="flex flex-row col-span-2">
                            {/* 地址 */}
                            <span className={`w-[23%] ${title}`}>地址</span>
                            <BaseInput type={InputTypeEnum.text} className={`mx-4 w-[70%] ${inputCss}`}/>
                        </div>
                    </div>
                    <hr className="w-full my-4"></hr>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className="grid grid-cols-2 gap-4 h-full">
                            {/* 主要聯絡人 */}
                            <span className={title}>主要聯絡人</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 關係 */}
                            <span className={title}>關係</span>
                            <select className={inputCss} name="choice">
                                {
                                    relationship && relationship.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 電話 */}
                            <span className={title}>電話</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 教育程度*/}
                            <span className={title}>教育程度</span>
                            <select className={inputCss} name="choice">
                                {
                                    education && education.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className="col-span-2"></div>
                        <div className="grid grid-cols-2 gap-4 h-full">
                            {/* 職業 */}
                            <span className={` ${title} `}>職業</span>
                            <select className={inputCss} name="choice">
                                {
                                    job && job.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 溝通語言 */}
                            <span className={title}>溝通語言</span>
                            <select className={inputCss} name="choice">
                                {
                                    lauguage && lauguage.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className="grid grid-cols-2 gap-4 h-full">
                            {/* 主要照護者 */}
                            <span className={title}>主要照護者</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 關係 */}
                            <span className={title}>關係</span>
                            <select className={inputCss} name="choice">
                                {
                                    relationship && relationship.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 電話 */}
                            <span className={title}>電話</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 教育程度*/}
                            <span className={title}>教育程度</span>
                            <select className={inputCss} name="choice">
                                {
                                    education && education.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="text-left ml-4">
                            {/* 同上btn*/}
                            <button className="h-10 px-4 border-[2px] bg-[#FAFAFA] text-black font-black border-[#D4D4D8] rounded-[6px]">同上</button>
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className="col-span-2"></div>
                        <div className="grid grid-cols-2 gap-4 h-full">
                            {/* 職業 */}
                            <span className={` ${title} `}>職業</span>
                            <select className={inputCss} name="choice">
                                {
                                    job && job.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 溝通語言 */}
                            <span className={title}>溝通語言</span>
                            <select className={inputCss} name="choice">
                                {
                                    lauguage && lauguage.map((data,index) => (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <hr className="w-full my-4"></hr>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className=" grid grid-cols-2 gap-4">
                            {/* 收案日期 */}
                            <span className={` ${title} `}>收案日期</span>
                            <BaseInput type={InputTypeEnum.date} inputMode={'date'} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 收案人員 */}
                            <span className={title}>收案人員</span>
                            <BaseInput  type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 護理師 */}
                            <span className={title}>護理師</span>
                            <BaseInput type={InputTypeEnum.text} className={inputCss}/>
                        </div>
                    </div>
                    <div className="mt-2 grid grid-cols-5 gap-4 h-[42px]">
                        <div className=" grid grid-cols-2 gap-4">
                            {/* 結案日期 */}
                            <span className={` ${title} `}>結案日期</span>
                            <BaseInput type={InputTypeEnum.date} inputMode={'date'} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 結案原因 */}
                            <span className={title}>結案原因</span>
                            <BaseInput type={InputTypeEnum.Text} className={inputCss}/>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            {/* 結案人員 */}
                            <span className={title}>結案人員</span>
                            <BaseInput type={InputTypeEnum.Text} className={inputCss}/>
                        </div>
                    </div>
                    <div className="">
                        <div className="mt-2 flex flex-row h-[120px]">
                            {/* 簡要訪視 */}
                            <Field label={"簡要訪視"}>
                                <TextArea
                                    // value={}
                                    // onChange={(e) =>{}}
                                />
                            </Field>
                        </div>
                        <div className="mt-2 flex flex-row h-[120px]">
                            {/* 注意事項 */}
                            <Field label={"注意事項"}>
                                <TextArea
                                    // value={}
                                    // onChange={(e) =>{}}
                                />
                            </Field>
                        </div>
                        <div className="mt-2 flex flex-row h-[120px]">
                            {/* 備註 */}
                            <Field label={"備註"}>
                                <TextArea
                                    // value={}
                                    // onChange={(e) =>{}}
                                />
                            </Field>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
            {/* 看診資料查詢popup */}
            {
                showPopup && (
                    <HomePotentialPopup closePopup={handleCloseShowPopup}/>
                )
            }

        </>
    )
}
export default HomePatentialCase
import {
    ApiErrorStatusEnum, objectIsEmpty,
    PopupModeEnum,
    RegsMedicalTypeEnum,
    RegsWeekModeEnum,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
    stringIsEmpty,
    TimeslotEnum
} from "edah_utils/dist"
import {t} from "i18next"
import React, {useEffect, useRef, useState} from "react"
import {addRegsWeek, delRegsWeek, editRegsWeek, regsCheckForWeekDuplicate, regsCheckBeforeAddClinicByRsis} from "../../../../api/v1/Regs"
import {sloganQueryContentForWeekOrClinic} from "../../../../api/v1/Slogn"
import DeletePromptPopup from "./DeletePromptPopup"
import useToast from "../../../../hooks/useToast"
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    ColorEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    RadioGroup,
    Select,
    SizeEnum,
    TextArea,
    TextField,
    Search
} from 'edah-component/dist'
import dayjs from 'dayjs';
import {SelectWidthEnum} from "edah-component";
import {CommonColorEnum} from "edah-component/dist/index";

/**
 * 新增週維護彈出視窗
 * @param mode {PopupModeEnum} 新增或修改
 * @param filterMode {Number} 篩選模式
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param baseData {Object} 基本資料
 * @param handleCreateSuccess {Function} 新增成功時
 * @param handleSaveSuccess {Function} 儲存成功時
 * @param handleDeleteSuccess {Function} 刪除成功時
 */
export const AddWeekMaintenancePopup = ({
                                            mode,
                                            filterMode,
                                            data,
                                            closePopup,
                                            baseData,
                                            handleCreateSuccess,
                                            handleSaveSuccess,
                                            handleDeleteSuccess
                                        }) => {
    const baseSettings = {
        //
        weekId: data.weekId ? data.weekId : '',
        // 預約最大看診人次
        maxPatient: data.maxPatient ? data.maxPatient.toString() : '100',
        // 現場最大看診人次
        onSiteNumber: data.onSiteNumber ? data.onSiteNumber.toString() : '100',
        // 看診時間(人/分)
        consultationTime: data.consultationTime ? data.consultationTime.toString() : '5',
        // 預約方式
        reservationMethod: data.reservationMethod ? data.reservationMethod : RegsWeekReservationMethodEnum.ClinicNumber,
        // 調病歷
        canGetMedicalRecords: data.canGetMedicalRecords ? data.canGetMedicalRecords : false,
        // 給號方式
        numberGivingMethod: data.numberGivingMethod ? data.numberGivingMethod : "9",
        // 診間開始展班日期
        effectiveDate: data.effectiveDate ? data.effectiveDate : "",
        // 收費別
        chargeType: data.chargeType ? data.chargeType : `${baseData.cashTypeList[0]['cashType']} ${baseData.cashTypeList[0]['cashName']}`,
        // 預約限制
        reservationLimit: data.reservationLimit ? data.reservationLimit : RegsWeekReservationLimitEnum.Normal,
        // 診別
        medicalType: data.medicalType ? data.medicalType : "O",
        // 展班月數
        exhibitionMonth: data.exhibitionMonth ? data.exhibitionMonth : 6,
        // 假日展班
        holidayExhibition: data.holidayExhibition ? data.holidayExhibition : false,
        // 開放網掛/App掛號
        webOrAppBooking: data.webOrAppBooking ? data.webOrAppBooking : false,
        // 開放網掛/App取消看診進度
        webOrAppCancel: data.webOrAppCancel ? data.webOrAppCancel : false,
        //網路預約期限
        onlineBookingDeadline: data.onlineBookingDeadline ? data.onlineBookingDeadline : 30,
        //看診項目
        consultationItems: data.consultationItems ? data.consultationItems : "empty",
        //診間地點
        clinicLocation: data.clinicLocation ? data.clinicLocation : "",
        //注意事項
        notes: data.notes ? data.notes : "",
        //診間標語
        clinicSlogan: data.clinicSlogan ? data.clinicSlogan : "",
        //醫生或科別
        doctorOrDepartment: data.doctorOrDepartment ? data.doctorOrDepartment : null,
        //診室名稱
        clinicName: data.clinicName ? data.clinicName : "",
        //時段
        timeslot: data.timeslot ? data.timeslot : null,
        //星期幾
        week: data.week ? data.week : null,
        // 醫師
        doctor: data.doctor ? data.doctor : "",
        // 科別
        department: data.department ? data.department : "",

        lockVersion: data.lockVersion
    }

    //展班月數Option
    const exhibitionMonthOptions = Array.from({length: 12}, (_, i) => i + 1);
    //當前設定
    const [record, setRecord] = useState(baseSettings)
    //是否可以修改醫生欄位
    const [canModifyDoctor, setCanModifyDoctor] = useState(true)
    //是否可以修改科別欄位
    const [canModifyDepartment, setCanModifyDepartment] = useState(true)
    // 顯示刪除提示popup
    const [showDeletePrompt, setShowDeletePrompt] = useState(false)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()
    // 給予收費別欄位顯示資訊
    const [chargeTypeValue, setChargeTypeValue] = useState(data.chargeType ? data.chargeType : baseData.cashTypeList[0]);
    // 給予醫師欄位顯示資訊
    const [doctorValue, setDoctorValue] = useState(null);//{userNo:'',userName:''}
    // 給予科別欄位顯示資訊
    const [departmentValue, setDepartmentValue] = useState(null);//{divNo: '', divName: ''}
    // 是否顯示醫師報備稽核失敗Dialog
    const [showCheckReportFailDialog, setShowCheckReportFailDialog] = useState(false)
    // 醫師報備稽核失敗原因
    const [checkReportFailReason, setCheckReportFailReason] = useState("")

    /**
     * 搜尋文字改變時
     * @param newValue {String}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (newValue) => {
        //可以修改醫生時
        if (canModifyDoctor) {
            //設定醫生值
            setRecord({...record, doctor: newValue})
        }
    }

    /**
     * 醫師選項點擊事件
     * @param option {Object} 選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            let sloganText = ''
            if (!stringIsEmpty(record.department) && record.department.split(' ')[0] !== null &&
                !stringIsEmpty(option.userNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(option.userNo, record.department.split(' ')[0])
            }
            //設定醫生 & 標語
            setRecord({...record, doctor: `${option.userNo} ${option.userName}`, clinicSlogan: sloganText})
        } else {
            //設定醫生值
            setRecord({...record, doctor: ''})
        }
    }

    /**
     * 取得過濾後的醫生選項
     * @return {Array} 過濾後的醫生選項
     */
    const getFilterDoctorOptions = () => {
        //可以修改醫生時
        if (true) {
            //取得搜尋文字
            const searchText = record?.doctor || ''
            // 搜尋文字為空
            if (searchText === '' || searchText === null) {
                return baseData?.doctorList
            } else { //搜尋文字不為空
                //是否有空白
                const hasSpace = searchText?.indexOf(' ') >= 0
                //分割搜尋文字
                const splitSearchTextArray = searchText?.split(' ')

                return baseData.doctorList.filter(doctor => hasSpace ?
                    (doctor.userNo.includes(splitSearchTextArray[0]) || doctor.userName.includes(splitSearchTextArray[1])) :
                    (doctor.userNo.includes(searchText) || doctor.userName.includes(searchText))
                )
            }
        } else { //不可以修改醫生
            return []
        }
    }

    /**
     * 科別搜尋文字改變時
     * @param newValue {String}
     */
    const handleDepartmentSearchTextOnChange = (newValue) => {
        //可以修改科別時
        if (canModifyDepartment) {
            //設定科別值
            setRecord({...record, department: newValue})
        }
    }

    const getSloganContentForWeekOrClinic = async (doctoorNo, divNo) => {
        let sloganText = ''
        await sloganQueryContentForWeekOrClinic({
            //醫師代碼
            doctorNo: doctoorNo,
            //科別代碼
            divNo: divNo,
            //時段
            apn: record.timeslot
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //成功
                sloganText = stringIsEmpty(res.data) ? "" : res.data
            } else {
                //失敗時
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
        return sloganText
    }

    /**
     * 取得科別選項
     * @return {Array} 科別選項
     */
    const getFilterDepartmentOptions = () => {
        //取得搜尋文字
        const searchText = record?.department || ''

        //搜尋文字為空
        if (searchText === '' || searchText === null) {
            return baseData.divisionList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText?.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText?.split(' ')

            return baseData.divisionList.filter(department => hasSpace ?
                (department.divNo.includes(splitSearchTextArray[0]) || department.divName.includes(splitSearchTextArray[1])) :
                (department.divNo.includes(searchText) || department.divName.includes(searchText))
            )
        }
    }

    /**
     * 科別選項點擊事件
     * @param option {Object} 選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = async (option) => {
        // 選項不為空
        if (option !== null) {
            const departmentText = `${option.divNo} ${option.divName}`
            let sloganText = ''
            if (!stringIsEmpty(record.doctor) && record.doctor.split(' ')[0] !== null &&
                !stringIsEmpty(option.divNo)) {
                //取得標語
                sloganText = await getSloganContentForWeekOrClinic(record.doctor.split(' ')[0], option.divNo)
            }
            //變更科別時自動變更診間名稱: 科別+診
            const clinicName = `${option.divName}診`
            //設定科別、標語、診間名稱
            setRecord({
                ...record,
                department: departmentText,
                clinicSlogan: sloganText,
                clinicName: clinicName
            })
        } else{
            //設定科別值
            setRecord({...record, department: ''})
        }
    }

    /**
     * 最大看診人次欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleMaxPatientOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定最大看診人次
            setRecord({...record, maxPatient: value.toString()})
        } else {
            setRecord({...record, maxPatient: ''})
        }
    }

    /**
     * 現場最大看診人次欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOnSiteNumberOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定現場最大看診人次
            setRecord({...record, onSiteNumber: value.toString()})
        } else {
            setRecord({...record, onSiteNumber: ''})
        }
    }

    /**
     * 看診時間(分/人)欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleConsultationTimeOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {
            //設定看診時間
            setRecord({...record, consultationTime: value.toString()})
        } else {
            setRecord({...record, consultationTime: ''})
        }
    }

    /**
     * 預約方式欄位改變時
     * @param type {Number} 預約方式種類
     * @return {void}
     */
    const handleReservationMethodOnChange = (type) => setRecord({...record, reservationMethod: type})

    /**
     * 給號方式欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNumberGivingMethod = (value) => setRecord({...record, numberGivingMethod: value})

    /**
     * 預約限制欄位改變時
     * @param type {Number} 預約限制種類
     * @return {void}
     */
    const handleReservationLimitOnChange = (type) => setRecord({...record, reservationLimit: type})

    /**
     * 診室名稱欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicNameOnChange = (e) => setRecord({...record, clinicName: e.target.value})

    /**
     * 診間開始展班日期改變時
     * @param {Dayjs | null} date 選定的日期
     * @return {void}
     */
    const handleEffectiveDateOnChange = (date) => {
        setRecord({
            ...record,
            effectiveDate: date.format('YYYY-MM-DD'),
        });
    };

    /**
     * 診室地點改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicLocationOnChange = (e) => setRecord({...record, clinicLocation: e.target.value})

    /**
     * 診別欄位改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleMedicalTypeOnChange = (value) => setRecord({...record, medicalType: value})

    /**
     * 調病歷改變時
     * @return void
     */
    const handleCanGetMedicalRecordsOnChange = () => setRecord({
        ...record,
        canGetMedicalRecords: !record.canGetMedicalRecords
    })

    /**
     * 收費別搜尋文字改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleChargeTypeSearchTextOnChange = (e) => setRecord({...record, chargeType: e?.target?.value || ''})

    /**
     * 網掛/APP看診進度改變時
     * @return {void}
     */
    const handleWebOrAppBookingOnChange = () => setRecord({...record, webOrAppBooking: !record.webOrAppBooking})

    /**
     * 取得收費別filter後的資料
     * @return {Array}
     */
    const getFilterChargeTypeOptions = () => {
        //取得搜尋文字
        const searchText = record?.chargeType || ''

        //搜尋文字為空
        if (searchText === '') {
            return baseData.cashTypeList
        } else { //搜尋文字不為空
            //是否有空白
            const hasSpace = searchText.indexOf(' ') >= 0
            //分割搜尋文字
            const splitSearchTextArray = searchText.split(' ')

            return baseData.cashTypeList.filter(cash => hasSpace ?
                (cash.cashType.includes(splitSearchTextArray[0]) || cash.cashName.includes(splitSearchTextArray[1])) :
                (cash.cashType.includes(searchText) || cash.cashName.includes(searchText))
            )
        }
    }
    /**
     * 收費別選項點擊事件
     * @param cash {Object} 選項
     * @return {void}
     */
    const handleChargeTypeOptionOnClick = (option) => {
        // 選項不為空
        if (option !== null) {
            //設定收費別
            setRecord({...record, chargeType: `${option.cashType} ${option.cashName}`})
        }
    }

    /**
     * 網掛/APP取消看診進度改變時
     * @return {void}
     */
    const handleWebOrAppCancelOnChange = () => setRecord({...record, webOrAppCancel: !record.webOrAppCancel})

    /**
     * 網掛預約期限改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleOnlineBookingDeadlineOnChange = (e) => {
        // 取得數值
        const value = parseInt(e.target.value)
        // 數值大於等於0
        if (value >= 0) {

            setRecord({...record, onlineBookingDeadline: value})
        }
    }

    /**
     * 備註改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNotesOnChange = (e) => setRecord({...record, notes: e.target.value})

    /**
     * 診間標語改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleClinicSloganOnChange = (e) => setRecord({...record, clinicSlogan: e.target.value})

    /**
     * 看診項目欄位改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleConsultationItemsOnChange = (value) => setRecord({...record, consultationItems: value})

    /**
     * 展班月數欄位改變時
     * @param {Event} e 事件
     * @returns
     */
    const handleExhibitionMonthOnChange = (value) => setRecord({...record, exhibitionMonth: value})

    /**
     * 假日展班改變時
     * @returns
     */
    const handleHolidayExhibitionOnChange = () => setRecord({...record, holidayExhibition: !record.holidayExhibition})

    /**
     * 點擊新增按鈕
     * @return {void}
     */
    const handleSaveOnClick = async() => {
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add
        if (isAdd) {
            if (stringIsEmpty(record.effectiveDate)) {
                //診間開始展班日期為空時
                showToast({message: "診間開始展班日期不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.doctor) || record.doctor.split(' ')[0] === null) {
                //醫師為空時
                showToast({message: "醫師不可為空", type: AlertTypeEnum.Error})
                return
            }
            else if (record.doctor.split(' ').length === 2) {
                const fundedDoctor = baseData.doctorList.find(item => item.userNo === record.doctor.split(" ")[0])
                if (objectIsEmpty(fundedDoctor)) {
                    showToast({
                        type: AlertTypeEnum.Error,
                        message: "此醫師不存在",
                    });
                    return;
                }
            }

            if (stringIsEmpty(record.department) || record.department.split(' ')[0] === null) {
                //科別為空時
                showToast({message: "科別不可為空", type: AlertTypeEnum.Error})
                return
            }
            else if (record.department.split(" ").length === 2) {
                const fundedDiv = baseData.divisionList.find(item => item.divNo === record.department.split(" ")[0])
                if (objectIsEmpty(fundedDiv)) {
                    showToast({
                        type: AlertTypeEnum.Error,
                        message: "此科別不存在",
                    });
                    return;
                }
            }

            if (stringIsEmpty(record.clinicName)) {
                //診室地點為空時
                showToast({message: "診室名稱不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.clinicLocation)) {
                //診室地點為空時
                showToast({message: "診室地點不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.chargeType)) {
                //收費別為空時
                showToast({message: "收費別不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                //收費別為空時
                showToast({message: "預約最大看診人次有誤", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.onSiteNumber) || parseInt(record.onSiteNumber) <= 0) {
                //收費別為空時
                showToast({message: "現場最大看診人次有誤", type: AlertTypeEnum.Error})
                return
            }
            if (stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                //收費別為空時
                showToast({message: "看診時間有誤", type: AlertTypeEnum.Error})
                return
            }

            //醫師報備稽核
            const ret = await checkBeforeAddClinicByRsis()
            if(ret.err && !stringIsEmpty(ret.msg)) {
                //稽核失敗，顯示稽核失敗的dialog
                setShowCheckReportFailDialog(true)
                setCheckReportFailReason(ret.msg)
                return
            }

            doAddRegsWeek()
        } else {
            if (stringIsEmpty(record.effectiveDate)) {
                //診間開始展班日期為空時
                showToast({message: "診間開始展班日期不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.clinicName)) {
                //診室地點為空時
                showToast({message: "診室名稱不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.clinicLocation)) {
                //診室地點為空時
                showToast({message: "診室地點不可為空", type: AlertTypeEnum.Error})
                return
            }

            if (stringIsEmpty(record.chargeType)) {
                //收費別為空時
                showToast({message: "收費別不可為空", type: AlertTypeEnum.Error})
                return
            }
            if (stringIsEmpty(record.maxPatient) || parseInt(record.maxPatient) <= 0) {
                //收費別為空時
                showToast({message: "預約最大看診人次有誤", type: AlertTypeEnum.Error})
                return
            }
            if (stringIsEmpty(record.onSiteNumber) || parseInt(record.onSiteNumber) <= 0) {
                //收費別為空時
                showToast({message: "現場最大看診人次有誤", type: AlertTypeEnum.Error})
                return
            }
            if (stringIsEmpty(record.consultationTime) || parseInt(record.consultationTime) <= 0) {
                //收費別為空時
                showToast({message: "看診時間有誤", type: AlertTypeEnum.Error})
                return
            }
            //醫師報備稽核
            const ret = checkBeforeAddClinicByRsis()
            if(ret.err && !stringIsEmpty(ret.msg)) {
                //稽核失敗，顯示稽核失敗的dialog
                setShowCheckReportFailDialog(true)
                setCheckReportFailReason(ret.msg)
                return
            }
            //更新醫師週班
            doSaveEditRegsWeek()
        }
    }

    /**
     * 展班前確認報備支援
     * @returns {{err: boolean, msg: string}}
     */
    const  checkBeforeAddClinicByRsis = async() => {
        let msg = ""
        let err = false
        await regsCheckBeforeAddClinicByRsis({
            //診間展班生效日期
            effectiveDate: `${record.effectiveDate} 00:00:00`,
            //星期1,2,3,4,5,6,7
            week: `${record.week}`,
            //時段
            apn: record.timeslot,
            //醫師代號
            doctorNo: record.doctor.split(' ')[0],
            //診室名稱
            clinicName: record.clinicName,
            //科別代碼
            divNo: record.department.split(' ')[0],
            //預約最大看診人次
            limit: parseInt(record.maxPatient),
            //現場最大看診人次
            onsiteLimit: parseInt(record.onSiteNumber),
            //看診時間(分/人)
            slotMin: parseInt(record.consultationTime),
            //給號方式:1.診號，2.時間
            giveNumType: record.reservationMethod.toString(),
            //給號代碼
            noType: record.numberGivingMethod,
            //送病歷：0.FALSE , 1.TRUE
            sentChartFlag: record.canGetMedicalRecords,
            //診間收費別代碼
            cashType: record.chargeType.split(' ')[0],
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            reserveLimitType: record.reservationLimit,
            //診間類別：O.門診，E.急診，T.外診，,P.預防保健。
            clinicType: record.medicalType,
            //展班月數
            tranMonth: record.exhibitionMonth,
            //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            webapptFlag: record.webOrAppBooking,
            //網掛/App取消看診進度, 0.FALSE , 1.TRUE
            showProcessFlag: record.webOrAppCancel,
            //網掛預約期限(天)
            webapptDay: record.onlineBookingDeadline,
            //診室地點
            clinicLocation: record.clinicLocation,
            //看診項目
            clinicMarkNo: record.consultationItems === "empty" ? null : record.consultationItems,
            //週班注意事項
            weekNotice: record.notes,
            //診間標語
            clinicSlogan: record.clinicSlogan,
            //假日展班, 0.FALSE , 1.TRUE
            holidayexclFlag: record.holidayExhibition,
            lockVersion: record.lockVersion
        }).then(res => {
            if(!stringIsEmpty(res.data)) {
                msg = res.data
                err = true
            }
        })

        return {err: err, msg: msg};
    }

    /**
     * 執行週班新增
     */
    const doAddRegsWeek = () => {
        //存檔前檢查周班是否有重複同星期 & 時段 & 醫師
        //儲存醫師週班
        addRegsWeek({
            //診間展班生效日期
            "effectiveDate": `${record.effectiveDate} 00:00:00`,
            //星期1,2,3,4,5,6,7
            "week": `${record.week}`,
            //時段
            "apn": record.timeslot,
            //醫生編號
            "doctorNo": record.doctor.split(' ')[0],
            //診室名稱
            "clinicName": record.clinicName,
            //科別
            "divNo": record.department.split(' ')[0],
            //預約最大看診人次
            "limit": parseInt(record.maxPatient),
            //現場最大看診人次
            "onsiteLimit": parseInt(record.onSiteNumber),
            //看診時間(人/分)
            "slotMin": parseInt(record.consultationTime),
            //預約方式
            "giveNumType": record.reservationMethod.toString(),
            //給號代碼
            "noType": record.numberGivingMethod,
            //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
            "sentChartFlag": record.canGetMedicalRecords,
            //診間收費別
            "cashType": record.chargeType.split(' ')[0],
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            "reserveLimitType": record.reservationLimit,
            ////診間類別  O:門診,T:外檢,P,預防保健
            "clinicType": record.medicalType,
            //展班月數
            "tranMonth": record.exhibitionMonth,
            //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            "webapptFlag": record.webOrAppBooking,
            ////網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
            "showProcessFlag": record.webOrAppCancel,
            //網掛預約期限(天)
            "webapptDay": record.onlineBookingDeadline,
            //診室地點
            "clinicLocation": record.clinicLocation,
            //看診項目
            "clinicMarkNo": record.consultationItems === "empty" ? null : record.consultationItems,
            //週班注意事項
            "weekNotice": record.notes,
            //診間標語
            "clinicSlogan": record.clinicSlogan,
            //假日展班, 0.FALSE , 1.TRUE
            "holidayexclFlag": record.holidayExhibition,
            //鎖定版本
            "lockVersion": 0
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //新增成功
                const dayStr = getWeekStr(record.week.toString())
                const timeslotString = getTimeslotString(data.timeslot)
                const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${record.doctor.split(' ')[1]}`
                //回傳訊息帶有重複日期的訊息時，擷取重複日期作為判斷條件
                const duplicateMsg = res.msg.split(':')[1]
                //若重複日期長度大於1，代表有重複日期，顯示重複日期訊息
                duplicateMsg.length > 1 ? showToast({message: res.msg, type: AlertTypeEnum.Success}) : handleCreateSuccess(msg)
                //欄位值回復預設
                setRecord(baseSettings)

                //
            } else {
                //儲存失敗時
                showToast({message: res.msg, type: AlertTypeEnum.Error})
                return
            }
        })
    }

    /**
     * 執行週班編輯儲存
     */
    const doSaveEditRegsWeek = () => {
        editRegsWeek({
            //週班編號
            weekId: record.weekId,
            //診間展班生效日期
            effectiveDate: `${record.effectiveDate} 00:00:00`,
            //星期1,2,3,4,5,6,7
            week: `${record.week}`,
            //時段
            apn: record.timeslot,
            //醫生編號
            doctorNo: record.doctor.split(' ')[0],
            //診室名稱
            clinicName: record.clinicName,
            //科別
            divNo: record.department.split(' ')[0],
            //預約最大看診人次
            limit: parseInt(record.maxPatient),
            //現場最大看診人次
            onsiteLimit: parseInt(record.onSiteNumber),
            //看診時間(人/分)
            slotMin: parseInt(record.consultationTime),
            //預約方式
            giveNumType: record.reservationMethod.toString(),
            //給號代碼
            noType: record.numberGivingMethod,
            //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
            sentChartFlag: record.canGetMedicalRecords,
            //診間收費別
            cashType: record.chargeType.split(' ')[0],
            //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
            reserveLimitType: record.reservationLimit,
            ////診間類別  O:門診,T:外檢,P,預防保健
            clinicType: record.medicalType,
            //展班月數
            tranMonth: record.exhibitionMonth,
            //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
            webapptFlag: record.webOrAppBooking,
            ////網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
            showProcessFlag: record.webOrAppCancel,
            //網掛預約期限(天)
            webapptDay: record.onlineBookingDeadline,
            //診室地點
            clinicLocation: record.clinicLocation,
            //看診項目
            clinicMarkNo: record.consultationItems === "empty" ? null : record.consultationItems,
            //週班注意事項
            weekNotice: record.notes,
            //診間標語
            clinicSlogan: record.clinicSlogan,
            //假日展班, 0.FALSE , 1.TRUE
            holidayexclFlag: record.holidayExhibition,

            lockVersion: record.lockVersion
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //新增成功
                const dayStr = getWeekStr(record.week.toString())
                const timeslotString = getTimeslotString(data.timeslot)
                const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${record.doctor.split(' ')[1]}`
                handleSaveSuccess(msg)
            } else {
                //儲存失敗時
                showToast({message: res.msg, type: AlertTypeEnum.Error})
                return
            }
        })
    }

    /**
     * 醫師報備稽核失敗dialog的確認按鈕點擊事件
     */
    const handleCheckReportFailDialogConfirmOnClick = () => {
        //關閉稽核失敗dialog
        setShowCheckReportFailDialog(false)

        const isAdd = mode === PopupModeEnum.Add
        if (isAdd) {
            //開始新增
            doAddRegsWeek()
        }
        else {
            //開始儲存編輯
            doSaveEditRegsWeek()
        }
    }

    /**
     * 醫師報備稽核失敗dialog的取消按鈕點擊事件
     */
    const handleCheckReportFailDialogCancelOnClick = () => {
        //關閉稽核失敗dialog
        setShowCheckReportFailDialog(false)
    }

    /**
     * 點擊刪除按鈕
     * @return {void}
     */
    const handleDeleteOnClick = () => {
        setShowDeletePrompt(true)

    }

    const handleConfirmDeleteOnClick = () => {
        setShowDeletePrompt(false)

        delRegsWeek({
            weekId: record.weekId
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //刪除成功
                const dayStr = getWeekStr(data.week.toString())
                const timeslotString = getTimeslotString(data.timeslot)
                const msg = `${dayStr}，${timeslotString}，${t('general.doctor')}：${data.doctor.split(' ')[1]}`
                handleDeleteSuccess(msg)
            } else {
                //刪除失敗時
                showToast({message: res.msg, type: AlertTypeEnum.Error})
                return
            }
        })
    }

    const handleCancelDeleteOnClick = () => {
        setShowDeletePrompt(false)
    }

    const getWeekStr = (week) => {
        let dayStr
        switch (week) {
            case "1":
                dayStr = t('general.dateTime.weekRange.1')
                break
            case "2":
                dayStr = t('general.dateTime.weekRange.2')
                break
            case "3":
                dayStr = t('general.dateTime.weekRange.3')
                break
            case "4":
                dayStr = t('general.dateTime.weekRange.4')
                break
            case "5":
                dayStr = t('general.dateTime.weekRange.5')
                break
            case "6":
                dayStr = t('general.dateTime.weekRange.6')
                break
            case "7":
                dayStr = t('general.dateTime.weekRange.7')
                break
        }
        return dayStr
    }

    const getTimeslotString = (timeslot) => {
        let timeslotString = ""
        switch (timeslot) {
            case TimeslotEnum.Morning:
                timeslotString = t('general.dateTime.timeslot.full.morning')
                break
            case TimeslotEnum.Afternoon:
                timeslotString = t('general.dateTime.timeslot.full.afternoon')
                break
            case TimeslotEnum.Night:
                timeslotString = t('general.dateTime.timeslot.full.night')
                break
        }
        return timeslotString
    }

    /**
     * 取得標題的內容
     * @return {String | JSX.Element}
     */
    const getHeaderContent = () => {
        const dayStr = getWeekStr(data.week.toString())
        const timeslotString = getTimeslotString(data.timeslot)
        return (
            mode === PopupModeEnum.Add ?
                <>
                    {`${dayStr}，${timeslotString}`}
                </> :
                <>
                    {`${dayStr}，${timeslotString}，${t('general.department')}：${data.department}，${t('general.doctor')}：${data.doctor}，診室名稱：${data.clinicName}`}
                </>
        )
    }

    /**
     * 只執行一遍
     */
    useEffect(() => {
        if (mode === PopupModeEnum.Add) {
            // 為新增模式
            //為醫生模式且有待醫生資料
            if (+filterMode === RegsWeekModeEnum.Doctor && !stringIsEmpty(record.doctor)) {
                //分割醫生資料
                const splitArray = record.doctor.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.doctorList.findIndex(doctor => doctor.userNo === splitArray[0] && doctor.userName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDoctor(false)
                }
            } else if (+filterMode === RegsWeekModeEnum.Department && record.department) { //為科別模式且有待科別資料
                //分割科別資料
                const splitArray = record.department.split(' ')
                //是否有資料
                const hasData = splitArray.length === 2 ? baseData.divisionList.findIndex(department => department.divNo === splitArray[0] && department.divName === splitArray[1]) !== -1 : false
                //不給予修改
                if (hasData) {
                    setCanModifyDepartment(false)

                    //變更科別時自動變更診間名稱: 科別+診
                    const clinicName = `${splitArray[1]}診`
                    //設定科別、標語、診間名稱
                    setRecord((prev) =>({
                        ...prev,
                        clinicName: clinicName
                    }))
                }
            }
        } else {
            // 為修改模式
            // 不給予修改醫生
            setCanModifyDoctor(false)
            // 不給予修改科別
            setCanModifyDepartment(false)
        }
        // 取得掛號基礎資料

    }, [])

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getFilterDoctorOptions())
    }, [record.doctor])
    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getFilterDepartmentOptions())
    }, [record.department])

    /**
     * 監聽收費別是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        // 將字串轉換為物件
        const convertChargeType = (chargeTypeString) => {
            const [cashType, cashName] = chargeTypeString.split(' ');
            return {
                cashType: cashType,
                cashName: cashName
            };
        };
        if (record && record.chargeType) {
            const convertedChargeType = convertChargeType(record.chargeType);
            setChargeTypeValue(convertedChargeType);
        }
    }, [record])

    /**
     * 監聽醫師是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        const selectedDoctorArray = record.doctor.split( ' ')
        if (selectedDoctorArray.length === 2) {
            console.log(record.doctor)
            setDoctorValue({userNo:selectedDoctorArray[0], userName:selectedDoctorArray[1]})

            //稽核重複排班提示
            regsCheckForWeekDuplicate({
                apn: record.timeslot,
                doctorNo: selectedDoctorArray[0],
                week: record.week
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    if (!stringIsEmpty(res.data)) {
                        showToast({message: res.data, type: AlertTypeEnum.Warning})
                    }
                }
                else {
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }
        else {
            if (stringIsEmpty(record.doctor)) {
                setDoctorValue(null)
            }
        }
    }, [record.doctor]);

    /**
     * 監聽科別是否存在值，如果有，將其轉換為物件並渲染於畫面上
     */
    useEffect(() => {
        const selectedDivArray = record.department.split(' ')
        if(selectedDivArray.length ===2){
            setDepartmentValue({divNo: selectedDivArray[0], divName: selectedDivArray[1]})
        }
        else {
            if(stringIsEmpty(record.department)) {
                setDepartmentValue(null)
            }
        }
    }, [record.department]);

    // const getDefaultValue = () => {
    //    const data = getFilterChargeTypeOptions().find(option => record.chargeType.includes(option?.cashName || ''))
    //     return data // || getFilterChargeTypeOptions()[0]
    // }
    return (
        <>
            <Dialog
                open={true}
                title={mode === PopupModeEnum.Add ? t('components.Appointment.Popup.AddWeekMaintenancePopup.title') : "班表設定"}
                content={<div className="px-[24px] py-[16px]">
                    <div className="text-left justify-left flex flex-row justify-between w-full items-center px-5">
                        {
                            mode === PopupModeEnum.Add ?
                                <div className="grid grid-cols-[150px,calc((100%-150px)/2),calc((100%-150px)/2)]">
                                    <div className="text-xl flex items-center">
                                        {
                                            getHeaderContent()
                                        }
                                    </div>
                                    {/*醫師搜尋文字以及下拉式選單*/}
                                    <Field label="醫師" labelWidth={60}>
                                        <Search
                                            freeSolo
                                            clearOnBlur
                                            disabled={!canModifyDoctor}
                                            onChange={(_e, value) => {
                                                handleDoctorOptionOnClick(value)
                                            }}
                                            onInputChange={(e, value) =>
                                                handleDoctorSearchTextOnChange(value)
                                            }
                                            disablePortal={false}
                                            options={doctorOptionList}
                                            getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                                            value={doctorValue}
                                        />

                                    </Field>
                                    {/*科別搜尋文字以及下拉式選單*/}
                                    <Field label="科別" labelWidth={60}>
                                        <Search
                                            freeSolo
                                            clearOnBlur
                                            disabled={!canModifyDepartment}
                                            onChange={(_e, value) => {
                                                handleDepartmentOptionOnClick(value)
                                            }}
                                             onInputChange={(e, value) => {
                                                 handleDepartmentSearchTextOnChange(value)
                                            }}
                                            disablePortal={false}
                                            options={departmentOptionList}
                                            getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                                            value={departmentValue}
                                        />

                                    </Field>
                                </div> :
                                <div className="items-center text-center w-full text-xl font-normal">
                                    {getHeaderContent()}
                                </div>
                        }
                    </div>
                    <div className="grid grid-cols-[50%,50%] mt-4">
                        <div className="space-y-2">
                            {/* 預約最大看診人次 */}
                            <Field label="預約最大看診人次" labelWidth={145}>
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="max-people"
                                    value={record.maxPatient}
                                    min={0}
                                    onChange={(e) => handleMaxPatientOnChange(e)}
                                />
                            </Field>
                            {/* 現場最大看診人次 */}
                            <Field label="現場最大看診人次" labelWidth={145}>
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="current-people-count"
                                    value={record.onSiteNumber}
                                    min={0}
                                    onChange={(e) => handleOnSiteNumberOnChange(e)}
                                />
                            </Field>
                            {/* 看診時間(分/人) */}
                            <Field label="看診時間(分/人)" labelWidth={145}>
                                <TextField
                                    size={SizeEnum.Small}
                                    type="number"
                                    id="time-per-person"
                                    value={record.consultationTime}
                                    min={0}
                                    onChange={(e) => handleConsultationTimeOnChange(e)}
                                />
                            </Field>
                            {/* 預約方式 */}
                            <Field label="預約方式" labelWidth={145}>
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={record.reservationMethod} value={record.reservationMethod}
                                    optionProps={{
                                        options: [
                                            // 科別
                                            {
                                                label: t("Regs.general.viewNumber"),
                                                value: RegsWeekReservationMethodEnum.ClinicNumber
                                            },
                                            // 醫師
                                            {label: t("general.time"), value: RegsWeekReservationMethodEnum.Time}
                                        ]
                                    }}
                                    onChange={(e) => handleReservationMethodOnChange(e.target.value)}/>
                            </Field>
                            {/* 給號方式 */}
                            <Field label="給號方式" labelWidth={145}>
                                <Select
                                    data={{
                                        label: '給號方式',
                                        options: baseData?.noTypeList?.map((option) => ({
                                            value: option.noType,
                                            label: option.typeName
                                        })) || [],
                                    }}
                                    value={record.numberGivingMethod}
                                    onChange={handleNumberGivingMethod}
                                    showLabel={false}
                                    width={SelectWidthEnum.Tiny}
                                />
                            </Field>
                            {/* 預約限制 */}
                            <Field label="預約限制" labelWidth={145}>
                                <RadioGroup
                                    row size={SizeEnum.Medium}
                                    defaultValue={record.reservationLimit} value={record.reservationLimit}
                                    optionProps={{
                                        options: [
                                            // 一般
                                            {label: t("general.normal"), value: RegsWeekReservationLimitEnum.Normal},
                                            // 限醫師
                                            {label: '限醫師', value: RegsWeekReservationLimitEnum.DoctorOnly},
                                            // 限醫師本人
                                            {label: '限醫師本人', value: RegsWeekReservationLimitEnum.DoctorSelfOnly}
                                        ]
                                    }}
                                    onChange={(e) => handleReservationLimitOnChange(e.target.value)}/>
                            </Field>
                            {/* 診室開始展班日期 */}
                            <Field
                                label={mode === PopupModeEnum.Add ? "診室開始展班日期" : t("Regs.general.clinicName")}
                                labelWidth={145}
                            >
                                {
                                    mode === PopupModeEnum.Add ? (
                                        <div className=" flex justify-start">
                                            <DatePicker
                                                size="small"
                                                label=""
                                                disablePast
                                                value={record.effectiveDate ? dayjs(record.effectiveDate) : null}
                                                onChange={handleEffectiveDateOnChange}
                                            />
                                        </div>

                                    ) : (
                                        <TextField
                                            size={SizeEnum.Small}
                                            type="text" id="max-people" value={record.clinicName}
                                            onChange={(e) => handleClinicNameOnChange(e)}/>
                                    )
                                }
                            </Field>
                            {/* 診間開始展班日期 */}
                            <Field
                                label={
                                    mode === PopupModeEnum.Add ?
                                        t("Regs.general.clinicName") : "診間開始展班日期"
                                }
                                labelWidth={145}>
                                {
                                    mode === PopupModeEnum.Add ? (
                                        <TextField
                                            size={SizeEnum.Small}
                                            type="text" id="max-people" value={record.clinicName}
                                            onChange={(e) => handleClinicNameOnChange(e)}/>
                                    ) : (
                                        <div className=" flex justify-start">
                                            <DatePicker
                                                size="small"
                                                label=""
                                                value={record.effectiveDate ? dayjs(record.effectiveDate) : null}
                                                onChange={handleEffectiveDateOnChange}
                                            />
                                        </div>
                                    )
                                }
                            </Field>
                            {/* 診間位置 */}
                            <Field label={t("Regs.general.clinicLocation")} labelWidth={145}>
                                <TextField
                                    size={SizeEnum.Small}
                                    id="max-people"
                                    value={record.clinicLocation}
                                    onChange={(e) => handleClinicLocationOnChange(e)}
                                />
                            </Field>
                            {/* 診別 */}
                            <Field label="診別" labelWidth={145}>
                                <div className="flex flex-row space-x-4">
                                    {/*診別*/}
                                    <Select
                                        id="medical-type"
                                        data={{
                                            label: "診別",
                                            options: [
                                                {value: RegsMedicalTypeEnum.OutpatientClinic, label: '門診'},
                                                {value: RegsMedicalTypeEnum.MedicalCheckup, label: '外檢'},
                                                {value: RegsMedicalTypeEnum.PreventiveHealthcare, label: '預防保健'}
                                            ]
                                        }}
                                        value={record.medicalType}
                                        onChange={handleMedicalTypeOnChange}
                                        showLabel={false}
                                    />
                                    {/*調病歷*/}
                                    <Checkbox
                                        label="調病歷"
                                        checked={record.canGetMedicalRecords}
                                        onChange={handleCanGetMedicalRecordsOnChange}/>
                                </div>
                            </Field>
                        </div>
                        {/* 收費別 */}
                        <div className="space-y-2">
                            <Field label="收費別" labelWidth={130}>
                                <Search
                                    options={baseData.cashTypeList || []}
                                    getOptionLabel={(cash) => cash ? `${cash.cashType} ${cash.cashName}` : ''}
                                    onChange={(_e, value) => {
                                        handleChargeTypeOptionOnClick(value)
                                        setChargeTypeValue(value)
                                    }}
                                    value={chargeTypeValue}
                                    // onInputChange={(e) =>
                                    //     handleChargeTypeSearchTextOnChange(e)
                                    // }
                                    // disablePortal={false}
                                    // options={getFilterChargeTypeOptions()}
                                    // getOptionLabel={(cash) => `${cash.cashType} ${cash.cashName}`}
                                />
                            </Field>
                            {/* 網掛/APP */}
                            <div className="w-full flex">
                                <div className="w-[145px]">
                                </div>
                                <div>
                                    <Checkbox
                                        label="網掛/APP"
                                        checked={record.webOrAppBooking}
                                        onChange={handleWebOrAppBookingOnChange}/>
                                </div>
                            </div>
                            {/* 網掛/APP取消看診進度 */}
                            <div className="w-full flex">
                                <div className="w-[145px]">
                                </div>
                                <div>
                                    <Checkbox
                                        label="網掛/APP取消看診進度"
                                        checked={record.webOrAppCancel}
                                        onChange={handleWebOrAppCancelOnChange}/>
                                </div>
                            </div>
                            {/* 網掛預約期限 */}
                            <Field label="網掛預約期限" labelWidth={130}>
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Small}
                                    type="number"
                                    value={record.onlineBookingDeadline}
                                    min={0}
                                    onChange={(e) => handleOnlineBookingDeadlineOnChange(e)}
                                />
                                <span className="vertical-middle">天</span>
                            </Field>
                            {/*看診項目*/}
                            <Field label="看診項目" labelWidth={130}>
                                <Select
                                    data={{
                                        label: "看診項目",
                                        options: [
                                            {value: "empty", label: "請選擇看診項目"}, // 空白項
                                            ...baseData.clinicMarkList?.map((option) => ({
                                                value: option.justnameNo,
                                                label: option.justnameName
                                            })) || []]
                                    }}
                                    value={record.consultationItems}
                                    onChange={handleConsultationItemsOnChange}
                                    showLabel={false}
                                />
                            </Field>
                            {/*注意事項*/}
                            <Field label={"注意事項"} labelWidth={130} htmlFor="notes">
                                <TextArea
                                    id="notes" value={record.notes}
                                    onChange={(e) => handleNotesOnChange(e)}
                                />
                            </Field>
                            {/* 展班月數 */}
                            <Field label="展班月數" labelWidth={130}>
                                <div className="flex items-center space-x-3">
                                    <Select
                                        data={{
                                            label: "展班月數",
                                            options:
                                                exhibitionMonthOptions?.map((option) => ({
                                                    value: option,
                                                    label: option
                                                }))
                                        }}
                                        value={record.exhibitionMonth}
                                        showLabel={false}
                                        onChange={handleExhibitionMonthOnChange}
                                        width={SelectWidthEnum.Small}
                                    />
                                    <Field label="假日展班" labelWidth={80}>
                                        <Checkbox
                                            checked={record.holidayExhibition}
                                            onChange={handleHolidayExhibitionOnChange}
                                        />
                                    </Field>
                                </div>
                            </Field>
                            {/*診間標語*/}
                            <Field label={"診間標語"} labelWidth={130} htmlFor="clinicSlogan">
                                <TextArea
                                    id="clinicSlogan"
                                    value={record.clinicSlogan}
                                    onChange={(e) => handleClinicSloganOnChange(e)}
                                />
                            </Field>
                        </div>
                    </div>
                    {
                        mode === PopupModeEnum.Modify &&
                        <div className="items-center w-full flex justify-center mt-3">
                            <Button
                                color={ColorEnum.Error} variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Medium}
                                onClick={handleDeleteOnClick}>
                                {t('general.delete')}
                            </Button>
                        </div>
                    }
                </div>}
                variant={DialogVariant.EDIT}
                paperStyleProps={{width: DialogSizeEnums.LG}}
                onClose={closePopup}
                onSave={handleSaveOnClick}
                muiDialogContentProps={{dividers: true}}
            />
            {
                //確定刪除提示
                <DeletePromptPopup
                    promptText={getHeaderContent()}
                    show={showDeletePrompt}
                    confirmOnClick={handleConfirmDeleteOnClick}
                    cancelOnClick={handleCancelDeleteOnClick}/>
            }

            {
                //醫師報備稽核失敗Dialog
                <Dialog
                    open={showCheckReportFailDialog}
                    title={"提醒"}
                    content={checkReportFailReason}
                    variant={DialogVariant.DECISION}
                    onClose={handleCheckReportFailDialogCancelOnClick}
                    onConfirm={handleCheckReportFailDialogConfirmOnClick}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                />
            }
        </>
    )
}

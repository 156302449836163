import React, {useEffect, useMemo, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String,} from "edah_utils/dist";
import {
    regsAddReg,
    regsQueryBasicDataClinicRes,
    regsReportPappt,
    regsReportGetFile,
    regsCheckAlertWhenChangeClinicBeforeReg,
    regsCheckDuplicateClinicByPatientAndTimeAndDocAndDiv
} from "../../api/v1/Regs";
import {checkRegsAddPermissionsByLoginUser} from "../../api/v1/Menu";
import {regsAddFirstview} from "../../api/v1/RegsFirstView";
import ClinicSchedule from "./AppointmentSchedule/AppointmentPopup/ClinicSchedule";
import {ShowGiveNumberPopup} from "./AppointmentSchedule/ShowGiveNumberPopup";
import ClinicVisitsNumberStatePopup from "./Popup/ClinicVisitsNumberStatePopup";
import WeeklySchedule from "./AppointmentSchedule/AppointmentPopup/WeeklySchedule";
import useToast from "../../hooks/useToast";
import {isCurrentDateTimeWithinRange} from "./utils";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    Card,
    Checkbox,
    ColorEnum,
    DatePicker,
    Divider,
    Field,
    RadioGroup,
    RedColorEnum,
    Search,
    Select,
    SizeEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist"
import {t} from "i18next";
import AppointmentCalendar from "../Regs/Popup/AppointmentCalendarPopup";
import dayjs from "dayjs";

/**
 * 看診資料
 * @param patientData {Object} 病人資料
 * @param showData {Boolean} 是否顯示資料
 * @param baseData {Object} 基本資料
 * @param updateGetRegsQueryRegHistory {Function} 更新取得病人預約資訊(看診資料)的函數
 * @param setIsPhoneReg {Function} 設定是否為電話預約
 */
const AppointmentInfo = ({
                             patientData,
                             showData,
                             baseData,
                             updateGetRegsQueryRegHistory,
                             triggerResetDefault,
                             appointmentData,
                             setAppointmentData,
                             resetAppointmentData,
                         }) => {
    // 醫師選項
    const [doctorOptions, setDoctorOptions] = useState([]);
    // 是否顯示醫師選項
    const [showDoctorOptions, setShowDoctorOptions] = useState(false);
    // 診間選項
    const [clinicOptions, setClinicOptions] = useState([]);
    // 科別選項
    const [divOptions, setDivOptions] = useState([]);
    // 是否顯示科別選項
    const [showDivOptions, setShowDivOptions] = useState(false);
    // 是否顯示診間當日班表
    const [showClinicSchedule, setShowClinicSchedule] = useState(false);
    //當日班表資料
    const [clinicOfDayScheduleData, setClinicOfDayScheduleData] =
        useState(null);
    //是否顯示給診號彈窗
    const [showGiveNumberPopup, setShowGiveNumberPopup] = useState(false);
    //給給診號彈窗資料
    const [giveNumberData, setGiveNumberData] = useState({});
    //給診號按鈕是否不能按
    const [disableAddRegsButton, setDisableAddRegsButton] = useState(true);
    //有無診室
    const [haveClinic, setHaveClinic] = useState(false);
    //加掛按鈕是否不能按
    const [disableExtraAddRegsButton, setDisableExtraAddRegsButton] =
        useState(true);
    const [showClinicNoStatePopup, setShowClinicNoStatePopup] = useState(false);
    // 是否顯示當周班表彈出框
    const [showWeeklySchedulePopup, setShowWeeklySchedulePopup] =
        useState(false);
    // 周班表查詢基本資料
    const [weeklyScheduleBaseData, setWeeklyScheduleBaseData] = useState(null);
    // 掛號別List
    const [registrationList, setRegistrationList] = useState(null);
    // 過濾後的科別選項
    const [filterDivOptions, serFilterDivOptions] = useState([]);
    /**
     * 過濾後的醫師選項
     * displayName : 顯示的名稱
     * doctorName : 醫師名稱
     * doctorNo : 醫師代號
     * locumDoctor : 代診醫師代號
     * locumDoctorName : 代診醫師名稱
     * stopClinicFlag : 是否停診
     */
        //
    const [filterDoctorOptions, serFilterDoctorOptions] = useState([]);
    //Toast Message Hooks
    const showToast = useToast();
    // 顯示時間日曆
    const [showCalendar, setShowCalendar] = useState(false);
    const [doctorValue, setDoctorValue] = useState({displayName: ''})
    const [divValue, setDivValue] = useState({divNo: '', divName: ''})
    // 是否顯示未成年提示彈出框
    const [showUnderageDialog, setShowUnderageDialog] = useState(false)
    // 是否顯示病人重複掛號(同天同時段同科同醫師)彈出框
    const [showPatientDuplicateRegMessagePopup, setShowPatientDuplicateRegMessagePopup] = useState(false)
    // 病人重複掛號訊息
    const [patientDuplicateRegMsg, setPatientDuplicateRegMsg] = useState('')
    // 補輸日期晚於現在日期
    const [showIsAfterNowDatePopup, setshowIsAfterNowDatePopup] = useState(false)
    // 是否有加掛權限
    const [extraAddRegsPermission, setExtraAddRegsPermission] = useState(null)
    // 是否顯示加掛確認彈出框
    const [showExtraAddRegsConfirm, setShowExtraAddRegsConfirm] = useState(false);
    // 確認加掛彈窗步驟
    const [confirmStep, setConfirmStep] = useState(1);

    /**
     * 處理看診資料變更
     * @param v {Number} 時段
     * @return {void}
     */
    const handleApnOnClick = (v) => {
        // 現在日期
        const presentDate = dayjs().format('YYYY-MM-DD');
        // 所選日期
        const selectedDate = dayjs(appointmentData.encounterDate).format('YYYY-MM-DD');

        // 判斷是否為補輸，若補輸當日之時段早於當下時段，則顯示彈窗
        if(appointmentData.fowardRegFlag === true && presentDate === selectedDate) {
            // 現在時段轉型為 number
            const presentSlot = parseInt(baseData.apn);
            // 所取時段轉型為 number
            const v2Int = parseInt(v);

            // 判斷是否為早於當下時段， 1 為早， 2 為午 ， 3 為晚
            if(presentSlot === 1) {
                // 不得早於選擇時段 1(早)
                if(v2Int !== 1){
                    setshowIsAfterNowDatePopup(!showIsAfterNowDatePopup)
                    return
                }

            }else if(presentSlot === 2) {
                // 不得早於選擇時段為 2(午)
                if(v2Int === 3){
                    setshowIsAfterNowDatePopup(!showIsAfterNowDatePopup)
                    return
                }
            }
            setAppointmentData({
                ...appointmentData,
                apn: parseInt(v),
                //醫師代碼
                doctorNo: "",
                //科別代碼
                divNo: "",
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: "",
            });
        }else{
            setAppointmentData({
                ...appointmentData,
                apn: parseInt(v),
                //醫師代碼
                doctorNo: "",
                //科別代碼
                divNo: "",
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: "",
            });
        }


    };

    /**
     * 處理院區變更
     * @param e {Event} 事件
     * @return {void}
     */
    const handleZoneOnChange = (value) =>
        setAppointmentData({...appointmentData, zone: value});

    /**
     * 處理補輸變更時
     * @return {void}
     */
    const handleFowardRegFlagOnChange = () =>
        setAppointmentData({
            ...appointmentData,
            fowardRegFlag: !appointmentData.fowardRegFlag,
        });
    /**
     * 處理補輸變更時disablePast狀態
     * @return {void}
     */
    const disablePast = !appointmentData.fowardRegFlag;

    /**
     * 處理補輸變更時 disableFuture 狀態
     * @return {void}
     */
    const disableFuture = appointmentData.fowardRegFlag;

    /**
     * 處理掛號別變更時
     * @param  value {String} 掛號別
     * @return {void}
     */
    const handleReserveTypeOnChange = (value) => {
        setAppointmentData({
            ...appointmentData,
            reserveType: value,
            //掛號別為電話時，要勾選預約掛號
            phoneRegFlag: value === "3",
        });
    };

    /**
     * 處理身份別變更時
     * @param  value {String} 身份別
     * @return {void}
     */
    const handlePtTypeOnChange = (value) => {
        setAppointmentData({...appointmentData, ptType: value});
    };

    /**
     * 處理電話預約變更時
     * @return {void}
     */
    const handlePhoneRegFlagOnChange = () => {
        const flag = !appointmentData.phoneRegFlag;
        let reserveType = appointmentData.reserveType;
        if (flag === false) {
            //勾掉時，掛號別改為現掛或預約
            reserveType = isCurrentDateTimeWithinRange(
                appointmentData.encounterDate,
                parseInt(appointmentData.apn)
            )
                ? "1"
                : "2";
        } else {
            //勾選時，掛號別要改為電話
            reserveType = "3";
        }
        setAppointmentData({
            ...appointmentData,
            phoneRegFlag: flag,
            reserveType: reserveType,
        });
    };

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const divText = appointmentData?.divNo?.trim();
        const doctorText = appointmentData?.doctorNo?.trim();
        //切割字串
        const splitArray = doctorText ? doctorText.split(" ") : [];
        //已經有選擇醫師
        if (splitArray.length === 2) {
            return doctorOptions.filter((doctor) => {
                return doctor.displayName.includes(doctorText)
            });
        } else {
            //透過輸入文字
            // 科別選項為空時
            if (objectIsEmpty(divText)) {
                // 醫師選項為空時
                if (stringIsEmpty(doctorText)) {
                    return doctorOptions;
                } else {
                    // 醫師選項不為空時
                    return doctorOptions.filter((doctor) => {
                        //無代班醫生，輸入的text與醫生號碼或醫生名稱是否與其中一個一樣
                        const doctorMatch =
                            (stringIsEmpty(doctor.locumDoctor) ||
                                stringIsEmpty(doctor.locumDoctorName)) &&
                            (doctor.doctorNo.includes(doctorText) ||
                                doctor.doctorName.includes(doctorText));
                        //代班醫生，輸入的text與代班醫生號碼或代班醫生名稱是否與其中一個一樣
                        const locumMatch =
                            !stringIsEmpty(doctor.locumDoctor) &&
                            !stringIsEmpty(doctor.locumDoctorName) &&
                            (doctor.locumDoctor.includes(doctorText) ||
                                doctor.locumDoctorName.includes(doctorText));
                        return doctorMatch || locumMatch;
                    });
                }
            } else {
                // 科別選項不為空時
                //取得科別切割字串
                const splitDepartmentArray = divText.split(" ");
                //已經有選擇科別
                if (splitDepartmentArray.length === 2) {
                    if (splitArray.length === 0) {
                        //醫生為空
                        //求聯集
                        return doctorOptions
                            .filter((doctor) =>
                                clinicOptions.some(
                                    (clinic) =>
                                        clinic.doctorNo === doctor.doctorNo &&
                                        clinic.divNo === splitDepartmentArray[0]
                                )
                            )
                            .map((doctor) => new Object({...doctor}));
                    } else if (splitArray.length === 1) {
                        //醫生有1段
                        return doctorOptions
                            .filter((doctor) =>
                                clinicOptions.some(
                                    (clinic) =>
                                        clinic.doctorNo === doctor.doctorNo &&
                                        clinic.divNo === splitDepartmentArray[0]
                                )
                            )
                            .filter((doctor) => {
                                const doctorMatch =
                                    (stringIsEmpty(doctor.locumDoctor) ||
                                        stringIsEmpty(
                                            doctor.locumDoctorName
                                        )) &&
                                    (doctor.doctorNo.includes(doctorText) ||
                                        doctor.doctorName.includes(doctorText));
                                const locumMatch =
                                    !stringIsEmpty(doctor.locumDoctor) &&
                                    !stringIsEmpty(doctor.locumDoctorName) &&
                                    (doctor.locumDoctor.includes(doctorText) ||
                                        doctor.locumDoctorName.includes(
                                            doctorText
                                        ));
                                return doctorMatch || locumMatch;
                            })
                            .map((doctor) => new Object({...doctor}));
                    } else {
                        return [];
                    }
                } else {
                    //透過輸入文字
                    return []; //clinicOptions.filter(div => div.doctorNo === appointmentData.doctorNo)
                }
            }
        }
    };

    /**
     * 依照日期與APN查詢診室(包含醫生與科別清單)
     */
    const getRegsQueryBasicDataClinicRes = () => {
        //依照日期與APN查詢診室(包含醫生與科別清單)
        regsQueryBasicDataClinicRes({
            //門診日期
            encounterDate: time2String(
                appointmentData.encounterDate,
                "YYYY-MM-DD 00:00:00"
            ),
            //時段
            apn: appointmentData.apn,
        }).then((res) => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data;
                //找出有代診醫師的醫師array
                const haveLocumDoctorArray = data.doctorList.filter(
                    (doctor) =>
                        !stringIsEmpty(doctor.locumDoctor) &&
                        !stringIsEmpty(doctor.locumDoctorName)
                );
                //找出有多診間的醫師且有兩個狀態 代診、一般看診
                const fundedDoctorList = haveLocumDoctorArray
                    .filter((doctor) => {
                        const funded = data.clinicList.find(
                            (clinic) =>
                                clinic.doctorNo === doctor.doctorNo &&
                                stringIsEmpty(clinic.locumDoctor)
                        );
                        return !objectIsEmpty(funded);
                    })
                    .map(
                        (doctor) =>
                            new Object({
                                doctorNo: doctor.doctorNo,
                                doctorName: doctor.doctorName,
                                stopClinicFlag: doctor.stopClinicFlag,
                                locumDoctor: null,
                                locumDoctorName: null,
                            })
                    );
                //合併
                const combineDoctors = [...data.doctorList, ...fundedDoctorList]
                //增加displayName用於顯示用
                const finalDoctorArray = combineDoctors.map(doctor => ({
                    ...doctor,
                    displayName: getDoctorOptionDisplayName(doctor)
                }));
                //設定醫師選項
                setDoctorOptions(finalDoctorArray);
                //設定科別選項
                setDivOptions(data.divList);
                //設定診間選項
                setClinicOptions(data.clinicList);
            }
        });
    };

    /**
     * 處理醫師搜尋文字框變更事件
     * @param newValue {String}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (newValue) => {
        //取得值
        const value = newValue
        //值為空
        if (stringIsEmpty(value)) {
            // 設定醫師選項
            setAppointmentData((prev) =>( {
                ...prev,
                doctorNo: null,
                //是否有代診醫生
                isLocumDoctor: false,
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: "",
            }));
        } else {
            // 設定醫師選項
            setAppointmentData((prev) =>( {
                ...prev,
                doctorNo: value
            }));
        }
    };

    /**
     * 處理醫師搜尋文字框焦點事件
     * @return {void}
     */
    const handleDoctorSearchTextOnFocus = () => {
        serFilterDoctorOptions(getDoctorOptionList());
        setShowDoctorOptions(true);
    };

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            //是否有代診醫師
            const isLocumDoctor =
                !stringIsEmpty(option.locumDoctor) &&
                !stringIsEmpty(option.locumDoctorName);
            // 醫師編號
            const no = isLocumDoctor ? option.locumDoctor : option.doctorNo;
            // 醫師名稱
            const name = isLocumDoctor
                ? option.locumDoctorName
                : option.doctorName;
            //取得科別切割字串
            const splitDepartmentArray = appointmentData.divNo?.split(" ") || [];
            //已經有選擇科別
            if (splitDepartmentArray.length === 2) {
                //取得科別編號
                const id = splitDepartmentArray[0];
                //取得相關的診間資訊
                const resultArray = clinicOptions.filter((clinic) => {
                    let isMatch =
                        clinic.doctorNo === option.doctorNo &&
                        clinic.divNo === id;
                    if (isLocumDoctor && isMatch) {
                        isMatch = clinic.locumDoctor === option.locumDoctor;
                    }
                    return isMatch;
                });
                //只有數量等於1才顯示診間資訊
                if (resultArray.length === 1) {
                    //取得診間資訊
                    const clinicInfo = resultArray[0];
                    // 設定醫師選項
                    setAppointmentData({
                        ...appointmentData,
                        //月班識別碼
                        clinicId: clinicInfo.clinicId,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo:
                            getDoctorOptionDisplayName({
                               displayName: '',
                               doctorName: option.doctorName,
                               doctorNo: option.doctorNo,
                               locumDoctor: option.locumDoctor,
                               locumDoctorName: option.locumDoctorName,
                               stopClinicFlag: option.stopClinicFlag
                           }),
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //診間號
                        clinicNo: clinicInfo.clinicNo,
                        //診室名稱
                        clinicName: clinicInfo.clinicName,
                        //診室地點
                        clinicLocation: clinicInfo.clinicLocation,
                        //停診
                        stopClinicFlag: clinicInfo.stopClinicFlag,
                        //給號方式
                        giveNumType: clinicInfo.giveNumType,
                    });
                } else {
                    if (resultArray.length > 1) {
                        //有多個時顯示日班表
                        handleClinicScheduleOnClick();
                        setAppointmentData((prev) => ({
                            ...prev,
                            doctorNo: '',
                        }));
                    } else {
                        // 設定醫師選項
                        setAppointmentData((prev) => ({
                            ...prev,
                            //醫師編號 (有代診醫師就代入代診醫師的)
                            doctorNo: `${no} ${name}`,
                            //是否有代診醫師
                            isLocumDoctor: isLocumDoctor,
                        }));
                    }
                }
            } else {
                // 科別選項為空時，找尋此醫師只有一個診間時自動帶入科別診間資料
                //找尋此醫師診間
                const findedClinic = clinicOptions.filter((clinic) => {
                    let isMatch = clinic.doctorNo === option.doctorNo;
                    if (isLocumDoctor && isMatch) {
                        isMatch = clinic.locumDoctor === option.locumDoctor;
                    }
                    return isMatch;
                });
                //找尋科別
                let findedDiv = null;
                if (!arrayIsEmpty(findedClinic) && findedClinic.length === 1) {
                    //當只有一個診間時，搜尋科別資料
                    findedDiv = divOptions.find(
                        (item) => item.divNo === findedClinic[0].divNo
                    );
                }
                if (objectIsEmpty(findedDiv)) {
                    // 沒有科別資料時
                    // 設定醫師選項
                    setAppointmentData((prev) => ({
                        ...prev,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo: null,
                        //是否有代診醫師
                        isLocumDoctor: false,
                    }));
                } else {
                    // 有科別資料時
                    // 身分別
                    let ptType = appointmentData.ptType
                    //判斷是否為醫療美容
                    if(isCosmeticSurgeryDiv(findedDiv.divNo)) {
                        //美容外科病人掛號健保身分預帶自費
                        ptType = '11'
                    }
                    //設定醫師、科別、診間資料
                    setAppointmentData({
                        ...appointmentData,
                        //醫師編號 (有代診醫師就代入代診醫師的)
                        doctorNo:
                            getDoctorOptionDisplayName({
                                displayName: '',
                                doctorName: option.doctorName,
                                doctorNo: option.doctorNo,
                                locumDoctor: option.locumDoctor,
                                locumDoctorName: option.locumDoctorName,
                                stopClinicFlag: option.stopClinicFlag
                            }),
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //科別編號
                        divNo: `${findedDiv.divNo} ${findedDiv.divName}`,

                        //月班識別碼
                        clinicId: findedClinic[0].clinicId,
                        //診間號
                        clinicNo: findedClinic[0].clinicNo,
                        //診室名稱
                        clinicName: findedClinic[0].clinicName,
                        //診室地點
                        clinicLocation: findedClinic[0].clinicLocation,
                        //停診
                        stopClinicFlag: findedClinic[0].stopClinicFlag,
                        //給號方式
                        giveNumType: findedClinic[0].giveNumType,
                        // 身分別
                        ptType:ptType
                    });
                }
            }
        } else {
            setAppointmentData((prev) => ({
                ...prev,
                doctorNo: null,
            }))
        }

        setShowDoctorOptions(false);
    };

    /**
     * 是否為醫療美容的科別
     * @param divNo 科別代碼
     * @returns {boolean}
     */
    const isCosmeticSurgeryDiv = (divNo) => {
        if(!objectIsEmpty(baseData) && !arrayIsEmpty(baseData.cosmeticSurgeryDivNoList) && !stringIsEmpty(divNo)) {
            //判斷是否為美容外科
            const funded = baseData.cosmeticSurgeryDivNoList.find(item => item === divNo)

            return !objectIsEmpty(funded)

        }
        return false
    }

    /**
     * 處理看診日期變更
     * @param newDate {Dayjs | null} 選中的日期
     * @return {void}
     */
    const handleEncounterDateOnChange = (newDate) => {
        // 現在日期
        const presentDate = dayjs()
        // 現在日期往前推 1 個月
        const oneMonthAgo = presentDate.subtract(1, 'month')
        // 當補輸被勾選時，進行以下判斷
        if (appointmentData.fowardRegFlag === true) {
            // 如果選擇的日期在補輸日期一個月之前，跳 Alert ，可寫入日期
            if (newDate < oneMonthAgo){
                const msg = '日期比今天早 1 個月'
                showToast({message: msg, type: AlertTypeEnum.Warning});
            }

        }

        setAppointmentData((prevData) => ({
            ...prevData,
            //門診日期
            // 如果有選中的日期，則格式化為 'YYYY-MM-DD'，否則設置為空字符串
            encounterDate: newDate ? newDate.format('YYYY-MM-DD') : "",
            //醫師代碼
            doctorNo: "",
            //是否有代理醫生
            isLocumDoctor: false,
            //科別代碼
            divNo: "",
            //診間id
            clinicId: "",
            //診間號
            clinicNo: "",
            //診室名稱
            clinicName: "",
            //診室地點
            clinicLocation: "",
            //停診
            stopClinicFlag: null,
            //給號方式
            giveNumType: "",
        }));
    };
    /**
     * 處理科別搜尋文字框變更事件
     * @param newValue {String}
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (newValue) => {
        //取得值
        const value = newValue;
        //值為空
        if (stringIsEmpty(value)) {
            // 設定醫師選項
            setAppointmentData({
                ...appointmentData,
                divNo: value,
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: "",
            });
        } else {
            //值不為空
            // 設定醫師選項
            setAppointmentData({...appointmentData, divNo: value});
        }
    };

    /**
     * 處理科別搜尋文字框焦點事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnFocus = () => {
        serFilterDivOptions(getDepartmentOptionList());
        setShowDivOptions(true);
    };

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 身分別
        let ptType = appointmentData.ptType
        if(!objectIsEmpty(option)) {
            //判斷是否為醫療美容
            if(isCosmeticSurgeryDiv(option.divNo)) {
                //美容外科病人掛號健保身分預帶自費
                ptType = '11'
            }
        }
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo;
            // 科別名稱
            const name = option.divName;
            // 醫師選項不為空時
            if (!stringIsEmpty(appointmentData.doctorNo)) {
                //取得醫生切割字串
                const splitDoctorArray = appointmentData.doctorNo.split(" ");
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    //是否有代診醫師
                    const isLocumDoctor =
                        appointmentData.isLocumDoctor === undefined ||
                        appointmentData.isLocumDoctor === null
                            ? false
                            : appointmentData.isLocumDoctor;
                    //取得醫生編號
                    const id = splitDoctorArray[0];
                    //取得相關的診間資訊
                    const resultArray = clinicOptions.filter((clinic) => {
                        let isMatch = false;
                        if (isLocumDoctor) {
                            isMatch =
                                clinic.divNo === option.divNo &&
                                clinic.locumDoctor === id;
                        } else {
                            isMatch =
                                clinic.divNo === option.divNo &&
                                clinic.doctorNo === id;
                        }
                        return isMatch;
                    });
                    //只有數量等於1才顯示診間資訊
                    if (resultArray.length === 1) {
                        //取得診間資訊
                        const clinicInfo = resultArray[0];
                        // 設定科別選項
                        setAppointmentData({
                            ...appointmentData,
                            //月班識別碼
                            clinicId: clinicInfo.clinicId,
                            //科別編號
                            divNo: `${no} ${name}`,
                            //診間號
                            clinicNo: clinicInfo.clinicNo,
                            //診室名稱
                            clinicName: clinicInfo.clinicName,
                            //診室地點
                            clinicLocation: clinicInfo.clinicLocation,
                            //停診
                            stopClinicFlag: clinicInfo.stopClinicFlag,
                            //給號方式
                            giveNumType: clinicInfo.giveNumType,
                            //身分別
                            ptType: ptType
                        });
                        // const divNo = appointmentData.divNo ? appointmentData.divNo.split(' ')[0] : ''
                    } else {
                        //數量不等於1時
                        if (resultArray.length > 1) {
                            //有多個時顯示日班表
                            handleClinicScheduleOnClick();
                            setAppointmentData((prev) => ({
                                ...prev,
                                divNo: '',
                            }));
                        } else {
                            //
                            setAppointmentData((prev) => ({
                                ...prev,
                                divNo: `${no} ${name}`,
                                //身分別
                                ptType: ptType
                            }));
                        }
                    }
                }
            } else {
                // 醫師選項為空時，找尋此科別只有一個診間時自動帶入科別診間資料
                // 設定科別選項
                setAppointmentData({
                    ...appointmentData,
                    divNo: `${no} ${name}`,
                });

                //找尋此診間
                const findedClinic = clinicOptions.filter(
                    (clinic) => clinic.divNo === no
                );
                //找尋醫生
                let findedDoctor = null;
                if (!arrayIsEmpty(findedClinic) && findedClinic.length === 1) {
                    //當只有一個診間時，搜尋科別資料
                    findedDoctor = doctorOptions.find(
                        (item) => item.doctorNo === findedClinic[0].doctorNo
                    );
                }

                if (objectIsEmpty(findedDoctor)) {
                    // 沒有科別資料時
                    // 設定科別選項
                    setAppointmentData({
                        ...appointmentData,
                        divNo: `${no} ${name}`,
                        //身分別
                        ptType: ptType
                    });
                } else {
                    const isLocumDoctor =
                        !stringIsEmpty(findedDoctor.locumDoctor) &&
                        !stringIsEmpty(findedDoctor.locumDoctorName);
                    //有科別資料時
                    //設定醫師、科別、診間資料
                    setAppointmentData({
                        ...appointmentData,
                        //醫師編號
                        doctorNo: findedDoctor.displayName,
                        //是否有代診醫師
                        isLocumDoctor: isLocumDoctor,
                        //科別編號
                        divNo: `${no} ${name}`,
                        //月班識別碼
                        clinicId: findedClinic[0].clinicId,
                        //診間號
                        clinicNo: findedClinic[0].clinicNo,
                        //診室名稱
                        clinicName: findedClinic[0].clinicName,
                        //診室地點
                        clinicLocation: findedClinic[0].clinicLocation,
                        //停診
                        stopClinicFlag: findedClinic[0].stopClinicFlag,
                        //給號方式
                        giveNumType: findedClinic[0].giveNumType,
                        //身分別
                        ptType: ptType
                    });
                }
            }
        } else {
            // 科別為空
            setAppointmentData((prev) =>({
                ...prev,
                divNo: '',
            }));
        }

        setShowDivOptions(false);
    };

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const divText = appointmentData?.divNo?.trim();
        const doctorText = appointmentData?.doctorNo?.trim();
        //切割科別字串
        const splitArray = divText ? divText.split(" ") : [];
        //已經有選擇科別
        if (splitArray.length === 2) {
            return divOptions.filter(
                (div) =>
                    div.divNo.includes(splitArray[0]) &&
                    div.divName.includes(splitArray[1])
            );
        } else {
            //透過輸入文字
            // 醫生選項為空時
            if (stringIsEmpty(doctorText)) {
                // 科別選項為空時
                if (stringIsEmpty(divText)) {
                    return divOptions;
                } else {
                    // 科別選項不為空時
                    return divOptions.filter(
                        (div) =>
                            div.divNo.includes(divText) ||
                            div.divName.includes(divText)
                    );
                }
            } else {
                // 醫生選項不為空時
                //取得醫生切割字串
                const splitDoctorArray = doctorText.split(" ");
                //已經有選擇醫生
                if (splitDoctorArray.length === 2) {
                    if (splitArray.length === 0) {
                        //科別為空
                        //求聯集
                        return divOptions
                            .filter((div) =>
                                clinicOptions.some(
                                    (clinic) =>
                                        (clinic.divNo === div.divNo && (clinic.doctorNo === splitDoctorArray[0] || clinic.locumDoctor === splitDoctorArray[0]))
                                )
                            )
                            .map(
                                (div) =>
                                    new Object({
                                        //科別代碼
                                        divNo: div.divNo,
                                        //科別名稱
                                        divName: div.divName,
                                    })
                            );
                    }
                    if (splitArray.length === 1) {
                        //科別有1段
                        return divOptions
                            .filter((div) =>
                                clinicOptions.some(
                                    (clinic) =>
                                        clinic.divNo === div.divNo &&
                                        clinic.doctorNo === splitDoctorArray[0]
                                )
                            )
                            .filter(
                                (div) =>
                                    div.divNo.includes(divText) ||
                                    div.divName.includes(divText)
                            )
                            .map(
                                (div) =>
                                    new Object({
                                        //科別代碼
                                        divNo: div.divNo,
                                        //科別名稱
                                        divName: div.divName,
                                    })
                            );
                    } else {
                        return [];
                    }
                } else {
                    //透過輸入文字
                    return []; //clinicOptions.filter(div => div.doctorNo === appointmentData.doctorNo)
                }
            }
        }
    };

    /**
     * 關閉診間當日班表時
     * @param item {Object|Null} 診間資訊
     * @return {void}
     */
    const handleClinicScheduleOnClose = (item = null) => {
        // 診間資訊不為空
        if (!objectIsEmpty(item)) {
        }
        // 關閉診間當日班表
        setShowClinicSchedule(false);
        // 設定診間當日班表資料
        setClinicOfDayScheduleData(null);
    };

    /**
     * 關閉診間當周班表時
     * @param item {Object|Null} 診間資訊
     * @return {void}
     */
    const handleWeeklySchedulePopupOnClose = () => {
        setShowWeeklySchedulePopup(false);
    };

    /**
     * 處理診間當日班表點擊事件
     */
    const handleClinicScheduleOnClick = () => {
        const doctorId = appointmentData?.doctorNo?.split(" ")[0]
        let doctor = null
        if(!stringIsEmpty(doctorId) && !arrayIsEmpty(doctorOptions)) {
            for(let i = 0; i < doctorOptions.length; i++) {
                if(!stringIsEmpty(doctorOptions[i].doctorNo) && doctorOptions[i].doctorNo === doctorId) {
                    doctor = `${doctorOptions[i].doctorNo} ${doctorOptions[i].doctorName}`
                    break
                }
                else if(!stringIsEmpty(doctorOptions[i].locumDoctor) && doctorOptions[i].locumDoctor === doctorId) {
                    doctor = `${doctorOptions[i].locumDoctor} ${doctorOptions[i].locumDoctorName}`
                    break
                }
            }
        }
        //設定診間當日班表資料
        setClinicOfDayScheduleData({
            //科別代碼
            divNo: appointmentData.divNo ? appointmentData.divNo : null,
            //醫生代碼
            doctorNo: doctor? doctor : null,
            //時段
            apn: appointmentData.apn,
            //日期
            date: appointmentData.encounterDate
                ? appointmentData.encounterDate
                : null,
            //院區列表
            zonesetList: baseData.zonesetList,
            //院區
            zone: appointmentData.zone,
        });

        //顯示診間當日班表
        setShowClinicSchedule(true);
    };

    /**
     * 處理診間當周班表點擊事件
     */
    const handleWeeklyScheduleOnClick = () => {
        setWeeklyScheduleBaseData({
            //科別代碼
            divNo: appointmentData.divNo ? appointmentData.divNo : null,
            //醫生代碼
            doctorNo: appointmentData.doctorNo
                ? appointmentData.doctorNo
                : null,
            //日期
            date: appointmentData.encounterDate
                ? appointmentData.encounterDate
                : null,
            //院區列表
            zonesetList: baseData.zonesetList,
            //院區
            zone: appointmentData.zone,
        });

        setShowWeeklySchedulePopup(true);
    };

    /**
     * 檢查是否為成年人
     * @param birthdate 生日
     * @returns {boolean}
     */
    const detectIsAdult = (birthdate) => {
        const birthDate = new Date(birthdate)
        const today = new Date()

        let ageYears = today.getFullYear() - birthDate.getFullYear()
        let ageMonths = today.getMonth() - birthDate.getMonth()

        // 調整月份和年份
        if (ageMonths < 0) {
            ageYears--
            ageMonths += 12 // 負數月份轉為正，並從年份中減去1
        }
        // 確保在當月的日期前出生不計入該月
        if (today.getDate() < birthDate.getDate()) {
            ageMonths--
            if (ageMonths < 0) {
                ageYears--
            //    ageMonths = 11
            }
        }

        return ageYears >= 18
    }

    /**
     * 新增給診號
     * @param isCheckAdule 是否檢查年齡
     */
    const handleAddRegsOnClick = (isCheckAdule) => {
        let startViewDatetime = "";
        let endViewDatetime = "";

        let divNo = appointmentData.divNo
                ? appointmentData.divNo.split(" ")[0]
                : null
        if (appointmentData.giveNumType === "2") {
            if (
                appointmentData.giveNumStartTime === null ||
                appointmentData.giveNumEndTime === null
            ) {
                const msg = `給診失敗: 此診間為依時間給號，尚未設定時間`;
                showToast({message: msg, type: AlertTypeEnum.Error});
                return;
            }
            // 給號方式依時間
            startViewDatetime = time2String(
                appointmentData.giveNumStartTime,
                "YYYY-MM-DD HH:mm:00"
            );
            endViewDatetime = time2String(
                appointmentData.giveNumEndTime,
                "YYYY-MM-DD HH:mm:00"
            );
        }

        const fundedClinic = clinicOptions.find(
            (item) => item.clinicId === appointmentData.clinicId
        );

        let doctorNo = appointmentData.doctorNo
            ? appointmentData.doctorNo.split(" ")[0]
            : null;
        if (!objectIsEmpty(fundedClinic)) {
            doctorNo = fundedClinic.doctorNo;
        }

        //檢查病人年齡
        if(isCheckAdule && !detectIsAdult(patientData.birthDate)) {
            if(!objectIsEmpty(baseData.pediatricsDiv) && !stringIsEmpty(baseData.pediatricsDiv.divNo)) {
                if(baseData.pediatricsDiv.divNo !== divNo) {
                    setShowUnderageDialog(true);
                    return
                }
            }
        }

        //判斷有無病歷號
        if (stringIsEmpty(appointmentData.patientId)) {
            //沒有病歷號執行初診預約存檔
            let genderNo = 3;
            if (patientData.genderName === "男") genderNo = 1;
            else if (patientData.genderName === "女") genderNo = 2;
            else if (patientData.genderName === "不明") genderNo = 3;

            regsAddFirstview({
                encounterDate: `${appointmentData.encounterDate} 00:00:00`, //看診日期
                apn: appointmentData.apn, //時段
                divNo: divNo, //科別
                doctorNo: doctorNo, //醫師
                zone: appointmentData.zone, //院區代碼
                clinicId: appointmentData.clinicId, //診間id
                clinicNo: appointmentData.clinicNo, //診間號
                clinicName: appointmentData.clinicName, //診室名稱
                idNo: patientData.idNo,
                patientName: patientData.patientName,
                birthDate: time2String(
                    patientData.birthDate,
                    "YYYY-MM-DD 00:00:00"
                ),
                gender: genderNo,
                phonenumber: stringIsEmpty(patientData.phonenumber)
                    ? ""
                    : patientData.phonenumber,
                homephonenumber: stringIsEmpty(patientData.homephonenumber)
                    ? ""
                    : patientData.homephonenumber,
                ptType: appointmentData.ptType,
                startViewDatetime: startViewDatetime,
                endViewDatetime: endViewDatetime,
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    let apnName;
                    if (res.data.apn === 1) apnName = "早";
                    else if (res.data.apn === 2) apnName = "午";
                    else apnName = "晚";
                    //新增初診預約成功
                    const givenNumberData = {
                        //regId: "018653a7-237e-4095-bf75-2881546e5cf6",
                        patientId: stringIsEmpty(res.data.patientId)
                            ? ""
                            : res.data.patientId,
                        patientName: res.data.patientName,
                        //zone: "ED",
                        zoneName: stringIsEmpty(res.data.zoneName)
                            ? ""
                            : res.data.zoneName,
                        encounterDate: res.data.encounterDate,
                        apn: res.data.apn,
                        apnName: apnName,
                        //clinicNo: "6001",
                        clinicName: res.data.clinicName,
                        //doctorNo: "1669",
                        doctorName: res.data.doctorName,
                        // 代班醫生
                        locumDoctor: res.data.locumDoctor,
                        //divNo: "60",
                        divName: res.data.divName,
                        viewNo: res.data.viewNo,
                        startViewDatetime: res.data.startViewDatetime,
                        //endViewDatetime: "2024-04-17 13:50:00"
                    };

                    //設定給診號資料
                    setGiveNumberData(givenNumberData);

                    //新增成功
                    setTimeout(() => setShowGiveNumberPopup(true), 150);

                    triggerResetDefault(true);
                } else {
                    const msg = `給診失敗: ${res.msg}`;
                    showToast({message: msg, type: AlertTypeEnum.Error});
                }
            });
        } else {
            //新增預約資料
            regsAddReg({
                patientId: appointmentData.patientId, //病歷ID
                zone: appointmentData.zone, //院區代碼
                clinicId: appointmentData.clinicId, //月班識別碼
                encounterDate: `${appointmentData.encounterDate} 00:00:00`, //看診日期
                apn: appointmentData.apn, //時段
                divNo: appointmentData.divNo
                    ? appointmentData.divNo.split(" ")[0]
                    : null, //科別
                doctorNo: doctorNo, //醫師
                clinicNo: appointmentData.clinicNo, //診間號
                clinicName: appointmentData.clinicName, //診室名稱
                ptType: appointmentData.ptType, //身份別
                reserveType: appointmentData.reserveType, //掛號別
                forwardRegFlag: appointmentData.fowardRegFlag, //是否可往前掛號
                startViewDatetime: startViewDatetime, //掛號看診開始時間 依時間給號時必填
                endViewDatetime: endViewDatetime, //掛號看診結束時間 依時間給號時必填
            }).then((res) => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定給診號資料
                    setGiveNumberData(res.data);

                    updateGetRegsQueryRegHistory(appointmentData.patientId);
                    //新增成功
                    setTimeout(() => setShowGiveNumberPopup(true), 150);

                    triggerResetDefault(true);

                    if(!stringIsEmpty(res.msg)) {
                        showToast({message: res.msg, type: AlertTypeEnum.Warning});
                    }
                } else {
                    const msg = `給診失敗: ${res.msg}`;
                    showToast({message: msg, type: AlertTypeEnum.Error});
                }
            });
        }
    };

    /**
     * 加掛按鈕點擊事件
     */
    const handleExtraAddRegsOnClick = () => {
        if (stringIsEmpty(appointmentData.patientId)) return;

        // 開啟加掛確認彈窗
        setShowExtraAddRegsConfirm(true);
        // 設定確認步驟為 1
        setConfirmStep(1);
    };

    /**
     * 確認加掛按鈕點擊事件
     * return {void}
     */
    const handleConfirmExtraAddRegs = () => {
        if (confirmStep === 1) {
            setConfirmStep(2);
        } else if (confirmStep === 2) {
            const fundedClinic = clinicOptions.find(
                (item) => item.clinicId === appointmentData.clinicId
            );
            let doctorNo = appointmentData.doctorNo
                ? appointmentData.doctorNo.split(" ")[0]
                : null;
            if (!objectIsEmpty(fundedClinic)) {
                doctorNo = fundedClinic.doctorNo;
            }

            //新增預約資料
            regsAddReg({
                patientId: appointmentData.patientId, //病歷ID
                zone: appointmentData.zone, //院區代碼
                clinicId: appointmentData.clinicId, //月班識別碼
                encounterDate: `${appointmentData.encounterDate} 00:00:00`, //看診日期
                apn: appointmentData.apn, //時段
                divNo: appointmentData.divNo
                    ? appointmentData.divNo.split(" ")[0]
                    : null, //科別
                doctorNo: doctorNo, //醫師
                clinicNo: appointmentData.clinicNo, //診間號
                clinicName: appointmentData.clinicName, //診室名稱
                ptType: appointmentData.ptType, //身份別
                reserveType: appointmentData.reserveType, //掛號別
                forwardRegFlag: appointmentData.fowardRegFlag, //是否可往前掛號
                extraAddFlag: true,
            }).then((res) => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定給診號資料
                    setGiveNumberData(res.data);

                    updateGetRegsQueryRegHistory(appointmentData.patientId);
                    //新增成功
                    setTimeout(() => setShowGiveNumberPopup(true), 150);

                    triggerResetDefault(true);

                    if(!stringIsEmpty(res.msg)) {
                        showToast({message: res.msg, type: AlertTypeEnum.Warning});
                    }
                } else {
                    const msg = `加掛失敗: ${res.msg}`;
                    showToast({message: msg, type: AlertTypeEnum.Error});
                }
            });
            // 關閉加掛確認彈窗
            setShowExtraAddRegsConfirm(false);
        }
    };

    /**
     * 取消加掛按鈕點擊事件
     * @return {void}
     */
    const handleCancelExtraAddRegs = () => {
        setShowExtraAddRegsConfirm(false);
    };

    /**
     * 關閉掛號完成的彈窗
     * @return {void}
     */
    const handleCloseGiveNumberPopup = () => {
        //取得RegID
        const {regId} = giveNumberData

        regsReportPappt({regId: regId}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得檔案Url
                const {fileUrl} = data
                //檔案URL存在時
                if (fileUrl) {
                    // 取得檔案名稱
                    const fileName = fileUrl.split('?fileName=')[1]
                    //取得檔案Blob
                    regsReportGetFile({fileName: fileName}).then(res => {
                        //取得成功
                        if (res) {
                        } else { //取得失敗
                            showToast({message: "請稍後重新嘗試取得檔案", type: AlertTypeEnum.Warning})
                        }
                    })
                }
            } else { //取得失敗
                //顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
        // 關閉掛號完成的彈窗
        setShowGiveNumberPopup(false)
        //setGiveNumberData({});
    }

    /**
     * 點擊診號狀況按鈕事件
     */
    const handleClinicNoStateOnClick = () => {
        if (appointmentData.giveNumType === "2") {
            // 給號方式依時間
            setShowCalendar(true);
        } else {
            setShowClinicNoStatePopup(true);
        }
    };

    /**
     * 關閉診號狀況彈窗
     */
    const handleCloseClinicNoStatePopup = () => {
        setShowClinicNoStatePopup(false);
    };

    /**
     * 診間選擇事件
     * @param {Time} date 門診日期
     * @param {Object} clinicData 門診資料
     */
    const onSelectClinic = (date, clinicData) => {
        //是否有代診醫師
        const isLocumDoctor =
            !stringIsEmpty(clinicData.locumDoctor) &&
            !stringIsEmpty(clinicData.locumDoctorName);

        //身分別
        let ptType = appointmentData.ptType
        //判斷是否為醫療美容
        if(isCosmeticSurgeryDiv(clinicData.divNo)) {
            //美容外科病人掛號健保身分預帶自費
            ptType = '11'
        }

        // 設定科別選項
        setAppointmentData({
            ...appointmentData,
            //門診日期
            encounterDate: time2String(date, "YYYY-MM-DD"),
            //時段
            apn: `${clinicData.apn}`,
            //月班識別碼
            clinicId: clinicData.clinicId,
            //醫師
            doctorNo:
                getDoctorOptionDisplayName({
                    displayName: '',
                    doctorName: clinicData.doctorName,
                    doctorNo: clinicData.doctorNo,
                    locumDoctor: clinicData.locumDoctor,
                    locumDoctorName: clinicData.locumDoctorName,
                    stopClinicFlag: clinicData.stopClinicFlag
                }),
            //是否有代診醫師
            isLocumDoctor: isLocumDoctor,
            //科別編號
            divNo: `${clinicData.divNo} ${clinicData.divName}`,
            //診間號
            clinicNo: clinicData.clinicNo,
            //診室名稱
            clinicName: clinicData.clinicName,
            //診室地點
            clinicLocation: clinicData.clinicLocation,
            //停診
            stopClinicFlag: clinicData.stopClinicFlag,
            //給號方式
            giveNumType: clinicData.giveNumType,
            //身分別
            ptType: ptType
        });
    };

    /**
     * 檢查給診號按鈕狀態
     */
    const detectAddRegsButtonState = () => {
        if (
            !stringIsEmpty(appointmentData.divNo) &&
            !stringIsEmpty(appointmentData.doctorNo) &&
            (appointmentData.stopClinicFlag === undefined ||
                appointmentData.stopClinicFlag === null ||
                appointmentData.stopClinicFlag === false) &&
            !objectIsEmpty(patientData)
        ) {
            setDisableAddRegsButton(false);
        } else {
            setDisableAddRegsButton(true);
        }
    };

    /**
     * 掛號給號時間行事曆儲存事件
     * @param {Object} timeRangObj 時間區間
     */
    const handleAppointmentCalendarOnSave = (timeRangObj) => {
        setAppointmentData({
            ...appointmentData,
            //給號開始時間
            giveNumStartTime: objectIsEmpty(timeRangObj)
                ? null
                : timeRangObj.start,
            //給號結束時間
            giveNumEndTime: objectIsEmpty(timeRangObj) ? null : timeRangObj.end,
        });
    };

    const getGiveNumberTimeRangeText = () => {
        if (!objectIsEmpty(appointmentData)) {
            const start = time2String(
                appointmentData.giveNumStartTime,
                "HH:mm"
            );
            const end = time2String(appointmentData.giveNumEndTime, "HH:mm");
            return stringIsEmpty(start) || stringIsEmpty(end)
                ? ""
                : `${start} ~ ${end}`;
        }
        return "";
    };

    /**
     * 未成年提示彈窗 按鈕"否"點擊事件
     */
    const handleUnderAgeDialogNoButtonOnClick = () => {
        setShowUnderageDialog(false)
        handleAddRegsOnClick(false)
    }

    /**
     * 未成年提示彈窗關閉事件
     */
    const handleCloseUnderAgeDialog = () => {
        setShowUnderageDialog(false)
    }

    /**
     * 未成年提示彈窗 按鈕"是"點擊事件
     */
    const handleUnderAgeDialogConfirmOnClick = () => {
        setShowUnderageDialog(false)
        //判斷有無小兒科
        const fundedDiv = divOptions.find(div => div.pediatricsFlag === true)
        if(!objectIsEmpty(fundedDiv) && !stringIsEmpty(fundedDiv.divNo) && !stringIsEmpty(fundedDiv.divName)) {
            //有找到小兒科，轉換至小兒科
            //檢查小兒科的診間是否只有一間
            const fundedClinic = clinicOptions.filter(
                (clinic) => clinic.divNo === fundedDiv.divNo
            );
            //找尋醫生
            let fundedDoctor = null;
            if (!arrayIsEmpty(fundedClinic) && fundedClinic.length === 1) {
                //當只有一個診間時，搜尋醫師資料
                fundedDoctor = doctorOptions.find(
                     (item) => item.doctorNo === fundedClinic[0].doctorNo
                 );
            }

            if(objectIsEmpty(fundedDoctor)) {
                setAppointmentData({
                    ...appointmentData,
                    //醫師編號
                    doctorNo: '',
                    //是否有代診醫師
                    isLocumDoctor: false,
                    //科別編號
                    divNo: `${fundedDiv.divNo} ${fundedDiv.divName}`,
                    //月班識別碼
                    clinicId: '',
                    //診間號
                    clinicNo: '',
                    //診室名稱
                    clinicName: '',
                    //診室地點
                    clinicLocation: '',
                    //停診
                    stopClinicFlag: null,
                    //給號方式
                    giveNumType: '',
                });
            }
            else {
                const isLocumDoctor =
                    !stringIsEmpty(fundedDoctor.locumDoctor) &&
                    !stringIsEmpty(fundedDoctor.locumDoctorName);
                setAppointmentData({
                    ...appointmentData,
                    //醫師編號
                    doctorNo: fundedDoctor.displayName,
                    //是否有代診醫師
                    isLocumDoctor: isLocumDoctor,
                    //科別編號
                    divNo: `${fundedDiv.divNo} ${fundedDiv.divName}`,
                    //月班識別碼
                    clinicId: fundedClinic[0].clinicId,
                    //診間號
                    clinicNo: fundedClinic[0].clinicNo,
                    //診室名稱
                    clinicName: fundedClinic[0].clinicName,
                    //診室地點
                    clinicLocation: fundedClinic[0].clinicLocation,
                    //停診
                    stopClinicFlag: fundedClinic[0].stopClinicFlag,
                    //給號方式
                    giveNumType: fundedClinic[0].giveNumType,
                });
            }
        }
        else {
            //沒找到清空
            setAppointmentData((prev) => ({
                ...prev,
                //是否有代診醫生
                isLocumDoctor: false,
                //診間id
                clinicId: "",
                //診間號
                clinicNo: "",
                //診室名稱
                clinicName: "",
                //診室地點
                clinicLocation: "",
                //停診
                stopClinicFlag: null,
                //給號方式
                giveNumType: "",
                //醫師
                doctorNo: "",
                //科別
                divNo: ""
            }))
        }
    }

    /**
     * 病人重複掛號(同天同時段同科同醫師)提示彈窗 按鈕"否"點擊事件
     */
    const handlePatientDuplicateRegMessagePopupOnClose = () => {
        setShowPatientDuplicateRegMessagePopup(false)
        //清掉選擇的醫師或科別
        setAppointmentData((prev) => ({
            ...prev,
            //醫師代碼
            doctorNo: "",
            //科別代碼
            divNo: "",
            //診間號
            clinicNo: "",
            //診室名稱
            clinicName: "",
            //診室地點
            clinicLocation: "",
            //月班識別碼
            clinicId: null,
            //停診
            stopClinicFlag: null,
            //是否有代診醫生
            isLocumDoctor: false,
            //給號方式
            giveNumType: "",
        }))
    }

    /**
     * 病人重複掛號(同天同時段同科同醫師)提示彈窗 按鈕"是"點擊事件
     */
    const handlePatientDuplicateRegMessagePopupOnConfirm = () => {
        setShowPatientDuplicateRegMessagePopup(false)
    }

    /**
     * 選取時段不可早於當前時段提示彈窗 按鈕"確定"點擊事件
     */
    const handleIsAfterPresentSlotPopupOnClose = () =>{
        setshowIsAfterNowDatePopup(false)
    }

    /**
     * 取得醫師option項目額外的字樣
     * @param {Object} doctorItem
     */
    const getDoctorOptionItemExtendText = (doctorItem) => {
        let text = "";
        if (!objectIsEmpty(doctorItem)) {
            if (
                stringIsEmpty(doctorItem.locumDoctor) ||
                stringIsEmpty(doctorItem.locumDoctorName)
            ) {
                if (
                    doctorItem.stopClinicFlag !== null &&
                    doctorItem.stopClinicFlag
                ) {
                    text = "[停]";
                }
            } else {
                text = "[代]";
            }
        }
        return text;
    };

    /**
     * 取得醫師option項目DisplayName
     * @param doctorItem
     * @returns {string}
     */
    const getDoctorOptionDisplayName = (doctorItem) => {
        if (objectIsEmpty(doctorItem) || stringIsEmpty(doctorItem.doctorNo) || stringIsEmpty(doctorItem.doctorName))
            return ''
        return doctorItem.locumDoctor && doctorItem.locumDoctorName
            ? `${doctorItem.locumDoctor} ${doctorItem.locumDoctorName}[代]`
            : doctorItem.stopClinicFlag === true
                ? `${doctorItem.doctorNo} ${doctorItem.doctorName}[停]`
                : `${doctorItem.doctorNo} ${doctorItem.doctorName}`
    }

    /**
     * 取得科別 option 預設值
     * @returns {*|null}
     */
    const getDefaultDivValue = useMemo(() => {
            const data = filterDivOptions.find(option => appointmentData?.divNo?.includes(option?.divNo || ''))
            return data ? data : null
        }, [filterDivOptions]
    )
    /**
     * 取得醫師 option 預設值
     * @returns {*|null}
     */
    const getDefaultDoctorNoValue = useMemo(() => {
        const data = filterDoctorOptions.find(option => {
            return option?.displayName?.includes(appointmentData?.doctorNo || '')
        })
        return data ? data : null
    }, [filterDoctorOptions, appointmentData?.doctorNo])

    /**
     * 確認是否俱加掛的權限
     * @return {void}
     */
    useEffect(() => {
        checkRegsAddPermissionsByLoginUser().then(res=>{
            setExtraAddRegsPermission(res)
        })
    }, []);

    /**
     * 監聽基本資料變更
     * @return {void}
     */
    useEffect(() => {
        // 基本資料不為空時
        if (baseData !== null) {
            //找出有代診醫師的醫師array
            const haveLocumDoctorArray = baseData.doctorList.filter(
                (doctor) =>
                    !stringIsEmpty(doctor.locumDoctor) &&
                    !stringIsEmpty(doctor.locumDoctorName)
            );
            //找出有多診間的醫師且有兩個狀態 代診、一般看診
            const fundedDoctorList = haveLocumDoctorArray
                .filter((doctor) => {
                    const funded = baseData.clinicList.find(
                        (clinic) =>
                            clinic.doctorNo === doctor.doctorNo &&
                            stringIsEmpty(clinic.locumDoctor)
                    );
                    return !objectIsEmpty(funded);
                })
                .map(
                    (doctor) =>
                        new Object({
                            doctorNo: doctor.doctorNo,
                            doctorName: doctor.doctorName,
                            stopClinicFlag: doctor.stopClinicFlag,
                            locumDoctor: null,
                            locumDoctorName: null,
                        })
                );

            //合併
            const combineDoctors = [...baseData.doctorList, ...fundedDoctorList]
            //增加displayName用於顯示用
            const finalDoctorArray = combineDoctors.map(doctor => ({
                ...doctor,
                displayName: getDoctorOptionDisplayName(doctor)
            }));
            // 設定醫師選項
            setDoctorOptions(finalDoctorArray);
            // 設定科別選項
            setDivOptions(baseData.divList);
            // 設定診間選項
            setClinicOptions(baseData.clinicList);
            // 設定時段
            setAppointmentData({
                ...appointmentData,
                apn: baseData.apn,
            });
            //在掛號別內加disabled的property
            const updateRegistrationList = baseData.registrationList.map(
                (obj) => ({
                    ...obj,
                    disabled: false,
                })
            );
            // 設定看診資料
            setAppointmentData((prevData) => ({
                ...prevData,
                // 加載 掛號別
                reserveType: "1", // 現掛
                // 加載 身分別
                ptType: "21", // 健保
                // 加載 掛號院區
                zone: "A", // 義大醫院
                // 保留現有的 apn 內容
                apn: prevData.apn || baseData.apn,
            }));

            setRegistrationList((prev) => updateRegistrationList);
        }
    }, [baseData]);

    /**
     * 監聽門診時段變更時
     * @return {void}
     */
    useEffect(() => {
        // 門診資料以及門診日期不為空且不等於當天時
        if (appointmentData && !stringIsEmpty(appointmentData.encounterDate)) {
            getRegsQueryBasicDataClinicRes();
        }

        const inRange = isCurrentDateTimeWithinRange(
            appointmentData.encounterDate,
            parseInt(appointmentData.apn)
        );
        if (registrationList) {
            let updatedList;
            let reserveType = appointmentData.reserveType;
            if (inRange) {
                updatedList = registrationList.map((obj) => ({
                    ...obj,
                    disabled: obj.justnameNo === "2",
                }));
                //如果原本是預約要改為現掛，原本是電話則不變
                if (appointmentData.reserveType === "2") reserveType = "1";
            } else {
                updatedList = registrationList.map((obj) => ({
                    ...obj,
                    disabled: obj.justnameNo === "1",
                }));
                //如果原本是現掛要改為預約，原本是電話則不變
                if (appointmentData.reserveType === "1") reserveType = "2";
            }
            setRegistrationList((prev) => updatedList);
            setAppointmentData({
                ...appointmentData,
                reserveType: reserveType,
            });
        }
    }, [appointmentData.encounterDate, appointmentData.apn]);

    /**
     * 監聽病歷ID變更
     */
    useEffect(() => {
        if (!objectIsEmpty(patientData)) {
            // 設定病歷ID
            setAppointmentData({
                ...appointmentData,
                patientId: patientData.patientId,
            });
            // 確認是否俱加掛權限，若有則顯示加掛按鈕，反之則 disable
            if(extraAddRegsPermission.data === true){
                setDisableExtraAddRegsButton(stringIsEmpty(patientData.patientId));
            }else{
                setDisableExtraAddRegsButton(true);
            }
        } else {
            setDisableExtraAddRegsButton(true);
        }
        detectAddRegsButtonState();
    }, [patientData]);

    /**
     * 監聽科別  醫生 變化
     */
    useEffect(() => {
        serFilterDivOptions(getDepartmentOptionList());
        serFilterDoctorOptions(getDoctorOptionList());
        detectAddRegsButtonState();

        //判斷有沒有診室
        if (
            !stringIsEmpty(appointmentData.divNo) &&
            !stringIsEmpty(appointmentData.doctorNo)
        ) {
            setHaveClinic(true);
        } else {
            setHaveClinic(false);
        }

    }, [appointmentData.divNo, appointmentData.doctorNo, divOptions, doctorOptions]);

    /**
     * 監聽醫師變化
     */
    useEffect(() => {
        const selectedDoctorArray = appointmentData.doctorNo?.split(' ')
        if(!arrayIsEmpty(selectedDoctorArray) && selectedDoctorArray.length === 2){
            setDoctorValue({displayName: appointmentData.doctorNo})
        }
        else {
            if(stringIsEmpty(appointmentData.doctorNo)) {
                setDoctorValue(null)
                setAppointmentData((prev) => ({
                    ...prev,
                    //是否有代診醫生
                    isLocumDoctor: false,
                    //診間id
                    clinicId: "",
                    //診間號
                    clinicNo: "",
                    //診室名稱
                    clinicName: "",
                    //診室地點
                    clinicLocation: "",
                    //停診
                    stopClinicFlag: null,
                    //給號方式
                    giveNumType: "",
                }))
            }
        }
    }, [appointmentData.doctorNo])

    /**
     * 監聽科別變化
     */
    useEffect(() => {
        const selectedDivArray = appointmentData.divNo.split(' ')
        if(selectedDivArray.length ===2){
            setDivValue({divNo: selectedDivArray[0], divName: selectedDivArray[1]})
        }
        else {
            if(stringIsEmpty(appointmentData.divNo)) {
                setDivValue(null)
                setAppointmentData((prev) => ({
                    ...prev,
                    //診間id
                    clinicId: "",
                    //診間號
                    clinicNo: "",
                    //診室名稱
                    clinicName: "",
                    //診室地點
                    clinicLocation: "",
                    //停診
                    stopClinicFlag: null,
                    //給號方式
                    giveNumType: "",
                }))
            }
        }
    }, [appointmentData.divNo])

    /**
     * 監聽診間id、病歷號變化
     */
    useEffect(() => {
        if(!stringIsEmpty(appointmentData.clinicId) && patientData) {
            //診號不為空
            if(!stringIsEmpty(patientData.patientId)) {
                //病人存在
                //異動診室時確認有無提醒
                regsCheckAlertWhenChangeClinicBeforeReg({
                    //診間id
                    clinicId: appointmentData.clinicId,
                    //病歷號
                    patientId: patientData.patientId,
                    //病人姓名
                    patientName: patientData.patientName,
                    //病人身分證號
                    idNo: patientData.idNo
                }).then(res => {
                    if(res.err === ApiErrorStatusEnum.Success) {
                        if(!stringIsEmpty(res.msg)) {
                            //有訊息
                            showToast({message: res.msg, type: AlertTypeEnum.Warning});
                        }
                    }
                    else {
                        const msg = `檢核診間異動失敗`;
                        showToast({message: msg, type: AlertTypeEnum.Error});
                    }
                })

                //同天同時段同科同醫師稽核
                regsCheckDuplicateClinicByPatientAndTimeAndDocAndDiv({
                    //病歷號
                    patientId: patientData.patientId,
                    //看診日
                    encounterDate: time2String(appointmentData.encounterDate, "YYYY-MM-DD 00:00:00"),
                    //時段
                    apn: appointmentData.apn,
                    //醫師號
                    doctorNo: appointmentData.doctorNo
                        ? appointmentData.doctorNo.split(" ")[0]
                        : null,
                    //科別號
                    divNo: appointmentData.divNo
                        ? appointmentData.divNo.split(" ")[0]
                        : null,
                }).then(res => {
                    if(res.err === ApiErrorStatusEnum.Success) {
                        if(!stringIsEmpty(res.data)) {
                            //有訊息
                            setPatientDuplicateRegMsg(res.data)
                            setShowPatientDuplicateRegMessagePopup(true)
                        }
                    }
                    else {
                        showToast({message: res.msg, type: AlertTypeEnum.Error});
                    }
                })
            }
        }

    }, [appointmentData.clinicId, patientData?.patientId])

    return (
        <Card title="看診資料">
            <div className="flex flex-col text-left">
                <div>
                    <h1 className="text-xl font-bold">掛號方式</h1>
                    {/*掛號別*/}
                    <Field labelWidth={80} label="掛號別">
                        <Select
                            data={{
                                label: "掛號別",
                                options:
                                    baseData?.registrationList?.map(
                                        (registration) => ({
                                            label: registration.justnameName,
                                            value: registration.justnameNo,
                                        })
                                    ) || [],
                            }}
                            value={appointmentData.reserveType}
                            onChange={handleReserveTypeOnChange}
                            disabledOptions={
                                registrationList
                                    ?.filter(
                                        (registration) => registration.disabled
                                    )
                                    .map(
                                        (registration) =>
                                            registration.justnameName
                                    ) || []
                            }
                            showLabel={false}
                        />
                    </Field>
                    {/*身份別*/}
                    <Field labelWidth={80} label="身份別">
                        <Select
                            data={{
                                label: "身份別",
                                options:
                                    baseData?.pttypeList?.map((ptType) => ({
                                        value: ptType.ptType,
                                        label: ptType.typeName,
                                    })) || [],
                            }}
                            value={appointmentData.ptType}
                            onChange={handlePtTypeOnChange}
                            showLabel={false}
                        />
                    </Field>
                    <div className="flex w-full flex-row space-x-4 items-center justify-center pl-20">
                        {/*電話預約*/}
                        <div className="flex flex-row space-x-2">
                            <Checkbox
                                label="電話預約"
                                labelSx={{color: RedColorEnum.Red600}}
                                checked={appointmentData.phoneRegFlag}
                                onChange={handlePhoneRegFlagOnChange}
                            />
                        </div>
                        {/*補輸*/}
                        <div className="flex flex-row space-x-2">
                            <Checkbox
                                label="補輸"
                                checked={appointmentData.fowardRegFlag}
                                onChange={handleFowardRegFlagOnChange}
                            />
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className="flex flex-col space-y-2">
                    <h1 className="text-xl font-bold pt-2">掛號</h1>
                    {/*預掛院區*/}
                    <Field labelWidth={80} label="預掛院區">
                        <Select
                            data={{
                                label: "預掛院區",
                                options:
                                    baseData?.zonesetList?.map((zone) => ({
                                        value: zone.zone,
                                        label: zone.zoneName,
                                    })) || [],
                            }}
                            value={appointmentData.zone}
                            onChange={handleZoneOnChange}
                            showLabel={false}
                        />
                    </Field>
                    {/*門診日期*/}
                    <Field labelWidth={80} label={t("Regs.general.clinicDate")}>
                        <DatePicker
                            size="small"
                            disableFuture={disableFuture}
                            disablePast={disablePast}
                            required
                            value={dayjs(appointmentData.encounterDate)}
                            onChange={handleEncounterDateOnChange}
                            label=""
                            // 若 disablePast 為 true，則設定最大日期為今天
                            maxDate={appointmentData.fowardRegFlag ? dayjs() : null}
                            // 若 disablePast 為 true，則設定最小日期為一年前
                            minDate={appointmentData.fowardRegFlag ? dayjs().subtract(1, 'year') : null}
                        />
                    </Field>
                    {/*時段*/}
                    <Field labelWidth={80} label={t("Regs.general.timeSlot")}>
                        <div className="flex">
                            {
                                !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.apnList) &&
                                <RadioGroup
                                    row={true} size={SizeEnum.Medium}
                                    value={appointmentData.apn}
                                    optionProps={{
                                        options: baseData.apnList.map(apn => ({
                                            label: apn.justnameName,
                                            value: apn.justnameNo
                                        }))
                                    }}
                                    onChange={(e) => handleApnOnClick(e.target.value)}/>
                            }
                        </div>
                    </Field>
                    {/*科別*/}
                    <Field labelWidth={80} label={t("general.department")}>
                        <Search
                            freeSolo
                            value={divValue}
                            clearOnBlur
                            onChange={(_e, value) => {
                                handleDepartmentOptionOnClick(value)
                            }}
                            onInputChange={
                                (e, value, reason ) => {
                                    handleDepartmentSearchTextOnChange(value)
                                }
                            }
                            options={filterDivOptions}
                            getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                        />
                    </Field>
                    {/*醫師*/}
                    <Field labelWidth={80} label={t("general.doctor")}>
                        <Search
                            freeSolo
                            clearOnBlur
                            value={doctorValue}
                            onChange={(_e, value, reason) => {
                                handleDoctorOptionOnClick(value)
                            }}
                            onInputChange={(e, value, reason) => {
                                handleDoctorSearchTextOnChange(value)
                            }
                            }
                            options={filterDoctorOptions}
                            getOptionLabel={(option) => `${option?.displayName}`}
                        />
                    </Field>
                    {/*診間*/}
                    <Field labelWidth={80} label={t("Regs.general.clinic")}>
                        <div>{appointmentData.clinicNo}</div>
                    </Field>
                    {/*診室名稱*/}
                    <Field labelWidth={80} label={t("Regs.general.clinicName")}>
                        <div>{appointmentData.clinicName}</div>
                    </Field>
                    {/*診室地點*/}
                    <Field labelWidth={80} label={t("Regs.general.clinicLocation")}>
                        <div>{appointmentData.clinicLocation}</div>
                    </Field>
                    <div className="flex flex-row w-full justify-between py-1">
                        <div className="flex flex-row space-x-2">
                            {/*當日班表*/}
                            <Button
                                color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                onClick={handleClinicScheduleOnClick}>
                                {"當日班表"}
                            </Button>
                            {/*週班表*/}
                            <Button
                                color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                onClick={handleWeeklyScheduleOnClick}>
                                {"當週班表"}
                            </Button>
                        </div>
                        <div className="flex justify-end">
                            <Button
                                color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} disabled={stringIsEmpty(appointmentData.clinicId)}
                                onClick={handleClinicNoStateOnClick}>
                                {"診號狀況"}
                            </Button>
                        </div>
                    </div>
                    <Divider/>
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        disabled={disableAddRegsButton}
                        onClick={() => handleAddRegsOnClick(true)}>
                        {"給診號"}
                    </Button>
                    <div className="flex flex-col justify-center space-y-5 text-2xl pt-2 text-[#18181B]">
                        <h1>{getGiveNumberTimeRangeText()}</h1>
                    </div>
                    <div className="pt-6 flex flex-row text-md space-x-8">
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} disabled={disableExtraAddRegsButton}
                            onClick={handleExtraAddRegsOnClick}>
                            {"加掛"}
                        </Button>
                    </div>
                </div>
            </div>
            {
                // 顯示掛號完成的彈窗
                <ShowGiveNumberPopup
                    showPopup={showGiveNumberPopup}
                    data={giveNumberData}
                    handleClosePopup={handleCloseGiveNumberPopup}
                />
            }
            {
                //日班表
                <ClinicSchedule
                    show={showClinicSchedule}
                    closeClinic={handleClinicScheduleOnClose}
                    data={clinicOfDayScheduleData}
                    onSelectClinic={onSelectClinic}
                    fowardRegFlag={appointmentData.fowardRegFlag}
                />
            }
            {
                <ClinicVisitsNumberStatePopup
                    show={showClinicNoStatePopup}
                    closePopupButtonOnClick={handleCloseClinicNoStatePopup}
                    clinicId={appointmentData.clinicId}
                />
            }
            {showCalendar && (
                <AppointmentCalendar
                    apn={appointmentData.apn}
                    choiceDate={appointmentData.encounterDate}
                    giveNumberTimeRangeRecord={{
                        start: appointmentData.giveNumStartTime,
                        end: appointmentData.giveNumEndTime,
                    }}
                    patientData={patientData}
                    clinicId={appointmentData.clinicId}
                    onSave={handleAppointmentCalendarOnSave}
                    closePopup={() => setShowCalendar(false)}
                />
            )}
            {
                //週班表
                <WeeklySchedule
                    show={showWeeklySchedulePopup}
                    data={weeklyScheduleBaseData}
                    closePopupButtonOnClick={handleWeeklySchedulePopupOnClose}
                    onSelectClinic={onSelectClinic}
                    fowardRegFlag={appointmentData.fowardRegFlag}
                />
            }
            {
                // 未成年提示彈窗
                <Dialog
                    open={showUnderageDialog}
                    title={"提醒"}
                    content={"病人未滿18歲，是否改掛小兒科"}
                    variant={DialogVariant.CUSTOM}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleCloseUnderAgeDialog}
                    footerContent={
                        <>
                            <Button
                                text={"是"}
                                color={ButtonColorEnum.Primary}
                                variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Medium}
                                onClick={handleUnderAgeDialogConfirmOnClick}
                            />
                            <Button
                                text={"否"}
                                color={ButtonColorEnum.Secondary}
                                variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                onClick={handleUnderAgeDialogNoButtonOnClick}
                            />
                        </>
                    }
                    muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems:'center'}}}
                />
            }
            {
                //同天同時段同科同醫師提示 彈窗
                <Dialog
                    open={showPatientDuplicateRegMessagePopup}
                    title={"提醒"}
                    content={patientDuplicateRegMsg}
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handlePatientDuplicateRegMessagePopupOnClose}
                    onConfirm={handlePatientDuplicateRegMessagePopupOnConfirm}
                    muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                />
            }
            {
                // 補輸不可早於同時段
                <Dialog
                    open={showIsAfterNowDatePopup}
                    title={"提醒"}
                    content={'日期 (時段) 不能比當下系統日期時間晚'}
                    variant={DialogVariant.REMIND}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleIsAfterPresentSlotPopupOnClose}
                    muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                />
            }
            {
                // 確認是否加掛 彈窗
                <Dialog
                    open={showExtraAddRegsConfirm}
                    title={"提醒"}
                    content={confirmStep === 1 ? "是否確認加掛" : "再次確認是否加掛"}
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleCancelExtraAddRegs}
                    onConfirm={handleConfirmExtraAddRegs}
                    muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                />
            }
        </Card>
    );
};

export default AppointmentInfo;

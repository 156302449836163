import React, {useEffect, useMemo, useRef, useState} from "react"
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, stringIsEmpty, time2String} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {queryAllDivision, queryAllDoctor} from "../../api/v1/Menu"
import {regsQueryClinic} from "../../api/v1/RegsMonth"
import useOutsideClick from "../../hooks/useOutsideClick"
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    DateRangePicker,
    RadioGroup,
    Search,
    Select,
    SizeEnum,
    SelectWidthEnum,
    Pagination,
    AdvancedDataGrid,
} from 'edah-component/dist'
import dayjs from 'dayjs';

/**
 * 診間班表查詢頁面
 * @return {JSX.Element}
 */
const ClinicScheduleSearch = () => {
    //時段列表
    const apnList = [
        {
            justnameNo: "1,2,3", justnameName: "全部"
        },
        {
            justnameNo: "1", justnameName: "早"
        },
        {
            justnameNo: "2", justnameName: "午"
        },
        {
            justnameNo: "3", justnameName: "晚"
        },
    ]

    //日期列表
    const weekList = [
        {justnameNo: "0", justnameName: "全部"},
        {justnameNo: "1", justnameName: "一"},
        {justnameNo: "2", justnameName: "二"},
        {justnameNo: "3", justnameName: "三"},
        {justnameNo: "4", justnameName: "四"},
        {justnameNo: "5", justnameName: "五"},
        {justnameNo: "6", justnameName: "六"},
        {justnameNo: "7", justnameName: "日"},
    ]

    // 預設建立時間起迄:起-今日
    const today = new Date()
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
        //醫師代碼
        doctorCode: '',
        // 科別代碼
        departmentCode: '',
        //星期
        week: weekList[0].justnameNo
    }
    // 所有醫生選項
    const [allDoctor, setAllDoctor] = useState(null)
    // 所有科別選項
    const [allDepartment, setAllDepartment] = useState(null)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    // 目前院區診間班表
    const [currentCampusClinicScheduleArray, setCurrentCampusClinicScheduleArray] = useState(null)
    // 其他院區診間班表
    const [otherCampusClinicScheduleArray, setOtherCampusClinicScheduleArray] = useState(null)
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    // 目前院區 pagination控制變數
    const [currentCampusPaginationProps, setCurrentCampusPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 其他院區 pagination控制變數
    const [otherCampusPaginationProps, setOtherCampusPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 預設選中的 value
    const [selectedValue, setSelectedValue] = useState('option1');
    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });

    /**
     * 取得醫師下拉選項
     * @return {void}
     */
    const getDoctorOptionList = () => {
        const splitArray = displayQueryInputData.doctorCode ? displayQueryInputData.doctorCode.split(" ") : []
        if (splitArray.length > 2) {
            return []
        }

        if (splitArray.length === 2) {
            return allDoctor.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(allDoctor, displayQueryInputData.doctorCode)
        }
    }

    /**
     * 取得科別代碼下拉選項
     * @return {void}
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData?.departmentCode ? displayQueryInputData?.departmentCode.split(" ") : []
        console.log({splitArray})

        if (splitArray.length === 2) {
            return allDepartment.filter(doctor => doctor.divNo.includes(splitArray[0]) && doctor.divName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(allDepartment, displayQueryInputData.departmentCode)
        }
    }

    /**
     * 日期範圍變更事件
     * @param  newValue {Array} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeOnChange = (newValue) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: newValue[0] ? dayjs(newValue[0]).format('YYYY-MM-DD') : null,
            dateEnd: newValue[1] ? dayjs(newValue[1]).format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 星期下拉選單選擇事件
     * @param value {String} 選擇的值
     * @return {void}
     */
    const handleSelectWeekOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            week: value
        })
    }

    /**
     * 時段模式改變時
     * @param e {Event} 事件
     */
    const handleApnModeOnChange = (e) => {
        const selectedApn = e.target.value;
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: selectedApn
        });
    };

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, doctorCode: e.target.value});
    }

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList())
        setShowDoctorDropDown(true)
    }

    /**
     * 選取醫師下拉選項時
     * @param doctor {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (doctor) => {
        const value = doctor?.userNo && doctor?.userName ? `${doctor?.userNo} ${doctor?.userName}` : null

        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: value
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 科別代碼方框的值變動時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, departmentCode: e.target.value});
    }

    /**
     * 科別代碼方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param department {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (department) => {
        const value = department?.divNo && department?.divName ? `${department?.divNo} ${department?.divName}` : null
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: value
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 點選查詢按鈕事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message: "起始日期不可大於結束日期", type: AlertTypeEnum.Error})
            return
        }

        //取得當前院區診間班表資料
        queryCurrentCampusClinicSchedule(displayQueryInputData, 1, currentCampusPaginationProps.pageSize)
    }

    /**
     * 查詢目前院區班表
     * @param queryInputObj {Object} 查詢條件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     * @return {void}
     */
    const queryCurrentCampusClinicSchedule = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        regsQueryClinic({
            //起始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            //星期
            week: stringIsEmpty(queryInputObj.week) || queryInputObj.week === "0" ? '' : queryInputObj.week,
            // 醫師代碼
            doctorNo: queryInputObj.doctorCode?.indexOf(' ') > 0 ? queryInputObj.doctorCode.split(' ')[0] : queryInputObj.doctorCode,
            // 科別代碼
            divNo: queryInputObj.departmentCode?.indexOf(' ') > 0 ? queryInputObj.departmentCode.split(' ')[0] : queryInputObj.departmentCode,
            //時段
            apn: (queryInputObj.apn === '1' || queryInputObj.apn === '2' || queryInputObj.apn === '3') ? parseInt(queryInputObj.apn) : null,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定標語類別資料
                setCurrentCampusClinicScheduleArray(data.dataList)

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else { // 取得資料失敗
                setCurrentCampusClinicScheduleArray([])

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得時段的名稱
     * @param id {Number} 時段代碼
     * @return {String}
     */
    const getApnNameByApnId = (id) => {
        if (id === null) {
            return ''
        }
        switch (id) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return ''
    }

    /**
     * 目前院區班表頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     * @return {void}
     */
    const onCurrentCampusPaginationPageOnChange = (page, pageSize) => {
        queryCurrentCampusClinicSchedule(queryInputData, page, pageSize)
    }

    /**
     * 其他院區班表頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     * @return {void}
     */
    const onOtherCampusPaginationPageOnChange = (page, pageSize) => {
    }

    /**
     * 取得表格醫生欄位內容
     * @param scheduleData {Object} 排班資料
     * @return {String}
     */
    const getTableDoctorFieldContent = (scheduleData) => {
        let doctorContent = ''
        if (!stringIsEmpty(scheduleData.locumDoctorName)) {
            doctorContent = `${scheduleData.locumDoctorName}[代]`
        } else {
            if (!stringIsEmpty(scheduleData.doctorName)) {
                doctorContent = scheduleData.doctorName
                if (scheduleData.stopClinicFlag !== null && scheduleData.stopClinicFlag) {
                    doctorContent = `${doctorContent}[停]`
                }
            }
        }
        return doctorContent
    }

    /**
     * 取得所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有醫生選項
                setAllDoctor(data)
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getQueryAllDepartment = () => {
        queryAllDivision().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有科別選項
                setAllDepartment(data)
            }
        })
    }

    /**
     * 系統登錄院區表格資料
     */
    const systemRows = useMemo(() => {
        return (currentCampusClinicScheduleArray || []).map((row, index) => ({
            ...row,
            indexId: index // 添加 indexId
        }));
    }, [currentCampusClinicScheduleArray]);
    const otherRows = useMemo(() => {
        return (otherCampusClinicScheduleArray || []).map((row, index) => ({
            ...row,
            indexId: index // 添加 indexId
        }));
    }, [otherCampusClinicScheduleArray]);

    // 系統登錄院區表頭
    const systemColumns = [
        {
            field: 'encounterDate',
            headerName: t("Regs.general.clinicDate"),
            width: 120,
            renderCell: (params) => time2String(params.value, "YYYY-MM-DD"),
        },
        {
            field: 'weekName',
            headerName: '星期',
            width: 80,
        },
        {
            field: 'apn',
            headerName: t("Regs.general.timeSlot"),
            width: 100,
            renderCell: (params) => {
                const apnMap = { 1: '早', 2: '午', 3: '晚' };
                return apnMap[params.value] || params.value;
            },
        },
        {
            field: 'divName',
            headerName: t('general.department'),
            width: 150,
        },
        {
            field: 'doctorName',
            headerName: t('general.doctor'),
            width: 120,
        },
        {
            field: 'clinicName',
            headerName: t("Regs.general.clinicName"),
            width: 150,
        },
        {
            field: 'totalRegLimit',
            headerName: '限額數',
            width: 100,
            type: 'number',
        },
        {
            field: 'currentRegCount',
            headerName: '掛號人數',
            width: 100,
            type: 'number',
        },
    ];


    /**
     * 第一次執行時
     */
    useMemo(() => {
        if (arrayIsEmpty(allDoctor)) {
            getQueryAllDoctor()
        }
        if (arrayIsEmpty(allDepartment)) {
            getQueryAllDepartment()
        }

        handleSearchOnClick()
    }, [])

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [displayQueryInputData.doctorCode, allDoctor, displayQueryInputData.departmentCode])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode, allDepartment, displayQueryInputData.doctorCode])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                <div className="date flex flex-row items-center justify-start space-x-2.5 mr-3.5">
                    <div className="flex flex-row items-center">
                        <DateRangePicker
                            size="small"
                            // value={[
                            //     displayQueryInputData.dateStart ? dayjs(displayQueryInputData.dateStart) : null,
                            //     displayQueryInputData.dateEnd ? dayjs(displayQueryInputData.dateEnd) : null
                            // ]}
                            required
                            onChange={handleDateRangeOnChange}
                        />
                    </div>
                </div>
                <div className="flex items-center mr-3 text-left">
                    <Select
                        data={{
                            label: "星期",
                            options: weekList?.map((item) => ({
                                value: item.justnameNo,
                                label: item.justnameName
                            })) || []
                        }}
                        onChange={handleSelectWeekOnChange}
                        width={SelectWidthEnum.Small}
                        value={displayQueryInputData.week}
                        notched={true}
                    />
                </div>
                <div className="flex flex-row justify-center items-center gap-[12px] text-base font-normal mr-3">
                    <RadioGroup
                        labelProps={{text: t('Regs.general.timeSlot')}}
                        row
                        value={displayQueryInputData.apn}
                        optionProps={{
                            options: apnList.map(item => ({
                                label: item.justnameName,
                                value: item.justnameNo,
                            }))
                        }}
                        onChange={handleApnModeOnChange} size={SizeEnum.Medium}/>
                </div>
            </div>
            <div className="flex flex-row">
                {/*醫師*/}
                <div className="flex items-center mr-2">
                    <Search
                        inputLabel={t('general.doctor')}
                        onChange={(_e, value) => {
                            handleDoctorOptionOnClick(value)
                        }}
                        onInputChange={(e) =>
                            handleInputDoctorNoOnChange(e)
                        }
                        disablePortal={false}
                        options={doctorOptionList}
                        getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}/>
                </div>

                {/*科別*/}
                <div className="flex items-center mr-2 space-x-2">
                    <Search
                        inputLabel={t('general.department')}
                        onChange={(_e, value) => {
                            handleDepartmentOptionOnClick(value)
                        }}
                        onInputChange={(e) => {
                            handleInputDepartmentNoOnChange(e)
                        }
                        }
                        disablePortal={false}
                        options={departmentOptionList}
                        getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                    />
                    {/*查詢*/}
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                        {t('general.query')}
                    </Button>
                </div>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*系統登錄院區*/}
                <div className='w-1/2 relative'>
                    <Card title='系統登錄院區'>
                        <div className="flex items-center mb-2 absolute right-5 top-2.5">
                            <div className="text-xl">目前院區：屏東義大醫院</div>
                        </div>
                        {/*系統登錄院區 Table*/}
                        <AdvancedDataGrid
                            rows={systemRows}
                            columns={systemColumns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick={false}
                            disableColumnMenu={true}
                            height={'620px'}
                            getRowHeight={(params) => 56}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <div className="flex justify-end w-[770px] mt-1.5">
                                <Pagination
                                    totalPageSize={currentCampusPaginationProps.totalPageSize}
                                    page={currentCampusPaginationProps.currentPage}
                                    pageSize={currentCampusPaginationProps.pageSize}
                                    totalSize={currentCampusPaginationProps.totalItemSize}
                                    onPageOnChange={(page, pageSize) => onCurrentCampusPaginationPageOnChange(page, pageSize)}
                                    showFirstButton
                                    showLastButton
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                {/*其他院區*/}
                <div className='w-1/2 relative'>
                    <Card title='其他院區'>
                        <div className="flex items-center mb-2 absolute right-2 top-0">
                            <Select
                                data={{
                                    label: '其他院區',
                                    options: [{value: '義大醫院', label: '義大醫院'}]
                                }}
                                value="義大醫院"
                                showLabel={false}
                                width={SelectWidthEnum.Tiny}
                            />
                        </div>
                        {/*其他院區 Table*/}
                        <AdvancedDataGrid
                            rows={otherRows}
                            columns={systemColumns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick={false}
                            disableColumnMenu={true}
                            height={'620px'}
                            getRowHeight={(params) => 56}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <div className="flex justify-end w-[770px] mt-1.5">
                                <Pagination
                                    totalPageSize={otherCampusPaginationProps.totalPageSize}
                                    page={otherCampusPaginationProps.currentPage}
                                    pageSize={otherCampusPaginationProps.pageSize}
                                    totalSize={otherCampusPaginationProps.totalItemSize}
                                    onPageOnChange={(page, pageSize) => onOtherCampusPaginationPageOnChange(page, pageSize)}
                                    showFirstButton
                                    showLastButton
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default ClinicScheduleSearch

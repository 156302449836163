import {ApiErrorStatusEnum} from 'edah_utils/dist'
import Button from '../../Button/Button'
import React, {useState} from 'react'
import {t} from 'i18next'
import useToast from '../../../hooks/useToast'
import {AlertTypeEnum, Field, TextField} from 'edah-component/dist'
import {addRmisTranCareCenter, editRmisTranCareCenter} from "../../../api/v1/Rmis";

const ButtonStyle = 'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SelectStyle = 'w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

/**
 * 長照中心維護表單
 * @returns {JSX.Element}
 */
const LongTermCareCenterForm = ({data}) => {
    // 顯示Toast
    const showToast = useToast()
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState('')
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    // 長期照護單位名稱
    const [longTermCenterName, setLongTermCenterName] = useState('')
    const handleLongTermCenterNameChange = (e) =>
        setLongTermCenterName(e.target.value)

    // 郵遞區號
    const [zipCode, setZipCode] = useState(data.zipCode || '')
    const [allZipCode, setAllZipCode] = useState([])
    const handleZipCodeChange = (e) => setZipCode(e.target.value)

    // 地址
    const [address, setAddress] = useState('')
    const handleAddressChange = (e) => setAddress(e.target.value)

    // 電話
    const [phone, setPhone] = useState('')
    const handlePhoneChange = (e) => setPhone(e.target.value)

    // 儲存
    const handleSave = async () => {
        const targetApi = data ? editRmisTranCareCenter : addRmisTranCareCenter
        const res = await targetApi({
            hospNo: hospitalCode,
            hospName: longTermCenterName,
            hospZipCode: zipCode,
            hospaddress: address,
            hospTelAreaCode: '',
            hospTel: phone,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    return (
        <>
            <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8">
                <div className="grid  grid-cols-2">
                    <Field label="院所代碼">
                        <TextField
                            value={hospitalCode}
                            onChange={handleHospitalCodeChange}
                        />
                    </Field>
                    <Field label="長期照護單位名稱" labelWidth={160}>
                        <TextField
                            value={longTermCenterName}
                            onChange={handleLongTermCenterNameChange}
                        />
                    </Field>
                </div>
                <div className="mt-2 grid  grid-cols-2">
                    <Field label={t('general.zipCode')}>
                        <select
                            className={SelectStyle}
                            value={zipCode}
                            onChange={(e) => {
                                handleZipCodeChange
                            }}
                        >
                            {allZipCode.map((item) => (
                                <option
                                    key={`zip_${item.value}`}
                                    value={item.value}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </Field>
                    <Field label={t('general.address')}>
                        <TextField
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </Field>
                </div>
                <div className="mt-2 grid  grid-cols-2]">
                    <Field label={t('general.telephone')}>
                        <TextField
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                    </Field>
                </div>
            </div>
            <div
                className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
                <Button
                    classNames={`ml-4 ${ButtonStyle}`}
                    text={t('general.saveFile')}
                    onClickFn={handleSave}
                />
            </div>
        </>
    )
}

export default LongTermCareCenterForm

import React, {useState} from "react";
import CrisisAlertAwaitReplyListPopup from "../../components/CVIS/Modal/CrisisAlertAwaitReplyListPopup";
import CrisisAlertReplyAssignmentPopup from "../../components/CVIS/Modal/CrisisAlertReplyAssignmentPopup";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    Divider,
    TextField
} from "edah-component/dist";
import {t} from "i18next";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 待回覆提示
 * @return {JSX.Element}
 */
const PendingReplyNotification = () => {
    // 帶入醫師
    const [doctor, setDoctor] = useState('9999')
    // 回覆作業查詢編號
    const [cvisID, setCvisID] = useState()
    // 危急值待回覆清單彈窗
    const [awaitListPopup, setAwaitListPopup] = useState(false)
    // 危急值回覆作業彈窗
    const [replyPopup, setReplyPopup] = useState(false)

    /**
     * 待回覆清單彈窗開關
     * @return {void}
     */
    const handleAwaitListPopup = () => {
        setAwaitListPopup(!awaitListPopup)
    }

    /**
     * 控制回覆作業彈窗開關，開啟時同步關閉待回覆清單彈窗
     * @return {void}
     */
    const handleReplyPopup = () => {
        // 確保目前回覆作業視窗是顯示狀態，回覆作業視窗關閉後，顯示待回覆清單視窗
        // setAwaitListPopup(!awaitListPopup)
        handleAwaitListPopup()
        setReplyPopup(!replyPopup)
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="w-full flex flex-row items-center justify-start mb-4">
                    <p className="mr-5">
                        <span className="mr-5">【2023/09/09】</span>
                        <span className="mr-5">【午】</span>
                        <span className="mr-5">【二樓一般外科2診】</span>
                        <span className="mr-5">【看診醫師：李醫師】</span>
                    </p>
                    <p
                        className="text-[#E53E3E] mr-4 hover:cursor-pointer"
                        onClick={handleAwaitListPopup}
                    >
                        危急值回覆
                    </p>
                    <Button sx={{marginRight: '1rem'}} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text='照會單回覆'
                    />
                    <Button sx={{marginRight: '1rem'}} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text='醫師審核'
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <TextField
                        sx={{mr: 2}}
                        label={"診號"}
                        value={""}
                        onChange={() => {
                        }}
                    />
                    <Button sx={{marginRight: '1rem'}} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text='實體IC'/>
                    <Button sx={{marginRight: '1.25rem'}} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text='虛擬IC'/>
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <Checkbox
                        id="r1"
                        label="已完成(4)"
                        onChange={() => {
                        }}
                    />
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <Checkbox
                        id="r2"
                        label="暫存(4)"
                        onChange={() => {
                        }}
                    />
                </div>
                <div className="flex flex-row space-x-3 mb-4">
                    <Checkbox
                        id="r3"
                        label="已完成(4)"
                        onChange={() => {
                        }}
                    />
                </div>
                <Divider orientation="vertical" variant='middle' sx={{mr: 2}}/>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <Checkbox
                        id="r4"
                        label="已報到"
                        onChange={() => {
                        }}
                    />
                </div>
                <div className="flex flex-row space-x-3 mb-4 mr-4">
                    <Checkbox
                        id="r5"
                        label="未報到"
                        onChange={() => {
                        }}
                    />

                </div>
                <div className="flex flex-row items-center justify-start mb-4 mr-4">
                    <Button sx={{marginRight: '0.5rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium} text={t('general.query')}/>
                </div>

            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <Card sx={{pt: 2}}>
                    <div className="searchGroup flex flex-row items-center justify-between mb-4">
                        <SearchTextField
                            sx={{mr: 2}}
                            placeholder="進階搜尋"
                            value={""}
                            onChange={() => {
                            }}
                        />
                    </div>
                    <ul className="tableList PRNTableList">
                        <li className="title">
                            <div>診號</div>
                            <div>報到</div>
                            <div>看診狀態</div>
                            <div>{t('general.patientNo')}</div>
                            <div>病患姓名</div>
                            <div>性別</div>
                            <div>年齡</div>
                            <div>身份</div>
                            <div>初/複</div>
                            <div>特殊身份</div>
                        </li>
                        <li>
                            <div>001</div>
                            <div>Y</div>
                            <div>已完成</div>
                            <div>
                                <a className="text-[#2B6CB0] underline" href="">0000123</a>
                            </div>
                            <div>黃ＯＯ</div>
                            <div>男</div>
                            <div>54歲4月9天</div>
                            <div>健保</div>
                            <div>複診</div>
                            <div></div>
                        </li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </Card>
            </div>
            {/* 危急值待回覆清單彈窗 */}
            {
                awaitListPopup &&
                <CrisisAlertAwaitReplyListPopup
                    doctor={doctor}
                    handlePopupClose={handleAwaitListPopup}
                    handleReplyPopup={handleReplyPopup}
                    handleSetCvisID={setCvisID}/>
            }
            {/* 危急值回覆作業彈窗 */}
            {
                replyPopup &&
                <CrisisAlertReplyAssignmentPopup
                    handlePopupClose={handleReplyPopup}
                    handleSaveSubmit={handleReplyPopup}
                    doctor={doctor}
                    cvisID={cvisID}/>
            }
        </div>
    )
}
export default PendingReplyNotification

import React, {useEffect, useState} from "react"
import {objectIsEmpty, stringIsEmpty} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../../hooks/useToast";
import {
    AlertTypeEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    RadioGroup,
    SizeEnum,
    TextField
} from 'edah-component/dist'
import dayjs from 'dayjs';
import {checkGender} from "../utils";

/**
 * 建立初診病人彈出框
 * @param show {Boolean} 是否顯示彈窗
 * @param closePopupButtonOnClick {Function} 關閉事件
 * @param onSave {Function} 存檔事件
 * @param data {Object} 病人資料
 * @returns
 */
const FirstVisitPatientFormPopup = ({
                                        show = false,
                                        closePopupButtonOnClick,
                                        onSave,
                                        data
                                    }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)
    /**
     * 輸入的姓名
     */
    const [inputName, setInputName] = useState("")
    /**
     * 輸入的身分證號
     */
    const [inputIdNo, setInputIdNo] = useState("")
    /**
     * 輸入的住家號碼
     */
    const [inputHomephonenumber, setInputHomephonenumber] = useState("")
    /**
     * 輸入的手機號碼
     */
    const [inputPhoneNumber, setInputPhoneNumber] = useState("")
    /**
     * 輸入的性別
     */
    const [inputGender, setInputGender] = useState("")
    /**
     * 輸入的出生日期
     */
    const [inputBirthDate, setInputBirthDate] = useState("")
    //Toast Message Hooks
    const showToast = useToast()
    /**
     * 性別list
     */
    const genderList = [
        {
            justnameNo: "1",
            justnameName: "男"
        },
        {
            justnameNo: "2",
            justnameName: "女"
        },
        {
            justnameNo: "3",
            justnameName: "不明"
        }
    ]

    /**
     * 按下存檔按鈕事件
     * @return {void}
     */
    const handleSaveOnClick = () => {

        if (stringIsEmpty(inputName)) {
            showToast({message: "姓名欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        if (stringIsEmpty(inputIdNo)) {
            showToast({message: "身分證號欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        if (stringIsEmpty(inputBirthDate)) {
            showToast({message: "出生日期欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        if (stringIsEmpty(inputHomephonenumber) && stringIsEmpty(inputPhoneNumber)) {
            showToast({message: "住家號碼或手機號碼至少填寫一個", type: AlertTypeEnum.Warning})
            return
        }

        if (!stringIsEmpty(inputPhoneNumber)) {
            //檢測手機號碼格式: 09開頭+後面8個數字
            const regex = /^09\d{8}$/;
            if (!regex.test(inputPhoneNumber)) {
                showToast({message: "手機號碼格式不正確", type: AlertTypeEnum.Warning})
                return
            }
        }

        let genderString = genderList[2].justnameName;
        if (!stringIsEmpty(inputGender)) {
            const gender = genderList.find(item => item.justnameNo === inputGender);
            if (gender != null) {
                genderString = gender.justnameName
            }
        }
        onSave(inputName, inputBirthDate, inputIdNo, genderString, inputHomephonenumber, inputPhoneNumber);
    }
    /**
     * 姓名輸入事件
     * @param e {Event}
     * @returns
     */
    const handleInputNameOnChange = (e) => setInputName(e.target.value)
    /**
     * 身分證號輸入事件
     * @param e {Event}
     * @returns
     */
    const handleInputIdNoOnChange = (e) => {
        const idNumber = e.target.value
        const regxIdNo = /^[A-Za-z][A-Za-z0-9]{9}$/;
        if(regxIdNo.test(idNumber)) {
            //判斷性別
            const genderName = checkGender(idNumber)
            const genderObj = genderList.find(item => item.justnameName === genderName);
            if(!objectIsEmpty(genderObj) && (genderObj.justnameNo === '1' || genderObj.justnameNo === '2')) {
                //判斷是男或是女時
                setInputGender(genderObj.justnameNo)
            }
        }
        setInputIdNo(e.target.value)
    }
    /**
     * 住家電話輸入事件
     * @param e {Event}
     * @returns
     */
    const handleInputHomephonenumberOnChange = (e) => setInputHomephonenumber(e.target.value)
    /**
     * 手機號碼輸入事件
     * @param e {Event}
     * @returns
     */
    const handleInputPhoneNumberOnChange = (e) => setInputPhoneNumber(e.target.value)

    /**
     * 性別選擇事件
     * @param gender {String}
     * @returns
     */
    const handleGenderModeOnChange = (gender) => {
        const genderName = checkGender(inputIdNo)
        const genderObj = genderList.find(item => item.justnameName === genderName);
        if(!objectIsEmpty(genderObj) && (genderObj.justnameNo === '1' || genderObj.justnameNo === '2')) {
            if(gender !== genderObj.justnameNo) {
                //選擇的性別與判斷出來的不同時
                showToast({message: "性別與身份證號不符", type: AlertTypeEnum.Warning})
            }
        }
        setInputGender(gender)
    }

    /**
     * 出生日期輸入事件
     * @param date {Dayjs} 選定的日期
     * @returns
     */
    const handleInputBirthDateOnChange = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');

        setInputBirthDate(formattedDate);
    }

    /**
     * 取得popup內容
     */
    const getContent = () => {
        return (
            <>
                <div className="mb-4 px-6 space-y-2">
                    <div className="space-y-3">
                        <Field label={t('general.username')} labelWidth={100} isRequired>
                            <TextField
                                error={stringIsEmpty(inputName)}
                                inputWidth={SizeEnum.Fill}
                                value={inputName}
                                onChange={handleInputNameOnChange}
                            />
                        </Field>
                        <Field label={t('general.idNumber')} labelWidth={100} isRequired>
                            <TextField
                                error={stringIsEmpty(inputIdNo)}
                                inputWidth={SizeEnum.Fill}
                                value={inputIdNo}
                                onChange={handleInputIdNoOnChange}
                            />
                        </Field>
                        <Field label={t('general.dateOfBirth')} labelWidth={100} isRequired>
                            <DatePicker
                                label=""
                                size="small"
                                disableFuture
                                value={dayjs(inputBirthDate)}
                                onChange={handleInputBirthDateOnChange}/>
                        </Field>
                        <Field label={t('general.gender.name')} labelWidth={100}>
                            <RadioGroup
                                row={true} size={SizeEnum.Medium}
                                value={inputGender}
                                optionProps={{
                                    options: genderList.map(item => ({
                                        label: item.justnameName,
                                        value: item.justnameNo
                                    }))
                                }}
                                onChange={(e) => handleGenderModeOnChange(e.target.value)}/>
                        </Field>
                        <Field label="住家號碼" labelWidth={100}>
                            <TextField
                                error={stringIsEmpty(inputHomephonenumber) && stringIsEmpty(inputPhoneNumber)}
                                inputWidth={SizeEnum.Fill}
                                value={inputHomephonenumber}
                                onChange={handleInputHomephonenumberOnChange}
                            />
                        </Field>
                        <Field label="手機號碼" labelWidth={100}>
                            <TextField
                                error={stringIsEmpty(inputHomephonenumber) && stringIsEmpty(inputPhoneNumber)}
                                inputWidth={SizeEnum.Fill}
                                value={inputPhoneNumber}
                                onChange={handleInputPhoneNumberOnChange}
                            />
                        </Field>
                    </div>
                </div>
            </>
        )
    }
    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (!show) {
            //清除欄位
            setInputName("")
            setInputIdNo("")
            setInputBirthDate("")
            setInputHomephonenumber("")
            setInputPhoneNumber("")
            setInputGender("")
        } else {
            setInputName(data.patientName)
            setInputIdNo(data.idNo)
            setInputBirthDate(data.birthDate)
            setInputHomephonenumber(data.homephonenumber)
            setInputPhoneNumber(data.mobilenumber)
            const genderName = checkGender(data.idNo)
            const gender = genderList.find(item => item.justnameName === genderName);
            if(!objectIsEmpty(gender)) {
                setInputGender(gender.justnameNo)
            }
        }
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            content={getContent()}
            title={"初診病人資訊"}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={closePopupButtonOnClick}
            onSave={handleSaveOnClick}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default FirstVisitPatientFormPopup;

import React, {useEffect, useMemo, useState} from 'react'
import {TRAN_FOREIGN_LIST_FIELDS} from '../../constants/referralSystem/disabilityCertificateList'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import CertificateEditorModal from '../../components/ReferralSystem/DisabilityCertificate/CertificateEditorModal'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    validDateFormat,
} from 'edah_utils/dist'
import {t} from 'i18next'
import useToast from '../../hooks/useToast'
import {queryTranForeignBySearch, trancaseQueryBasicData} from '../../api/v1/Rmis'
import {completePrintMode, updatePrintContent,} from '../../redux/Slice/printSlice'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import store from '../../redux/store'
import {useSelector} from 'react-redux'
import {addTab} from '../../redux/Slice/TabSlice'
import {LONG_TERM_CARE_CENTER_NO} from '../../constants/menuFuncNo'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    DateRangePicker,
    IconEnum,
    SizeEnum,
    TextField
} from "edah-component/dist";
import dayjs from "dayjs"

/**
 * 病症暨失能診斷證明書
 * @returns {JSX.Element}
 */
const DisabilityCertificateList = () => {
    const [dataList, setDataList] = useState([])

    /**
     * 欄位檢核
     */
    const [inputState, setInputState] = useState({
        tranOutStartDate: null,
        tranOutEndDate: null,
    })

    /**
     * 顯示編輯視窗
     */
    const [isShowCertificateEditorModal, setIsShowCertificateEditorModal] =
        useState(false)

    const [editRow, setEditRow] = useState(null)

    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        tranOutStartDate: '',
        tranOutEndDate: '',
        patientId: '',
        hospGrade: '',
        acceptFlag: '',
        condition: '',
        aidGradeFlag: false,
        ruralType: false,
    })

    const [baseData, setBaseData] = useState({
        divList: [],
        doctorList: []
    })

    // toast message
    const showToast = useToast()

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 編輯 or 新增內容
     */
    const [isEdit, setIsEdit] = useState(false)

    /**
     * 更新搜尋條件
     * @param e {Event} 事件
     * @param field {String} 欄位
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 查詢日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            tranOutStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            tranOutEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 編輯按鈕
     * @param {object} referralCase 要編輯的單筆資料
     * @returns {JSX.Element}
     */
    const Editor = (referralCase) => (
        <Button
            icon={IconEnum.Edit}
            text={t('general.edit')}
            variant={ButtonVariantEnum.Text}
            color={ButtonColorEnum.Primary}
            size={ButtonSizeEnum.Medium}
            onClick={() => {
                showEditModal(referralCase)
            }}
        />
    )

    const exportExcel = () => {
        const selectedList = dataList.filter((item) => item.isSelected)
        if (selectedList.length === 0) {
            return
        }
        const dataArray = []
        const fields = TRAN_FOREIGN_LIST_FIELDS.slice(1)
        selectedList.forEach((item) => {
            const data = []
            fields.forEach((field) => {
                const value = field.format
                    ? field.format(item[field.key])
                    : item[field.key]

                data.push(value)
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: fields.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '病症暨失能診斷證明書_清單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <Button
                icon={IconEnum.Export}
                text={t('general.export')}
                variant={ButtonVariantEnum.Text}
                color={ButtonColorEnum.Success}
                size={ButtonSizeEnum.Large}
                onClick={exportExcel}
            />
        )
    }

    /**
     * table cell checkbox
     * @param {object} referralCase
     * @returns
     */
    const CusomtCheckbox = (value) => {
        return (
            <Checkbox
                checked={value}
                readOnly
            />
        )
    }

    /**
     * 轉出清單 table 顯示欄位
     */
    const renderFields = useMemo(() => {
        const fields = TRAN_FOREIGN_LIST_FIELDS
        return fields.map((item) => {
            switch (item.key) {
                case 'edit':
                    return {
                        ...item,
                        render: Editor,
                    }
                case 'barthelFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            CusomtCheckbox(!!referralCase.barthelFlag),
                    }
                case 'cdrFlag':
                    return {
                        ...item,
                        render: (referralCase) =>
                            CusomtCheckbox(!!referralCase.cdrFlag),
                    }
                default:
                    return item
            }
        })
    })

    /**
     * 查詢病症暨失能診斷證明書清單
     */
    const queryDisabilityCertificateList = async (pageNum, pageSize) => {
        const tranOutStartDateIsValid = validDateFormat(
            searchParams.tranOutStartDate
        )
        const tranOutEndDateIsValid = validDateFormat(
            searchParams.tranOutEndDate
        )
        setInputState({
            tranOutStartDate: tranOutStartDateIsValid,
            tranOutEndDate: tranOutEndDateIsValid,
        })
        if (!tranOutStartDateIsValid || !tranOutEndDateIsValid) {
            return
        }
        const result = await queryTranForeignBySearch({
            applicantStartDate: `${searchParams.tranOutStartDate} 00:00:00`,
            applicantEndDate: `${searchParams.tranOutEndDate} 00:00:00`,
            search: searchParams.patientId,
            pageNum: pageNum,
            pageSize: pageSize,
        })

        if (result.err === ApiErrorStatusEnum.Success) {
            const list = result?.data?.dataList || []
            if (arrayIsEmpty(list.length)) {
                showToast({
                    message: '查無資料',
                    type: AlertTypeEnum.Warning,
                })
            }
            const selectableList = result?.data?.dataList?.map((item) => ({
                ...item,
                isSelected: false,
                applyName: item.patientName,
            }))
            setDataList(selectableList)
            setFilter({
                ...filter,
                total: result?.data?.totalItemSize,
                totalPage: result?.data?.totalPageSize,
            })
        } else {
            showToast({
                message: result.msg,
                type: AlertTypeEnum.Error,
            })
        }
    }

    const showCertificateEditorModal = () => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(false)
    }

    const handleQueryOnClick = () => {
        queryDisabilityCertificateList(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        queryDisabilityCertificateList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        queryDisabilityCertificateList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        queryDisabilityCertificateList(page, filter.pageSize)
    }

    /**
     * 開啟回覆個管作業_維護modal
     * @param {object} referralCase 點選編輯的單筆資料
     */
    const showEditModal = (referralCase) => {
        setIsShowCertificateEditorModal(true)
        setIsEdit(true)
        setEditRow(referralCase)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item, itemIndex) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }
    /**
     * 列印申請人信封
     */
    const handlePrintEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.applyEnvelope,
                printData,
            })
        )
    }

    /**
     * 列印長照信封
     */
    const handlePrintLongTermCareEnvelope = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.longTermCareEnvelope,
                printData,
            })
        )
    }

    const handleClose = () => {
        setIsShowCertificateEditorModal(false)
        handleQueryOnClick()
    }

    const handleOpenTab = () => {
        store.dispatch(
            addTab({id: LONG_TERM_CARE_CENTER_NO, name: '長照中心維護'})
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTranCaseQueryBasicData = () => {
        trancaseQueryBasicData({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData({
                    divList: res.data.divList,
                    doctorList: res.data.doctorList
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    useEffect(() => {
        getTranCaseQueryBasicData()
    }, [])

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between">
                <div className="flex flex-wrap p-2">
                    <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                        {/* 查詢日期起訖 */}
                        <DateRangePicker
                            size={SizeEnum.Small}
                            required
                            localeText={{
                                start: '查詢開始日期',
                                end: '查詢結束日期',
                            }}
                            value={[
                                searchParams.tranOutStartDate ? dayjs(searchParams.tranOutStartDate) : null,
                                searchParams.tranOutEndDate ? dayjs(searchParams.tranOutEndDate) : null,
                            ]}
                            onChange={handleDateRangeChange}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <TextField
                            label="病歷號/身分證號"
                            value={searchParams.patientId}
                            onChange={(e) => updateSearchParams(e, 'patientId')}
                        />
                    </div>
                    <Button
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleQueryOnClick}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
                <div className="flex flex-wrap items-center justify-between p-2 mb-2">
                    <div className="flex flex-wrap gap-2">
                        <Button
                            text='長照中心維護'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={handleOpenTab}
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                        />
                        <Button
                            text='申請人信封'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handlePrintEnvelope()}
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                        />
                        <Button
                            text='長照信封'
                            color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handlePrintLongTermCareEnvelope()}
                            sx={{fontWeight: 'bold', height: '2.5rem'}}
                        />
                    </div>
                </div>
            </div>
            <Button
                icon={IconEnum.Add}
                text='新增內容'
                color={ButtonColorEnum.Success}
                variant={ButtonVariantEnum.Text}
                size={ButtonSizeEnum.Large}
                onClick={() => showCertificateEditorModal(true)}
                sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '3px'}}
            />
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
                slotButton={ExportExcelBtn()}
            />
            <div className="flex justify-end mt-2">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {isShowCertificateEditorModal && (
                <CertificateEditorModal
                    popupOnclose={() => setIsShowCertificateEditorModal(false)}
                    onClose={handleClose}
                    isEdit={isEdit}
                    applicantNo={editRow?.applicantNo}
                    doctorList={baseData.doctorList}
                    divList={baseData.divList}
                />
            )}
        </div>
    )
}
export default DisabilityCertificateList

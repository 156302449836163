import React, {useEffect, useMemo, useState} from 'react'
import {usePopup} from '../PopupProvider'
import {time2String} from 'edah_utils/dist'
import {rsisQueryMail} from "../../../api/v1/RSIS";
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum,
    SizeEnum,
    DatePicker,
    Search,
    AlertTypeEnum,
    TextField
} from "edah-component/dist"
import {t} from "i18next"
import dayjs from "dayjs";

/**
 * Mail警示信箱發送清單列表項目
 * @param item {object} 列表項目
 * @param onSave {function} 保存事件
 * @param onCancel {function} 取消事件
 * @param dropListData {Array<object>} 下拉選單資料
 * @return {JSX.Element}
 * @constructor
 */
const ListItem = ({item, onSave, onCancel, dropListData}) => {
    const [userList, setUserList] = useState([])
    const {setShowDeletePopup, setFocusItem, showToast} = usePopup()
    const [editedItem, setEditedItem] = useState(item)
    const [show, setShow] = useState(false)
    const {setShowQueryCreateUserList, focusItem} = usePopup()

    /**
     * 刪除列表項目
     * @return {void}
     */
    const handleOnDelete = () => {
        setFocusItem(item)
        setShowDeletePopup(true)
    }

    /**
     * 取消編輯
     * @return {void}
     */
    const handleOnCancel = () => {
        if (item.isNew) {
            onCancel(item.mailListsUUid)
        } else {
            setEditedItem(item)
        }
    }

    /**
     * 變更列表狀態為編輯狀態
     * @return {void}
     */
    const handleEdit = () => {
        // 設定為編輯狀態
        setEditedItem(prev => ({
            ...prev,
            editable: true
        }))
    }

    /**
     * 保存列表項目
     * @return {void}
     */
    const handleOnSave = () => {
        const startDate = new Date(time2String(editedItem.effStartDatetime, 'YYYY-MM-DD'))
        const endDate = new Date(time2String(editedItem.effEndDatetime, 'YYYY-MM-DD'))
        // 驗證結束日期是否早於開始日期
        if (endDate < startDate) {
            showToast({message: "結束日期不能早於開始日期", type: AlertTypeEnum.Warning})
            return;
        }
        // 保存列表項目
        onSave(editedItem, () => setEditedItem(prev => ({...prev, editable: false})))
    }

    /**
     * 當員工代號改變時
     * @param item {Object} 員工物件
     * @return {void}
     */
    const handleUserChange = (item) => {
        setEditedItem(prev => ({
            ...prev,
            userNo: item.userNo,
            userName: item.userName
        }))
    }

    const handleInputChange = (field, value) => {
        if (field === 'effStartDatetime' || field === 'effEndDatetime') {
            const formattedDate = formatDateTime(value);
            setEditedItem(prev => {
                const newState = {...prev, [field]: formattedDate};
                return newState;
            })
        } else {
            setEditedItem(prev => {
                const newState = {...prev, [field]: value};
                return newState;
            })
        }
    }

    const handleMailTypeSelect = (selectedMailType) => {
        setEditedItem(prev => {
            const newState = {
                ...prev,
                mailType: selectedMailType.codeNo,
                codeValue1: selectedMailType.codeValue1
            };
            return newState;
        });
        setShow(false);
    }

    /**
     * 當開始日期改變時
     * @param newValue {dayjs} 開始日期
     * @return {void}
     */
    const handleStartDateChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            setEditedItem(prev => ({
                ...prev,
                effStartDatetime: newValue.format('YYYY-MM-DD')
            }));
        }
    };

    /**
     * 當結束日期改變時
     * @param newValue {dayjs} 結束日期
     * @return {void}
     */
    const handleEndDateChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            setEditedItem(prev => ({
                ...prev,
                effEndDatetime: newValue.format('YYYY-MM-DD')
            }));
        }
    };

    /**
     * 根據編輯狀態渲染按鈕
     * @return {JSX.Element}
     */
    const renderSlotBtn = () => {
        if (editedItem.editable) {
            return (
                <div className='flex flex-row gap-2'>
                    {/*儲存按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} icon={IconEnum.Save}
                        text={t('general.save')} variant={ButtonVariantEnum.Text}
                        onClick={handleOnSave}/>
                    {/*取消按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} icon={IconEnum.Cancel}
                        text={t('general.cancel')} variant={ButtonVariantEnum.Text}
                        onClick={handleOnCancel}/>
                </div>
            )
        } else {
            return (
                <div className='flex flex-row gap-2'>
                    {/*編輯按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large}
                        icon={IconEnum.Edit}
                        text={t('general.edit')} variant={ButtonVariantEnum.Text}
                        onClick={handleEdit}/>
                    {/* 刪除按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large}
                        icon={IconEnum.Delete}
                        text={t('general.delete')} variant={ButtonVariantEnum.Text}
                        onClick={handleOnDelete}/>
                </div>
            )
        }
    }

    /**
     * 獲取郵件用戶列表
     * @return {Promise<void>}
     */
    const getAllMailUsers = async () => {
        try {
            const response = await rsisQueryMail({});
            if (response.statusCode === 0) {
                setUserList(response.data);
            } else {
                showToast({message: "獲取郵件用戶列表失敗", type: "error"})
            }
        } catch (error) {
            showToast({message: "獲取郵件用戶列表時發生錯誤", type: "error"})
        }
    }

    /**
     * 取得預設事件代號
     */
    const getDefaultMailEventValue = useMemo(() => {
            const data = dropListData?.find(option => editedItem.mailType?.includes(option?.codeNo || ''))
            return data && editedItem.mailType ? data : null
        }, [dropListData, editedItem.mailType]
    )


    // 監聽focusItem, 當focusItem改變時，將focusItem的值設定到editedItem
    useEffect(() => {
        if (focusItem) handleUserChange(focusItem)
    }, [focusItem])

    // 第一次執行時
    useMemo(() => {
        getAllMailUsers()
    }, []);

    // 當item改變時，若為新增的ListItem確保初始化時資料為空值
    useEffect(() => {
        if (item.isNew) {
            setEditedItem({
                mailType: "",
                codeValue1: "",
                userNo: "",
                userName: "",
                effStartDatetime: "",
                effEndDatetime: "",
                memo: "",
                ...item
            });
        }
    }, [item]);

    return (
        <li key={item.mailListsUUid}>
            {renderSlotBtn()}
            {
                editedItem.editable ? (
                        <>
                            {/*事件代號*/}
                            <div className='flex items-center relative'>
                                {/*TODO: 當把這邊 Table 換成元件庫之後。以下的 sx 可以移除，目前是為了讓叉叉不會跑板才加的*/}
                                <Search
                                    sx={{
                                        "& .MuiAutocomplete-endAdornment": {
                                            position: 'absolute !important',
                                            width: '30px !important',
                                            right: '20px !important'
                                        }
                                    }}
                                    freeSolo
                                    value={getDefaultMailEventValue}
                                    onChange={(_e, value) => {
                                        handleMailTypeSelect(value)
                                    }}
                                    disablePortal={false}
                                    onInputChange={(e, value) => handleInputChange('mailType', value)}
                                    options={dropListData}
                                    getOptionLabel={(option) => `${option.codeNo}`}
                                />
                            </div>
                            {/*事件說明*/}
                            <div>
                                <TextField
                                    disabled={true} value={editedItem.codeValue1}
                                    onChange={(e) => handleInputChange('codeValue1', e.target.value)}/>
                            </div>
                            {/*員工代號*/}
                            <div>
                                <TextField
                                    inputWidth={SizeEnum.Small}
                                    value={`${editedItem ? `${editedItem.userNo} ${editedItem.userName}` : ''}`}
                                    onClick={(e) => setShowQueryCreateUserList(true)}/>
                            </div>
                            {/*警示信箱發送-開始日期*/}
                            <div>
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={editedItem.effStartDatetime ? dayjs(editedItem.effStartDatetime) : null}
                                    onChange={handleStartDateChange}
                                />
                            </div>
                            {/*警示信箱發送-結束日期*/}
                            <div>
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={editedItem.effEndDatetime ? dayjs(editedItem.effEndDatetime) : null}
                                    onChange={handleEndDateChange}
                                />
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div>{item.mailType ? item.mailType : ''}</div>
                            <div>{item.codeValue1 ? item.codeValue1 : ''}</div>
                            <div>{`${item.userNo} ${item.userName}`}</div>
                            <div>{item.effStartDatetime ? item.effStartDatetime.split(' ')[0] : ''}</div>
                            <div>{item.effEndDatetime ? item.effEndDatetime.split(' ')[0] : ''}</div>
                        </>
                    )
            }
        </li>
    )
}

export default ListItem

import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, time2String,} from "edah_utils/dist";
import {regsCancelRegSave} from "../../../api/v1/Regs";
import {queryJustNameByCategories} from "../../../api/v1/Menu";
import useToast from "../../../hooks/useToast";
import {AlertTypeEnum, Field, Select, Dialog, DialogVariant, CommonColorEnum} from "edah-component/dist";

/**
 * 退掛確認彈窗
 * @param show {Boolean} 是否顯示
 * @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 * @param candidateDatas {Array} 候選資料
 * @param cancelRegReasonList {Array} 取消原因List
 * @returns
 */
const CancelAppointmentMessagePopup = ({
                                           show = false,
                                           closePopupButtonOnClick,
                                           candidateDatas,
                                           handleCancelResult,
                                       }) => {
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show);
    // 取消原因list
    const [cancelRegReasonList, setCancelRegReasonList] = useState(null);
    //取消的原因
    const [cancelReason, setCancelReason] = useState("");
    //Toast Message Hooks
    const showToast = useToast();

    /**
     * 取得退掛原因類別陣列
     */
    const getCancelRegReasonTypeArray = () => {
        queryJustNameByCategories({
            // 類別: 退掛原因
            categories: "CANCEL_REG_REASON",
        }).then((res) => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res;
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定退掛原因類別資料
                if (arrayIsEmpty(data)) {
                    setCancelRegReasonList(null);
                    setCancelReason(null);
                } else {
                    setCancelRegReasonList(data);
                    setCancelReason(data[0].justnamedNo);
                }
            } else {
                // 取得資料失敗
                setCancelRegReasonList(null);
                setCancelReason(null);
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        //將regId用逗號串起來
        const regIdString = candidateDatas.map((item) => item.regId).join(",");

        regsCancelRegSave({
            regIdList: regIdString,
            cancelRegReasoncode: cancelReason,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //刪除成功
                handleCancelResult(true, "已取消掛號");
            } else {
                handleCancelResult(false, res.msg);
            }
        });
    };

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick();
    };

    /**
     * 取消原因變更時
     * @param value {String}
     */
    const handleCancelReasonOnChange = (value) => {
        setCancelReason(value);
    };

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="text-left text-lg font-normal px-16 pb-4 pt-2">
                是否確認取消
            </div>

            {/**要被取的資料列表 */}
            <div className="px-24">
                <ul className="mb-4 max-h-[250px] lg:max-h-[350px] overflow-y-auto space-y-2 text-left font-medium text-lg">
                    {candidateDatas.map((item, index) => (
                        <li key={index}>
                            {time2String(item.encounterDate, "YYYY-MM-DD")}{" "}
                            {item.patientName} {item.divName} -{" "}
                            {item.doctorName}
                        </li>
                    ))}
                </ul>
            </div>
            {/**原因 */}
            <div className="flex flex-row px-16 mb-6 items-center text-left">
                <Field label="原因" labelWidth={60}>
                    <Select
                        data={{
                            label: "原因",
                            options:
                                cancelRegReasonList?.map((reason) => ({
                                    value: reason.justnamedNo,
                                    label: reason.justnamedName,
                                })) || [],
                        }}
                        value={cancelReason}
                        onChange={handleCancelReasonOnChange}
                        showLabel={false}
                    />
                </Field>
            </div>
        </>
    );
    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (show) {
            getCancelRegReasonTypeArray();
            //setCancelReason(cancelRegReasonList[0].justnameNo)
        } else {
        }
        setShowPopup(show);
    }, [show]);
    return (
        <Dialog
            open={showPopup}
            title={"提醒"}
            content={candidateDatas && getContent()}
            variant={DialogVariant.DECISION}
            onClose={handleCancelOnClick}
            onConfirm={handleConfirmOnClick}
        />
    )
};

export default CancelAppointmentMessagePopup;

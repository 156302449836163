//Import css
import '../../TableList/tableList.scss';

//Import function
import React, {useEffect, useState} from 'react';
import {stringIsEmpty, time2String} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import {t} from "i18next";
import { Checkbox } from 'edah-component/dist';

/**
 * RSIS整批變更註銷或刪除-申請列表組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @param isSelectedAll {boolean} 是否包含全選
 * @param handleOnSelected {Function} 選取事件
 * @param handleOnUnSelected {Function} 取消選取事件
 * @return {JSX.Element}
 */
const RegistryListItem = ({item, index, isSelectAll = null, handleOnSelected, handleOnUnSelected}) => {
    // 使用上下文
    const {
        cleanBatchSelected,
        setFocusIndex
    } = usePopup();

    const [isSelected, setIsSelected] = useState(false)

    /**
     * 勾選框觸發事件
     * @param {Event} e
     * @return {void}
     */
    const handleOnSelectedChange = (e) => {
        setIsSelected(e.target.checked)
        if (e.target.checked) {
            handleOnSelected(item);
        } else {
            handleOnUnSelected(item);
        }
    }

    useEffect(() => {
        setIsSelected(false)
    },[cleanBatchSelected])

    // 全選
    useEffect(() => {
        setIsSelected(isSelectAll)
    }, [isSelectAll]);

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 選擇框 */}
            <div>
                <Checkbox
                    checked={isSelected}
                    onChange={handleOnSelectedChange}
                />
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportNo}</p>
                <p className='w-full'>{t(`campus.shortName.${item.zone}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.createUser}</p>
                <p className='w-full'>{item.createUserName}</p>
            </div>
            {/* 支援醫師 姓名 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportUserNo}</p>
                <p className='w-full'>{item.supportUserName}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportOrgNo}</p>
                <p className='w-full'>{item.supportOrgName}</p>
            </div>
            {/* 支援科別 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportDivNo}</p>
                <p className='w-full'>{item.supportDivName}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col'>
                <p className='w-full'>{`${time2String(item.itemStartDate, 'YYYY-MM-DD')} ${item.itemStartTime.slice(0, 2)+':'+item.itemStartTime.slice(2)}`}</p>
                <p className='w-full'>{`${time2String(item.itemEndDate, 'YYYY-MM-DD')} ${item.itemEndTime.slice(0, 2)+':'+item.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {t(`RSIS.StepStatusEnum.${item.stepStatus}`)}
            </div>
            {/* 匯出編號 */}
            <div>{item.sendFileName}</div>
            {/* 衛福部案號 */}
            <div>{item.sendCaseNo}</div>
        </li>
    )
}

/**
 * RSIS整批變更註銷或刪除-送審列表組件
 * @param item {Object} 清單資料
 * @param index {Number} 清單索引
 * @param isSelectedAll {boolean} 是否包含全選
 * @param handleOnSelected {Function} 選取事件
 * @param handleOnUnSelected {Function} 取消選取事件
 * @return {JSX.Element}
 */
const SubmitListItem = ({item, index, isSelectAll = false, handleOnSelected, handleOnUnSelected}) => {
    // 使用上下文
    const {
        cleanBatchSelected,
        setFocusIndex
    } = usePopup();

    // 是否選取
    const [isSelected, setIsSelected] = useState(false)

    /**
     * 勾選框觸發事件
     * @param {Event} e
     * @return {void}
     */
    const handleOnSelectedChange = (e) => {
        setIsSelected(e.target.checked)
        if (e.target.checked) {
            handleOnSelected(item);
        } else {
            handleOnUnSelected(item);
        }
    }

    useEffect(() => {
        setIsSelected(false)
    },[cleanBatchSelected])

    // 全選
    useEffect(() => {
        setIsSelected(isSelectAll)
    }, [isSelectAll]);

    return (
        <li key={index} onClick={() => setFocusIndex(index)}>
            {/* 選擇框 */}
            <div>
                <Checkbox
                    checked={isSelected}
                    onChange={handleOnSelectedChange}
                />
            </div>
            {/* 申請單號、院區名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportNo}</p>
                <p className='w-full'>{t(`campus.shortName.${item.zone}`)}</p>
            </div>
            {/* 申請人 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.createUser}</p>
                <p className='w-full'>{item.createUserName}</p>
            </div>
            {/* 支援醫師 姓名 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportUserNo}</p>
                <p className='w-full'>{item.supportUserName}</p>
            </div>
            {/* 支援機構代號、名稱 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportOrgNo}</p>
                <p className='w-full'>{item.supportOrgName}</p>
            </div>
            {/* 支援科別 */}
            <div className='flex flex-col'>
                <p className='w-full'>{item.supportDivNo}</p>
                <p className='w-full'>{item.supportDivName}</p>
            </div>
            {/* 報備期間、開始-結束日期 */}
            <div className='flex flex-col'>
                <p className='w-full'>{`${time2String(item.itemStartDate, 'YYYY-MM-DD')} ${item.itemStartTime.slice(0, 2) + ':' + item.itemStartTime.slice(2)}`}</p>
                <p className='w-full'>{`${time2String(item.itemEndDate, 'YYYY-MM-DD')} ${item.itemEndTime.slice(0, 2) + ':' + item.itemEndTime.slice(2)}`}</p>
            </div>
            {/* 狀態別 */}
            <div>
                {t(`RSIS.StepStatusEnum.${item.stepStatus}`)}
            </div>
            <div>
                {item.supportModifyStatus === '0' || item.supportModifyStatus === '' || stringIsEmpty(item.supportModifyStatus) ? '' : t(`RSIS.SupportModifyStatusEnum.${item.supportModifyStatus}`)}
            </div>
            {/* 匯出編號 */}
            <div>{item.sendFileName}</div>
            {/* 衛福部案號 */}
            <div>{item.sendCaseNo}</div>
        </li>
    );
}

export {RegistryListItem, SubmitListItem};

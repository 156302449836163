import '../../TableList/tableList.scss'
import React, {useEffect, useState} from 'react'
import {ApiErrorStatusEnum, objectIsEmpty, multiTermFuzzySearchObjects, fuzzySearchObjects} from "edah_utils/dist"
import CvisListItem from "../CvisListItem"
import {fuseObject} from "../utils"
import {countCvisResponseNumber, queryCvisResponseByStatus,} from "../../../api/v1/Critical"
import {t} from "i18next"
import {Dialog, DialogSizeEnums, DialogVariant, Field, TextField} from "edah-component/dist";
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import {Pagination} from "../../Pagination/Pagination";

/**
 * 危急值待回覆清單彈窗
 * @param doctor {String} 主治醫師，需帶入查詢未回覆清單及回覆未回覆筆數API
 * @param handleSetCvisID {function} 設定回覆作業查詢編號
 * @param handlePopupClose {function} 待回覆清單彈窗事件
 * @param handleReplyPopup {function} 回覆作業彈窗事件
 * @return {JSX.Element}
 */
const CrisisAlertAwaitReplyListPopup = ({
                                            doctor = "9999",
                                            handleSetCvisID,
                                            handlePopupClose,
                                            handleReplyPopup
                                        }) => {
    // 危急值待回覆清單
    const [awaitReplyList, setAwaitReplyList] = useState([])
    // 過濾危急值待回覆清單
    const [filteredItems, setFilteredItems] = useState([])
    // 未回覆筆數
    const [unReplyQty, setUnReplyQty] = useState()
    // 已回覆筆數
    const [repliedQty, setRepliedQty] = useState()
    // 回覆狀態查詢條件
    const [queryStatus, setQueryStatus] = useState('')
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // 當前頁碼
    const [currentPage, setCurrentPage] = useState(1)
    // 當前頁面資料筆數
    const [pageSize, setPageSize] = useState(10)
    // 總筆數
    const [totalItemSize, setTotalItemSize] = useState(0)
    // 總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    /**
     * @param index {Number} 選擇的索引
     * @return {void}
     */
    const handleReplyWithCvisID = (index) => {
        if (!objectIsEmpty(awaitReplyList[index])) handleSetCvisID(awaitReplyList[index].cvisNotifyId)
        handleReplyPopup()
    }
    /**
     * 控制回覆狀態選擇事件
     * @param event {Event}
     * @return {void}
     */
    const handleQueryStatusOnChange = (event) => setQueryStatus(event.target.value)

    /**
     * 控制Query輸入框內的值變動事件
     * @param event {Event}
     */
    const handleQueryInputOnChange = (event) => {
        if (event.target.value === ' ')
            setQueryString('');
        else
            setQueryString(event.target.value.toUpperCase())
    }

    /**
     * 控制頁碼變動事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const handlePaginationOnChange = (page) => {
        //設定當前頁碼
        setCurrentPage(page)
    }

    /**
     * 每頁筆數變動事件
     * @param e {Event} 每頁筆數
     * @return {void}
     */
    const handlePaginationOnPageSizeChange = (e) => {
        //取得每頁筆數
        const {value} = e.target
        //設定每頁筆數
        setPageSize(parseInt(value))
    }

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const handlePaginationOnPrevPageOnClick = () => {
        //取得上一頁
        const page = currentPage - 1 > 0 ? currentPage - 1 : 1
        //設定當前頁碼
        setCurrentPage(page)
    }

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const handlePaginationOnNextPageOnClick = () => {
        //取得下一頁
        const page = currentPage + 1 <= totalPageSize ? currentPage + 1 : totalPageSize
        //設定當前頁碼
        setCurrentPage(page)
    }

    /**
     * 查詢危急值回覆清單
     * @return {void}
     */
    const getQueryResponseByStatus = () => {
        // 根據狀態取得危急值待回覆清單
        queryCvisResponseByStatus({
            "cvisResponseUser": doctor,                           //doctor,
            "cvisResponseStatus": queryStatus,                    //空白：全部，1：未回覆，2：已回覆,
            "pageNum": currentPage,
            "pageSize": pageSize
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 取得資料 /
                const {dataList, totalPageSize, totalItemSize} = res.data
                setAwaitReplyList(dataList)
                setFilteredItems(dataList)
                // 設定總頁數
                setTotalPageSize(totalPageSize)
                // 設定總筆數
                setTotalItemSize(totalItemSize)
            } else { // 取得失敗
                if (res.err === ApiErrorStatusEnum.Error) {
                    // 清空資料
                    setAwaitReplyList([])
                    // 清空過濾資料
                    setFilteredItems([])
                    // 清空當前頁碼
                    setCurrentPage(1)
                    // 清空每頁資料筆數
                    setPageSize(10)
                    // 清空總筆數
                    setTotalItemSize(0)
                    // 清空總頁數
                    setTotalPageSize(0)
                }
            }
        })
    }

    /**
     * 監聽事件，當每頁筆數或當前頁碼改變呼叫API重新取得清單
     * @return {void}
     */
    useEffect(() => {
        //取得資料
        getQueryResponseByStatus()
    }, [pageSize, currentPage])

    /**
     * 回覆狀態監聽事件，當回覆狀態改變呼叫API重新取得清單
     * @return {void}
     */
    useEffect(() => {
        getQueryResponseByStatus()
    }, [queryStatus])

    /**
     * @return {void}
     */
    useEffect(() => {
        if (queryString) {
            // const result = fuseObject(awaitReplyList).search(queryString)
            const result = fuzzySearchObjects(awaitReplyList, queryString)
            console.log(result)
            setFilteredItems(result)
            // setFilteredItems(result.map(item => item.item))
        } else {
            setFilteredItems(awaitReplyList)
        }
    }, [queryString, awaitReplyList])

    /**
     * 初始化後取得未回覆筆數及已回覆筆數
     * @return {void}
     */
    useEffect(() => {
        // 取得危急值回覆未回覆筆數
        countCvisResponseNumber({
            "cvisResponseUser": doctor,
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setUnReplyQty(res.data.noReplyNumber)
                setRepliedQty(res.data.repliesNumber)
            } else {
                console.error(res)
            }
        }))
    }, [])

    return (
        <Dialog
            open={true}
            title={t('CVIS.page.CrisisAlertAwaitReplyListPopup')}
            content={
                <div className="w-full p-4">
                    {/* 主要內容 */}
                    <div className='w-full py-4 flex flex-col gap-4'>
                        {/* 醫生及回覆資訊 */}
                        <div className='w-full flex flex-row justify-between items-center'>
                            <div className='w-[210px] h-10 flex flex-row justify-start items-center'>
                                <TextField label="主治醫師" value='張醫師' disabled/>
                            </div>
                            <div className='flex flex-row w-[366px] justify-end items-center gap-4'>
                                <select
                                    className="w-[102px] h-10 px-2 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    value={queryStatus}
                                    onChange={handleQueryStatusOnChange}
                                >
                                    <option value="">全部</option>
                                    <option value="1">未回覆</option>
                                    <option value="2">已回覆</option>
                                </select>
                                <Field label="未回覆筆數">{unReplyQty}</Field>
                                <Field label="已回覆筆數">{repliedQty}</Field>
                            </div>
                        </div>
                        {/* 資料內容 */}
                        <div
                            className=' flex flex-col w-full h-fit max-h-[864px] p-2 border-[1px] border-[#D4D4D8] rounded-[6px] gap-2 overflow-y-scroll'>
                            {/* 搜尋欄 */}
                            <SearchTextField
                                placeholder={t('general.advancedSearch')}
                                value={queryString}
                                onChange={(event) => handleQueryInputOnChange(event)}
                            />

                            {/* 清單表格 */}
                            <div className='min-w-[1541px]'>
                                <ul className='tableList crisisAlertAwaitReplyList max-h-[736px]'>
                                    <li className='title'>
                                        <div>回覆</div>
                                        <div>通報項目</div>
                                        <div>事件等級</div>
                                        <div>通知日期時間</div>
                                        <div>病歷號</div>
                                        <div>{t('general.username')}</div>
                                        <div>回復狀態</div>
                                        <div>回復期限</div>
                                        <div>狀態</div>
                                    </li>
                                    {/* API資料 */}
                                    {
                                        !objectIsEmpty(awaitReplyList) && filteredItems.map((item, index) =>
                                            <CvisListItem
                                                key={index}
                                                index={index} item={item} type='AwaitReplyList'
                                                handleItemOnClick={() => handleReplyWithCvisID(index)}/>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <Pagination
                            totalPageSize={totalPageSize} pageSize={pageSize}
                            totalSize={totalItemSize} currentPage={currentPage}
                            onPageOnChange={(page) => handlePaginationOnChange(page)}
                            onPageSizeChange={handlePaginationOnPageSizeChange}
                            onPrevPageOnClick={handlePaginationOnPrevPageOnClick}
                            onNextPageOnClick={handlePaginationOnNextPageOnClick}/>
                    </div>
                </div>
            }
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            muiDialogContentProps={{dividers: true}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            onClose={handlePopupClose}/>
    )
}

export default CrisisAlertAwaitReplyListPopup

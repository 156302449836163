import React, {useEffect, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {INP_FIELDS, OPD_ER_FIELDS,} from '../../constants/referralSystem/queryPatientVisitListModal'
import {queryOpdInpTestData} from '../../api/v1/Rmis'
import {t} from 'i18next'
import {ApiErrorStatusEnum, enum2Array} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"

const TABLE_TYPE = Object.freeze({
    /**
     * 門/急
     */
    opdEr: 0,
    /**
     * 住院
     */
    inp: 1,
})

/**
 * 選單列舉
 */
const OptionEnums = Object.freeze({
    /**
     * 一年內病歷
     */
    YEAR: {label: "一年內病歷", value: 'YEAR'},
    /**
     * 所有病歷
     */
    ALL: {label: "所有病歷", value: 'ALL'},
})


/**
 * 看診清單查詢
 * @return {JSX.Element}
 */
const QueryPatientVisitList = () => {
    /**
     * 顯示Toast
     */
    const showToast = useToast()

    //查詢條件
    const [params, setParams] = useState({
        //病歷號/身分證號
        id: '',
        //姓名
        patientName: '',
        //Record
        patientRecord: '',
    })

    // 門/急清單
    const [opdErDataList, setOpdErDataList] = useState([])

    // 住院清單
    const [inpDataList, setInpDataList] = useState([])

    //門/急 table 頁碼
    const [opdErFilter, setOpdErFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    //住院 table 頁碼
    const [inpFilter, setInpFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 當前選擇選項
    const [selectedOption, setSelectedOption] = useState(OptionEnums.YEAR.value)

    /**
     * 更新查詢條件
     * @param {event} e
     * @param {string} field
     * @return {void}
     */
    const updateParams = (e, field) => {
        // RadioButton 會直接傳 value
        if (field === 'patientRecord') {
            setParams((prev) => ({
                ...prev,
                [field]: e,
            }))
        } else {
            setParams((prev) => ({
                ...prev,
                [field]: e.target.value,
            }))
        }
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     * @param tableType {String}
     * @return {void}
     */
    const onPageOnChange = (page, tableType) => {
        const currentPage = Number(page)
        switch (tableType) {
            case TABLE_TYPE.opdEr:
                setOpdErFilter({...opdErFilter, pageNum: currentPage})
                break
            case TABLE_TYPE.inp:
                setInpFilter({...inpFilter, pageNum: currentPage})
                break
        }
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     * @param tableType {String}
     * @return {void}
     */
    const onPageSizeChange = (event, tableType) => {
        const pageSize = parseInt(event.target.value)
        switch (tableType) {
            case TABLE_TYPE.opdEr:
                setOpdErFilter({...opdErFilter, pageSize: pageSize})
                break
            case TABLE_TYPE.inp: {
                setInpFilter({...inpFilter, pageSize: pageSize})
                break
            }
        }
    }

    /**
     * 點選上一頁
     * @param tableType {String}
     * @return {void}
     */
    const onPrevPageOnClick = (tableType) => {
        switch (tableType) {
            case TABLE_TYPE.opdEr: {
                const prevPage = opdErFilter.pageNum - 1
                const page = prevPage < 1 ? opdErFilter.totalPageSize : prevPage
                setOpdErFilter({...opdErFilter, pageNum: page})
                break
            }
            case TABLE_TYPE.inp: {
                const prevPage = inpFilter.pageNum - 1
                const page = prevPage < 1 ? inpFilter.totalPageSize : prevPage
                setInpFilter({...inpFilter, pageNum: page})
                break
            }
        }
    }

    /**
     * 點選下一頁
     * @param tableType {String}
     * @return {void}
     */
    const onNextPageOnClick = (tableType) => {
        switch (tableType) {
            case TABLE_TYPE.opdEr: {
                const nextPage = opdErFilter.pageNum + 1
                const firstPage = 1
                const page =
                    nextPage > opdErFilter.totalPageSize ? firstPage : nextPage
                setOpdErFilter({...opdErFilter, pageNum: page})
                break
            }
            case TABLE_TYPE.inp: {
                const nextPage = opdErFilter.pageNum + 1
                const firstPage = 1
                const page =
                    nextPage > opdErFilter.totalPageSize ? firstPage : nextPage
                setInpFilter({...inpFilter, pageNum: page})
            }
        }
    }

    /**
     * 選項改變時
     * @param e {Event} value
     * @return {void}
     */
    const handleSelectedOptionOnChange = (e) => setSelectedOption(e.target.value)

    /**
     * 點擊搜尋按鈕時
     * @return {void}
     */
    const handleSearchOnClick = () => getQueryOpdInpTestData()

    /**
     *  取得查詢看診清單測試資料
     *  @return {void}
     */
    const getQueryOpdInpTestData = () => {
        queryOpdInpTestData({
            //病歷號/身分證號
            search: params.id,
            // 姓名
            patientName: params.patientName,
            // 門診第幾頁
            opdPageNum: opdErFilter.pageNum,
            // 門診每頁幾筆
            opdPageSize: opdErFilter.pageSize,
            //住院第幾頁
            inpPageNum: inpFilter.pageNum,
            //住院每頁幾筆
            inpPageSize: inpFilter.pageSize,
        }).then((res) => {
            // 錯誤狀態 / 訊息 / 資料
            const {err, msg, data} = res
            // 取得正確
            if (err === ApiErrorStatusEnum.Success) {
                // 取得門/急和住院資料
                const {inp, opd} = data
                // 設定數據
                setOpdErDataList(opd.dataList)
                // 設定分頁
                setOpdErFilter({
                    ...opdErFilter,
                    totalPageSize: opd.totalPageSize,
                    totalItemSize: opd.totalItemSize,
                })
                // 設定數據
                setInpDataList(inp.dataList)
                // 設定分頁
                setInpFilter({
                    ...inpFilter,
                    totalPageSize: inp.totalPageSize,
                    totalItemSize: inp.totalItemSize,
                })
            } else {
                //無法取得時
                // 清空資料
                setOpdErDataList([])
                setInpDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次運行時
     * @return {void}
     */
    useEffect(() => getQueryOpdInpTestData(), [])

    /**
     * 分頁相關數據改變時
     * @return {void}
     */
    useEffect(
        () => getQueryOpdInpTestData(),
        [
            opdErFilter.pageSize,
            opdErFilter.pageNum,
            inpFilter.pageSize,
            inpFilter.pageNum,
        ]
    )

    return (
        <div className="w-full overflow-auto p-2">
            <div className="flex space-x-2">
                {/*病歷號/身分證號*/}
                <TextField
                    label="病歷號/身分證號"
                    value={params.id}
                    onChange={(e) => updateParams(e, 'id')}
                />
                {/*姓名*/}
                <TextField
                    label={t('general.username')}
                    value={params.patientName}
                    onChange={(e) => updateParams(e, 'patientName')}
                />
                {/*一年內病歷和所有病歷RadioGroup*/}
                <RadioGroup size={SizeEnum.Medium} value={selectedOption}
                            optionProps={{
                                options: enum2Array(OptionEnums).map(obj => ({
                                    label: obj.value.label,
                                    value: obj.value.value
                                }))
                            }}
                            onChange={handleSelectedOptionOnChange}/>
                {/*查詢*/}
                <Button
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    variant={ButtonVariantEnum.Contained}
                    onClick={handleSearchOnClick}
                    text={t('general.query')}
                    sx={{fontWeight: 'bold'}}
                />
            </div>
            <div className="flex w-full">
                <div className="w-[50%] min-h-[calc(100vh-200px)] h-full p-2">
                    <h3></h3>
                    <CustomTable
                        title="門/急"
                        fields={OPD_ER_FIELDS}
                        dataList={opdErDataList}
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={opdErFilter.pageSize}
                            totalSize={opdErFilter.totalItemSize}
                            currentPage={opdErFilter.pageNum}
                            totalPageSize={opdErFilter.totalPageSize}
                            onPageOnChange={(e) =>
                                onPageOnChange(e, TABLE_TYPE.opdEr)
                            }
                            onPageSizeChange={(e) =>
                                onPageSizeChange(e, TABLE_TYPE.opdEr)
                            }
                            onPrevPageOnClick={() =>
                                onPrevPageOnClick(TABLE_TYPE.opdEr)
                            }
                            onNextPageOnClick={() =>
                                onNextPageOnClick(TABLE_TYPE.opdEr)
                            }
                        />
                    </div>
                </div>
                <div className="w-[50%] min-h-[calc(100vh-200px)] h-full p-2">
                    <CustomTable
                        title="住院"
                        fields={INP_FIELDS}
                        dataList={inpDataList}
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={inpFilter.pageSize}
                            totalSize={inpFilter.totalItemSize}
                            currentPage={inpFilter.pageNum}
                            totalPageSize={inpFilter.totalPageSize}
                            onPageOnChange={(e) =>
                                onPageOnChange(e, TABLE_TYPE.inp)
                            }
                            onPageSizeChange={(e) =>
                                onPageSizeChange(e, TABLE_TYPE.inp)
                            }
                            onPrevPageOnClick={() =>
                                onPrevPageOnClick(TABLE_TYPE.inp)
                            }
                            onNextPageOnClick={() =>
                                onNextPageOnClick(TABLE_TYPE.inp)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueryPatientVisitList

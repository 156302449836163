import React, {useEffect, useState} from 'react'
import {ApiErrorStatusEnum, arrayIsEmpty, getLocalStorage, stringIsEmpty} from "edah_utils/dist";
import {rsisChangeMaster, rsisCheckTimeValid, rsisQueryCodeValue, rsisQueryOrganization} from "../../../api/v1/RSIS";
import useToast from "../../../hooks/useToast";
import {
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    Select,
    TextField,
    AlertTypeEnum,
    SelectWidthEnum
} from "edah-component/dist";
import {t} from 'i18next';
import dayjs from "dayjs";


/**
 * 比較兩個日期字串
 * @param {string} date1 - 格式為 "YYYY-MM-DD" 的日期字串
 * @param {string} date2 - 格式為 "YYYY-MM-DD" 的日期字串
 * @returns {number} - 如果 date1 > date2 返回 1，如果 date1 < date2 返回 -1，如果相等返回 0
 */
const compareDates = (date1, date2) => {
    // 將日期字串轉換為 Date 對象
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // 比較日期
    if (d1 > d2) {
        return 1;
    } else if (d1 < d2) {
        return -1;
    } else {
        return 0;
    }
}

/**
 * 整批變更彈窗
 * @param {number} num 紀錄數
 * @param {boolean} isOwnUser 是否為承辦人員
 * @param {function} handleOnUpdate 更新資料
 * @param {function} handleOnClose 關閉視窗
 * @param {Array} selectItems 選取項目
 * @return {JSX.Element}
 */
const BatchesChange = ({
                           num,
                           isOwnUser,
                           handleOnUpdate,
                           handleOnClose,
                           selectItems
                       }) => {
    // 支援機構搜尋字串
    const [organization, setOrganization] = useState('')
    // 起始時間
    const [startTime, setStartTime] = useState('')
    // 結束時間
    const [endTime, setEndTime] = useState('')
    // 變更原因
    const [reason, setReason] = useState({
        field1: '',
        field2: '',
    })

    // 機構清單
    const [organizationList, setOrganizationList] = useState([])
    // 變更原因選項
    const [changeReasonOptions, setChangeReasonOptions] = useState([])

    const showToast = useToast()

    /**
     * 存檔
     * @return {void}
     */
    const handleOnSave = async () => {

        // 當變更原因為其他時
        if (reason.field1 === 'other' && reason.field2 === '') {
            showToast({message: '請輸入其它原因之說明', type: AlertTypeEnum.Warning})
            return
        }

        // 當未選擇記錄時
        if (num === 0) {
            showToast({message: '請選擇記錄', type: AlertTypeEnum.Warning})
            return
        }

        // 資料
        const data = selectItems.map((item) => {
            return {
                // 單據編號:院區代號(1)+申請日期(YYYYMMDD)+流水號(3)
                supportNo: item.supportNo,
                // 項目修改版號, 從1開始
                itemVersion: item.itemVersion,
                // 支援人員代號
                supportUserNo: item.supportUserNo,
                // 支援機構代號
                // 如機構代碼選擇為空則代表沿用之前的機構代碼
                supportOrgPkey: organization ? organizationList.find(o => o.orgNo === organization).orgPkey : item.supportOrgPkey,
                // 支援科別代號
                supportDivNo: item.supportDivNo,
                // 支援目的代碼
                purposeCode: item.purposeCode,
                // 支援目的說明
                purposeCodeExt: item.purposeCodeExt,
                // 項目開始日期
                itemStartDate: startTime ? `${startTime} 00:00:00` : (item.itemStartDate ? item.itemStartDate : ''),
                // 項目結束日期
                itemEndDate: endTime ? `${endTime} 00:00:00` : (item.itemEndDate ? item.itemEndDate : ''),
                // 開始時間(24HHMM)
                itemStartTime: item.itemStartTime,
                // 結束時間(24HHMM)
                itemEndTime: item.itemEndTime,
                // 支援性質
                supportProperties: item.supportProperties,
                // 執行內容
                executeContent: item.executeContent,
                // 備註
                memo: item.memo,
                // 代理人代號
                agentUserNo: item.agentUserNo,
                // 狀態(1申請/2確認/3送審/9失效)
                stepStatus: item.sendDatetime,
                // 變更/註銷狀態(null空:無/M:變更/D:刪除)
                supportModifyStatus: item.supportModifyStatus,
                // 變更/註銷日期
                supportModifyDatetime: item.supportModifyDatetime,
                // 變更/註銷原因代號
                supportModifyReasonCode: reason.field1,
                // 變更/註銷原因說明
                supportModifyReasonDoc: reason.field2,
                // 送審檔案名稱
                sendFileName: item.sendFileName,
                // 送審案號
                sendCaseNo: item.sendCaseNo,
                // 送審日期
                sendDatetime: item.sendDatetime,
                // 申請方式(N:一般 / E:緊急申請)
                applyType: item.applyType,
                // 院區
                zone: item.zone,
                // 有效否(0/1)
                effFlag: item.effFlag,
                // 承辦部門代號
                ownerDeptNo: item.ownerDeptNo,
                // 欄位變更說明,以中文標記欄位名稱
                changeFieldsDoc: item.changeFieldsDoc,
                // 確認人員
                confirmUserNo: item.confirmUserNo,
                // 確認時間
                confirmDatetime: item.confirmDatetime
            }
        })

        //檢查支援機構跟時間至少要填一樣
        if (stringIsEmpty(startTime) && stringIsEmpty(endTime) && organization === '') {
            showToast({message: '請變更欲變更之欄位！', type: AlertTypeEnum.Warning})
            return
        }

        // 檢查是否有改變時間
        if (startTime === '' && endTime !== '') {
            showToast({message: '請填入變更的開始時間!!', type: AlertTypeEnum.Warning})
            return
        }
        // 檢查是否有改變時間
        if (startTime !== '' && endTime === '') {
            showToast({message: '請填入變更的結束時間!!', type: AlertTypeEnum.Warning})
            return
        }
        //
        if (reason.field1 === 'other' && stringIsEmpty(reason.field2)) {
            //變更原因如選其他，則field 2必須要填寫
            showToast({message: '請輸入其他變更原因！', type: AlertTypeEnum.Warning})
            return
        }

        // 沒有改變時間
        if (startTime === '' && endTime === '') {
            rsisChangeMaster(data).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    handleOnUpdate()
                    showToast({message: `資料已變更！`, type: AlertTypeEnum.Success});
                    handleOnClose();
                } else {
                    showToast({message: `變更失敗！`, type: AlertTypeEnum.Error});
                }
            })
        } else {
            if (compareDates(startTime, endTime) !== -1) {
                //結束時間<=起始時間
                showToast({message: '結束時間必須大於起始時間', type: AlertTypeEnum.Warning})
                return
            }
            try {
                const result = await handleCheckOverlap(data);

                // 當白名單有資料時，整批變更白名單
                if (result.whitelist.length > 0) {
                    try {
                        const res = await rsisChangeMaster(result.whitelist);
                        if (res.err === ApiErrorStatusEnum.Success) {
                            console.log('change success')
                            handleOnUpdate()
                            showToast({
                                message: `成功更新 ${result.whitelist.length} 筆記錄`,
                                type: AlertTypeEnum.Success
                            });
                        } else {
                            showToast({message: `更新失敗，${res.msg}`, type: AlertTypeEnum.Error});
                        }
                    } catch (error) {
                        console.error('Error saving whitelist items:', error);
                        showToast({message: '儲存非衝突項目時發生錯誤', type: AlertTypeEnum.Error});
                    }
                }

                // 當黑名單有資料時，顯示時間衝突訊息
                if (result.blacklist.length > 0) {
                    // 根據支援單號
                    const conflictMessages = result.blacklist.map(item =>
                        `單號 ${item.supportNo}`
                    ).join(', ');

                    const overlapMessage = `以下單號存在時間衝突，未被更新: ${conflictMessages}`;
                    showToast({message: overlapMessage, type: AlertTypeEnum.Warning});
                }

                //整批變更結束後關閉視窗
                handleOnClose();
            } catch (error) {
                console.error('Error checking overlap:', error);
                showToast({message: '檢查過程中發生錯誤', type: AlertTypeEnum.Error});
            }
        }
    }

    /**
     * 開始時間改變時
     * @param newValue {dayjs} 選擇的新日期
     * @return {void}
     */
    const handleStartTime = (newValue) => {
        setStartTime(dayjs(newValue).format('YYYY-MM-DD'));
    }

    /**
     * 結束時間改變時
     * @param newValue {dayjs} 選擇的新日期
     * @return {void}
     */
    const handleEndTime = (newValue) => {
        setEndTime(dayjs(newValue).format('YYYY-MM-DD'));
    }

    /**
     * 檢查伺服器是否有時間重疊
     * @param {Object} data - 要檢查的時間項
     * @return {Promise<{overlaps: boolean, time: string}>}
     */
    const handleCheckOverlap = async (data) => {
        // 白名單 (時間檢查不重疊)
        const whitelist = [];
        // 黑名單 (時間檢查重疊)
        const blacklist = [];

        for (let index = 0; index < data.length; index++) {
            const item = data[index];
            const time = [{
                itemStartDate: item.itemStartDate,
                itemEndDate: item.itemEndDate,
                itemStartTime: '0000', // 前端頁面沒有給時間欄位，預設為0000
                itemEndTime: '2359' // 前端頁面沒有給時間欄位，預設為2359
            }];

            try {
                const res = await rsisCheckTimeValid({
                    supportNo: item.supportNo,
                    supportUserNo: item.supportUserNo,
                    itemVersion: item.itemVersion,
                    timeList: time
                });

                if (res.err === ApiErrorStatusEnum.Success) {
                    if (res.data[0].overlapFlag) { // 返回true代表有時間重疊
                        // 加入黑名單
                        blacklist.push(item);
                    } else {
                        // 時間未重疊，加入白名單
                        whitelist.push(item);
                    }
                } else {
                    console.error(`${item.supportUserName}：單號：${item.supportNo} 發生錯誤`, res.msg);
                    blacklist.push(item);
                }
            } catch (error) {
                console.error(`檢查時間時在單號${item.supportNo}發生錯誤`, error);
                blacklist.push(item);
            }
        }

        return {
            whitelist,
            blacklist
        };
    };

    /**
     * 取得排除本院後的機構清單
     * @param orgList
     * @return {Array}
     */
    const getFilteredOrgList = (orgList) => {
        // 取得本院院區ID
        const campusId = getLocalStorage('campusId')
        // 過濾並且排除本院
        return orgList.filter((org) => {
            return org.orgNo !== campusId
        })
    }

    /**
     * 取得機構清單
     * @return {void}
     */
    const getQueryOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單資料
                setOrganizationList(getFilteredOrgList(data))
            } else {
                // 清空機構清單
                setOrganizationList([])
            }
        })
    }

    /**
     * 取得變更原因選項
     * @return {void}
     */
    const getChangeReasonOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                //取得變更原因清單
                let changeReasonList
                //是否為承辦人員
                isOwnUser
                    ? changeReasonList = data.filter(item => item.codeType === 'CHANGE')
                    : changeReasonList = (data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N'))
                // 設定變更原因
                setChangeReasonOptions(changeReasonList)
                //變更原因清單不為空
                if (!arrayIsEmpty(changeReasonList)) {
                    //設定變更原因代號
                    setReason({...reason, field1: changeReasonList[0].codeNo})
                }
            } else {
                setChangeReasonOptions([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 理由選擇變更
     * @param value {String}
     */
    const handleReasonSelectOnChange = (value) => {
        setReason({...reason, field1: value})
    }

    /**
     * 支援機構選擇變更
     * @param value {String}
     */
    const handleOrganizationSelectOnChange = (value) => {
        setOrganization(value)
    }

    //第一次執行時
    useEffect(() => {
        // 取得機構清單
        getQueryOrganization()
        // 取得變更原因選項
        getChangeReasonOptions()
    }, []);

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.BatchesChange')}
            content={
                <div className='flex flex-col px-6 py-4'>
                    <p className='flex justify-start items-center text-red-600 pb-4'>{`共${num}筆紀錄選取，不改動之欄位請留空白`}</p>
                    <div className='flex flex-col gap-2'>
                        <Field label="支援機構" labelWidth={70}>
                            <Select
                                data={{
                                    label: "",
                                    options: [
                                        {value: "", label: ""},
                                        ...organizationList.map((item) => ({
                                            label: item.orgName,
                                            value: item.orgNo,
                                        })),
                                    ],
                                }}
                                value={organization}
                                onChange={handleOrganizationSelectOnChange}
                                width={SelectWidthEnum.XLarge}
                                displayEmpty={true}
                            />

                        </Field>
                        {/* 起始時間、結束時間 */}
                        <Field label={'開始日期'} labelWidth={70}>
                            <DatePicker
                                size="small"
                                label=""
                                value={startTime ? dayjs(startTime) : null}
                                onChange={handleStartTime}
                            />
                        </Field>

                        <Field label={'結束日期'} labelWidth={70}>
                            <DatePicker
                                size="small"
                                label=""
                                value={endTime ? dayjs(endTime) : null}
                                onChange={handleEndTime}
                            />
                        </Field>
                        {/* 變更原因 */}
                        <Field label={'變更原因'} labelWidth={70}>
                            <div className='flex flex-row gap-2 items-center'>
                                <Select
                                    data={{
                                        label: "",
                                        options:
                                            changeReasonOptions.map((item) => ({
                                                label: item.codeValue1,
                                                value: item.codeNo,
                                            })),
                                    }}
                                    value={reason.field1}
                                    onChange={handleReasonSelectOnChange}
                                    width={SelectWidthEnum.Medium}
                                />
                                <TextField
                                    value={reason.field2}
                                    onChange={(e) => setReason({...reason, field2: e.target.value})}
                                />
                            </div>
                        </Field>
                    </div>
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.MD}}
            onClose={handleOnClose}
            onSave={handleOnSave}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default BatchesChange

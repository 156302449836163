import React, {useEffect, useMemo, useState} from "react"
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, objectIsEmpty, stringIsEmpty} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {curdDelete, curdQuery, curdSave} from "../../api/v1/CRUD"
import {DeletePopup} from "../../components/Popup/DeletePopup"
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    IconEnum,
    TextField,
    InlineEditDataGrid,
    EDITABLE_ON_ADD_CLASS,
    Pagination
} from 'edah-component/dist'
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 科別維護頁面
 * @return {JSX.Element}
 */
const DepartmentMaintenance = () => {
    // 科別維護資料
    const [deptMaintenance, setDeptMaintenance] = useState(null)
    // 進階搜尋清單
    const [advDeptMaintenance, setAdvDeptMaintenance] = useState(null)
    // 新增Array
    const [insertDeptData, setInsertDeptData] = useState(null)
    // pagination控制變數
    const [deptMaintenancePaginationProps, setDeptMaintenancePaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })

    // 類別輸入框搜尋文字
    const [inputTypeSearchText, setInputTypeSearchText] = useState('')
    // 類別搜尋的文字
    const [deptQueryText, setDeptQueryText] = useState('')
    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 維護資料 進階搜尋文字
    const [advDeptSearchText, setAdvDeptSearchText] = useState('')
    // 選擇的科別資料
    const [selectedDeptData, setSelectedDeptData] = useState(null)
    // 編輯的科別資料
    const [editDeptDatas, setEditDeptDatas] = useState(null)
    const [delItemKey, setDelItemKey] = useState(null)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 點選查詢按鈕事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
        //設定文字
        setDeptQueryText(inputTypeSearchText)
        //master
        queryDeptData(inputTypeSearchText, 1, deptMaintenancePaginationProps.pageSize)
    }

    /**
     * 查詢科別維護
     * @param queryText {String} 查詢的文字
     * @param page {Number} 查詢頁碼
     * @param pageSize {Number} 每頁資料筆數
     * @return {void}
     */
    const queryDeptData = (queryText, page, pageSize) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        curdQuery({
            //module name
            system: "menu",
            //compare operator
            compare: "==",
            tableName: "Division",
            searchColumn: "divNo",
            value: queryText,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data ? res.data.map((item, index) => {
                    return {...item, key: index}
                }) : []
                setDeptMaintenance(dataWithKeys)

                setDeptMaintenancePaginationProps({
                    ...deptMaintenancePaginationProps,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else {
                const msg = `取得科別維護資料失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})

                setDeptMaintenance(null)
                setDeptMaintenancePaginationProps({
                    ...deptMaintenancePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        }))
    }


    /**
     * 進階搜尋
     * @return {void}
     */
    const getAdvanceDeptSearchTypeData = () => {
        const tmpArray =
            arrayIsEmpty(deptMaintenance) ? [] :
                deptMaintenance.map((item) => (
                    {
                        //key
                        key: item.key,  //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //科別代號
                        divNo: item.divNo,
                        //科別名稱
                        divName: item.divName,
                        //門診申報科別
                        opdheDivNo: item.opdheDivNo,
                        //住院申報科別
                        inpheDivNo: item.inpheDivNo,
                        //報備支援科別
                        supportDivNo: item.supportDivNo,
                        //國健局科別
                        bhpDivNo: item.bhpDivNo
                    }
                ))

        //開始模糊搜尋
        const findedDataArray = fuzzySearchObjects(tmpArray, advDeptSearchText, ["key"]) //key不要搜尋
        setAdvDeptMaintenance(findedDataArray)
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onDeptMaintenancePaginationPageOnChange = (page, pageSize ) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)
        queryDeptData(deptQueryText, page, pageSize)
        setDeptMaintenancePaginationProps({
                ...deptMaintenancePaginationProps,
                currentPage: page,
                pageSize: pageSize
            }
        )
    }

    /**
     * 類別輸入框搜尋文字變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleInputTypeSearchTextOnChange = (e) => setInputTypeSearchText(e.target.value)

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupCloseOnClick = () => {
        setDelItemKey(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupYesBtnOnClick = (targetKey) => {
        const key = targetKey ?? delItemKey
        if (!isNaN(key)) {
            const fundedData = deptMaintenance.find(item => item.key === key)
            if (!objectIsEmpty(fundedData)) {
                //要刪除的項目存在
                const tableData = [{
                    "createDatetime": stringIsEmpty(fundedData.createDatetime) ? null : fundedData.createDatetime,
                    "createUser": fundedData.createUser,
                    "modifyDatetime": stringIsEmpty(fundedData.modifyDatetime) ? null : fundedData.modifyDatetime,
                    "modifyUser": fundedData.modifyUser,
                    "lockVersion": fundedData.lockVersion,
                    "divNo": fundedData.divNo,
                    "divName": fundedData.divName,
                    "opdheDivNo": fundedData.opdheDivNo,
                    "inpheDivNo": fundedData.inpheDivNo,
                    "precedeDivNo": fundedData.precedeDivNo,
                    "bhpDivNo": fundedData.bhpDivNo,
                    "supportDivNo": fundedData.supportDivNo
                }]
                //轉成json格式
                const tableDataJSON = JSON.stringify(tableData)
                //開始刪除
                curdDelete({
                    "tableName": "tw.com.hismax.his.entity.menu.Division",
                    "tableData": tableDataJSON
                }).then((res => {
                    if (res.err === ApiErrorStatusEnum.Success) {
                        //更新table
                        queryDeptData(deptQueryText, 1, deptMaintenancePaginationProps.pageSize)
                    } else {
                        const msg = `刪除失敗: ${res.msg}`
                        showToast({message: msg, type: AlertTypeEnum.Error})
                    }
                }))
            }
        }

        setDelItemKey(null)
        setShowDeletePopup(false)
    }

    /**
     * 刪除彈窗點擊刪除按鈕時
     * @return {void}
     */
    const handleDeletePopupNoBtnOnClick = () => handleDeletePopupCloseOnClick()

    /**
     * 科別項目刪除按鈕點擊事件
     * @param keyToDel {Number}
     * @return {void}
     */
    const handleDeptDeleteTypeBtnOnClick = (keyToDel) => {
        setDelItemKey(keyToDel)
        handleDeletePopupYesBtnOnClick(keyToDel)
    }


    /**
     * 進階搜尋輸入框文字變更事件
     * @param e {Event}
     * @return {void}
     */
    const handleAdvDeptSearchTextOnChange = (e) => {
        //清除新增&編輯
        setInsertDeptData(null)
        setEditDeptDatas(null)

        setAdvDeptSearchText(e.target.value)
    }

    /**
     * 科別編輯項目儲存按鈕點擊事件
     * @param keyToSave {Number} 儲存的項目key
     * @param row {Object} 要儲存的資料
     * @return {void}
     */
    const handleDeptEditSaveBtnOnClick = (keyToSave, row) => {
        const old = deptMaintenance.find(item => item.key === keyToSave)
        const fundedData = {...old, ...row}
        if (objectIsEmpty(fundedData)) return

        const tableData = [{
            "createDatetime": null,
            "createUser": null,
            "modifyDatetime": null,
            "modifyUser": null,
            "lockVersion": fundedData.lockVersion,
            "divNo": fundedData.divNo,
            "divName": fundedData.divName,
            "opdheDivNo": fundedData.opdheDivNo,
            "inpheDivNo": fundedData.inpheDivNo,
            "precedeDivNo": fundedData.precedeDivNo,
            "bhpDivNo": fundedData.bhpDivNo,
            "supportDivNo": fundedData.supportDivNo
        }]

        //轉成json格式
        const tableDataJSON = JSON.stringify(tableData)
        //存檔
        curdSave({
            "tableName": "tw.com.hismax.his.entity.menu.Division",
            "tableData": tableDataJSON
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //更新table
                queryDeptData(deptQueryText, 1, deptMaintenancePaginationProps.pageSize)
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     * 科別維護 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'divNo', headerName: '科別代號', flex: 1, cellClassName: EDITABLE_ON_ADD_CLASS, type: 'string'  },
        { field: 'divName', headerName:'科別名稱', flex: 2,  editable: true, type: 'string' },
        { field: 'opdheDivNo', headerName:'門診申報科別代碼', flex: 1,  editable: true, type: 'string' },
        { field: 'inpheDivNo', headerName: '住院申報科別代碼', flex: 1,  editable: true, type: 'string' },
        { field: 'supportDivNo', headerName: '報備支援科別代碼', flex: 1,  editable: true, type: 'string' },
        { field: 'bhpDivNo', headerName: '國健局科別代碼', flex: 1,  editable: true, type: 'string' },
    ]


    /**
     * 監聽advDeptSearchText, deptMaintenance的變化
     * @return {void}
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceDeptSearchTypeData()
    }, [advDeptSearchText, deptMaintenance])

    /**
     * 初始
     */
    useMemo(() => {
        handleSearchOnClick()
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="flex flex-row flex-wrap items-center">
                {/*類別代碼*/}
                    <TextField
                        label="科別代碼"
                        sx={{mr: 1}}
                        value={inputTypeSearchText}
                        onChange={handleInputTypeSearchTextOnChange}
                    />

                <Button
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </Button>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*科別維護*/}
                <div
                    className="w-full p-2 bg-[#FFFFFF] border rounded-md ">
                    <Card title={"科別維護"}>
                        <div className="h-[50px] px-1.5 py-1.5 mb-2 flex items-center">
                            <SearchTextField
                                placeholder={t('general.advancedSearch')}
                                value={advDeptSearchText}
                                onChange={handleAdvDeptSearchTextOnChange}
                            />
                        </div>

                        {/*Table*/}
                        <InlineEditDataGrid
                            rows={advDeptMaintenance || []}
                            columns={header}
                            checkboxSelection={false}
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            disableDensitySelector
                            disableRowSelectionOnClick
                            height={'600px'}
                            onSave={(row, key)=> handleDeptEditSaveBtnOnClick(row.key, row)}
                            onDeleteRow={(row)=> handleDeptDeleteTypeBtnOnClick(row.key)}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <Pagination
                                totalPageSize={deptMaintenancePaginationProps.totalPageSize}
                                totalSize={deptMaintenancePaginationProps.totalItemSize}
                                onPageOnChange={(page, pageSize) =>
                                    onDeptMaintenancePaginationPageOnChange(page, pageSize)
                                }
                                page={deptMaintenancePaginationProps.currentPage}
                                pageSize={deptMaintenancePaginationProps.pageSize}
                                showFirstButton
                                showLastButton
                            />
                        </div>
                    </Card>
                </div>

            </div>
            {/* 刪除彈窗 */}
            <DeletePopup show={showDeletePopup} content="是否確定刪除?"
                         onClosePopupButtonClick={handleDeletePopupCloseOnClick}
                         onYesButtonClick={handleDeletePopupYesBtnOnClick}
                         onNoButtonClick={handleDeletePopupNoBtnOnClick}/>
        </div>
    )
}

export default DepartmentMaintenance

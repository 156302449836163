import {ApiErrorStatusEnum} from "edah_utils/dist";
import Button from "../../Button/Button";
import React, {useState} from "react";
import {AlertTypeEnum, Field, TextField} from "edah-component/dist";
import useToast from "../../../hooks/useToast";

const InputStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"

/**
 * 轉診代碼維護表單
 * @returns {JSX.Element}
 */
const ReferralCodeMaintainForm = ({data}) => {
    // showToast
    const showToast = useToast()
    // 類別代碼
    const [typeCode, setTypeCode] = useState("")
    const handleTypeCodeChange = (e) => setTypeCode(e.target.value)

    // 類別名稱
    const [typeName, setTypeName] = useState("")
    const handleTypeNameChange = (e) => setTypeName(e.target.value)

    //  項目代碼
    const [itemCode, setItemCode] = useState("")
    const handleItemCodeChange = (e) => setItemCode(e.target.value)


    //  項目名稱
    const [itemName, setItemName] = useState("")
    const handleItemNameChange = (e) => setItemName(e.target.value)

    // 上傳碼
    const [uploadCode, setUploadCode] = useState("")
    const handleUploadCodeChange = (e) => setUploadCode(e.target.value)


    // 開始日期
    const [startDate, setStartDate] = useState("")
    const handleUpdateStartDate = (e) => {
        setStartDate(e.target.value)
    }
    const [endDate, setEndDate] = useState("")

    const handleUpdateEndDate = (e) => {
        setEndDate(e.target.value)
    }

    // 儲存
    const handleSave = async () => {
        const targetApi = data ? editTranCode : addTranCode
        const res = await targetApi({
            codeType: typeCode,
            codeTypeName: typeName,
            codeNo: itemCode,
            codeName: itemName,
            uploadCodeNo: uploadCode,
            cancelFlag: "",
            sortBy: 1,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    return <>
        <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-8 space-y-2">
            <div className="grid grid-cols-3">
                <Field label="類別代碼">
                    <TextField
                        value={typeCode}
                        onChange={handleTypeCodeChange}
                    />
                </Field>
                <Field label="類別名稱">
                    <TextField
                        value={typeName}
                        onChange={handleTypeNameChange}
                    />
                </Field>
            </div>

            <div className="mt-2 grid  grid-cols-3">
                <Field label="項目代碼">
                    <TextField
                        value={itemCode}
                        onChange={handleItemCodeChange}
                    />
                </Field>
                <Field label="項目名稱">
                    <TextField
                        value={itemName}
                        onChange={handleItemNameChange}
                    />
                </Field>
                <Field label="上傳碼">
                    <TextField
                        value={uploadCode}
                        onChange={handleUploadCodeChange}
                    />
                </Field>
            </div>
            <Field label="開始日期">
                <input
                    className={InputStyle}
                    type="date"
                    value={startDate}
                    onChange={(e) => handleUpdateStartDate(e)}
                />
                <span className="m-1">~</span>
                <input
                    className={InputStyle}
                    value={endDate}
                    onChange={(e) => handleUpdateEndDate(e)}
                    type="date"
                />
            </Field>

        </div>
        <div
            className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
            <Button classNames={`ml-4 ${ButtonStyle}`} text={"存檔"} onClickFn={handleSave}/>
        </div>

    </>
}

export default ReferralCodeMaintainForm

import React, {useState} from 'react'
import CustomTable from './CustomTable'
import {
    ADMISSION_FIELDS,
    ADMISSION_RESERVATION_FIELDS,
    OPD_FIELDS,
    REPLY_LETTER_FIELDS,
    TRAN_OUT_FIELDS,
} from '../../constants/referralSystem/tranInCase'
import {t} from 'i18next'
import {queryTransTool} from '../../api/v1/Rmis'
import {ApiErrorStatusEnum, time2String, validDateFormat,} from 'edah_utils/dist'
import {DATETIME_FORMAT} from '../../constants/common'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"
import {DateRangePicker, SizeEnum} from "edah-component/dist/index";
import dayjs from "dayjs";

const QueryToolsModal = () => {
    const [searchToolParams, setSearchToolParams] = useState({
        search: '',
        encounterStartDate: '',
        encounterEndDate: '',
        encounterId: '',
    })

    const [inputState, setInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })

    const [queryList, setQueryList] = useState({
        // 預約住院查詢table
        reserveInpList: [],
        // 住院主檔查詢table
        inpList: [],
        // 門診看診主檔查詢table
        opdList: [],
        // 轉診單查詢table
        tranOutList: [],
        // 回覆單查詢table
        tranInList: [],
    })

    const showToast = useToast()

    const updateParams = (e, field) => {
        const value = e.target.value
        setSearchToolParams((prev) => ({...prev, [field]: value}))
    }

    /**
     * 就醫日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchToolParams({
            ...searchToolParams,
            encounterDateStart: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    const handleQueryOnClick = () => {
        const encounterStartDateIsValid = validDateFormat(
            searchToolParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchToolParams.encounterEndDate
        )
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterEndDateIsValid || !encounterEndDateIsValid) {
            return
        }
        queryTransTool({
            ...searchToolParams,
            encounterStartDate: time2String(
                searchToolParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchToolParams.encounterEndDate,
                DATETIME_FORMAT
            ),
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQueryList({
                    inpList: res.data?.inpList || [],
                    opdList: res.data?.opdList || [],
                    reserveInpList: res.data?.reserveInpList || [],
                    tranInList: res.data?.tranInList || [],
                    tranOutList: res.data?.tranOutList || [],
                })
            } else {
                showToast({
                    message: '預約住院查無資料',
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    return (
        <>
            <div className="flex">
                <div className="date flex items-center justify-start mb-4 space-x-2">
                    {/* 就醫日期起訖 */}
                    <DateRangePicker
                        size={SizeEnum.Small}
                        required
                        localeText={{
                            start: '就醫開始日期',
                            end: '就醫結束日期',
                        }}
                        value={[
                            searchToolParams.encounterDateStart ? dayjs(searchToolParams.encounterDateStart) : null,
                            searchToolParams.encounterEndDate ? dayjs(searchToolParams.encounterEndDate) : null,
                        ]}
                        onChange={handleDateRangeChange}
                    />
                    {/* 病歷號/身分證號 */}
                    <TextField
                        label={`${t('general.patientNo')}/${t('general.idNumber')}`}
                        value={searchToolParams.search}
                        onChange={(e) => updateParams(e, 'search')}
                    />
                    {/* 就醫號 */}
                    <TextField
                        label="就醫號"
                        value={searchToolParams.encounterId}
                        onChange={(e) => updateParams(e, 'encounterId')}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        text={t('general.query')}
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        onClick={handleQueryOnClick}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-2">
                <CustomTable
                    title="預約住院查詢"
                    fields={ADMISSION_RESERVATION_FIELDS}
                    dataList={queryList.reserveInpList}
                />
                <CustomTable
                    title="住院主檔查詢"
                    fields={ADMISSION_FIELDS}
                    dataList={queryList.inpList}
                />
                <CustomTable
                    title="門診看診主檔查詢"
                    fields={OPD_FIELDS}
                    dataList={queryList.opdList}
                />
                <CustomTable
                    title="轉診單查詢"
                    fields={TRAN_OUT_FIELDS}
                    dataList={queryList.tranOutList}
                />
                <CustomTable
                    title="回覆單查詢"
                    fields={REPLY_LETTER_FIELDS}
                    dataList={queryList.tranInList}
                />
            </div>
        </>
    )
}

export default QueryToolsModal

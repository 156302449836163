import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import {Field, SizeEnum, TextArea} from "edah-component/dist/index";

/**
 * 基本評估
 * @return {JSX.Element}
 */
const BasicAssessment = () => {

    //同上變數：主要聯絡人
    const [mainContact, setMainContact] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    //同上變數：主要照顧者
    const [primaryCaregiver, setPrimaryCaregiver] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    /**
     * 同上
     * @return void
     */
    const handleSameAsAbove = () => {
        setPrimaryCaregiver({ ...mainContact });
        console.log(primaryCaregiver)
    };

    /**
     * 資料輸入
     * @param e
     * @param setFunc {Function}
     * @param key
     * @return void
     */
    const handleInputChange = (e, setFunc, key) => {
        setFunc(prevState => ({...prevState, [key]: e.target.value}));
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white">
            <div className="flex justify-end items-center pb-4 mb-2.5 w-full border-b-[1px] border-[#111111]/15">
                <div className="flex flex-row">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        結案
                    </button>
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        看診資料查詢
                    </button>
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>

            <div className="mb-4 border-b-[1px] border-[#111111]/15 overflow-x-auto">
                <p className="w-full text-right">級別：Ａ</p>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[298px] mb-4 flex-shrink-0">
                        <p className="w-[160px] text-right">個案姓名：</p>
                        <span>黃ＯＯ</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[236px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">病歷號：</p>
                        <span>numbers</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[347px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">身分證號：</p>
                        <span>E123456789</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[315px] mb-4 flex-shrink-0">
                        <p className="w-[64px] text-right">性別：</p>
                        <span>男</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[298px] mb-4 flex-shrink-0">
                        <p className="w-[160px] text-right">家字號碼：</p>
                        <span>A009484738</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[236px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">出生日期：</p>
                        <span>2023/09/01</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[347px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">電話：</p>
                        <span>0987888000 / 072235500</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[315px] mb-4 flex-shrink-0">
                        <p className="w-[64px] text-right">地址：</p>
                        <span>高雄市燕巢區義大路1號</span>
                    </div>
                </div>
            </div>

            {/*主要聯絡人*/}
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">主要聯絡人</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={mainContact.name}
                                   onChange={e => handleInputChange(e, setMainContact, 'name')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">關係</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.relation}
                                onChange={e => handleInputChange(e, setMainContact, 'relation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">電話</p>
                        <BaseInput className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={mainContact.phone}
                                   onChange={e => handleInputChange(e, setMainContact, 'phone')}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">教育程度</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.education}
                                onChange={e => handleInputChange(e, setMainContact, 'education')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">職業</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.occupation}
                                onChange={e => handleInputChange(e, setMainContact, 'occupation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">溝通語言</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.lang}
                                onChange={e => handleInputChange(e, setMainContact, 'lang')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                </div>
            </div>

            {/*主要照顧者*/}
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">主要照顧者</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={primaryCaregiver.name}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'name')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">關係</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.relation}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'relation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">電話</p>
                        <BaseInput className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   value={primaryCaregiver.phone}
                                   type={InputTypeEnum.Text}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'phone')}
                        />
                    </div>
                    {/*同上按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]"
                        onClick={handleSameAsAbove}>
                        同上
                    </button>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">教育程度</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.education}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'education')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">職業</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.occupation}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'occupation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">溝通語言</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.lang}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'lang')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4 mr-4">疾病主診斷</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   placeholder="C50.919"
                        />
                        <p>女性乳房未明示部位惡性腫瘤</p>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4 mr-4">次要診斷</p>
                        <p className="mr-2.5">1</p>
                        <BaseInput className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                        <p className="mr-2.5 pl-4">2</p>
                        <BaseInput className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                        <p className="mr-2.5 pl-4">3</p>
                        <BaseInput className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                        <p className="mr-2.5 pl-4">4</p>
                        <BaseInput className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="mr-[60px]">重大傷病卡號：1.C50.919</p>
                        <p className="mr-[60px]">有效日期：2024/09/09</p>
                        <p className="min-w-[76px] mr-[60px]">2.</p>
                        <p>有效日期：</p>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4">殘障手冊</p>
                        <p className="w-[160px] pl-4 text-left">1.第七類</p>
                        <p className="w-[160px] text-right pr-4 mr-4">等級</p>
                        <select className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                name="" id="">
                            <option value="0">低</option>
                            <option value="1">中</option>
                            <option value="2">高</option>
                        </select>
                        <p className="text-right pr-4 mr-2.5">有效日期：2024/09/09</p>
                        <button
                            className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                            更新重大傷病，殘障手冊
                        </button>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4">殘障手冊</p>
                        <p className="w-[160px] pl-4 text-left">2.</p>
                        <p className="w-[160px] text-right pr-4 mr-4">等級</p>
                        <select className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2.5"
                                name="" id="">
                            <option value="0">低</option>
                            <option value="1">中</option>
                            <option value="2">高</option>
                        </select>
                        <p className="text-right pr-4 mr-2.5">有效日期：</p>
                    </div>
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">轉介來源</p>
                    <input type="radio" id="source1" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source1">住院</label>
                    <input type="radio" id="source2" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source2">門診</label>
                    <input type="radio" id="source3" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source3">外院轉介</label>
                    <input type="radio" id="source4" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source4">自行聯絡</label>
                    <input type="radio" id="source5" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="source5">長照中心</label>
                    <input type="radio" id="source6" name="source"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="source6">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">科別</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]">
                        <option value="0"></option>
                    </select>
                    <p className="w-[160px] text-right pr-4 mr-4">轉介人</p>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"/>
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">收案日期</p>
                    <BaseInput className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date}/>
                </div>
                <div className="flex flex-row items-start justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">收案原因</p>
                    <div className="flex flex-row flex-wrap items-start justify-start w-[940px]">
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start1"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start1">一般照護</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start2"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start2">留置鼻胃管</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start3"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start3">留置尿管</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start4"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start4">氣切更換</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start5"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start5">呼吸器</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start6"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start6">傷口照護</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start7"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start7">造口護理</label>
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="start8"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-4" htmlFor="start8">檢體收集</label>
                        <div className="mt-4 flex flex-row items-center justify-start">
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start9"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start9">其他</label>
                            <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                                       type={InputTypeEnum.Text}
                                       placeholder={'安寧居家ECG04級'}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">收案時ADL評分</p>
                    <p className="mr-1">柯氏</p>
                    <BaseInput className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">級</p>
                    <p className="mr-1">巴氏</p>
                    <BaseInput className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">級</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">收案居家護理師</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]">
                        <option value="0"></option>
                    </select>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">立案居家護理師</p>
                    <p>廖怡娟</p>
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">結案日期</p>
                    <BaseInput className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date}/>
                </div>
                <div className="flex flex-row items-start justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">結案原因</p>
                    <div className="flex flex-row flex-wrap items-start justify-start w-[940px]">
                        <input type="radio" id="close1" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close1">問題解決</label>
                        <input type="radio" id="close2" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close2">家屬已可自理</label>
                        <input type="radio" id="close3" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close3">住護理之家/安養中心</label>
                        <input type="radio" id="close4" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close4">住院</label>
                        <input type="radio" id="close5" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close5">死亡</label>
                        <input type="radio" id="close6" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close6">遷出照護區</label>
                        <input type="radio" id="close7" name="closeCase"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="close7">其他</label>
                        <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   type={InputTypeEnum.Text}/>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">收案時ADL評分</p>
                    <p className="mr-1">柯氏</p>
                    <BaseInput className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">級</p>
                    <p className="mr-1">巴氏</p>
                    <BaseInput className="w-[100px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">級</p>
                </div>
                <div className="flex flex-row items-start justify-start mb-4">
                    <Field label={"結案摘要"}>
                        <TextArea inputSize={SizeEnum.Fill}/>
                    </Field>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">結案居家護理師</p>
                    <p>廖怡娟</p>
                </div>
            </div>

            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">1.意識狀況</p>
                    <input type="radio" id="consciousness1" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness1">清醒警覺</label>
                    <input type="radio" id="consciousness2" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness2">人時地混淆</label>
                    <input type="radio" id="consciousness3" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness3">木檯/</label>
                    <input type="radio" id="consciousness4" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness4">昏迷</label>
                    <input type="radio" id="consciousness5" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="consciousness5">躁動</label>
                    <input type="radio" id="consciousness6" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="consciousness6">瞻妄</label>
                    <input type="radio" id="consciousness7" name="consciousness"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="consciousness7">植物人狀態</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">昏迷指數</p>
                    <p className="mr-1">E</p>
                    <select className="w-[64px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0"></option>
                    </select>
                    <p className="mr-1">M</p>
                    <select className="w-[64px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0"></option>
                    </select>
                    <p className="mr-1">V</p>
                    <select className="w-[64px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0"></option>
                    </select>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">2.呼吸狀況</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="respiratory1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="respiratory1">正常</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="respiratory2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="respiratory2">端坐</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="respiratory3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="respiratory3">氧氣使用</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="respiratory4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="respiratory4">氣切套管</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="respiratory5"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="respiratory5">其他</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">3.感官功能</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">視力</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="eyes1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="eyes1">正常</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="eyes2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="eyes2">缺陷</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="eyes3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="eyes3">輔助物</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0">R&L</option>
                    </select>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="eyes4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="eyes4">其他</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">聽力</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="ears1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="ears1">正常</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="ears2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="ears2">缺陷</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="ears3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="ears3">輔助物</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0">R&L</option>
                    </select>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="ears4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="ears4">其他</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">口腔</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="mouth1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="mouth1">正常</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                        <option value="1">R</option>
                        <option value="2">L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="mouth2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="mouth2">缺陷</label>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0">R&L</option>
                        <option value="1">R</option>
                        <option value="2">L</option>
                    </select>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="mouth3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="mouth3">輔助物</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="mouth4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="mouth4">其他</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">語言</p>
                    <input type="radio" id="lang1" name="language"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="lang1">正常</label>
                    <input type="radio" id="lang2" name="language"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="lang2">缺陷</label>
                    <input type="radio" id="lang3" name="language"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="lang3">其他</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">4.營養/飲食</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">營養狀況</p>
                    <p className="pr-4 mr-4">體重</p>
                    <input type="radio" id="weight1" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight1">過重</label>
                    <input type="radio" id="weight2" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight2">正常</label>
                    <input type="radio" id="weight3" name="weight"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="weight3">過輕</label>
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">每日攝取</p>
                    <input type="radio" id="daily1" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily1">過多</label>
                    <input type="radio" id="daily2" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily2">適當</label>
                    <input type="radio" id="daily3" name="daily"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="daily3">不足</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">飲食限制</p>
                    <input type="radio" id="diet1" name="diet"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="diet1">是</label>
                    <input type="radio" id="diet2" name="diet"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="diet2">否</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">進食方式及種類</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">由口</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="fromMouth1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="fromMouth1">普通飲食</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="fromMouth2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="fromMouth2">軟質</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="fromMouth3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="fromMouth3">流質</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="fromMouth4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="fromMouth4">營養補充品</label>
                    <div className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px]">
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="fromMouth5"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-2" htmlFor="fromMouth5">素食</label>
                        <input type="radio" id="vegetable1" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="vegetable1">早素</label>
                        <input type="radio" id="vegetable2" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="vegetable2">全素</label>
                        <input type="radio" id="vegetable2" name="vegetable"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label htmlFor="vegetable2">其他</label>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">管灌</p>
                    <input type="radio" id="stomach1" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach1">胃管</label>
                    <input type="radio" id="stomach2" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach2">胃造瘻</label>
                    <input type="radio" id="stomach3" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach3">腸造瘻</label>
                    <input type="radio" id="stomach4" name="stomach"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="stomach4">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}/>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">5.排尿功能</p>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="pee1" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee1">正常</label>
                        <input type="radio" id="pee2" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee2">頻尿</label>
                        <input type="radio" id="pee3" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee3">失禁</label>
                        <input type="radio" id="pee4" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee4">少尿</label>
                        <input type="radio" id="pee4" name="peepee"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee4">導尿</label>
                    </div>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="pee5" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee5">間歇導尿</label>
                        <input type="radio" id="pee6" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee6">膀胱造瘻</label>
                        <input type="radio" id="pee7" name="peepee2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="pee7">導尿留置</label>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">6.排便功能</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">型態</p>
                    <input type="radio" id="type1" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type1">正常</label>
                    <input type="radio" id="type2" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type2">便秘</label>
                    <input type="radio" id="type3" name="type"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="type3">腹瀉</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">途徑</p>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="w1" name="way"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w1">肛門</label>
                        <input type="radio" id="w2" name="way"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w2">造瘻</label>
                    </div>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="w3" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w3">迴腸</label>
                        <input type="radio" id="w4" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w4">空腸</label>
                        <input type="radio" id="w5" name="way2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="w5">結腸</label>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">輔助方式</p>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="s1" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s1">軟便劑</label>
                        <input type="radio" id="s2" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s2">塞劑</label>
                        <input type="radio" id="s3" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s3">灌腸</label>
                        <input type="radio" id="s4" name="support"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s4">糞崁塞處理</label>
                    </div>
                    <div
                        className="bg-[#F4F4F5] h-[42px] px-4 flex flex-row items-center justify-center rounded-[6px] mr-4">
                        <input type="radio" id="s5" name="support2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s5">每次</label>
                        <input type="radio" id="s6" name="support2"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="s6">偶爾</label>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">7.皮膚狀況</p>
                    <input type="radio" id="skin1" name="skin"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="skin1">完整</label>
                    <input type="radio" id="skin2" name="skin"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="skin2">不完整</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">傷口1</p>
                    <p className="mr-1">部位</p>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0"></option>
                    </select>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <p className="ml-4 mr-1">程度</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0"></option>
                    </select>
                    <p className="ml-4 mr-1">型態</p>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0"></option>
                    </select>
                    <p className="ml-4 mr-1">大小</p>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">傷口2</p>
                    <p className="mr-1">部位</p>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0"></option>
                    </select>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <p className="ml-4 mr-1">程度</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4">
                        <option value="0"></option>
                    </select>
                    <p className="ml-4 mr-1">型態</p>
                    <select className="w-[94px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2">
                        <option value="0"></option>
                    </select>
                    <p className="ml-4 mr-1">大小</p>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">8.肌肉骨骼功能</p>
                </div>
                <div className="flex flex-row flex-wrap items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4"></p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="bone1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="bone1">正常</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="bone2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="bone2">關節腫-部位</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-8"
                               type={InputTypeEnum.Text}
                    />
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="bone3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="bone3">脫臼-部位</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-8"
                               type={InputTypeEnum.Text}
                    />
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="bone4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="bone4">截肢-部位</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-8"
                               type={InputTypeEnum.Text}
                    />
                    <p className="w-[160px] flex-shrink-0 text-right pr-4"></p>
                    <div className="flex flex-row items-center justify-start mt-4">
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="bone4"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-2" htmlFor="bone4">萎縮-部位</label>
                        <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-8"
                                   type={InputTypeEnum.Text}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mt-4">
                        <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                               type="checkbox" id="bone5"
                               style={{accentColor: "#38A169"}}/>
                        <label className="mr-2" htmlFor="bone5">僵硬-部位</label>
                        <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-8"
                                   type={InputTypeEnum.Text}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">9.家庭功能</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">經濟來源</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="money1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="money1">自己</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="money2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="money2">子女</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="money3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-2" htmlFor="money3">親友</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4"></p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="money4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="money4">政府補助</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-8">元/月</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="money5"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="money5">社會補助</label>
                    <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p>元/月</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">經濟狀況</p>
                    <input type="radio" id="economy1" name="economy"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="economy1">富裕</label>
                    <input type="radio" id="economy2" name="economy"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="economy2">小康</label>
                    <input type="radio" id="economy3" name="economy"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="economy3">中、低收入戶</label>
                    <input type="radio" id="economy4" name="economy"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="economy4">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">宗教</p>
                    <input type="radio" id="religion1" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion1">無</label>
                    <input type="radio" id="religion2" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion2">佛教</label>
                    <input type="radio" id="religion3" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion3">基督教</label>
                    <input type="radio" id="religion4" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion4">天主教</label>
                    <input type="radio" id="religion5" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion5">民間信仰</label>
                    <input type="radio" id="religion6" name="religion"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="religion6">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">溝通能力</p>
                    <input type="radio" id="cs1" name="communicationSkills"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="cs1">正常</label>
                    <input type="radio" id="cs2" name="communicationSkills"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="cs2">失語</label>
                    <input type="radio" id="cs3" name="communicationSkills"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="cs3">言語不輕</label>
                    <input type="radio" id="cs4" name="communicationSkills"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="cs4">昏迷</label>
                    <input type="radio" id="cs5" name="communicationSkills"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-2" htmlFor="cs5">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4">溝通方式</p>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm1"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm1">國語</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm2"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm2">台語</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm3"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm3">客語</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm4"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm4">筆談</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm5"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm5">圖片</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm6"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm6">手勢</label>
                    <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                           type="checkbox" id="cm7"
                           style={{accentColor: "#38A169"}}/>
                    <label className="mr-4" htmlFor="cm7">其他</label>
                    <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                </div>
            </div>
        </div>
    );
}
export default BasicAssessment
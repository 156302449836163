import React, {useCallback, useEffect, useState} from 'react'
import {
    ApiErrorStatusEnum,
    CampusEnum,
    enum2Array,
    getLocalStorage,
    RsisStepStatusEnum,
    RsisSupportModifyStatusEnum,
    stringIsEmpty,
    time2String
} from "edah_utils/dist";
import {RSISCaseManagementTable} from '../Constant'
import {getCurrentZoneNo, Layout} from '../utils'
import CustomTable from '../RSISCustomTable'
import ListItem from './ListItem'
import {usePopup} from '../PopupProvider'
import {USER_ROLE} from "../userRole";
//報備支援收案確認Modal
import RSISCaseConfirmation from '../Modal/RSISCaseConfirmation'
//報備支援內容顯示Modal
import RSISContentView from '../Modal/RSISContentView'
// 所有人員查詢Modal
import QueryCreateUserList from '../Modal/QueryCreateUserList'
// 支援機構查詢Modal
import QuerySupportOrg from '../Modal/QuerySupportOrg'
//變更個案管理清單Popup
import ChangeModal from "../Modal/ChangeModal";
//編輯個案管理清單Popup
import EditModal from "../Modal/EditModal";
//複製新增個案管理清單Popup
import CopyModal from "../Modal/CopyModal";
//註銷個案管理清單Popup
import LogOffModal from "../Modal/LogOffModal";
//刪除個案管理清單Popup
import DeletePopup from '../Popup/DeletePopup'
import {t} from 'i18next'
import {
    rsisDelMaster,
    rsisMailConfirm,
    rsisQueryBaseData,
    rsisQueryMaster,
    rsisQueryOrganization,
    rsisQueryOwnerUser,
    rsisReturnReply
} from "../../../api/v1/RSIS";
import QueryPersonList from "../Modal/QueryPersonList"
import ExportFIleNameModal from "../Modal/ExportFIleNameModal";
import SendMOHWConfirm from "../Popup/SendMOHWConfirm";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    DateRangePicker,
    Select,
    SelectWidthEnum,
    TextField
} from "edah-component/dist"
import dayjs from 'dayjs';

const BatchUpdate = [
    {
        id: "all",
        name: "收案確認",
        value: "1"
    },
    {
        id: "one",
        name: "衛福部送審轉出",
        value: "2"
    },
    {
        id: "two",
        name: "退回申請",
        value: "3"
    }
]

/**
 * RSIS個案管理
 * @return {JSX.Element}
 */
const RSISCaseManagement = () => {
    // 是否為承辦人員
    const [isOwnUser, setIsOwnUser] = useState(false)
    //分頁資訊
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 個案管理彈出視窗全域狀態管理
    const {
        batchSelected,
        setBatchSelected,
        showCaseConfirmation,
        setShowCaseConfirmation,
        showContentView,
        setShowContentView,
        showChangeModal,
        showEditModal,
        showCopyModal,
        showLogOffModal,
        setShowChangeModal,
        setShowEditModal,
        setShowCopyModal,
        setShowLogOffModal,
        showQueryPersonList,
        setShowQueryPersonList,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        showQuerySupportOrgModal,
        setShowQuerySupportOrgModal,
        showSendMOHWConfirm,
        setShowSendMOHWConfirm,
        showDeletePopup,
        setShowDeletePopup,
        setShowExportFileNameInputModal,
        showExportFileNameInputModal,
        setIsReadyForExport,
        focusIndex,
        showToast
    } = usePopup()
    // 當前院區
    const currentZone = getLocalStorage('campus')
    // 申請人工號
    const [createUser, setCreateUser] = useState('')
    //分院類型
    const [branchType, setBranchType] = useState([])
    // 報備起始日期
    const [itemStartDate, setItemStartDate] = useState('')
    // 報備結束日期
    const [itemEndDate, setItemEndDate] = useState('')
    //衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')
    // 階段狀態
    const [stepStatus, setStepStatus] = useState(RsisStepStatusEnum.All)
    // 狀態
    const [supportModifyStatus, setSupportModifyStatus] = useState(RsisSupportModifyStatusEnum.All)
    // 支援人員工號
    const [supportUserNo, setSupportUserNo] = useState('')
    // 支援機構工號
    const [supportOrgNo, setSupportOrgNo] = useState('')
    // 申請人員清單
    const [createUserList, setCreateUserList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 機構清單
    const [organizationList, setOrganizationList] = useState([])
    // 承辦部門清單
    const [deptList, setDeptList] = useState([])
    // 承辦人員清單資料
    const [ownerList, setOwnerList] = useState([])
    // 資料集
    const [dataList, setDataList] = useState([])
    // 被選取清單
    const [selectItems, setSelectItems] = useState([])
    // 匯出檔案名稱
    const [exportFileName, setExportFileName] = useState('')

    /**
     * 監聽後台資料是否更新
     * @return {void}
     */
    const handleDataIsUpdate = () => getQueryMaster()

    /**
     * 表格表頭條件勾選欄位，收案確認，衛福部送審轉出，退回申請
     * @return {JSX.Element}
     */
    const tableHeader = () => {
        return (
            <div className='flex justify-start items-center gap-4'>
                <p>要批次作業請先點選右方按鈕：</p>
                <div className='space-x-4'>
                    {
                        BatchUpdate.map((item, index) =>
                            <Checkbox
                                id={item.id}
                                label={item.name}
                                checked={batchSelected === item.value}
                                key={item.id}
                                onChange={() => {
                                    batchSelected === item.value ? setBatchSelected(null) : setBatchSelected(item.value)
                                }}
                            />
                        )
                    }
                </div>
            </div>
        )
    }

    /**
     * 申請人/工號方框點擊時
     * @return {void}
     */
    const handleQueryCreateUserOnClick = () => setShowQueryCreateUserList(true)

    /**
     * 申請人/工號更新時
     * @param item 清單選取的人員
     * @return {void}
     */
    const handleOnCreateUserUpdate = (item) => {
        setCreateUser(`${item.userNo} ${item.userName}`)
        setShowQueryCreateUserList(false)
    }

    /**
     * 報備支援醫師方框點擊時
     * @return {void}
     */
    const handleQueryDoctorOnClick = () => setShowQueryPersonList(true)

    /**
     * 支援機構方框點擊時
     * @return {void}
     */
    const handleQuerySupportOrgOnClick = () => setShowQuerySupportOrgModal(true)

    /**
     * 支援機構更新時
     * @param item 清單選取的支援機構
     * @return {void}
     */
    const handleOnSupportOrgUpdate = (item) => {
        setSupportOrgNo(`${item.orgNo} ${item.orgName}`)
        setShowQuerySupportOrgModal(false)
    }

    /**
     * 送審表格表頭條件勾選欄位，整批變更
     * @param {Array} items 選取的項目
     * @return {void}
     */
    const handleOnSelectedItems = useCallback((items) => {
        setSelectItems(items);
    }, [])

    /**
     * 醫師更新時
     * @param item 清單選取的醫師
     * @return {void}
     */
    const handleOnDoctorUpdate = (item) => {
        setSupportUserNo(`${item.userNo} ${item.userName}`)
        setShowQueryPersonList(false)
    }

    /**
     * 分院改變時
     * @param index {Number} 分院代碼
     * @return {void}
     */
    const handleBranchOnChange = (index) => {
        // 產生新資料
        const newCampus = [...branchType]
        // 設定新資料
        newCampus[index] = {
            // 取得原本資料
            ...newCampus[index],
            // 設定選取狀態
            selected: !(newCampus[index].selected)
        }
        // 設定分院類型
        setBranchType(newCampus)
    }

    /**
     * 日期範圍變更事件
     * @param {Array} newValue 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeOnChange = (newValue) => {
        setItemStartDate(newValue[0] ? dayjs(newValue[0]).format('YYYY-MM-DD') : null);
        setItemEndDate(newValue[1] ? dayjs(newValue[1]).format('YYYY-MM-DD') : null);
    }

    /**
     * 衛福部案號改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSendCaseNoOnChange = (e) => setSendCaseNo(e.target.value)

    /**
     * 階段狀態改變時
     * @param value {String} 事件
     * @return {void}
     */
    const handleStepStatusOnChange = (value) => setStepStatus(value)

    /**
     * 修改狀態改變時
     * @param value {String}
     * @return {void}
     */
    const handleSupportModifyStatusOnChange = (value) => setSupportModifyStatus(value)

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryMaster()

    /**
     * 重置搜尋條件按鈕點擊時
     * @return {void}
     */
    const handleResetSearchButtonOnClick = () => {
        // 清空值
        setCreateUser("")
        // 清空支援人員工號
        setSupportUserNo("")
        // 清空支援機構工號
        setSupportOrgNo("")
        // 清空報備起始日期
        setItemStartDate("")
        // 清空報備結束日期
        setItemEndDate("")
        // 清空衛福部案號
        setSendCaseNo("")
        // 重置階段狀態
        setStepStatus(RsisStepStatusEnum.All)
        // 重置修改狀態
        setSupportModifyStatus(RsisSupportModifyStatusEnum.All)
        // 重置分院類型
        initBranchType()
    }

    /**
     * 刪除按鈕點擊時
     * @return {void}
     */
    const handleOnDelItem = () => {
        // 關閉刪除彈窗
        setShowDeletePopup(false)
        handleDelMaster()
    }

    /**
     * 匯出按鈕更新回調函數
     * @param exportName {String} 匯出名稱
     * @return {void}
     */
    const handleExportNameUpdate = (exportName) => {
        // 顯示匯出檔案名稱輸入modal
        setExportFileName(exportName)
        // 關閉匯出檔案名稱輸入modal
        setShowExportFileNameInputModal(false)
        // 準備好存檔匯出
        setIsReadyForExport(true)
    }

    /**
     * 個案管理存檔
     * @param data {Array}
     * @return {void}
     */
    const handleSaveOnClick = (data) => {
        // 判斷是否選擇批次處理方法
        if (batchSelected === null) {
            showToast({message: '請選擇批次處理方法', type: AlertTypeEnum.Warning})
            return
        }

        // 判斷是否選擇需要處理的資料
        if (data.length === 0) {
            showToast({message: '請選擇需要處理的資料', type: AlertTypeEnum.Warning})
        } else {
            // 處理要送出的資料
            const processedData = data.map(item => {
                // 取得代理人員編號
                const agentUserNo = item.agentUserNo.split(' ')[0]
                // 取得支援人員編號，支援人員名稱
                const [supportUserNo, supportUserName] = item.supportUserNo.split(' ')

                return {
                    ...item,
                    agentUserNo,
                    supportUserNo,
                    supportUserName: supportUserName || item.supportUserName
                }
            })
            if (batchSelected === '1') { // 收案確認
                handleCaseConfirmation(processedData)
            } else if (batchSelected === '2') { // 衛福部送審轉出
                // 顯示使用者輸入匯出檔案名稱彈窗
                setShowExportFileNameInputModal(true)
            } else if (batchSelected === '3') { // 退回申請
                handleReturnApplication(processedData)
            }
        }
    }

    /**
     * 取得承辦人員清單
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 過濾清單只取得userName
                const list = data.map(item => item.userNo + ' ' + item.userName)
                // 設定承辦人員清單
                setOwnerList(list)
            } else { // 取得失敗
                // 清空承辦人員清單
                setOwnerList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除報備支援資料
     * @return {void}
     */
    const handleDelMaster = () => {
        const data = dataList[focusIndex]
        rsisDelMaster([data]).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQueryMaster()
                showToast({message: msg, type: AlertTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得查詢報備支援資料
     * @return {void}
     */
    const getQueryMaster = () => {
        // 送審案號為空時，判斷申請人與支援醫師是否為空
        if (stringIsEmpty(sendCaseNo)) {
            // 當申請人與支援醫師皆為空時，顯示提示訊息
            if (stringIsEmpty(createUser) && stringIsEmpty(supportUserNo)) {
                showToast({message: t('RSIS.toast.queryDataNotComplete'), type: AlertTypeEnum.Warning})
                return
            }
        }
        // 清空目前資料
        setDataList([])
        // 取得勾選院區
        const zone = branchType.filter(item => item.selected).map(item => item.campus).join(',')
        // 支援機構代碼
        const orgNo = supportOrgNo ? (supportOrgNo.indexOf(' ') > 0 ? supportOrgNo.split(' ')[0] : supportOrgNo) : ''
        // 支援機構PK
        const org = organizationList.find(o => o.orgNo === orgNo)
        rsisQueryMaster({
            // 申請人/工號
            createUser: createUser ? (createUser.indexOf(' ') > 0 ? createUser.split(' ')[0] : createUser) : '',
            // 支援醫師代碼
            supportUserNo: supportUserNo ? (supportUserNo.indexOf(' ') > 0 ? supportUserNo.split(' ')[0] : supportUserNo) : '',
            // 支援機構代碼
            supportOrgNo: org ? org.orgPkey : '',
            // 狀態(1申請/2確認/3送審/9失效)
            stepStatus: stepStatus,
            // 送審案號
            sendCaseNo: sendCaseNo,
            // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
            supportModifyStatus: supportModifyStatus,
            // 院區代號
            zone: zone,
            // 支援期間起始日期
            itemStartDate: stringIsEmpty(itemStartDate) ? null : `${itemStartDate} 00:00:00`,
            // 支援期間結束日期
            itemEndDate: stringIsEmpty(itemEndDate) ? null : `${itemEndDate} 23:59:59`,
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                const loginUser = getLocalStorage('userno')

                // 處理數組中的每個對象
                const processedData = data.map(item => {

                    // 判斷是否為承辦人員
                    const isOwner = ownerList.some(owner => owner.split(' ')[0] === loginUser)
                    // 非承辦人員，確定角色
                    let role = ''
                    if (isOwner) {
                        role = USER_ROLE.DEPT_USER
                        setIsOwnUser(true)
                    } else {
                        if (loginUser === item.createUser) role = USER_ROLE.CREATE_USER
                        else if (loginUser === item.supportUserNo) role = USER_ROLE.SUPPORT_USER
                        else role = USER_ROLE.OTHER_USER
                    }

                    // 過濾null或undefined的值，轉為空字串，並加入role資料
                    return Object.entries(item).reduce((acc, [key, value]) => {
                        acc[key] = (value === null || value === undefined) ? '' : value;
                        return acc;
                    }, {role});
                });

                // 當查詢資料為空時，顯示提示訊息
                if (processedData.length === 0) {
                    // 當有輸入申請人，沒有輸入支援醫師時
                    if (!stringIsEmpty(createUser) && stringIsEmpty(supportUserNo)) {
                        // 顯示查無此申請人資料
                        showToast({message: t('RSIS.toast.createUserNotFound'), type: AlertTypeEnum.Warning})
                    } else if (stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.supportUserNotFound'), type: AlertTypeEnum.Warning})
                    } else if (!stringIsEmpty(createUser) && !stringIsEmpty(supportUserNo)) {
                        showToast({message: t('RSIS.toast.rsisDataNotFound'), type: AlertTypeEnum.Warning})
                    }
                }

                // 設定資料
                setDataList(processedData)

                // 重置批次作業選項
                if (batchSelected !== null) setBatchSelected(null)
                else setSelectItems([]) // 清空被選取清單

            } else { // 取得失敗
                // 清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基本資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        rsisQueryBaseData({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setDoctorList(data.doctorList)
                // 設定承辦人員清單資料
                setCreateUserList(data.ownUserList)
                // 設定承辦部門清單資料
                setDeptList(data.deptList)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 清空承辦人員清單
                setCreateUserList([])
                // 清空承辦部門清單
                setDeptList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援機構所有資料
     * @return {void}
     */
    const getOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                setOrganizationList(data)
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 報備支援收案確認
     * @param data {Object} 資料
     * @return {void}
     */
    const handleCaseConfirmation = (data) => {
        // 更新收案確認資料
        const updatedData = data.map(item => ({
            ...item,
            // 收案確認人員編號
            confirmUserNo: getLocalStorage('userno'),
            // 收案確認日期
            confirmDatetime: time2String(new Date(), 'YYYY-MM-DD HH-mm-ss').split('_')[0].replace(/(\d{4}-\d{2}-\d{2}) (\d{2})-(\d{2})-(\d{2})/, "$1 $2:$3:$4")
        }))
        rsisMailConfirm(updatedData).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 更新成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQueryMaster()
                showToast({message: '收案確認成功', type: AlertTypeEnum.Success})
            } else {
                // 顯示錯誤訊息
                showToast({message: `收案確認失敗，${msg}`, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 退回申請
     * @param data {Object} 資料
     * @return {void}
     */
    const handleReturnApplication = (data) => {
        rsisReturnReply(data).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 退回申請成功，重新取得資料
                getQueryMaster()
                showToast({message: '退回申請成功', type: AlertTypeEnum.Success})
            } else {
                // 顯示錯誤訊息
                showToast({message: `退回申請失敗，${msg}`, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 初始化分院類型
     * @return {void}
     */
    const initBranchType = () => {
        //取得所有分院
        const allCampus = enum2Array(CampusEnum)
        // 產生分院選單
        const newCampus = allCampus.map(item => ({
            // 分院代碼
            campus: item.value,
            // 分院名稱
            campusName: t(`campus.shortName.${item.value}`),
            // 是否選取
            selected: currentZone === item.value,
        }))
        // 設定分院類型
        setBranchType(newCampus)
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 初始化分院類型
        initBranchType()
        // 取得基本資料
        getQueryBaseData()
        // 取得承辦人員清單
        getOwnerUserList()
        // 取得支援機構清單
        getOrganization()
        setBatchSelected(null)
        // 若尚未取當前院區代號，則取得代號並儲存在localStorage
        if (getLocalStorage('campusId') === null) {
            // 取得院區代號
            getCurrentZoneNo()
        }
    }, [])

    /**
     * 監聽批次作業選項是否改變
     * @return {void}
     */
    useEffect(() => {
        // 清空被選取清單
        setSelectItems([])
    }, [batchSelected]);

    return (
        <Layout>
            {/* 查詢區塊 */}
            <div className='w-full flex flex-row justify-between items-center p-2 '>
                {/* 查詢條件 */}
                <div className='flex flex-col'>
                    {/* 第一列查詢條件 */}
                    <div className='flex items-center p-2 gap-6'>
                        {/*支援人員*/}
                        <TextField
                            label={`${t('RSIS.general.applicant')}/${t('RSIS.general.jobNum')}`}
                            value={createUser}
                            onClick={handleQueryCreateUserOnClick}
                            autoComplete='off'
                        />
                        {/*報備支援醫師/工號*/}
                        <TextField label={`${t('RSIS.general.supportDoc')}/${t('RSIS.general.jobNum')}`}
                                   value={supportUserNo}
                                   onClick={handleQueryDoctorOnClick}
                        />
                        {/*支援機構/工號*/}
                        <TextField
                            label={`${t('RSIS.general.supportOrg')}/${t('RSIS.general.jobNum')}`}
                            value={supportOrgNo}
                            onClick={handleQuerySupportOrgOnClick}
                        />
                        {/*院區選項*/}
                        <div>
                            {
                                branchType.map((item, index) =>
                                    <Checkbox
                                        label={item.campusName}
                                        checked={item.selected}
                                        key={item.campus}
                                        onChange={() => handleBranchOnChange(index)}/>
                                )
                            }
                        </div>
                    </div>
                    {/* 第二列查詢條件 */}
                    <div className='flex items-center p-3 gap-6'>
                        <div className='flex items-center gap-1'>
                            {/*報備起始日期*/}
                            <DateRangePicker
                                size="small"
                                localeText={{
                                    start: '報備開始日期',
                                    end: '報備結束日期'
                                }}
                                value={[
                                    itemStartDate ? dayjs(itemStartDate) : null,
                                    itemEndDate ? dayjs(itemEndDate) : null
                                ]}
                                onChange={handleDateRangeOnChange}  // 日期範圍變更處理
                            />
                        </div>
                        {/*衛福部案號*/}
                        <TextField
                            label={t('RSIS.general.ministryOfHealthAndWelfareNum')}
                            value={sendCaseNo}
                            onChange={handleSendCaseNoOnChange}
                        />
                        <div className='flex items-center gap-1 text-left'>
                            {/*階段狀態*/}
                            <Select
                                data={{
                                    label: '階段狀態',
                                    options: enum2Array(RsisStepStatusEnum).map((item) => ({
                                        value: item.value,
                                        label: t(`RSIS.StepStatusEnum.${item.value}`)
                                    }))
                                }}
                                value={stepStatus}
                                onChange={handleStepStatusOnChange}
                                width={SelectWidthEnum.Small}
                                notched={true}
                                displayEmpty={true}
                            />
                            {/*修改狀態*/}
                            <Select
                                data={{
                                    label: '修改狀態',
                                    options: enum2Array(RsisSupportModifyStatusEnum).map((item) => ({
                                        value: item.value,
                                        label: t(`RSIS.SupportModifyStatusEnum.${item.value}`)
                                    }))
                                }}
                                value={supportModifyStatus}
                                onChange={handleSupportModifyStatusOnChange}
                                width={SelectWidthEnum.Small}
                                notched={true}
                                displayEmpty={true}
                            />
                        </div>
                        <div className="source flex flex-row items-center justify-start mr-4">
                            {/*查詢按鈕*/}
                            <Button sx={{marginRight: '0.5rem'}}
                                    color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                                    variant={ButtonVariantEnum.Contained} text={t('general.query')}
                                    onClick={handleQueryOnClick}
                            />
                            {/*清除查詢條件按鈕*/}
                            <Button sx={{marginRight: '0.5rem'}}
                                    color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                    size={ButtonSizeEnum.Medium}
                                    onClick={handleResetSearchButtonOnClick}
                                    text={t('general.clearQueryParams')}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* 查詢結果 */}
            <CustomTable
                header={tableHeader()}
                fields={RSISCaseManagementTable.fields}
                dataList={dataList}
                ListItem={ListItem}
                hasSelectAll={true}
                type="RSISCaseManagementTable"
                saveData={handleSaveOnClick}
                exportFileName={exportFileName}
                exportSlotBtn={true}
                paginationProps={paginationProps}
                handleGetSelectItem={handleOnSelectedItems}
                handleOnUpdateCallback={handleDataIsUpdate}/>

            {/* 查詢人員清單彈出視窗 */}
            {
                showQueryCreateUserList && (
                    <QueryCreateUserList
                        handleOnClose={() => setShowQueryCreateUserList(false)}
                        handleOnUpdate={handleOnCreateUserUpdate}
                    />
                )
            }

            { /* 查詢醫師清單彈出視窗 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        handleOnClose={() => setShowQueryPersonList(false)}
                        handleOnUpdate={handleOnDoctorUpdate}
                    />
                )
            }

            {/* 查詢支援機構彈出視窗 */}
            {
                showQuerySupportOrgModal && (
                    <QuerySupportOrg
                        handleOnClose={() => setShowQuerySupportOrgModal(false)}
                        handleOnUpdate={handleOnSupportOrgUpdate}
                    />
                )
            }
            {/* 報備支援收案確認彈出視窗 */}
            {
                showCaseConfirmation && (
                    <RSISCaseConfirmation data={dataList[focusIndex]}
                                          docotrList={doctorList}
                                          orgList={organizationList}
                                          deptList={deptList}
                                          handleOnUpdate={handleDataIsUpdate}
                                          handleOnClose={() => setShowCaseConfirmation(false)}
                    />
                )
            }

            {/* 報備支援複製-彈出視窗 */}
            {
                showCopyModal && (
                    <CopyModal data={dataList[focusIndex]}
                               doctorList={doctorList}
                               orgList={organizationList}
                               deptList={deptList}
                               handleOnUpdate={handleDataIsUpdate}
                               handleOnClose={() => setShowCopyModal(false)}/>
                )
            }

            {/* 報備支援修改-編輯彈出視窗 */}
            {
                showEditModal && (
                    <EditModal data={dataList[focusIndex]}
                               doctorList={doctorList}
                               orgList={organizationList}
                               deptList={deptList}
                               ownerList={ownerList}
                               handleOnUpdate={handleDataIsUpdate}
                               handleOnClose={() => setShowEditModal(false)}/>
                )
            }

            {/* 報備支援修改-彈出視窗 */}
            {
                showChangeModal && (
                    <ChangeModal data={dataList[focusIndex]}
                                 isOwnUser={true}
                                 handleOnClose={() => setShowChangeModal(false)}
                                 handleOnUpdate={handleDataIsUpdate}
                    />
                )
            }

            {/* 報備支援申請註銷作業-彈出視窗 */}
            {
                showLogOffModal && (
                    <LogOffModal data={dataList[focusIndex]}
                                 isOwnUser={true}
                                 handleOnClose={() => setShowLogOffModal(false)}
                                 handleOnUpdate={handleDataIsUpdate}
                    />
                )
            }

            {/* 報備支援內容顯示彈出視窗 */}
            {
                showContentView && (
                    <RSISContentView
                        data={dataList[focusIndex]}
                        handleOnClose={() => {
                            setShowContentView(false);
                        }}
                    />
                )
            }
            {/* 刪除個案管理清單彈出視窗 */}
            {
                showDeletePopup && (
                    <DeletePopup
                        handleDeletePopupClose={() => setShowDeletePopup(false)}
                        handleNotificationDelete={handleOnDelItem}
                        currentKey={focusIndex}
                    />
                )
            }
            {/* 匯出檔案名稱輸入彈窗 */}
            {
                showExportFileNameInputModal && (
                    <ExportFIleNameModal
                        handleOnClose={() => setShowExportFileNameInputModal(false)}
                        handleOnExport={handleExportNameUpdate}/>
                )
            }
            {/* 確認使用者是否送至衛福部彈窗 */}
            {
                showSendMOHWConfirm && (
                    <SendMOHWConfirm
                        handleOnClose={() => setShowSendMOHWConfirm(false)}
                    />
                )
            }

        </Layout>
    )
}

export default RSISCaseManagement

import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {INP_FIELDS, OPD_ER_FIELDS,} from '../../constants/referralSystem/addReferralForm'
import {Pagination} from '../../components/Pagination/Pagination'
import ReferralTicketModal from '../../components/ReferralSystem/ReferralTicketModal/ReferralTicketModal'
import {ApiErrorStatusEnum, objectIsEmpty, time2String, validDateFormat,} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {getPartNoByEncounterList, queryNotTranBackReasonByDay, queryTestData,} from '../../api/v1/Rmis'
import ReturnAppointmentModal from './ReturnAppointmentModal'
import {DATETIME_FORMAT} from '../../constants/common'
import NoReferralReasonModal from '../../components/ReferralSystem/NoReferralReasonModal'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    SizeEnum,
    DateRangePicker,
    TextField
} from "edah-component/dist";
import dayjs from "dayjs"
import {t} from 'i18next'

/**
 * 區分勾選資料是門急還是住院資料
 * OPD_ER: 門/急 資料
 * INP: 住院 資料
 */
const OPD_ER = 'opdEr'
const INP = 'inp'

/**
 * 轉出轉入開立作業
 * @returns {JSX.Element}
 */
const AddReferralForm = () => {
    /**
     * 是否顯示轉診開單作業
     */
    const [isShowReferralTicketModal, setIsShowReferralTicketModal] =
        useState(false)
    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        search: '',
        encounterStartDate: '',
        encounterEndDate: '',
        patientName: '',
    })

    /**
     * 搜尋輸入框的狀態
     */
    const [inputState, setInputState] = useState({
        encounterStartDateState: null,
        encounterEndDateState: null,
    })

    /**
     * 門/急表格
     */
    const [opdErList, setOpdErList] = useState([])

    /**
     * 住院表格
     */
    const [inpList, setInpList] = useState([])

    /**
     * 看診資料
     */
    const [selectedVisitInfo, setSelectedVisitInfo] = useState({})

    /**
     * 開啟轉診回診清單視窗
     */
    const [isShowReturnAppointmentModal, setIsShowReturnAppointmentModal] =
        useState(false)

    const [isShowNoReferralReasonModal, setIsShowNoReferralReasonModal] =
        useState(false)
    /**
     * 門/急table的pagination
     */
    const [opdErFilter, setOpdErFilter] = useState({
        pageNum: 1,
        pageSize: 10,
        totalPageSize: 0,
        totalItemSize: 0,
    })

    /**
     * 住院table的pagination
     */
    const [inpFilter, setInpFilter] = useState({
        pageNum: 1,
        pageSize: 10,
        totalPageSize: 0,
        totalItemSize: 0,
    })

    const showToast = useToast()

    /**
     * 更新查詢條件
     * @param {Event} e
     * @param {string} field 欄位
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        setSearchParams((prev) => ({
            ...prev,
            [field]: e.target.value,
        }))
    }

    /**
     * 看診/住院日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            encounterStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 查詢 (測試資料)
     * @return {void}
     */
    const handleQuerOnClick = () => {
        setSelectedVisitInfo(null)
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        setInputState({
            encounterStartDateState: encounterStartDateIsValid,
            encounterEndDateState: encounterEndDateIsValid,
        })
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        getInpOpdList({
            inpPageNum: 1,
            inpPageSize: inpFilter.pageSize,
            opdPageNum: 1,
            opdPageSize: opdErFilter.pageSize,
        })
    }

    const getInpOpdList = ({
                               inpPageNum,
                               inpPageSize,
                               opdPageNum,
                               opdPageSize,
                           }) => {
        queryTestData({
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            search: searchParams.search,
            patientName: searchParams.patientName,
            inpPageNum,
            inpPageSize,
            opdPageNum,
            opdPageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const {data} = res
                const opdList = data?.opd.dataList.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
                const inpList = data?.inp.dataList.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
                setOpdErList(opdList)
                setInpList(inpList)
                setInpFilter({
                    ...inpFilter,
                    pageNum: inpPageNum,
                    pageSize: inpPageSize,
                    totalPageSize: data?.inp.totalPageSize,
                    totalItemSize: data?.inp.totalItemSize,
                })
                setOpdErFilter({
                    ...opdErFilter,
                    pageNum: opdPageNum,
                    pageSize: opdPageSize,
                    totalPageSize: data?.opd.totalPageSize,
                    totalItemSize: data?.opd.totalItemSize,
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開啟轉診開單作業視窗
     * @return {void}
     */
    const showAddReferralFormModal = () => {
        if (selectedVisitInfo?.patient) {
            setIsShowReferralTicketModal(true)
        }
    }

    /**
     * 轉診回診清單
     */
    const handleReturnAppointment = () => {
        if (selectedVisitInfo?.patient) {
            setIsShowReturnAppointmentModal(true)
        }
    }

    /**
     * 查詢45天內未輸入不轉診原因之轉診單
     * api回傳有資料不轉診原因視窗
     */
    const handleNoReferralReason = () => {
        if (selectedVisitInfo?.patient) {
            queryNotTranBackReasonByDay({
                patientId: selectedVisitInfo.patient.patientId,
                encounterDate: selectedVisitInfo.patient.encounterDate,
                divNo: selectedVisitInfo.patient.divNo,
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    if (!objectIsEmpty(res.data)) {
                        setIsShowNoReferralReasonModal(true)
                    }
                } else {
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 不轉診原因視窗點選確定
     */
    const handleShowReferralTicket = () => {
        setIsShowReferralTicketModal(true)
    }

    /**
     * 選擇單筆門/急資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleOpdErItem = (isChecked, selectedItem, index) => {
        if (!isChecked) {
            setSelectedVisitInfo(null)
        } else {
            setSelectedVisitInfo({
                patient: selectedItem,
                type: OPD_ER,
            })
        }
        // 只能選擇單筆
        if (selectedVisitInfo && selectedVisitInfo.type === INP && isChecked) {
            setInpList((prev) =>
                prev.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
            )
        }
        const modifiedList = opdErList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        setOpdErList(modifiedList)
    }

    /**
     * 選擇單筆住院資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleInpItem = (isChecked, selectedItem, index) => {
        if (!isChecked) {
            setSelectedVisitInfo(null)
        } else {
            setSelectedVisitInfo({
                patient: selectedItem,
                type: INP,
            })
        }
        if (
            selectedVisitInfo &&
            selectedVisitInfo.type === OPD_ER &&
            isChecked
        ) {
            setOpdErList((prev) =>
                prev.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
            )
        }
        const modifiedList = inpList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.encounterId === item.encounterId
                        ? isChecked
                        : false,
            }
        })
        setInpList(modifiedList)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     * @return {void}
     */
    const onPageOnChange = (page, type) => {
        const currentPage = Number(page)
        const filter =
            type === 'opdEr'
                ? {
                    inpPageNum: inpFilter.pageNum,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: currentPage,
                    opdPageSize: opdErFilter.pageSize,
                }
                : {
                    inpPageNum: currentPage,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: opdErFilter.pageNum,
                    opdPageSize: opdErFilter.pageSize,
                }
        getInpOpdList(filter)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     * @return {void}
     */
    const onPageSizeChange = (event, type) => {
        const pageSize = Number(event.target.value)
        const filter =
            type === 'opdEr'
                ? {
                    inpPageNum: inpFilter.pageNum,
                    inpPageSize: inpFilter.pageSize,
                    opdPageNum: opdErFilter.pageNum,
                    opdPageSize: pageSize,
                }
                : {
                    inpPageNum: inpFilter.pageNum,
                    inpPageSize: pageSize,
                    opdPageNum: opdErFilter.pageNum,
                    opdPageSize: opdErFilter.pageSize,
                }
        getInpOpdList(filter)
    }

    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = (type) => {
        let filter = {}
        if (type === 'opdEr') {
            const prevPage = opdErFilter.pageNum - 1
            const page = prevPage < 1 ? opdErFilter.totalPageSize : prevPage
            filter = {
                inpPageNum: inpFilter.pageNum,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: page,
                opdPageSize: opdErFilter.pageSize,
            }
        } else {
            const prevPage = inpFilter.pageNum - 1
            const page = prevPage < 1 ? inpFilter.totalPageSize : prevPage
            filter = {
                inpPageNum: page,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: opdErFilter.pageNum,
                opdPageSize: opdErFilter.pageSize,
            }
        }
        getInpOpdList(filter)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        let filter = {}
        if (type === 'opdEr') {
            const nextPage = opdErFilter.pageNum + 1
            const firstPage = 1
            const page =
                nextPage > opdErFilter.totalPageSize ? firstPage : nextPage
            filter = {
                inpPageNum: inpFilter.pageNum,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: page,
                opdPageSize: opdErFilter.pageSize,
            }
        } else {
            const nextPage = inpFilter.pageNum + 1
            const firstPage = 1
            const page =
                nextPage > inpFilter.totalPageSize ? firstPage : nextPage
            filter = {
                inpPageNum: page,
                inpPageSize: inpFilter.pageSize,
                opdPageNum: opdErFilter.pageNum,
                opdPageSize: opdErFilter.pageSize,
            }
        }
        getInpOpdList(filter)
    }

    // 轉診回診清單視窗[確定]按鈕
    const handleReturnAppointmentOnConfirm = (data) => {
        const info = data[0]
        if (info) {
            getPartNoByEncounterList({
                tranEncounterId: info.tranEncounterId,
                encounterId: info.encounterId,
                encounterDate: info.encounterDate,
                inpOpd: info.inpOpd,
            })
            setIsShowReturnAppointmentModal(false)
        }
    }

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div className="flex justify-between items-center">
                <div className="flex items-center justify-start space-x-2 p-2">
                    {/* 病歷號/身分證號 */}
                    <TextField
                        label={'病歷號/身分證號'}
                        value={searchParams.search}
                        onChange={(e) => updateSearchParams(e, 'search')}
                    />
                    {/* 姓名 */}
                    <TextField
                        label={t('general.username')}
                        value={searchParams.patientName}
                        onChange={(e) => updateSearchParams(e, 'patientName')}
                    />
                    {/* 看診/住院日期起訖 */}
                    <DateRangePicker
                        size={SizeEnum.Small}
                        required
                        localeText={{
                            start: '看診/住院開始日期',
                            end: '看診/住院結束日期',
                        }}
                        value={[
                            searchParams.encounterStartDate ? dayjs(searchParams.encounterStartDate) : null,
                            searchParams.encounterEndDate ? dayjs(searchParams.encounterEndDate) : null,
                        ]}
                        onChange={handleDateRangeChange}
                    />
                    {/* 查詢按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary}
                        text={t('general.query')}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleQuerOnClick}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
                <div className="flex items-end space-x-2">
                    <Button
                        color={ButtonColorEnum.Primary}
                        text='不轉診原因'
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleNoReferralReason}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                    <Button
                        color={ButtonColorEnum.Primary}
                        text='轉診回診清單'
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleReturnAppointment}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                    <Button
                        color={ButtonColorEnum.Secondary}
                        text='轉診開單作業'
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        onClick={showAddReferralFormModal}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                </div>
            </div>
            <div className="flex flex-wrap mt-2">
                <div className="w-[50%] pr-1 min-h-[600px]">
                    <CustomTable
                        title="門/急"
                        fields={OPD_ER_FIELDS}
                        dataList={opdErList}
                        toggleItem={toggleOpdErItem}
                        isSingleSelect
                        isSelectable
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={opdErFilter.pageSize}
                            totalSize={opdErFilter.totalItemSize}
                            currentPage={opdErFilter.pageNum}
                            totalPageSize={opdErFilter.totalPageSize}
                            onPageOnChange={(page) =>
                                onPageOnChange(page, 'opdEr')
                            }
                            onPageSizeChange={(page) =>
                                onPageSizeChange(page, 'opdEr')
                            }
                            onPrevPageOnClick={() => onPrevPageOnClick('opdEr')}
                            onNextPageOnClick={() => onNextPageOnClick('opdEr')}
                        />
                    </div>
                </div>
                <div className="w-[50%] pl-1 min-h-[600px]">
                    <CustomTable
                        title="住院"
                        fields={INP_FIELDS}
                        dataList={inpList}
                        toggleItem={toggleInpItem}
                        isSingleSelect
                        isSelectable
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={inpFilter.pageSize}
                            totalSize={inpFilter.totalItemSize}
                            currentPage={inpFilter.pageNum}
                            totalPageSize={inpFilter.totalPageSize}
                            onPageOnChange={(page) =>
                                onPageOnChange(page, 'inp')
                            }
                            onPageSizeChange={(page) =>
                                onPageSizeChange(page, 'inp')
                            }
                            onPrevPageOnClick={() => onPrevPageOnClick('inp')}
                            onNextPageOnClick={() => onNextPageOnClick('inp')}
                        />
                    </div>
                </div>
            </div>
            {isShowReferralTicketModal && (
                <ReferralTicketModal
                    selectedVisitInfo={selectedVisitInfo}
                    onClose={() => setIsShowReferralTicketModal(false)}
                />
            )}
            {isShowReturnAppointmentModal && (
                <ReturnAppointmentModal
                    patientInfo={selectedVisitInfo.patient}
                    onConfirm={handleReturnAppointmentOnConfirm}
                    onClose={() => setIsShowReturnAppointmentModal(false)}
                />
            )}
            {isShowNoReferralReasonModal && (
                <NoReferralReasonModal
                    patientInfo={selectedVisitInfo.patient}
                    onClose={() => setIsShowNoReferralReasonModal(false)}
                    goReferralTicket={handleShowReferralTicket}
                />
            )}
        </div>
    )
}

export default AddReferralForm

import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import Button from "../../Button/Button";
import React, {useState} from "react";
import {TextField, Field} from "edah-component/dist";

const Title = ({children, extendClass}) => {
    return (
        <div className={`flex flex-row items-center justify-start mr-8 ${extendClass}`}>
            <div>{children}</div>
        </div>
    )
}
const InputStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"
const ButtonStyle = "flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white"
const SelectStyle = "w-[260px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * 急救責任醫院維護表單
 * @returns {JSX.Element}
 */
const EmergencyHospitalMaintainForm = ({data}) => {
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState("")
    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)

    // 院所名稱
    const [hospitalName, setHospitalName] = useState("")
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    // 分院名稱
    const [subHospitalName, setSubHospitalName] = useState("")
    const handleSubHospitalNameChange = (e) => setSubHospitalName(e.target.value)


    // 開始日期
    const [startDate, setStartDate] = useState("")
    const handleUpdateStartDate = (e) => {
        setStartDate(e.target.value)
    }
    const [endDate, setEndDate] = useState("")

    const handleUpdateEndDate = (e) => {
        setEndDate(e.target.value)
    }

    // 儲存
    const handleSave = () => {
        // TODO
    }

    return <>
        <div className="w-full overflow-auto max-h-[calc(100vh-180px)] p-4">
            <div className="grid grid-cols-3 mb-2">
                <Field label="院所代碼">
                    <TextField
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                </Field>
                <Field label="院所名稱">
                    <TextField
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                </Field>
                <Field label="分院名稱">
                    <TextField
                        value={subHospitalName}
                        onChange={handleSubHospitalNameChange}
                    />
                </Field>
            </div>
            <div className="mb-2">
                <Field label="開始日期">
                    <input
                        className={InputStyle}
                        type="date"
                        value={startDate}
                        onChange={(e) => handleUpdateStartDate(e)}
                    />
                    <span className="m-1">~</span>
                    <input
                        className={InputStyle}
                        value={endDate}
                        onChange={(e) => handleUpdateEndDate(e)}
                        type="date"
                    />
                </Field>
            </div>
            <div
                className="title w-full flex flex-row items-center justify-end px-6 py-4 border-t-[1px] border-[rgba(0,0,0,0.15)]">
                <Button classNames={`ml-4 ${ButtonStyle}`} text={"存檔"} onClickFn={handleSave}/>
            </div>
        </div>
    </>
}

export default EmergencyHospitalMaintainForm
import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import addIcon from "../../../assets/images/icons/add_circle.svg";
import deleteCircle from "../../../assets/images/icons/del_circle.svg";
import {t} from "i18next";

/**
 * 巴柯氏量表
 * @return {JSX.Element}
 */
const BarChartForm = () => {

    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    // 預設表單資料
    const [formData, setFormData] = useState({
        // 評估日期
        evaluationDate: "",

        // 評估人員
        evaluator: "",

        // 各項目的分數
        items: {
            // 進食
            feeding: {score: 0},
            // 移位
            transfer: {score: 0},
            // 個人衛生
            personalHygiene: {score: 0},
            // 如廁
            toiletUse: {score: 0},
            // 洗澡
            bathing: {score: 0},
            // 行走於平地上
            walking: {score: 0},
            // 上下樓梯
            stairs: {score: 0},
            // 穿脫衣服
            dressing: {score: 0},
            // 大便控制
            bowelControl: {score: 0},
            // 小便控制
            bladderControl: {score: 0}
        }
    });

    /**
     * 處理輸入改變事件
     * @param e {Event} 事件物件
     * @param item {String} 項目名稱
     * @return {void}
     **/
    const handleInputChange = (e, item) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            items: {
                ...formData.items,
                [item]: {score: parseInt(value)}
            }
        });
    };

    // 計算總分
    const totalScore = Object.values(formData.items).reduce((sum, item) => sum + item.score, 0);

    // 各項目對應的內容
    const itemDescriptions = {
        feeding: "進食",
        transfer: "移位",
        personalHygiene: "個人衛生",
        toiletUse: "如廁",
        bathing: "洗澡",
        walking: "行走於平地上",
        stairs: "上下樓梯",
        dressing: "穿脫衣服",
        bowelControl: "大便控制",
        bladderControl: "小便控制"
    };

    // 各分數對應的內容
    const scoreDescriptions = {
        // 進食
        feeding: {
            10: "自己在合理的時間內(約十秒鐘吃一口)可用筷子取食眼前的食物。若需進食輔具時，應會自行穿脫。",
            5: "需別人幫忙穿脫輔具或只會用湯匙進食。",
            0: "無法自行取食或耗費時間過長。"
        },
        // 移位
        transfer: {
            15: "可獨立完成，包括輪椅的煞車及移開腳踏板。",
            10: "需要稍微的協助(例如:予以輕扶以保持平衡)或需要口頭指導。",
            5: "可自行從床上坐起來，但移位時仍需要別人幫忙。",
            0: "需別人幫忙方可坐起來或需別人幫忙方可移位。"
        },
        // 個人衛生
        personalHygiene: {
            5: "可獨立完成洗臉、洗手、刷牙及梳頭髮。",
            0: "需要別人幫忙。"
        },
        // 如廁
        toiletUse: {
            10: "可自行進出廁所，不會弄髒衣物，並能穿好衣服。使用便盆者，可自行清理便盆。",
            5: "需幫忙保持姿勢的平衡，整理衣物或使用衛生紙。使用便盆者，可自行取放便盆，但須仰賴他人清理。",
            0: "需他人幫忙。"
        },
        // 洗澡
        bathing: {
            5: "可獨立完成(不論盆浴或沐浴)。",
            0: "需他人幫忙。"
        },
        // 行走於平地上
        walking: {
            15: "使用或不使用輔具皆可獨立行走50公尺以上。",
            10: "需要稍微的扶持或口頭指導方可行走50公尺以上。",
            5: "雖無法行走，但可獨立操作輪椅(包括轉彎、進門、及接近桌子、床沿)並可推行輪椅50公尺以上。",
            0: "需他人幫忙。"
        },
        // 上下樓梯
        stairs: {
            10: "可自行上下樓梯(允許抓扶手或用拐杖)。",
            5: "需要稍微幫忙或口頭指導。",
            0: "無法上下樓梯。"
        },
        // 穿脫衣服
        dressing: {
            10: "可自行穿脫衣服、鞋子及輔具。",
            5: "在別人幫忙下，可自行完成一半以上的動作。",
            0: "需別人幫忙。"
        },
        // 大便控制
        bowelControl: {
            10: "不會失禁，並可自行使用塞劑。",
            5: "偶爾失禁(每週不超過一次)或使用塞劑時需人幫助。",
            0: "需別人處理(挖大便)。"
        },
        // 小便控制
        bladderControl: {
            10: "日夜皆不會尿失禁，必要時會自行使用並清潔尿套、尿椅。",
            5: "偶爾會尿失禁(每週不超過一次)或尿急(無法等待便盆或無法即時趕到廁所)或需別人幫忙處理。",
            0: "需別人處理。"
        }
    };

    return (
        <div className="w-full h-[calc(100vh-165px)] overflow-y-auto p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>巴柯氏量表</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <button
                    className="flex items-center"
                >
                    <div className="py-[10px] h-10 mr-1">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <button className={`${SecondButtonStyle} ml-2`}>上頁</button>
                <button className={`${SecondButtonStyle} ml-2 bg-gray-200}`}>2024/06/28</button>
                <button className={`${SecondButtonStyle} ml-2`}>下頁</button>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <button className="flex flex-row justify-start items-center w-[112px] h-10">
                        <div className="w-5 h-5 mr-1">
                            <img className="w-full h-full" src={deleteCircle} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#E53E3E]">{t('general.delete')}</p>
                    </button>
                </div>
                <div className="flex flex-row flex-shrink-0">
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        Ditto
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                    value={formData.evaluationDate}
                    onChange={(e) => setFormData({...formData, evaluationDate: e.target.value})}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                    value={formData.evaluator}
                    onChange={(e) => setFormData({...formData, evaluator: e.target.value})}
                />
            </div>

            {/* 各項目評分 */}
            <div className="grid grid-cols-1 gap-4">
                <div className="flex flex-row items-center justify-center">
                    <div className="w-full flex flex-col justify-around mt-2">
                        <ul className="w-[1194px]">
                            <li className="flex flex-row items-center justify-start h-[42px] w-full border-[1px] border-[#111111]/15">
                                <div
                                    className="w-[307px] h-full flex items-center justify-center flex-shrink-0 font-bold border-r-[1px] border-[#111111]/15">項目
                                </div>
                                <div className="flex flex-row items-center justify-start h-full">
                                    <p className="w-[48px] flex items-center justify-center flex-shrink-0 h-full border-r-[1px] border-[#111111]/15">分數</p>
                                    <label className="w-[791px] flex items-center justify-start h-full px-2 border-r-[1px] border-[#111111]/15">日常生活功能量表紀錄單-巴氏量表</label>
                                </div>
                                <p className="w-[48px] text-center font-bold">得分</p>
                            </li>
                            {Object.keys(formData.items).map((item, index) => (
                                <li key={index} className="flex flex-row items-center justify-start border-[1px] border-t-0 border-[#111111]/15">
                                    <div
                                        className="w-[306px] h-full flex items-center justify-start px-4 flex-shrink-0">{itemDescriptions[item]}
                                    </div>
                                    <ul className="border-x border-[#111111]/15">
                                        {Object.keys(scoreDescriptions[item]).sort((a, b) => b - a).map((score) => (
                                            <li>
                                                <div className="flex flex-row items-center justify-start h-full border-t-0 border-[#111111]/15">
                                                    <p className="w-[48px] py-1 flex items-center justify-end pr-2 border-r border-[#111111]/15">{score}</p>
                                                    <label key={score}
                                                           className="w-[791px] flex flex-row items-center justify-start px-2 py-1">
                                                        <input
                                                            className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                                            type="radio"
                                                            name={item}
                                                            value={score}
                                                            checked={formData.items[item].score === parseInt(score)}
                                                            onChange={(e) => handleInputChange(e, item)}
                                                            style={{accentColor: "#2B6CB0"}}
                                                        />
                                                        <span className="ml-2">{scoreDescriptions[item][score]}</span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="w-full text-center font-bold">
                                        {formData.items[item].score}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* 總分顯示 */}
            <div className="w-[1194px] flex flex-row items-center justify-center border-x border-b border-[#D4D4D8]">
                <p className="w-full py-1 border-r border-[#111111]/15">總分</p>
                <p className="w-[48px] py-1">{totalScore}</p>
            </div>

            {/* 紀錄單 */}
            <div className="w-[1194px] flex flex-row items-center justify-center border-x border-b border-[#D4D4D8]">
                <p className="w-[48px] py-1"></p>
                <p className="w-full py-1 border-l border-[#111111]/15">日常生活功能量表紀錄單-Karnofsky scale</p>
            </div>
            {/* 紀錄單 */}
            <div className="w-[1194px] flex flex-row items-center justify-center border-x border-b border-[#D4D4D8]">
                <ul className="w-full">
                    <li className="w-full flex flex-row items-center justify-center">
                        <p className="w-[48px] py-1">0級</p>
                        <label className="w-full flex flex-row items-center justify-start px-2 py-1 border-l border-[#111111]/15">
                            <input
                                className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                style={{accentColor: "#2B6CB0"}}
                                type="radio"
                                name="rate"
                            />
                            完全活動，能維持所有之活動，不受任何限制。
                        </label>
                    </li>
                    <li className="w-full flex flex-row items-center justify-center">
                        <p className="w-[48px] py-1">1級</p>
                        <label className="w-full flex flex-row items-center justify-start px-2 py-1 border-l border-[#111111]/15">
                            <input
                                className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                style={{accentColor: "#2B6CB0"}}
                                type="radio"
                                name="rate"
                            />
                            能夠步行及維持輕度工作，如:簡單之家務、辦公室之工作、但受限於體力消耗量大之活動。
                        </label>
                    </li>
                    <li className="w-full flex flex-row items-center justify-center">
                        <p className="w-[48px] py-1">2級</p>
                        <label className="w-full flex flex-row items-center justify-start px-2 py-1 border-l border-[#111111]/15">
                            <input
                                className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                style={{accentColor: "#2B6CB0"}}
                                type="radio"
                                name="rate"
                            />
                            能夠步行及維持自我照顧，但無法進行辦公或家務，50%以上清醒時間，可以起床活動不必限制在床上或椅子上。
                        </label>
                    </li>
                    <li className="w-full flex flex-row items-center justify-center">
                        <p className="w-[48px] py-1">3級</p>
                       <label className="w-full flex flex-row items-center justify-start px-2 py-1 border-l border-[#111111]/15">
                            <input
                                className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                style={{accentColor: "#2B6CB0"}}
                                type="radio"
                                name="rate"
                            />
                            只能維持有限之自我照顧，超過50%以上之清醒時間，活動限制在床上或椅子上。
                        </label>
                    </li>
                    <li className="w-full flex flex-row items-center justify-center">
                        <p className="w-[48px] py-1">4級</p>
                        <label className="w-full flex flex-row items-center justify-start px-2 py-1 border-l border-[#111111]/15">
                            <input
                                className="w-5 h-5 px-2 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-1"
                                style={{accentColor: "#2B6CB0"}}
                                type="radio"
                                name="rate"
                            />
                            完全無法活動，不能進行任何自我照顧，且完全限制在床上或椅子上。
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BarChartForm;

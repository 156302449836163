import React, { useRef }  from 'react'
import {
    TextArea,
    SizeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
} from 'edah-component/dist'
const SecondButtonStyle = "bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2"
const InputStyle = "w-full px-2 bg-white text-black rounded-[6px] border border-gray-300"

/**
 * 有展開按鈕的 TextArea
 * @param maxHeight
 * @param handleOnChange
 * @param value
 * @returns {Element}
 * @constructor
 */
const ExtendBtnTextArea = ({maxHeight, handleOnChange , value }) => {
    const inputRef = useRef(null);

    const handleExtendTextArea = ()=>{
        // 如果沒有文字或者高度小於目前的欄位高就不動作
        if (!value || inputRef.current.scrollHeight <= maxHeight) {
            return
        }
        if(!!maxHeight && inputRef.current.scrollHeight > maxHeight){
            inputRef.current.style.height = maxHeight + 'px';
        } else{
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
        }
    }

    return (
        <div className="flex items-center">
            <TextArea inputSize={SizeEnum.Fill} value={value} className={InputStyle} inputRef={inputRef} onChange={(e)=>handleOnChange(e)}></TextArea>
            <Button
                text={'展開'}
                color={ButtonColorEnum.Secondary}
                size={ButtonSizeEnum.Medium}
                variant={ButtonVariantEnum.Outlined}
                onClick={handleExtendTextArea}
                sx={{fontWeight:'bold', marginLeft:'0.5rem'}}
            />
        </div>
    )
}

export default ExtendBtnTextArea
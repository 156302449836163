import React, {useState} from "react";
import deleteCircle from "../../../assets/images/icons/del_circle.svg"
import {t} from "i18next";
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import addIcon from "../../../assets/images/icons/add_circle.svg";

/**
 * 安寧居家療護持續疼痛評估表S3
 * @return {JSX.Element}
 */
const CareContinuousPainAssessment = () => {

    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    const [formData, setFormData] = useState({
        diagnosis: '',
        transferDepartment: '',
        painIntensity: '',
        prescription: '',
        respiration: '',
        consciousness: '',
        sideEffects: '',
        otherCareMethods: '',
        medicationRecords: {
            date: '',
            time: '',
            staff: '',
            medication: '',
            scheduledDose: '',
            prnDose: '',
            totalDose: '',
        }
    });

    /**
     * 處理表單變更
     * @param {Object} e - 事件對象
     */
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /**
     * 處理藥物記錄變更
     * @param {Object} e - 事件對象
     */
    const handleMedicationChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            medicationRecords: {
                ...prevState.medicationRecords,
                [name]: value,
            },
        }));
    };

    /**
     * 處理表單提交
     * @param {Object} e - 事件對象
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        // 處理表單提交邏輯
        console.log(formData);
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white">
            <h3 className="flex items-center justify-start z-10 text-[20px] mr-4 mb-2.5 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>安寧居家療護持續疼痛評估表(S3)</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <button
                    className="flex items-center"
                >
                    <div className="py-[10px] h-10 mr-1">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <button className={`${SecondButtonStyle} ml-2`}>上頁</button>
                <button className={`${SecondButtonStyle} ml-2 bg-gray-200}`}>2024/06/28</button>
                <button className={`${SecondButtonStyle} ml-2`}>下頁</button>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <button className="flex flex-row justify-start items-center w-[112px] h-10">
                        <div className="w-5 h-5 mr-1">
                            <img className="w-full h-full" src={deleteCircle} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#E53E3E]">{t('general.delete')}</p>
                    </button>
                </div>
                <div className="flex flex-row flex-shrink-0">
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        Ditto
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                />
            </div>
            <form className="border border-[#111111]/15 py-4 px-6" onSubmit={handleSubmit}>
                <div className="flex flex-row items-center justify-start mb-4">
                    {/* 診斷 */}
                    <label className="w-[160px] text-right pr-4 mr-4">診斷</label>
                    <BaseInput
                        className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="diagnosis"
                        value={formData.diagnosis}
                        onChange={handleChange}
                    />

                    {/* 轉移部位 */}
                    <label className="w-[160px] text-right pr-4 mr-4">轉移部位</label>
                    <BaseInput
                        className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="transferDepartment"
                        value={formData.transferDepartment}
                        onChange={handleChange}
                    />
                </div>

                {/* 疼痛強度(0-10) */}
                <div className="flex flex-row items-center justify-start mb-4">
                    <label className="w-[160px] text-right pr-4 mr-4">疼痛強度(0-10):</label>
                    <BaseInput
                        className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="painIntensity"
                        value={formData.painIntensity}
                        onChange={handleChange}
                    />
                </div>

                {/* 處方(藥物劑量、時間、途徑) */}
                <div className="flex flex-row items-center justify-start mb-4">
                    <label className="w-[160px] text-left pr-4 mr-4">處方(藥物劑量、時間、途徑):</label>
                    <BaseInput
                        className="w-[530px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="prescription"
                        value={formData.prescription}
                        onChange={handleChange}
                    />
                </div>

                {/* 呼吸(分) */}
                <div className="flex flex-row items-center justify-start mb-4">
                    {/* 呼吸(分) */}
                    <label className="w-[160px] text-right pr-4 mr-4">呼吸(分):</label>
                    <BaseInput
                        className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="respiration"
                        value={formData.respiration}
                        onChange={handleChange}
                    />
                </div>

                {/* 意識狀態 */}
                <div className="flex flex-row items-center justify-start mb-4">
                    <label className="w-[160px] text-right pr-4 mr-4">意識狀態:</label>
                    <BaseInput
                        className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="consciousness"
                        value={formData.consciousness}
                        onChange={handleChange}
                    />
                </div>

                {/* 其他如副作用、情緒及治療後之反應等(家住護理方法) */}
                <div className="flex flex-row items-center justify-start mb-4">
                    <label
                        className="w-[160px] text-justify pr-4 mr-4">其他如副作用、情緒及治療後之反應等(家住護理方法):</label>
                    <BaseInput
                        className="w-[520px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                        type={InputTypeEnum.Text}
                        name="sideEffects"
                        value={formData.sideEffects}
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="text-right pr-4 mr-4">副作用請填寫代碼如下:</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="text-right pr-4 mr-4">1. 呼吸抑制 &nbsp;&nbsp; 2. 鎮靜作用 &nbsp;&nbsp; 3.
                        頭暈 &nbsp;&nbsp; 4. 噁心 &nbsp;&nbsp; 5. 嘔吐 &nbsp;&nbsp; 6. 流汗 &nbsp;&nbsp; 7.
                        便秘 &nbsp;&nbsp; 8. 欣快感 &nbsp;&nbsp; 9. 口乾 &nbsp;&nbsp; 10. 皮膚紅疹 &nbsp;&nbsp; 11.
                        其他(請註明)</p>
                </div>
                <div className="flex flex-row items-center justify-start mb-4 w-full pb-4 border-b border-[#111111]/15">
                    <p className="text-right pr-4 mr-4">其他護理方法，如：想像治療、鬆弛治療、音樂治療、娛樂治療、寵物治療、芳香治療、指壓按摩、TENS等</p>
                </div>
                <div className="flex flex-row items-start justify-between mb-4">
                    <h3 className="w-[287px] h-[317px] p-2 font-bold text-left border-[1px] border-[#D4D4D8] rounded-[6px]">藥物點滴記錄</h3>
                    {/* 記錄日期 */}
                    <div>
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">記錄日期</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Date}
                                name="date"
                                value={formData.medicationRecords.date}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* 記錄時間 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">記錄時間</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="time"
                                value={formData.medicationRecords.time}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* 記錄人員 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">記錄人員</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="staff"
                                value={formData.medicationRecords.staff}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* 藥物 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">藥物</label>
                            <BaseInput
                                className="w-[523px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="medication"
                                value={formData.medicationRecords.medication}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* 按時給藥一天總劑量 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">按時給藥一天總劑量</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="scheduledDose"
                                value={formData.medicationRecords.scheduledDose}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* p.r.n給藥一天總劑量 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">p.r.n給藥一天總劑量</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="prnDose"
                                value={formData.medicationRecords.prnDose}
                                onChange={handleMedicationChange}
                            />
                        </div>

                        {/* 全天給藥天總劑量 */}
                        <div className="flex flex-row items-center justify-start mb-4">
                            <label className="w-[160px] text-right pr-4 mr-4">全天給藥天總劑量:</label>
                            <BaseInput
                                className="w-[148px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                type={InputTypeEnum.Text}
                                name="totalDose"
                                value={formData.medicationRecords.totalDose}
                                onChange={handleMedicationChange}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default CareContinuousPainAssessment
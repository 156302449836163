import React, {useState} from 'react';
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import addIcon from "../../../assets/images/icons/add_circle.svg";
import deleteCircle from "../../../assets/images/icons/del_circle.svg";
import {t} from "i18next";

/**
 * 社會/環境/心裡
 * @returns {JSX.Element}
 * @constructor
 */
const EvaluationForm = () => {
    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    const [formData, setFormData] = useState({
        serviceNeeds: [],
        equipmentUse: '',
        socialContact: '',
        roleChange: '',
        interpersonalRelations: '',
        livingPlace: '',
        livingPlace1: '',
        livingPlace2: '',
        livingSituation: '',
        housingType: '',
        environmentSafety: {
            noThreshold: '',
            environmentImprovement: '',
            wheelchairAccessible: '',
            removeObstacles: '',
            smokeDetectors: '',
            installDetectors: '',
        },
        hygiene: {
            odor: '',
            cleanEnvironment: '',
            ventilation: '',
            maintainVentilation: '',
            insectProblems: '',
            installScreens: '',
        },
        religiousBelief: '',
        grief: '',
        sexualFunction: '',
        caregivingAbility: '',
        healthManagement: '',
    });

    const labelStyle = () => {
        return 'flex flex-row items-center justify-start mr-4'
    }

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleMultiChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => {
            const list = prevState[name];
            return {
                ...prevState,
                [name]: list.includes(value)
                    ? list.filter((item) => item !== value)
                    : [...list, value],
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form submission
        console.log(formData);
    };

    const yesNoOptions = ['無', '有'];
    const agreementOptions = ['同意', '考慮', '拒絕', '其他'];

    return (
        <div className="w-full h-[calc(100vh-165px)] overflow-y-auto pl-4 py-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>社會/環境/心裡</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <button
                    className="flex items-center"
                >
                    <div className="py-[10px] h-10 mr-1">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <button className={`${SecondButtonStyle} ml-2`}>上頁</button>
                <button className={`${SecondButtonStyle} ml-2 bg-gray-200}`}>2024/06/28</button>
                <button className={`${SecondButtonStyle} ml-2`}>下頁</button>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <button className="flex flex-row justify-start items-center w-[112px] h-10">
                        <div className="w-5 h-5 mr-1">
                            <img className="w-full h-full" src={deleteCircle} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#E53E3E]">{t('general.delete')}</p>
                    </button>
                </div>
                <div className="flex flex-row flex-shrink-0">
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        Ditto
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                    value={formData.evaluationDate}
                    onChange={(e) => setFormData({...formData, evaluationDate: e.target.value})}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                    value={formData.evaluator}
                    onChange={(e) => setFormData({...formData, evaluator: e.target.value})}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row items-start justify-start border-t border-[#111111]/15">
                    <h2 className="w-[39px] h-[364px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                        style={{writingMode: "vertical-lr"}}>社會資源評估</h2>
                    <div className="w-full">
                        <div
                            className="w-full h-[208px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">一、社會資源聯繫</p>
                            <div>
                                <div className="flex flex-row items-start justify-start">
                                    <p className="w-[104px] h-[52px] flex items-center justify-center mr-2">1.需要之服務</p>
                                    <div>
                                        <div className="h-[52px] flex flex-row items-center justify-start">
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="居家服務"
                                                    checked={formData.serviceNeeds.includes("居家服務")}
                                                    onChange={handleMultiChange}
                                                />
                                                居家服務
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="喘息服務"
                                                    checked={formData.serviceNeeds.includes("喘息服務")}
                                                    onChange={handleMultiChange}
                                                />
                                                喘息服務
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="復康巴士"
                                                    checked={formData.serviceNeeds.includes("復康巴士")}
                                                    onChange={handleMultiChange}
                                                />
                                                復康巴士
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="其他"
                                                    checked={formData.serviceNeeds.includes("其他")}
                                                    onChange={handleMultiChange}
                                                />
                                                其他
                                            </label>
                                            <BaseInput
                                                className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                                type={InputTypeEnum.Text}
                                            />
                                        </div>
                                        <div className="h-[52px] flex flex-row items-center justify-start">
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="輔具申請"
                                                    checked={formData.serviceNeeds.includes("輔具申請")}
                                                    onChange={handleMultiChange}
                                                />
                                                輔具申請
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="輪椅"
                                                    checked={formData.serviceNeeds.includes("輪椅")}
                                                    onChange={handleMultiChange}
                                                />
                                                輪椅
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="病床"
                                                    checked={formData.serviceNeeds.includes("病床")}
                                                    onChange={handleMultiChange}
                                                />
                                                病床
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="氣墊床"
                                                    checked={formData.serviceNeeds.includes("氣墊床")}
                                                    onChange={handleMultiChange}
                                                />
                                                氣墊床
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="抽痰機"
                                                    checked={formData.serviceNeeds.includes("抽痰機")}
                                                    onChange={handleMultiChange}
                                                />
                                                抽痰機
                                            </label>
                                            <label className={labelStyle()}>
                                                <input
                                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                                    style={{accentColor: "#38A169"}}
                                                    type="checkbox"
                                                    name="serviceNeeds"
                                                    value="氧氣製造機"
                                                    checked={formData.serviceNeeds.includes("氧氣製造機")}
                                                    onChange={handleMultiChange}
                                                />
                                                氧氣製造機
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-start justify-start">
                                    <p className="w-[104px] h-[52px] flex items-center justify-center mr-2">2.使用情形</p>
                                    <div>
                                        <div className="h-[52px] flex flex-row items-center justify-start">
                                            <label className={labelStyle()}>
                                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                       style={{accentColor: "#2B6CB0"}}
                                                       type="radio"
                                                       name="equipmentUse"
                                                       value="已辦理(正辦理中)相關資源應用"
                                                       checked={formData.equipmentUse === "已辦理(正辦理中)相關資源應用"}
                                                       onChange={handleChange}
                                                />
                                                已辦理(正辦理中)相關資源應用
                                            </label>
                                            <label className={labelStyle()}>
                                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                       style={{accentColor: "#2B6CB0"}}
                                                       type="radio"
                                                       name="equipmentUse"
                                                       value="知道如何辦理但未使用"
                                                       checked={formData.equipmentUse === "知道如何辦理但未使用"}
                                                       onChange={handleChange}
                                                />
                                                知道如何辦理但未使用
                                            </label>
                                            <label className={labelStyle()}>
                                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                       style={{accentColor: "#2B6CB0"}}
                                                       type="radio"
                                                       name="equipmentUse"
                                                       value="不知道如何辦理"
                                                       checked={formData.equipmentUse === "不知道如何辦理"}
                                                       onChange={handleChange}
                                                />
                                                不知道如何辦理
                                            </label>
                                        </div>
                                        <div className="h-[52px] flex flex-row items-center justify-start">
                                            <label className={labelStyle()}>
                                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                       style={{accentColor: "#2B6CB0"}}
                                                       type="radio"
                                                       name="equipmentUse"
                                                       value="不願使用"
                                                       checked={formData.equipmentUse === "不願使用"}
                                                       onChange={handleChange}
                                                />
                                                不願使用
                                            </label>
                                            <label className={labelStyle()}>
                                                <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                                       style={{accentColor: "#2B6CB0"}}
                                                       type="radio"
                                                       name="equipmentUse"
                                                       value="其他"
                                                       checked={formData.equipmentUse === "其他"}
                                                       onChange={handleChange}
                                                />
                                                其他
                                            </label>
                                            <BaseInput
                                                className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                                type={InputTypeEnum.Text}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b  border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">二、社會接觸</p>
                            <div>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="socialContact"
                                               value="有外出活動"
                                               checked={formData.socialContact === "有外出活動"}
                                               onChange={handleChange}
                                        />
                                        有外出活動
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="socialContact"
                                               value="家人或親友經常來探視"
                                               checked={formData.socialContact === "家人或親友經常來探視"}
                                               onChange={handleChange}
                                        />
                                        家人或親友經常來探視
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="socialContact"
                                               value="家人或親友偶爾來探視"
                                               checked={formData.socialContact === "家人或親友偶爾來探視"}
                                               onChange={handleChange}
                                        />
                                        家人或親友偶爾來探視
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="socialContact"
                                               value="缺乏社會接觸"
                                               checked={formData.socialContact === "缺乏社會接觸"}
                                               onChange={handleChange}
                                        />
                                        缺乏社會接觸
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="socialContact"
                                               value="其他"
                                               checked={formData.socialContact === "其他"}
                                               onChange={handleChange}
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                        type={InputTypeEnum.Text}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b  border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">三、角色改變</p>
                            <div>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="roleChange"
                                               value="未受影響"
                                               checked={formData.roleChange === "未受影響"}
                                               onChange={handleChange}
                                        />
                                        未受影響
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="roleChange"
                                               value="略受影響但家庭運作正常"
                                               checked={formData.roleChange === "略受影響但家庭運作正常"}
                                               onChange={handleChange}
                                        />
                                        略受影響但家庭運作正常
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="roleChange"
                                               value="嚴重影響家庭運作"
                                               checked={formData.roleChange === "嚴重影響家庭運作"}
                                               onChange={handleChange}
                                        />
                                        嚴重影響家庭運作
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="roleChange"
                                               value="其他"
                                               checked={formData.roleChange === "其他"}
                                               onChange={handleChange}
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                        type={InputTypeEnum.Text}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b  border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">四、人際關係</p>
                            <div>
                                <div className="h-[52px] flex flex-row items-center justify-start">
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="interpersonalRelations"
                                               value="家人間維持良好之互動關係"
                                               checked={formData.interpersonalRelations === "家人間維持良好之互動關係"}
                                               onChange={handleChange}
                                        />
                                        家人間維持良好之互動關係
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="interpersonalRelations"
                                               value="偶有爭吵或不滿之主訴"
                                               checked={formData.interpersonalRelations === "偶有爭吵或不滿之主訴"}
                                               onChange={handleChange}
                                        />
                                        偶有爭吵或不滿之主訴
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="interpersonalRelations"
                                               value="家人間關係惡劣"
                                               checked={formData.interpersonalRelations === "家人間關係惡劣"}
                                               onChange={handleChange}
                                        />
                                        家人間關係惡劣
                                    </label>
                                    <label className={labelStyle()}>
                                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                               style={{accentColor: "#2B6CB0"}}
                                               type="radio"
                                               name="interpersonalRelations"
                                               value="其他"
                                               checked={formData.interpersonalRelations === "其他"}
                                               onChange={handleChange}
                                        />
                                        其他
                                    </label>
                                    <BaseInput
                                        className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                        type={InputTypeEnum.Text}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-start border-[#111111]/15">
                    <h2 className="w-[39px] h-[884px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                        style={{writingMode: "vertical-lr"}}>環境評估</h2>
                    <div className="w-full h-[884px]">
                        <div className="h-[156px] border-b border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-left mr-2">一、</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace"
                                           value="居住狀況"
                                           checked={formData.livingPlace === "居住狀況"}
                                           onChange={handleChange}
                                    />
                                    居住狀況
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace"
                                           value="照護機構狀況"
                                           checked={formData.livingPlace === "照護機構狀況"}
                                           onChange={handleChange}
                                    />
                                    照護機構狀況
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">1.</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingSituation"
                                           value="自宅"
                                           checked={formData.livingSituation === "自宅"}
                                           onChange={handleChange}
                                    />
                                    自宅
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingSituation"
                                           value="租屋"
                                           checked={formData.livingSituation === "租屋"}
                                           onChange={handleChange}
                                    />
                                    租屋
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingSituation"
                                           value="非住宅(如工廠)"
                                           checked={formData.livingSituation === "非住宅(如工廠)"}
                                           onChange={handleChange}
                                    />
                                    非住宅(如工廠)
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingSituation"
                                           value="其他"
                                           checked={formData.livingSituation === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">2.</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="housingType"
                                           value="平房"
                                           checked={formData.housingType === "平房"}
                                           onChange={handleChange}
                                    />
                                    平房
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="housingType"
                                           value="無電梯樓房"
                                           checked={formData.housingType === "無電梯樓房"}
                                           onChange={handleChange}
                                    />
                                    無電梯樓房
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="housingType"
                                           value="有電梯樓房"
                                           checked={formData.housingType === "有電梯樓房"}
                                           onChange={handleChange}
                                    />
                                    有電梯樓房
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="housingType"
                                           value="其他"
                                           checked={formData.housingType === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                        <div className="h-[364px] border-b border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-left mr-2">二、</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace1"
                                           value="居住狀況"
                                           checked={formData.livingPlace1 === "居住狀況"}
                                           onChange={handleChange}
                                    />
                                    居住
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace1"
                                           value="照護機構狀況"
                                           checked={formData.livingPlace1 === "照護機構狀況"}
                                           onChange={handleChange}
                                    />
                                    照護機構
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">1.1 </p>
                                <p className="mr-2">屋內有無門檻，使得輪椅無法通行</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.noThreshold"
                                           value="無"
                                           checked={formData.environmentSafety.noThreshold === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.noThreshold"
                                           value="有"
                                           checked={formData.environmentSafety.noThreshold === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">1.2 </p>
                                <p className="mr-2">建議環境改善工程</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.environmentImprovement"
                                           value="同意"
                                           checked={formData.environmentSafety.environmentImprovement === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.environmentImprovement"
                                           value="考慮"
                                           checked={formData.environmentSafety.environmentImprovement === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.environmentImprovement"
                                           value="拒絕"
                                           checked={formData.environmentSafety.environmentImprovement === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.environmentImprovement"
                                           value="其他"
                                           checked={formData.environmentSafety.environmentImprovement === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">2.1 </p>
                                <p className="mr-2">走道輪椅是否可通行</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.wheelchairAccessible"
                                           value="無"
                                           checked={formData.environmentSafety.wheelchairAccessible === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.wheelchairAccessible"
                                           value="有"
                                           checked={formData.environmentSafety.wheelchairAccessible === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">2.2 </p>
                                <p className="mr-2">建議移除障礙物</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.removeObstacles"
                                           value="同意"
                                           checked={formData.environmentSafety.removeObstacles === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.removeObstacles"
                                           value="考慮"
                                           checked={formData.environmentSafety.removeObstacles === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.removeObstacles"
                                           value="拒絕"
                                           checked={formData.environmentSafety.removeObstacles === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.removeObstacles"
                                           value="其他"
                                           checked={formData.environmentSafety.removeObstacles === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">3.1 </p>
                                <p className="mr-2">屋內有無煙霧偵測器或滅火裝置</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.smokeDetectors"
                                           value="無"
                                           checked={formData.environmentSafety.smokeDetectors === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.smokeDetectors"
                                           value="有"
                                           checked={formData.environmentSafety.smokeDetectors === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">3.2 </p>
                                <p className="mr-2">建議增設</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.installDetectors"
                                           value="同意"
                                           checked={formData.environmentSafety.installDetectors === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.installDetectors"
                                           value="考慮"
                                           checked={formData.environmentSafety.installDetectors === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.installDetectors"
                                           value="拒絕"
                                           checked={formData.environmentSafety.installDetectors === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="environmentSafety.installDetectors"
                                           value="其他"
                                           checked={formData.environmentSafety.installDetectors === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                        <div className="h-[364px] border-b border-[#111111]/15">
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-left mr-2">三、</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace2"
                                           value="居住狀況"
                                           checked={formData.livingPlace2 === "居住狀況"}
                                           onChange={handleChange}
                                    />
                                    居住
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="livingPlace2"
                                           value="照護機構狀況"
                                           checked={formData.livingPlace2 === "照護機構狀況"}
                                           onChange={handleChange}
                                    />
                                    照護機構
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">1.1 </p>
                                <p className="mr-2">屋內有無異味</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.odor"
                                           value="無"
                                           checked={formData.hygiene.odor === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.odor"
                                           value="有"
                                           checked={formData.hygiene.odor === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">1.2 </p>
                                <p className="mr-2">建議環境清潔，改善異味</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.cleanEnvironment"
                                           value="同意"
                                           checked={formData.hygiene.cleanEnvironment === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.cleanEnvironment"
                                           value="考慮"
                                           checked={formData.hygiene.cleanEnvironment === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.cleanEnvironment"
                                           value="拒絕"
                                           checked={formData.hygiene.cleanEnvironment === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.cleanEnvironment"
                                           value="其他"
                                           checked={formData.hygiene.cleanEnvironment === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">2.1 </p>
                                <p className="mr-2">屋內有無通風</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.ventilation"
                                           value="無"
                                           checked={formData.hygiene.ventilation === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.ventilation"
                                           value="有"
                                           checked={formData.hygiene.ventilation === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">2.2 </p>
                                <p className="mr-2">建議保持空氣流通</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.maintainVentilation"
                                           value="同意"
                                           checked={formData.hygiene.maintainVentilation === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.maintainVentilation"
                                           value="考慮"
                                           checked={formData.hygiene.maintainVentilation === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.maintainVentilation"
                                           value="拒絕"
                                           checked={formData.hygiene.maintainVentilation === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.maintainVentilation"
                                           value="其他"
                                           checked={formData.hygiene.maintainVentilation === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">3.1 </p>
                                <p className="mr-2">屋內有無蚊蟲孳生</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.insectProblems"
                                           value="無"
                                           checked={formData.hygiene.insectProblems === "無"}
                                           onChange={handleChange}
                                    />
                                    無
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.insectProblems"
                                           value="有"
                                           checked={formData.hygiene.insectProblems === "有"}
                                           onChange={handleChange}
                                    />
                                    有
                                </label>
                            </div>
                            <div className="h-[52px] flex flex-row items-center justify-start pl-2">
                                <p className="w-[44px] text-center mr-2">3.2 </p>
                                <p className="mr-2">建議增設紗窗紗門或捕蚊設施</p>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.installScreens"
                                           value="同意"
                                           checked={formData.hygiene.installScreens === "同意"}
                                           onChange={handleChange}
                                    />
                                    同意
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.installScreens"
                                           value="考慮"
                                           checked={formData.hygiene.installScreens === "考慮"}
                                           onChange={handleChange}
                                    />
                                    考慮
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.installScreens"
                                           value="拒絕"
                                           checked={formData.hygiene.installScreens === "拒絕"}
                                           onChange={handleChange}
                                    />
                                    拒絕
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="hygiene.installScreens"
                                           value="其他"
                                           checked={formData.hygiene.installScreens === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start justify-start border-[#111111]/15">
                    <h2 className="w-[39px] h-[260px] flex-shrink-0 flex items-center justify-center border-x border-b border-[#111111]/15"
                        style={{writingMode: "vertical-lr"}}>心理評估</h2>
                    <div className="w-full">
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">一、宗教</p>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="religiousBelief"
                                           value="有宗教信仰為心靈寄託"
                                           checked={formData.religiousBelief === "有宗教信仰為心靈寄託"}
                                           onChange={handleChange}
                                    />
                                    有宗教信仰為心靈寄託
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="religiousBelief"
                                           value="對信仰產生動搖"
                                           checked={formData.religiousBelief === "對信仰產生動搖"}
                                           onChange={handleChange}
                                    />
                                    對信仰產生動搖
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="religiousBelief"
                                           value="對信仰失去信心"
                                           checked={formData.religiousBelief === "對信仰失去信心"}
                                           onChange={handleChange}
                                    />
                                    對信仰失去信心
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="religiousBelief"
                                           value="意識不清，無法評估"
                                           checked={formData.religiousBelief === "意識不清，無法評估"}
                                           onChange={handleChange}
                                    />
                                    意識不清，無法評估
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="religiousBelief"
                                           value="其他"
                                           checked={formData.religiousBelief === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>

                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">二、哀傷</p>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="grief"
                                           value="無哀傷"
                                           checked={formData.grief === "無哀傷"}
                                           onChange={handleChange}
                                    />
                                    無哀傷
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="grief"
                                           value="處於正常哀傷過程"
                                           checked={formData.grief === "處於正常哀傷過程"}
                                           onChange={handleChange}
                                    />
                                    處於正常哀傷過程
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="grief"
                                           value="處理哀傷過程有困難"
                                           checked={formData.grief === "處理哀傷過程有困難"}
                                           onChange={handleChange}
                                    />
                                    處理哀傷過程有困難
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="grief"
                                           value="意識不清，無法評估"
                                           checked={formData.grief === "意識不清，無法評估"}
                                           onChange={handleChange}
                                    />
                                    意識不清，無法評估
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="grief"
                                           value="其他"
                                           checked={formData.grief === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">三、性功能</p>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="sexualFunction"
                                           value="與配偶有性關係"
                                           checked={formData.sexualFunction === "與配偶有性關係"}
                                           onChange={handleChange}
                                    />
                                    與配偶有性關係
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="sexualFunction"
                                           value="與配偶有親密身體接觸"
                                           checked={formData.sexualFunction === "與配偶有親密身體接觸"}
                                           onChange={handleChange}
                                    />
                                    與配偶有親密身體接觸
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="sexualFunction"
                                           value="無相關性行為"
                                           checked={formData.sexualFunction === "無相關性行為"}
                                           onChange={handleChange}
                                    />
                                    無相關性行為
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="sexualFunction"
                                           value="其他"
                                           checked={formData.sexualFunction === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">四、照顧或養育</p>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="caregivingAbility"
                                           value="提供適當身體照顧"
                                           checked={formData.caregivingAbility === "提供適當身體照顧"}
                                           onChange={handleChange}
                                    />
                                    提供適當身體照顧
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="caregivingAbility"
                                           value="對部分照顧有困難"
                                           checked={formData.caregivingAbility === "對部分照顧有困難"}
                                           onChange={handleChange}
                                    />
                                    對部分照顧有困難
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="caregivingAbility"
                                           value="無法勝任照顧工作"
                                           checked={formData.caregivingAbility === "無法勝任照顧工作"}
                                           onChange={handleChange}
                                    />
                                    無法勝任照顧工作
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="caregivingAbility"
                                           value="個案被忽略或虐待"
                                           checked={formData.caregivingAbility === "個案被忽略或虐待"}
                                           onChange={handleChange}
                                    />
                                    個案被忽略或虐待
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="caregivingAbility"
                                           value="其他"
                                           checked={formData.caregivingAbility === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                        <div
                            className="w-full h-[52px] flex flex-row items-center justify-start border-b border-r border-[#111111]/15">
                            <p className="w-[307px] h-full px-2 flex items-center justify-start h-full mr-2 border-r border-[#111111]/15">五、對健康問題的處理方法</p>
                            <div className="h-[52px] flex flex-row items-center justify-start">
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="主動就醫"
                                           checked={formData.healthManagement === "主動就醫"}
                                           onChange={handleChange}
                                    />
                                    主動就醫
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="建議後就醫"
                                           checked={formData.healthManagement === "建議後就醫"}
                                           onChange={handleChange}
                                    />
                                    建議後就醫
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="自行購買藥物"
                                           checked={formData.healthManagement === "自行購買藥物"}
                                           onChange={handleChange}
                                    />
                                    自行購買藥物
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="民俗療法"
                                           checked={formData.healthManagement === "民俗療法"}
                                           onChange={handleChange}
                                    />
                                    民俗療法
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="不理會"
                                           checked={formData.healthManagement === "不理會"}
                                           onChange={handleChange}
                                    />
                                    不理會
                                </label>
                                <label className={labelStyle()}>
                                    <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                           style={{accentColor: "#2B6CB0"}}
                                           type="radio"
                                           name="healthManagement"
                                           value="其他"
                                           checked={formData.healthManagement === "其他"}
                                           onChange={handleChange}
                                    />
                                    其他
                                </label>
                                <BaseInput
                                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-2"
                                    type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EvaluationForm;

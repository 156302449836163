//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import React, {useState} from "react"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    enum2Array,
    fuzzySearchObjects,
    ICCMDataCorrectFlagEnum,
    ICCMInpOpdEnum,
    ICCMMaintainPageActiveTabEnum,
    ICCMMaintainPageFunEnum,
    ICCMOperationModeEnum,
    ICCMUploadStatusEnum,
    InputTypeEnum,
    ToastTypeEnum
} from "edah_utils/dist"
import {ActiveTab} from "../../components/ICCard/MaintainPage/ActiveTab"
import {iccmPaticQueryPatic} from "../../api/v1/ICCM";
import useToast from "../../hooks/useToast"
import {BaseInput} from "../../components/Input/BaseInput"

/**
 * IC卡寫卡資料維護
 * @return {JSX.Element}
 */
const MaintainPage = () => {
    /**
     * Toast
     */
    const showToast = useToast()

    // 進階搜尋文字
    const [searchText, setSearchText] = useState('')

    // 切換基本資料 醫囑清單 檢核結果
    const [activeTab, setActiveTab] = useState(ICCMMaintainPageActiveTabEnum.BasicData)

    // 操作模式
    const [operateMode, setOperateMode] = useState(ICCMOperationModeEnum.None)

    // 選取的功能
    const [funEnum, setFunEnum] = useState(ICCMMaintainPageFunEnum.uploadA1)

    // 資料
    const [data, setData] = useState([])

    //就醫起始日期
    const [encounterDateStartDate, setEncounterDateStartDate] = useState('')

    //就醫結束日期
    const [encounterDateEndDate, setEncounterDateEndDate] = useState('')

    // 門/住
    const [inpOpd, setInpOpd] = useState(ICCMInpOpdEnum.outpatient)

    // 上傳狀態
    const [icUploadStatus, setIcUploadStatus] = useState(ICCMUploadStatusEnum.uploaded)

    // 資料是否正確
    const [icDataCorrectFlag, setIcDataCorrectFlag] = useState(ICCMDataCorrectFlagEnum.YES)

    // 身分證號
    const [idNo, setIdNo] = useState('')

    // 是否全選
    const [selectedAll, setSelectedAll] = useState(false)

    /**
     * 取得簡化btn class
     * @param fun {ICCMMaintainPageFunEnum} 功能
     * @return {string}
     */
    const getButtonClass = (fun) => `flex items-center justify-center h-10 px-4 mr-2 border-[2px] rounded-[6px] ${funEnum === fun ? 'bg-[#2B6CB0] text-white border-[#2B6CB0]' : 'border-[#D4D4D8] font-bold'}`

    /**
     * 處理上傳(A1)按鈕點擊
     * @return {void}
     */
    const handleUploadA1ButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.uploadA1)

    /**
     * 處理轉成XML按鈕點擊
     * @return {void}
     */
    const handleConvertToXmlButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.convertToXml)

    /**
     * 處理檢核資料正確性按鈕點擊
     * @return {void}
     */
    const handleCheckDataCorrectButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.checkDataCorrect)

    /**
     * 處理刪除按鈕點擊
     * @return {void}
     */
    const handleDeleteButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.delete)

    /**
     * 處理新增退掛(ZB)按鈕點擊
     * @return {void}
     */
    const handleAddReturnZbButtonOnClick = () => setFunEnum(ICCMMaintainPageFunEnum.addReturnZB)

    /**
     * 處理進階搜尋文字變更
     * @param {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => setSearchText(e.target.value)

    /**
     * 處理就醫日期起始日期變更
     * @param {Event} e
     * @return {void}
     */
    const handleEncounterDateStartDateOnChange = (e) => setEncounterDateStartDate(e.target.value)

    /**
     * 處理就醫日期結束日期變更
     * @param {Event} e
     * @return {void}
     */
    const handleEncounterDateEndDateOnChange = (e) => setEncounterDateEndDate(e.target.value)

    /**
     * 處理來源下拉選單變更
     * @param {Event} e
     * @return {void}
     */
    const handleInpOpdOnChange = (e) => setInpOpd(e.target.value)

    /**
     * 處理上傳狀態下拉選單變更
     * @param {Event} e
     * @return {void}
     */
    const handleIcUploadStatusOnChange = (e) => setIcUploadStatus(e.target.value)

    /**
     * 處理資料正確下拉選單變更
     * @param {Event} e
     * @return {void}
     */
    const handleIcDataCorrectFlagOnChange = (e) => setIcDataCorrectFlag(e.target.value)

    /**
     * 處理身分證號變更
     * @param {Event} e
     * @return {void}
     */
    const handleIdNoOnChange = (e) => setIdNo(e.target.value)

    /**
     * 取得過濾資料
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(data, searchText)

    /**
     * 點擊查詢按鈕
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQueryPatic()

    /**
     * 選取欄位變更
     * @param index {number} 索引
     * @return {void}
     */
    const handleSelectionOnChange = (index) => {
        // 複製資料
        const newData = [...data]
        // 設定選取
        newData[index].selected = !newData[index].selected
        // 設定資料
        setData(newData)
    }

    /**
     * 選取全部變更
     * @return {void}
     */
    const handleSelectedAllOnChange = () => {
        // 複製資料
        const newData = [...data]
        // 設定選取
        newData.forEach(item => item.selected = !selectedAll)
        // 設定資料
        setData(newData)
        // 設定是否全選
        setSelectedAll(!selectedAll)
    }

    /**
     * 取得是否全選禁用
     * @return {boolean}
     */
    const getSelectedAllDisabled = () => arrayIsEmpty(data)

    /**
     * 新增選擇欄位
     * @param data {Array<Object>} 資料
     * @return {Array<Object>}
     */
    const addSelectionField = (data) => data.map(item => ({...item, selected: false}))

    /**
     *　取得選取的資料
     * @return {Array<Object>}
     */
    const getSelectedList = () => arrayIsEmpty(data) ? [] : data.filter(item => item.selected)

    /**
     * 取得選取的筆數
     * @return {number}
     */
    const getSelectedCount = () => arrayIsEmpty(data) ? 0 : getSelectedList().length

    /**
     *　取得功能按鈕是否被禁用
     * @return {boolean}
     */
    const getFunButtonDisable = () => getSelectedCount() === 0

    /**
     * 取得IC卡寫卡基本資料讀取
     * @return {void}
     */
    const getQueryPatic = () => {
        iccmPaticQueryPatic({}).then(res => {
            // 取得錯誤 / 訊息 / 資料
            const {err, msg, data} = res

            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(addSelectionField(data))
            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    {/*就醫日期開始日期*/}
                    <p className="mr-4">就醫日期起訖</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} value={encounterDateStartDate}
                               onChange={handleEncounterDateStartDateOnChange}/>
                    {/*就醫日期結束日期*/}
                    <p className="m-1">~</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date} inputMode={InputTypeEnum.Date} value={encounterDateEndDate}
                               onChange={handleEncounterDateEndDateOnChange}/>
                </div>
                {/*來源下拉選單*/}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">來源</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]" value={inpOpd}
                            onChange={handleInpOpdOnChange}>
                        {
                            enum2Array(ICCMInpOpdEnum).map((item, index) =>
                                <option key={index} value={item.value}>
                                    {t(`ICCard.InpOpdEnum.${item.value}`)}
                                </option>
                            )
                        }
                    </select>
                </div>
                {/*上傳狀態下拉選單*/}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">上傳狀態</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={icUploadStatus}
                            onChange={handleIcUploadStatusOnChange}>
                        {
                            enum2Array(ICCMUploadStatusEnum).map((item, index) =>
                                <option key={index} value={item.value}>
                                    {t(`ICCard.UploadStatusEnum.${item.value}`)}
                                </option>
                            )
                        }
                    </select>
                </div>
                {/*資料正確下拉選單*/}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">資料正確</p>
                    <select className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                            value={icDataCorrectFlag}
                            onChange={handleIcDataCorrectFlagOnChange}>
                        {
                            enum2Array(ICCMDataCorrectFlagEnum).map((item, index) =>
                                <option key={index} value={item.value}>
                                    {t(`ICCard.DataCorrectFlagEnum.${item.value}`)}
                                </option>
                            )
                        }
                    </select>
                </div>
                {/*身分證號*/}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <p className="mr-4">身分證號</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text} value={idNo}
                               onChange={handleIdNoOnChange}/>
                </div>
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    {/*病歷號碼開始*/}
                    <p className="mr-4">病歷號碼起訖</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               placeholder="1111201" type={InputTypeEnum.Text}/>
                    <p className="m-1">~</p>
                    {/*病歷號碼結束*/}
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               placeholder="1111216" type={InputTypeEnum.Text}/>
                </div>
                {/*查詢按鈕*/}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                        onClick={handleQueryButtonOnClick}>
                        {t('general.query')}
                    </button>
                </div>
            </div>
            <div className="maintainContainer flex flex-row items-start justify-start">
                <div className="p-4 bg-white w-[773px] border-[1px] border-[#D4D4D8] rounded-[12px] mr-4">
                    <div className="btnGroup flex flex-row mb-4">
                        {/*上傳(A1)按鈕*/}
                        <button className={getButtonClass(ICCMMaintainPageFunEnum.uploadA1)}
                                disabled={getFunButtonDisable()}
                                onClick={handleUploadA1ButtonOnClick}>
                            {t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.uploadA1}`)}
                        </button>
                        {/*轉成XML*/}
                        <button className={getButtonClass(ICCMMaintainPageFunEnum.convertToXml)}
                                disabled={getFunButtonDisable()}
                                onClick={handleConvertToXmlButtonOnClick}>
                            {t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.convertToXml}`)}
                        </button>
                        {/*檢核資料正確性*/}
                        <button className={getButtonClass(ICCMMaintainPageFunEnum.checkDataCorrect)}
                                disabled={getFunButtonDisable()}
                                onClick={handleCheckDataCorrectButtonOnClick}>
                            {t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.checkDataCorrect}`)}
                        </button>
                        {/*刪除*/}
                        <button className={getButtonClass(ICCMMaintainPageFunEnum.delete)}
                                disabled={getFunButtonDisable()}
                                onClick={handleDeleteButtonOnClick}>
                            {t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.delete}`)}
                        </button>
                        {/*新增退掛(ZB)*/}
                        <button className={getButtonClass(ICCMMaintainPageFunEnum.addReturnZB)}
                                disabled={getFunButtonDisable()}
                                onClick={handleAddReturnZbButtonOnClick}>
                            {t(`ICCard.page.maintainPage.fun.${ICCMMaintainPageFunEnum.addReturnZB}`)}
                        </button>
                    </div>
                    {/*進階搜尋*/}
                    <div className="searchGroup flex flex-row items-center justify-between px-2 mb-4">
                        <BaseInput className="w-[320px] h-10 pl-4 border-[2px] border-[#D4D4D8] rounded-[6px]"
                                   type={InputTypeEnum.Text} placeholder={t('general.advancedSearch')}
                                   value={searchText} inputMode={InputTypeEnum.Search}
                                   onChange={handleSearchTextOnChange}/>
                        {
                            //已選取筆數
                            getSelectedCount() > 0 && (
                                <p>{t('general.selectedCount', {selectedCount: getSelectedCount()})}</p>
                            )
                        }
                    </div>
                    <ul className="tableList icMaintain">
                        <li className="title">
                            <div>
                                <BaseInput type={InputTypeEnum.Checkbox} value={selectedAll}
                                           disable={getSelectedAllDisabled()}
                                           onChange={handleSelectedAllOnChange}/>
                            </div>
                            <div>就醫序號</div>
                            <div>門/住</div>
                            <div>就醫類別</div>
                            <div>{t('general.patientNo')}</div>
                            <div>姓名</div>
                            <div>序號</div>
                            <div>寫卡狀態</div>
                            <div>上傳狀態</div>
                        </li>
                        {
                            !arrayIsEmpty(data) && getFilterData().map((item, index) =>
                                <li key={index}>
                                    {/*選擇欄位*/}
                                    <div>
                                        <BaseInput type={InputTypeEnum.Checkbox} value={item.selected}
                                                   onChange={() => handleSelectionOnChange(index)}/>
                                    </div>
                                    {/*就醫序號*/}
                                    <div>{item.encounterId}</div>
                                    {/*門/住*/}
                                    <div>{item.inpOpd}</div>
                                    {/*就醫類別*/}
                                    <div>{item.icEncType}</div>
                                    {/*病歷號*/}
                                    <div>{item.patientId}</div>
                                    {/*姓名*/}
                                    <div></div>
                                    {/*序號*/}
                                    <div>{item.bedNo}</div>
                                    {/*寫卡狀態*/}
                                    <div></div>
                                    {/*上傳狀態*/}
                                    <div>{item.icUploadStatus}</div>
                                </li>
                            )
                        }
                    </ul>
                </div>
                {/*Active Tab*/}
                <ActiveTab activeTab={activeTab}/>
            </div>
        </div>
    )
}
export default MaintainPage

import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import React, {useState} from "react";

/**
 * 安寧居家療護病歷
 * @return {JSX.Element}
 */
const PalliativeHomeCareMedicalRecord = () => {

    //同上變數：主要聯絡人
    const [mainContact, setMainContact] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    //同上變數：主要照顧者
    const [primaryCaregiver, setPrimaryCaregiver] = useState({
        name: '',
        relation: '',
        phone: '',
        education: '',
        occupation: '',
        lang: ''
    });

    /**
     * 同上
     * @return void
     */
    const handleSameAsAbove = () => {
        setPrimaryCaregiver({...mainContact});
        console.log(primaryCaregiver)
    };

    /**
     * 資料輸入
     * @param e
     * @param setFunc {Function}
     * @param key
     * @return void
     */
    const handleInputChange = (e, setFunc, key) => {
        setFunc(prevState => ({...prevState, [key]: e.target.value}));
    };

    return (
        <div className="h-[calc(100vh-175px)] overflow-y-auto p-6 bg-white">
            <div className="flex justify-end items-center pb-4 mb-2.5 w-full border-b-[1px] border-[#111111]/15">
                <div className="flex flex-row">
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        結案
                    </button>
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        看診資料查詢
                    </button>
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>

            <div className="mb-4 border-b-[1px] border-[#111111]/15 overflow-x-auto">
                <p className="w-full text-right">級別：S3</p>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[298px] mb-4 flex-shrink-0">
                        <p className="w-[160px] text-right">個案姓名：</p>
                        <span>黃ＯＯ</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[236px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">病歷號：</p>
                        <span>numbers</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[347px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">身分證號：</p>
                        <span>E123456789</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[315px] mb-4 flex-shrink-0">
                        <p className="w-[64px] text-right">性別：</p>
                        <span>男</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[298px] mb-4 flex-shrink-0">
                        <p className="w-[160px] text-right">家字號碼：</p>
                        <span>A009484738</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[236px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">出生日期：</p>
                        <span>2023/09/01</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[347px] mb-4 flex-shrink-0">
                        <p className="w-[120px] text-right">電話：</p>
                        <span>0987888000 / 072235500</span>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[315px] mb-4 flex-shrink-0">
                        <p className="w-[64px] text-right">地址：</p>
                        <span>高雄市燕巢區義大路1號</span>
                    </div>
                </div>
            </div>

            {/*主要聯絡人*/}
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">主要聯絡人</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={mainContact.name}
                                   onChange={e => handleInputChange(e, setMainContact, 'name')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">關係</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.relation}
                                onChange={e => handleInputChange(e, setMainContact, 'relation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">電話</p>
                        <BaseInput className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={mainContact.phone}
                                   onChange={e => handleInputChange(e, setMainContact, 'phone')}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">教育程度</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.education}
                                onChange={e => handleInputChange(e, setMainContact, 'education')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">職業</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.occupation}
                                onChange={e => handleInputChange(e, setMainContact, 'occupation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">溝通語言</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={mainContact.lang}
                                onChange={e => handleInputChange(e, setMainContact, 'lang')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                </div>
            </div>

            {/*主要照顧者*/}
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">主要照顧者</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                                   value={primaryCaregiver.name}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'name')}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">關係</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.relation}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'relation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">電話</p>
                        <BaseInput className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   value={primaryCaregiver.phone}
                                   type={InputTypeEnum.Text}
                                   onChange={e => handleInputChange(e, setPrimaryCaregiver, 'phone')}
                        />
                    </div>
                    {/*同上按鈕*/}
                    <button
                        className="flex items-center justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]"
                        onClick={handleSameAsAbove}>
                        同上
                    </button>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start w-[358px]">
                        <p className="w-[160px] text-right pr-4 mr-4">教育程度</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.education}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'education')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">職業</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.occupation}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'occupation')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                    <div className="flex flex-row items-center justify-start w-[256px]">
                        <p className="w-[96px] text-right pr-4 mr-4">溝通語言</p>
                        <select className="w-[128px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                value={primaryCaregiver.lang}
                                onChange={e => handleInputChange(e, setPrimaryCaregiver, 'lang')}
                        >
                            <option value="0"></option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4 mr-4">疾病主診斷</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4 mr-4">重大傷病卡號</p>
                        <input type="radio" id="card1" name="card"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="card1">有</label>
                        <input type="radio" id="card2" name="card"
                               className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}/>
                        <label className="mr-4" htmlFor="card2">沒有</label>
                        <p className="w-[160px] text-right pr-4 mr-4">迄未日期</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Date}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <div className="flex flex-row items-center justify-start">
                        <p className="w-[160px] text-right pr-4 mr-4">ICD-10</p>
                        <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                   type={InputTypeEnum.Text}
                        />
                    </div>
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">轉介來源</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <p className="w-[160px] text-right pr-4 mr-4">住ODP</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <p className="w-[160px] text-right pr-4 mr-4">轉介日期</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date}
                    />
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">轉介人</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <input type="radio" id="referrals1" name="referrals"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="referrals1">居家醫療個管師</label>
                    <input type="radio" id="referrals2" name="referrals"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="referrals2">安寧共照護理師</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">居家模式</p>
                    <input type="radio" id="liveMode1" name="liveMode"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="liveMode1">新制</label>
                    <input type="radio" id="liveMode2" name="liveMode"
                           className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                           style={{accentColor: "#2B6CB0"}}/>
                    <label className="mr-4" htmlFor="liveMode2">舊制</label>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">轉介居家收案醫師</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                               type={InputTypeEnum.Text}
                    />
                    <p className="w-[160px] text-right pr-4 mr-4">居家護理師</p>
                    <BaseInput className="w-[166px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Text}
                    />
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">收案日期</p>
                    <BaseInput className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date}/>
                </div>
                <div className="flex flex-row items-start justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pt-2 pr-4 mr-4">收案原因</p>
                    <div>
                        <div className="flex flex-row items-center justify-start w-[940px] h-[40px]">
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start1"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start1">疼痛</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start2"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start2">呼吸困難</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start3"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start3">噁心、嘔吐</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start4"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start4">便秘</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start5"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start5">吞嚥困難</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start6"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start6">腫瘤潰爛傷口</label>
                        </div>
                        <div className="flex flex-row flex-wrap items-center justify-start w-[940px] h-[40px]">
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start7"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start7">腹水</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start8"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start8">淋巴水腫</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start9"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start9">淋巴水腫</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start10"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start10">軟弱、倦怠</label>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   type="checkbox" id="start11"
                                   style={{accentColor: "#38A169"}}/>
                            <label className="mr-4" htmlFor="start11">大小便失禁</label>
                            <div className="flex flex-row items-center justify-start">
                                <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                       type="checkbox" id="start12"
                                       style={{accentColor: "#38A169"}}/>
                                <label className="mr-4" htmlFor="start12">其他</label>
                                <BaseInput className="w-[168px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                                           type={InputTypeEnum.Text}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[197px] text-right pr-4 mr-4">收案時Karnofsky Scale</p>
                    <BaseInput className="w-[160px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">分</p>
                    <p className="mr-1">ECOG</p>
                    <BaseInput className="w-[160px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="mr-4">分</p>
                </div>
            </div>
            <div className="mb-4 border-b-[1px] border-[#111111]/15 pt-4">
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] text-right pr-4 mr-4">結案日期</p>
                    <BaseInput className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                               type={InputTypeEnum.Date}/>
                </div>
                <div className="flex flex-row items-start justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">結案原因</p>
                    <div>
                        <div className="flex flex-row items-center justify-start w-[940px] h-[40px]">
                            <input type="radio" id="close1" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close1">問題解決</label>
                            <input type="radio" id="close2" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close2">轉安寧病房</label>
                            <input type="radio" id="close3" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close3">死亡</label>
                            <input type="radio" id="close4" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close4">轉其他醫療機構</label>
                            <input type="radio" id="close5" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close5">轉他願安寧居家</label>
                        </div>
                        <div className="flex flex-row items-center justify-start w-[940px] h-[40px]">
                            <input type="radio" id="close6" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close6">病人拒訪</label>
                            <input type="radio" id="close6" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close6">家屬拒訪</label>
                            <input type="radio" id="close7" name="closeCase"
                                   className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}/>
                            <label className="mr-4" htmlFor="close7">其他</label>
                            <BaseInput className="w-[236px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                       type={InputTypeEnum.Text}/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start mb-4">
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">結案醫師</p>
                    <BaseInput className="w-[160px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                    <p className="w-[160px] flex-shrink-0 text-right pr-4 mr-4">結案居家護理師</p>
                    <BaseInput className="w-[160px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-1"
                               type={InputTypeEnum.Text}
                    />
                </div>
            </div>
        </div>
    );
}
export default PalliativeHomeCareMedicalRecord
import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {Pagination} from '../../components/Pagination/Pagination'
import {ER_QUALITY_VPN_FIELDS} from '../../constants/referralSystem/erQualityVpnUploadExport'
import {queryRmisEmgquality, tranRmisEmgqualityToTxt} from '../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    downloadFile,
    FileExtensionsEnum,
    BlobTypeEnum,
} from 'edah_utils/dist'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    DatePicker,
    SizeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    IconEnum
} from "edah-component/dist"
import dayjs from "dayjs"
import {t} from "i18next";

/**
 * 急診品質方案VPN上傳匯出作業
 * @returns {JSX.Element}
 */
const ErQualityVpnUploadExport = () => {
    const [encounterYearMonth, setEncounterYearMonth] = useState('')
    const [encounterYearMonthInputState, setEncounterYearMonthInputState] =
        useState(null)
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPage: 1,
        total: 0,
        currentPage: 1,
        pageSize: 10,
    })
    const showToast = useToast()

    /**
     * 匯出
     */
    const exportText = () => {
        const month = encounterYearMonth ? encounterYearMonth.split('-')[1] : ''
        if (stringIsEmpty(month)) {
            return
        }
        const data = {
            //月份(only for file name)
            month,
            //需要轉換的資料
            rmisEmgualityData: dataList,
        }

        tranRmisEmgqualityToTxt(data).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const blob = new Blob([res.data.data], {
                    type: BlobTypeEnum.PlainText,
                })
                downloadFile(blob, res.data.fileName, FileExtensionsEnum.TXT)
            } else {
                showToast({
                    message: `匯出失敗: ${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getErQualityList(currentPage, filter.pageSize)
    }

    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getErQualityList(filter.currentPage, pageSize)
    }
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getErQualityList(page, filter.pageSize)
    }
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getErQualityList(page, filter.pageSize)
    }

    const handleSearch = () => {
        getErQualityList(1, filter.pageSize)
    }

    const getErQualityList = (pageNum, pageSize) => {
        const encounterYearMonthIsValid = !stringIsEmpty(encounterYearMonth)
        setEncounterYearMonthInputState(encounterYearMonthIsValid)
        if (!encounterYearMonthIsValid) {
            return
        }
        queryRmisEmgquality({
            pageNum,
            pageSize,
            month: time2String(encounterYearMonth, 'YYYY-MM'),
        }).then((result) => {
            if (result.err === ApiErrorStatusEnum.Success) {
                const list = result.data || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalPage: result.totalPageSize,
                    total: result.totalItemSize,
                    pageNum,
                    pageSize,
                })
            } else {
                showToast({message: result.msg, type: AlertTypeEnum.Warning})
            }
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex items-center space-x-2 p-2">
                    {/* 就醫年月選取 */}
                    <DatePicker
                        size={SizeEnum.Small}
                        required
                        value={encounterYearMonth ? dayjs(encounterYearMonth) : null}
                        views={['year', 'month']}  // 限制選擇範圍只到年和月
                        label="就醫年月"
                        onChange={(newValue) => {
                            setEncounterYearMonth(newValue ? newValue.format('YYYY-MM') : '')
                        }}/>
                    {/* 查詢按鈕 */}
                    <Button
                        color={ButtonColorEnum.Primary}
                        text={t('general.query')}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearch}
                        sx={{fontWeight: 'bold', height: '2.5rem'}}
                    />
                    <div className="flex-grow"></div>
                    {/* Add this line to push the export button to the right */}
                    <div className="flex justify-end">
                        {/*匯出按鈕*/}
                        <Button
                            color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large} icon={IconEnum.Print}
                            onClick={exportText}>
                            {t('general.export')}
                        </Button>
                    </div>
                </div>


                <CustomTable
                    fields={ER_QUALITY_VPN_FIELDS}
                    dataList={dataList}
                    totalPage={filter.page}
                    total={filter.total}
                />
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.total}
                        currentPage={filter.currentPage}
                        totalPageSize={filter.totalPage}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}
export default ErQualityVpnUploadExport

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {validDateFormat} from 'edah_utils/dist'
import {BaseInput} from '../../Input/BaseInput'
import {t} from 'i18next'
import {
    TextField,
    DateRangePicker,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum
} from 'edah-component/dist'
import dayjs from "dayjs";

/**
 * 電子轉診單搜尋列
 * @param {function} handleQuery - 查詢按鈕的callback
 * @return {JSX.Element}
 */
const ReferralFormSearchBar = ({
                                   handleQuery,
                                   searchParamsOnChange,
                                   slotElement,
                               }) => {
    // 日期區間
    const [dateRange, setDateRange] = useState([null, null]);

    /**
     * 查詢條件
     */
    const [searchParams, setSearchParams] = useState({
        effStartDate: null,
        effEndDate: null,
        idNo: '',
    })

    /**
     * 日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates
     */
    const handleEffDateOnChange = (newDates) => {
        const [startDate, endDate] = newDates;
        setDateRange([startDate, endDate]);

        setSearchParams({
            ...searchParams,
            effStartDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            effEndDate: endDate ? endDate.format('YYYY-MM-DD') : null
        });
    };
    /**
     * 更新查詢條件
     * @param {event} event
     * @param {string} fields
     */
    const updateSearchParams = (event, field) => {
        const params = {
            ...searchParams,
            [field]: event.target.value,
        }
        setSearchParams(params)
    }

    const handleQuerOnClick = () => {
        const effStartDateIsValid = !!dateRange[0];
        const effEndDateIsValid = !!dateRange[1];

        if (!effStartDateIsValid || !effEndDateIsValid) {
            return
        }
        handleQuery()
    }

    useEffect(() => {
        searchParamsOnChange(searchParams)
    }, [searchParams.effStartDate, searchParams.effEndDate, searchParams.idNo])

    return (
        <div className="flex text-center">
            <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                <DateRangePicker
                    size="small"
                    required
                    localeText={{
                        start: '有效開始日期',
                        end: '有效結束日期'
                    }}
                    value={[
                        searchParams.effStartDate ? dayjs(searchParams.effStartDate) : null,
                        searchParams.effEndDate ? dayjs(searchParams.effEndDate) : null
                    ]}
                    onChange={handleEffDateOnChange}
                />
            </div>
            <div className="flex flex-row items-center justify-start mb-4 mr-4">
                <TextField
                    label={t('general.idNumber')}
                    value={searchParams.idNo}
                    onChange={(e) => updateSearchParams(e, 'idNo')}
                />
            </div>
            {slotElement}
            <div>
                <Button
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    text={t('general.query')}
                    onClick={handleQuerOnClick}
                    sx={{fontWeight: 'bold', height: '2.5rem'}}
                />
            </div>
        </div>
    )
}

ReferralFormSearchBar.propTypes = {
    handleQuery: PropTypes.func,
}
export default ReferralFormSearchBar

import React, {useEffect, useMemo, useState} from 'react'
import Button from '../../Button/Button'
import {objectIsEmpty, time2String} from 'edah_utils/dist'
import ExtendBtnTextArea from '../../Input/ExtendBtnTextArea'
import ReferralHospitalModal from '../ReferralHospitalModal'
import {DATE_FORMAT} from '../../../constants/common'
import {t} from 'i18next'
import {Checkbox, Field, RadioButton, RadioGroup, SizeEnum, TextField, DatePicker, Search, Select} from "edah-component/dist"
import dayjs from "dayjs"

const InputStyle = 'w-[200px] h-10  px-2 text-black rounded-[6px] border border-gray-300'
const SecondButtonStyle = 'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle = 'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

/**
 * 佈局
 * @param children {Element}
 * @return {JSX.Element}
 */
const Layout = ({children}) =>
    <div className="p-8 w-full h-[calc(100vh-98px)] overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md">
        {children}
    </div>

// 用於區別轉入院所/處理情形:院所查詢
const REFERAL_HOSP_MODAL_MODE = {
    hospIn: 'hospIn', // 轉入院所
    hospOut: 'hospOut', // 處理情形:院所查詢
}

// 處理情形每個選項的類別，用於判斷該選項顯示病房下拉選單/院所查詢/科別查詢
const InHadleStatusType = {
    HOSPITAL: 'HOSPITAL', // 已予急診處置並移至{醫院}
    INP: 'INP', // 已安排本院病房治療中
    ER_TO_INP: 'ER_TO_INP', // 已予急診處置並住本院病房治療中
    DEPARTMENT: 'DEPARTMENT', // 已安排本院病{科別}門診治療
    TURN_BACK: 'TURN_BACK', // 已予適當處理並轉回原院所
    OTHER: 'OTHER', // 其他
    ER_OBS: 'ER_OBS', // 急診留觀(緊急傷病患)
    OHCA: 'OHCA', // 到院前心跳停止(緊急傷病患)
    ICU: 'ICU', // 加護病房治療(緊急傷病患)
}

// 處理情形選項
const InHandleStatusOptions = [
    {
        label: '已予急診處置並移至',
        subLabel: '',
        selectorType: InHadleStatusType.HOSPITAL,
        isHasInput: false,
        value: '1',
    },
    {
        label: '已予急診處置並住本院',
        subLabel: '病房治療中',
        selectorType: InHadleStatusType.ER_TO_INP,
        isHasInput: false,
        value: '2',
    },
    {
        label: '已安排本院',
        subLabel: '病房治療中',
        selectorType: InHadleStatusType.INP,
        isHasInput: false,
        value: '3',
    },
    {
        label: '已安排本院',
        subLabel: '科門診治療',
        selectorType: InHadleStatusType.DEPARTMENT,
        isHasInput: false,
        value: '4',
    },
    {
        label: '已予適當處理並轉回原院所',
        subLabel: '',
        selectorType: InHadleStatusType.TURN_BACK,
        isHasInput: false,
        value: '5',
    },
    {
        label: '其他',
        subLabel: '',
        selectorType: InHadleStatusType.OTHER,
        isHasInput: true,
        value: '6',
    },
    {
        label: '急診留觀(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.ER_OBS,
        isHasInput: false,
        value: '7',
    },
    {
        label: '到院前心跳停止(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.OHCA,
        isHasInput: false,
        value: '8',
    },
    {
        label: '加護病房治療(緊急傷病患)',
        subLabel: '',
        selectorType: InHadleStatusType.ICU,
        isHasInput: false,
        value: '9',
    },
]

// TODO:病房下拉選單
const inpList = [{label: '病房'}]

/**
 * 轉出轉入開立作業-轉診開單作業
 * 回覆單
 * @param tranInInfo {object}  回覆單資料
 * @param tranFindingList {Array}  轉診結果下拉選單
 * @param divisionList {Array}  科別清單
 * @param onUpdateData {Function}  更新輸入框資料
 * @param zipCodeList {array} 郵遞區號下拉選單
 * @return {JSX.Element}
 */
const ReferralTurnInModal = ({tranInInfo, tranFindingList, divisionList, onUpdateData, zipCodeList = []}) => {
    // 回覆單號
    const [tranNo, setTranNo] = useState('')
    // 註銷
    const [cancelFlag, setCancelFlag] = useState(true)
    // 轉入院所代號
    const [hospNoIn, setHospNoIn] = useState('')
    // 轉入院所名稱
    const [hospNameIn, setHospNameIn] = useState('')
    // 轉診結果
    const [tranFinding, setTranFinding] = useState('')
    // 轉出日期
    const [tranDate, setTranDate] = useState('')
    // 藥物過敏
    const [allergy, setAllergy] = useState('')
    // 建議事項
    const [advise, setAdvise] = useState('')
    // 處理情形選項後帶有輸入框的欄位
    const [inHandleStatusRemarkMap, setInHandleStatusRemarkMap] = useState({
        inHandleStatusHospRemark: '', // 已予急診處置並移至{醫院} - 醫院代碼
        inHandleStatusErInpRemark: '', // 已予急診處置並住本院病房治療中
        inHandleStatusInpRemark: '', // 已安排本院病房治療中
        inHandleStatusDivRemark: '', // 已安排本院病{科別}門診治療 - 科別帶碼
        inHandleStatusOtherRemark: '', // 其他
        inHandleStatusHospRemarkName: '', // 已予急診處置並移至{醫院} - 醫院名稱
        inHandleStatusDivRemarkName: '', // 已安排本院病{科別}門診治療 - 科別名稱
    })
    // 處理情形remark(依照inHandleStatus的value去填入inHandleStatusRemarkMap內的remark)
    const [inHandleStatusRemark, setInHandleStatusRemark] = useState('')

    // 處理情形
    const [inHandleStatus, setInHandleStatus] = useState(InHandleStatusOptions[0].value)

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] = useState(false)

    /**
     * 會開啟轉診院所查詢視窗的欄位分別有2個
     * 1. 轉入院所:REFERAL_HOSP_MODAL_MODE.hospIn
     * 3. 轉出院所: REFERAL_HOSP_MODAL_MODE.hospOut
     */
    const [referralHospitalMode, setReferralHospitalModalMode] = useState('')

    // 更新是否註銷
    const handleCancelFlagChange = (e) => setCancelFlag(e.target.checked)

    /**
     * 更新轉出原因
     * @param value {String}
     * @return {void}
     */
    const handleTranFindingChange = (value) => setTranFinding(value)

    /**
     * 轉出日期改變時
     * @param newValue {dayjs}
     * @return {void}
     */
    const handleTranDateChange = (newValue) => {
        setTranDate(dayjs(newValue).format('YYYY-MM-DD'))
    }

    // 更新藥物過敏
    const handleAllergy = (e) => setAllergy(e.target.value)

    // 建議事項
    const handleAdvise = (e) => setAdvise(e.target.value)

    /**
     * 處理情形
     * @param e {Event}
     * @return {void}
     */
    const handleInHandleStatus = (e) => setInHandleStatus(e.target.value)

    /**
     * 處理情形 - 院所查詢
     * @return {JSX.Element}
     */
    const RenderHospital = () => useMemo(() =>
        <div className="flex space-x-2 items-center">
            <Button
                classNames={`${SecondButtonStyle} ml-2`}
                text={'院所查詢'}
                onClickFn={() =>
                    showReferralHospital(
                        REFERAL_HOSP_MODAL_MODE.hospOut
                    )
                }
            />
            <TextField
                size={SizeEnum.Small}
                inputWidth={SizeEnum.Small}
                value={inHandleStatusRemarkMap.inHandleStatusHospRemark}
                disabled
            />
            <TextField
                size={SizeEnum.Small}
                value={
                    inHandleStatusRemarkMap.inHandleStatusHospRemarkName
                }
                disabled
            />
        </div>, [inHandleStatusRemarkMap.inHandleStatusHospRemark])

    /**
     * 開啟院所查詢視窗
     * @param mode {String}
     * @return {void}
     */
    const showReferralHospital = (mode) => {
        setIsShowReferralHospitalModal(true)
        setReferralHospitalModalMode(mode)
    }

    /**
     * 已安排本院{科別}門診治療
     * @param {object} division
     * @returns
     */
    const onSelectDiv = (division) => {
        if (!division) {
            return
        }
        setInHandleStatusRemarkMap((prev) => {
            return {
                ...prev,
                inHandleStatusDivRemark: division.divNo,
                inHandleStatusDivRemarkName: division.divName,
            }
        })
    }

    /**
     * 選擇院所
     * @param hospital {Object}
     * @return {void}
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        if (referralHospitalMode === REFERAL_HOSP_MODAL_MODE.hospIn) {
            setHospNoIn(hospital.hospNo)
            setHospNameIn(hospital.hospName)
        } else {
            setInHandleStatusRemarkMap({
                ...inHandleStatusRemarkMap,
                inHandleStatusHospRemark: hospital.hospNo,
                inHandleStatusHospRemarkName: hospital.hospName,
            })
        }
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 處理情形選項內的remark
     * @param e {Event}
     * @return {void}
     */
    const inHandleStatusRemarkOnChange = (e, field) => {
        setInHandleStatusRemark(e.target.value)
        switch (field) {
            case InHadleStatusType.INP:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusInpRemark: e.target.value,
                }))
                break
            case InHadleStatusType.OTHER:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusOtherRemark: e.target.value,
                }))
                break
            case InHadleStatusType.ER_TO_INP:
                setInHandleStatusRemarkMap((prev) => ({
                    ...prev,
                    inHandleStatusErInpRemark: e.target.value,
                }))
                break
        }
    }

    /**
     * 取得radio button內容
     * @param type {String}
     * @return {JSX.Element}
     */
    const getRadioButtonContent = (type) => {
        switch (type) {
            // 已予急診處置並住本院病房治療中
            case InHadleStatusType.HOSPITAL:
                return RenderHospital()
            //已安排本院病房治療中
            case InHadleStatusType.INP:
                return <Select
                    data = {{
                        label: '已安排本院病房治療中',
                        options : inpList.map((item) => ({
                            value: item.label,
                            label: item.label
                        }))
                    }}
                    value={inHandleStatusRemarkMap.inHandleStatusInpRemark}
                    onChange = {(newValue) => inHandleStatusRemarkOnChange({ 
                        target: { value: newValue } },
                        InHadleStatusType.INP
                    )}
                    showLabel = {false}
                    width={SizeEnum.Small.charAt(0).toUpperCase() + SizeEnum.Small.slice(1).toLowerCase()}
                />
                // return <select className={`${SelectStyle} mx-4 w-[90px]`}
                //                value={inHandleStatusRemarkMap.inHandleStatusInpRemark}
                //                onChange={(e) => inHandleStatusRemarkOnChange(e, type)}>
                //     <option value=""/>
                //     {
                //         inpList.map((item) =>
                //             <option key={`deal_${item.label}`} value={item.label}>
                //                 {item.label}
                //             </option>
                //         )
                //     }
                // </select>
            // 已予急診處置並於本院病房治療中
            case InHadleStatusType.ER_TO_INP:
                return <Select
                    data = {{
                        label: '已予急診處置並於本院病房治療中',
                        options : inpList.map((item) => ({
                            value: item.label,
                            label: item.label
                        }))
                    }}
                    value = {inHandleStatusRemarkMap.inHandleStatusErInpRemark}
                    onChange = {(newValue) => inHandleStatusRemarkOnChange({ 
                        target: { value: newValue } },
                        InHadleStatusType.ER_TO_INP
                    )}
                    showLabel = {false}
                    width={SizeEnum.Small.charAt(0).toUpperCase() + SizeEnum.Small.slice(1).toLowerCase()}
                />
                // return <select className={`${SelectStyle} mx-4 w-[90px]`}
                //                value={inHandleStatusRemarkMap.inHandleStatusErInpRemark}
                //                onChange={(e) => inHandleStatusRemarkOnChange(e, type)}>
                //     {
                //         inpList.map((item) =>
                //             <option key={`erinp_${item.label}`} value={item.label}>
                //                 {item.label}
                //             </option>
                //         )
                //     }
                // </select>
            // 已安排本院科門診治療
            case InHadleStatusType.DEPARTMENT:
                return <div className="mx-4 w-[180px]">
                    <Search 
                        options = {divisionList}
                        getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                        value={{
                            divNo: inHandleStatusRemarkMap.inHandleStatusDivRemark || '',
                            divName: inHandleStatusRemarkMap.inHandleStatusDivRemarkName || '',
                        }}
                        onChange={ (_event, newValue)=> onSelectDiv(newValue)}
                    />
                </div>
            //其他
            case InHadleStatusType.OTHER:
                return <TextField sx={{ml: 2}}
                                  value={inHandleStatusRemarkMap.inHandleStatusOtherRemark}
                                  onChange={(e) => inHandleStatusRemarkOnChange(e, type)}/>
            default:
                return null
        }
    }

    /**
     * 取得自定義radio button
     * @return {JSX.Element[]}
     */
    const getCustomRadioButton = () =>
        InHandleStatusOptions.map(({label, value, selectorType}, index) => {
            return <div key={index} className="flex mb-4 items-center">
                <RadioButton value={value} checked={inHandleStatus === value}/>
                <span>{label}</span>
                {getRadioButtonContent(selectorType)}
            </div>
        })

    /**
     * 取得radio group
     * @return {JSX.Element}
     */
    const getRadioGroup = () => {
        return <RadioGroup size={SizeEnum.Medium} row={false}
                           value={inHandleStatus}
                           optionProps={{options: getCustomRadioButton()}}
                           onChange={handleInHandleStatus}/>
    }

    /**
     * mounting
     * @return {void}
     */
    useEffect(() => {
        setTranNo(tranInInfo.tranNo || '')
        setCancelFlag(!!tranInInfo.cancelFlag)
        setHospNoIn(tranInInfo.hospNoIn || '')
        setHospNameIn(tranInInfo.hospNameIn || '')
        setTranFinding(tranInInfo.tranFinding || '')
        setTranDate(time2String(tranInInfo.tranDate, DATE_FORMAT) || '')
        setAllergy(tranInInfo.allergy || '')
        setAdvise(tranInInfo.advise || '')
        const remark = tranInInfo.inHandleStatusRemark || ''
        const remarkName = tranInInfo.inHandleStatusRemarkName || ''
        const defaultValue = {
            inHandleStatusHospRemark: '',
            inHandleStatusErInpRemark: '',
            inHandleStatusInpRemark: '',
            inHandleStatusDivRemark: '',
            inHandleStatusOtherRemark: '',
            inHandleStatusHospRemarkName: '',
        }

        setInHandleStatusRemark(remark)
        // inHandleStatus去mapping對應的remark
        switch (tranInInfo.inHandleStatus) {
            case InHandleStatusOptions[0].value: //1.已予急診處置並移至{醫院}
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusHospRemark: remark,
                    inHandleStatusHospRemarkName: remarkName,
                })
                break
            case InHandleStatusOptions[1].value: // 2.已予急診處置並住本院病房
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusErInpRemark: remark,
                })
                break
            case InHandleStatusOptions[2].value: // 3.已安排本院病房治療中
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusInpRemark: remark,
                })
                break
            case InHandleStatusOptions[3].value: // 4.已安排本院{科別}門診
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusDivRemark: remark,
                    inHandleStatusDivRemarkName: remarkName,
                })
                break
            case InHandleStatusOptions[5].value: // 6.其他
                setInHandleStatusRemarkMap({
                    ...defaultValue,
                    inHandleStatusOtherRemark: remark,
                })
                break
        }
        setInHandleStatus(tranInInfo.inHandleStatus || '')
    }, [tranInInfo])

    /**
     * 轉入單資料更新回傳到父層
     * @return {void}
     */
    useEffect(() => {
        let remark = ''
        let remarkName = ''
        switch (inHandleStatus) {
            case '1': //已予急診處置並移至{醫院}
                remark = inHandleStatusRemarkMap.inHandleStatusHospRemark
                remarkName =
                    inHandleStatusRemarkMap.inHandleStatusHospRemarkName
                break
            case '2': //已予急診處置並住本院病房
                remark = inHandleStatusRemarkMap.inHandleStatusErInpRemark
                remarkName = ''
                break
            case '3': // 已安排本院病房治療中
                remark = inHandleStatusRemarkMap.inHandleStatusInpRemark
                remarkName = ''
                break
            case '4': // 已安排本院{科別}門診
                remark = inHandleStatusRemarkMap.inHandleStatusDivRemark
                remarkName = inHandleStatusRemarkMap.inHandleStatusDivRemarkName
                break
            case '6': // 其他
                remark = inHandleStatusRemarkMap.inHandleStatusOtherRemark
                remarkName = ''
                break
        }

        onUpdateData &&
        onUpdateData({
            ...tranInInfo,
            lockVersion: tranInInfo.lockVersion,
            encounterId: tranInInfo.encounterId,
            encounterDate: tranInInfo.encounterDate,
            inpOpd: tranInInfo.inpOpd,
            patientId: tranInInfo.patientId,
            contact: tranInInfo.contact,
            contactTel: tranInInfo.contactTel,
            contactAddress: tranInInfo.contactAddress,
            divNo: tranInInfo.divN,
            doctorNo: tranInInfo.doctorNo,
            treatmentCode: tranInInfo.treatmentCode,
            treatmentCode1: tranInInfo.treatmentCode1,
            treatmentCode2: tranInInfo.treatmentCode2,
            subject: tranInInfo.subject,
            clinicInfo: tranInInfo.clinicInfo,
            reasonOther: tranInInfo.reasonOther,
            urgentReplyDatetime: tranInInfo.urgentReplyDatetime,
            orderDatetime: tranInInfo.orderDatetime,
            opdStatus: tranInInfo.opdStatus,
            etranNo: tranInInfo.etranNo,
            tranPurposeIn: tranInInfo.tranPurposeIn,
            tranPurposeRemarkIn: tranInInfo.tranPurposeRemarkIn,
            replyDate: tranInInfo.replyDate,
            inTraninCompleteFlag: tranInInfo.inTraninCompleteFlag,
            inSummaryCompleteFlag: tranInInfo.inSummaryCompleteFlag,
            inTrandataAgreeFlag: tranInInfo.inTrandataAgreeFlag,
            inReceiptDate: tranInInfo.inReceiptDate,
            inSummaryReplyDate: tranInInfo.inSummaryReplyDate,
            inRecoveryDate: tranInInfo.inRecoveryDate,
            inUnprintSummaryReason: tranInInfo.inUnprintSummaryReason,
            inOrderDate: tranInInfo.inOrderDate,
            inReplyDate: tranInInfo.inReplyDate,
            inReplyStatus: tranInInfo.inReplyStatus,
            inTranPaperFlag: tranInInfo.inTranPaperFlag,
            inTranPaperReason: tranInInfo.inTranPaperReason,
            inSpecialReplyDate: tranInInfo.inSpecialReplyDate,
            inCancerFlag: tranInInfo.inCancerFlag,
            inRemark: tranInInfo.inRemark,
            inPickOut: tranInInfo.inPickOut,
            inPickDate: tranInInfo.inPickDate,
            uploadDatetime: tranInInfo.uploadDatetime,
            tranInReceiveDate: tranInInfo.tranInReceiveDate,
            inpEncounterId: tranInInfo.inpEncounterId,
            tranInAcceptFlag: tranInInfo.tranInAcceptFlag,
            notTranBackReason: tranInInfo.notTranBackReason,
            bedNo: tranInInfo.bedNo,
            dischargeDate: tranInInfo.dischargeDate,
            admissionDate: tranInInfo.admissionDate,
            inpDischargeDate: tranInInfo.inpDischargeDate,
            inpBedNo: tranInInfo.inpBedNo,
            inpDivNo: tranInInfo.inpDivNo,
            inpDoctorNo: tranInInfo.inpDoctorNo,
            tranOut: tranInInfo.tranOut,
            advise: advise,
            allergy: allergy,
            tranFinding: tranFinding,
            tranDate: tranDate,
            tranNo: tranNo,
            hospNoIn: hospNoIn,
            cancelFlag: cancelFlag,
            inHandleStatus: inHandleStatus,
            inHandleStatusRemark: remark,
            inHandleStatusRemarkName: remarkName,
        })
    }, [
        advise,
        allergy,
        tranFinding,
        tranDate,
        tranNo,
        hospNoIn,
        cancelFlag,
        inHandleStatus,
        inHandleStatusRemark,
        inHandleStatusRemarkMap.inHandleStatusDivRemark,
        inHandleStatusRemarkMap.inHandleStatusHospRemark,
        inHandleStatusRemarkMap.inHandleStatusErInpRemark,
        inHandleStatusRemarkMap.inHandleStatusInpRemark,
        inHandleStatusRemarkMap.inHandleStatusOtherRemark,
        inHandleStatusRemarkMap.inHandleStatusHospRemarkName,
        inHandleStatusRemarkMap.inHandleStatusDivRemarkName,
    ])

    return (
        <Layout>
            {/* 標題 */}
            <div className="flex items-center">
                <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                <div className="ml-4 text-[#2B6CB0] text-[20px]">回覆單</div>
            </div>
            {/* 回覆單號 */}
            <Field labelWidth={100} label="回覆單號" sx={{mb: 2}}>
                <div className="flex justify-between items-center">
                    {tranNo}
                    <Checkbox
                        label={t('general.logoff')}
                        checked={cancelFlag}
                        onChange={(e) => handleCancelFlagChange(e)}
                    />
                </div>
            </Field>
            {/* 轉入院所 */}
            <Field labelWidth={100} label="轉入院所" sx={{mb: 2}}>
                <div className="flex items-center space-x-2">
                    <Button
                        classNames={SecondButtonStyle}
                        text={'院所查詢'}
                        onClickFn={() =>
                            showReferralHospital(REFERAL_HOSP_MODAL_MODE.hospIn)
                        }
                    />
                    <TextField
                        size={SizeEnum.Small}
                        inputWidth={SizeEnum.Small}
                        value={hospNoIn}
                        disabled
                    />
                    <TextField
                        size={SizeEnum.Small}
                        inputWidth={SizeEnum.Large}
                        value={hospNameIn}
                        disabled
                    />
                </div>
            </Field>
            {/* 轉診結果 */}
            <Field labelWidth={100} label="轉診結果" sx={{mb: 2}}>
                <div className="flex items-center">
                    <Select 
                        data ={{
                            label: '轉診結果',
                            options: [
                                {value: '', label:''},
                                ...tranFindingList.map((item) => ({
                                    value: item.tranFinding, 
                                    label: item.tranFindingName
                                })),
                            ]
                        }}
                        value={tranFinding}
                        onChange={handleTranFindingChange}
                        showLabel = {false}
                        width={SizeEnum.Medium.charAt(0).toUpperCase() + SizeEnum.Medium.slice(1).toLowerCase()}
                    />
                </div>
            </Field>
            {/* 轉出日期 */}
            <Field labelWidth={100} label="轉出日期" sx={{mb: 2}}>
                <DatePicker
                    label=""
                    size={SizeEnum.Small}
                    value={dayjs(tranDate)}
                    onChange={handleTranDateChange}
                />
            </Field>
            {/* 藥物過敏 */}
            <Field labelWidth={100} label="藥物過敏" sx={{mb: 2, alignItems: 'start'}}>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={handleAllergy}
                    value={allergy}
                ></ExtendBtnTextArea>
            </Field>
            {/* 建議事項 */}
            <Field labelWidth={100} label="建議事項" sx={{mb: 2, alignItems: 'start'}}>
                <ExtendBtnTextArea
                    defaultHeight={4}
                    maxHeight={400}
                    handleOnChange={handleAdvise}
                    value={advise}
                ></ExtendBtnTextArea>
            </Field>
            {/* 處理情形 */}
            <Field labelWidth={100} label="處理情形" sx={{mb: 2, alignItems: 'start'}}>
                <div className="ml-1">
                    {/*取得RadioGroup*/}
                    {getRadioGroup()}
                </div>
            </Field>
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    onConfirm={onSelectHospital}
                    zipCodeList={zipCodeList}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </Layout>
    )
}

export default ReferralTurnInModal

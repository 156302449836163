import React, {useEffect, useState} from 'react'
import {t} from 'i18next'
import CustomTable from '../CustomTable'
import {REFERRAL_FORM_UPLOAD_ATTACHMENT} from '../../../constants/referralSystem/maintainAreaHeader'
import {queryAppendixByEncounterId, sendAppendix} from '../../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    arrayIsEmpty,
} from 'edah_utils/dist'
import useToast from '../../../hooks/useToast'
import {AlertTypeEnum} from "edah-component/dist"

const FILE_TYPE_TEXT = {
    LAB_REPORT: '檢驗檢查報告',
    IMAGE_REPORT: '影像報告',
    OPERATION_RECORD: '手術紀錄',
}
const FILE_TYPE = {
    LAB_REPORT: 'LAB_REPORT',
    IMAGE_REPORT: 'IMAGE_REPORT',
    OPERATION_RECORD: 'OPERATION_RECORD',
}

const AttachmentModal = ({onClose, info, onUploadSuccess}) => {
    // 檔案類別
    const [activeFileType, setActiveFileType] = useState(FILE_TYPE.LAB_REPORT)

    const [fileLists, setFileLists] = useState({
        // 檢驗檢查報告
        [FILE_TYPE.LAB_REPORT]: [],
        // 影像報告
        [FILE_TYPE.IMAGE_REPORT]: [],
        // 手術紀錄
        [FILE_TYPE.OPERATION_RECORD]: [],
    })

    const [patientName, setPatientName] = useState('')

    // toast message
    const showToast = useToast()

    /**
     * 選擇檔案類型
     * @param key
     */
    const handleActiveFileType = (key) => {
        setActiveFileType(key)
    }

    /**
     * 上傳檔案
     */
    const uploadFiles = () => {
        const selectedLabList = fileLists[FILE_TYPE.LAB_REPORT].filter(
            (item) => item.isSelected
        )
        const selectedImageReportList = fileLists[
            FILE_TYPE.IMAGE_REPORT
            ].filter((item) => item.isSelected)
        const selectedOpNoteList = fileLists[FILE_TYPE.OPERATION_RECORD].filter(
            (item) => item.isSelected
        )

        if (
            arrayIsEmpty(selectedLabList) &&
            arrayIsEmpty(selectedImageReportList) &&
            arrayIsEmpty(selectedOpNoteList)
        ) {
            return
        }
        sendAppendix().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '上傳成功', type: AlertTypeEnum.Success})
                onUploadSuccess()
            } else {
                showToast({
                    message: `上傳失敗:${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 取得檔案
     */
    const getFileLists = () => {
        queryAppendixByEncounterId({
            encounterId: info.encounterId,
            etranNo: info.etranNo,
            patientId: info.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const labList =
                    res.data?.labList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                const imageList =
                    res.data?.xRayList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                const opNoteList =
                    res.data?.orList?.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                setFileLists({
                    [FILE_TYPE.LAB_REPORT]: labList,
                    [FILE_TYPE.IMAGE_REPORT]: imageList,
                    [FILE_TYPE.OPERATION_RECORD]: opNoteList,
                })
                setPatientName(res.data.patientName)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    // 選取要上傳的檔案 因api回傳的list沒有id故用index查找相對應的資料
    const handleToggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = fileLists[activeFileType].map(
            (item, fileIndex) => {
                if (index === fileIndex) {
                    return {
                        ...item,
                        isSelected: isChecked,
                    }
                }
                return item
            }
        )

        setFileLists((prev) => {
            return {
                ...prev,
                [activeFileType]: modifiedList,
            }
        })
    }

    // 全選要上傳的檔案
    const handleToggleAll = (isAllSelected) => {
        setFileLists((prev) => {
            return {
                ...prev,
                [activeFileType]: prev[activeFileType].map((item) => ({
                    ...item,
                    isSelected: isAllSelected,
                })),
            }
        })
    }

    useEffect(() => {
        getFileLists()
    }, [])

    return (
        <div className="p-4">
            <div className="p-5 flex justify-between">
                <div className="flex justify-start mb-3">
                    <div className="text-right ">開單日期：</div>
                    <div>
                        {info?.orderDatetime &&
                            time2String(info?.orderDatetime, 'YYYY-MM-DD')}
                    </div>
                </div>
                <div className="flex justify-start mb-3">
                    <div className="text-right">{t('general.patientNo')}：</div>
                    <div>{info?.patientId}</div>
                </div>

                <div className="flex justify-start mb-3">
                    <div className="text-right">{t('general.username')}：</div>
                    <div>{patientName}</div>
                </div>

                <div className="flex justify-start mb-3">
                    <div className="text-right pr-4">電子轉診單號：</div>
                    <div>{info?.etranNo}</div>
                </div>
            </div>
            <div className="px-5 flex">
                <div className="w-[30%]">
                    {/* 標題 */}
                    <div className="flex items-center mb-2">
                        <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                        <div className="ml-4 text-[#2B6CB0] text-[20px]">
                            檔案類別
                        </div>
                    </div>
                    <div className="border-[1px] rounded-[4px]">
                        {Object.keys(FILE_TYPE).map((key) => (
                            <div
                                key={key}
                                className={`flex items-center cursor-pointer hover:bg-[#BEE3F8] ${
                                    activeFileType === key ? 'bg-[#BEE3F8]' : ''
                                }`}
                                onClick={() => handleActiveFileType(key)}
                            >
                                <div className="m-1 ml-2">
                                    {FILE_TYPE_TEXT[key]}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-[70%] ml-2">
                    {/* 標題 */}
                    <div className="flex items-center mb-2">
                        <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                        <div className="ml-4 text-[#2B6CB0] text-[20px]">
                            院內檔案
                        </div>
                    </div>
                    <div className="border-2 rounded-[4px]">
                        <CustomTable
                            isShoeDataFilter={false}
                            isSelectable={true}
                            fields={REFERRAL_FORM_UPLOAD_ATTACHMENT}
                            dataList={fileLists[activeFileType]}
                            toggleAll={handleToggleAll}
                            toggleItem={handleToggleItem}
                            isCrud={false}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-3">
                <button
                    className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                    onClick={uploadFiles}
                >
                    附件上傳
                </button>
                <button
                    className="flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white"
                    onClick={onClose}
                >
                    返回清單
                </button>
            </div>
        </div>
    )
}

export default AttachmentModal

import {useState} from "react"
import { arrayIsEmpty, time2String } from "edah_utils/dist"
import {t} from 'i18next'
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DateRangePicker,
    IconEnum,
    Select,
    SelectWidthEnum,
    TextField,
    AdvancedDataGrid,
    Pagination
} from "edah-component/dist";
import dayjs from 'dayjs';
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 醫師報備查詢
 * @return {JSX.Element}
 */
const DoctorInformSearch = () => {
    // 申請人工號
    const [createUser, setCreateUser] = useState('')
    // 醫師工號
    const [supportUserNo, setSupportUserNo] = useState('')
    // 申請起始日期
    const [applyStartDate, setApplyStartDate] = useState('')
    // 申請結束日期
    const [applyEndDate, setApplyEndDate] = useState('')
    // 進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("")
    // 查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null)
    // 進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })

    /**
     * 取得輸入框樣式
     * @param  width {number} 寬度
     * @return  {string} 輸入框樣式
     */
    const getInputClassName = (width = 186) => `w-[${width}px] h-10 bg-white text-black rounded-md border border-gray-300`

    /**
     * 申請人/工號方框改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleCreateUserOnChange = (e) => setCreateUser(e.target.value)

    /**
     * 報備支援醫師/工號方框改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleSupportUserNoOnChange = (e) => {
        setSupportUserNo(e.target.value)
    }

    /**
     * 日期範圍變更事件
     * @param  newValue {Array} 日期範圍 [開始日期, 結束日期]
     * @returns {void}
     */
    const handleDateRangeOnChange = (newValue) => {
        // 確認新值是否為有效的日期範圍
        const [startDate, endDate] = newValue;

        // 如果有開始日期，則格式化並更新報備起始日期
        if (startDate && startDate.isValid()) {
            setApplyStartDate(startDate.format('YYYY-MM-DD'));
        }

        // 如果有結束日期，則格式化並更新報備結束日期
        if (endDate && endDate.isValid()) {
            setApplyEndDate(endDate.format('YYYY-MM-DD'));
        }
    };

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => {

    }

    /**
     * 進階搜尋文字改變時
     * @param e {Event} 事件
     */
    const handleAdvanceSearchTextOnChange = (e) => setAdvSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onPaginationPageOnChange = (page,pageSize) => {
    }


    // 表頭資料
    const columns = [
        {
            field: 'action',
            headerName: '操作',
            width: 224,
            sortable: false,
            renderCell: (params) => (
                <Button
                    onClick={() => handleAction(params.row)}
                    variant="contained"
                    size="small"
                >
                    操作
                </Button>
            ),
        },
        {
            field: 'applicationNumber',
            headerName: '申請單號',
            width: 104,
        },
        {
            field: 'applicant',
            headerName: '申請人',
            width: 90,
        },
        {
            field: 'supportDoctorInfo',
            headerName: '支援醫師代號/工號姓名',
            width: 227,
        },
        {
            field: 'supportInstitution',
            headerName: '支援機構代號名稱',
            width: 121,
            renderCell: (params) => (
                <div>
                    {params.value.code}<br/>{params.value.name}
                </div>
            ),
        },
        {
            field: 'supportDepartment',
            headerName: '支援科別',
            width: 121,
        },
        {
            field: 'startDate',
            headerName: '開始日期',
            width: 121,
            renderCell: (params) => time2String(params.value, "YYYY-MM-DD"),
        },
        {
            field: 'endDate',
            headerName: '結束日期',
            width: 121,
            renderCell: (params) => time2String(params.value, "YYYY-MM-DD"),
        },
        {
            field: 'startTime',
            headerName: '開始時間',
            width: 121,
            renderCell: (params) => time2String(params.value, "HH:mm"),
        },
        {
            field: 'endTime',
            headerName: '結束時間',
            width: 121,
            renderCell: (params) => time2String(params.value, "HH:mm"),
        },
        {
            field: 'status',
            headerName: '狀態別',
            width: 110,
        },
        {
            field: 'mohwCaseNumber',
            headerName: '衛福部案號版次',
            width: 150,
        },
    ];

    return (
        <div className="w-full overflow-auto h-[calc(100vh-110px)] max-h-[calc(100vh-110px)] p-2">
            {/* 查詢區塊 */}
            <div className='flex flex-col'>
                {/* 第一列查詢條件 */}
                <div className='flex items-center p-2 gap-6'>
                    <div className="flex flex-row space-x-2 items-center">
                        <TextField
                            label={`${t('Regs.DoctorInformSearch.applicant')}/${t('Regs.DoctorInformSearch.jobNum')}`}
                            value={createUser}
                            onChange={handleCreateUserOnChange}
                        />
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                        <TextField
                            label={`${t('Regs.DoctorInformSearch.supportDoc')}/${t('Regs.DoctorInformSearch.jobNum')}`}
                            value={supportUserNo}
                            onChange={handleSupportUserNoOnChange}
                        />
                    </div>
                    <div className="flex flex-row space-x-2 items-center">
                        <div>{`${t('Regs.DoctorInformSearch.byStatus')}：`}</div>
                        <Select
                            data={{
                                label: '依狀態',
                                options: []
                            }}
                            showLabel={false}
                            value=""
                            width={SelectWidthEnum.Small}
                        />
                    </div>
                    <div className="flex items-center gap-1">
                        {/*依申請期間*/}
                        <div className='flex items-center gap-1'>
                            <DateRangePicker
                                size="small"
                                value={[
                                    applyStartDate ? dayjs(applyStartDate) : null,
                                    applyEndDate ? dayjs(applyEndDate) : null
                                ]}
                                onChange={handleDateRangeOnChange}
                            />
                        </div>
                    </div>
                    {/*查詢按鈕*/}
                    <div className="source flex flex-row items-center justify-start mr-4">
                        <Button
                            sx={{marginRight: "0.5rem"}}
                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}
                            onClick={handleQueryOnClick}>
                            {t('general.query')}
                        </Button>
                    </div>
                </div>
                {/* 第二列新增按鈕 */}
                <div className="flex ml-4 mt-2 h-10">
                    {/*新增按鈕*/}
                    <Button
                        color={ColorEnum.Success} variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large} icon={IconEnum.Add}>
                        {t('Regs.DoctorInformSearch.addContent')}
                    </Button>
                </div>
            </div>
            {/*查詢結果*/}
            <div
                className="flex flex-col p-2 mt-3 bg-white h-[calc(100%_-_120px)] w-full border-[1px] border-[#D4D4D8] rounded-md">
                <div className="flex flex-row pb-3">
                    {/*進階搜尋*/}
                    <SearchTextField
                        placeholder={t('general.advancedSearch')}
                        value={advSearchText}
                        onChange={(e) => handleAdvanceSearchTextOnChange(e)}
                    />

                </div>
                {/*醫師報備查詢 table*/}
                <AdvancedDataGrid
                    rows={advSearchDataList}
                    columns={columns}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={false}
                    disableColumnMenu={true}
                    height={'700px'}
                    getRowHeight={(params) => 56}
                />

                {/*分頁*/}
                <div className="flex justify-end w-full mt-1.5">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        page={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page, pageSize) => onPaginationPageOnChange(page, pageSize)}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </div>
        </div>
    )
}

export default DoctorInformSearch

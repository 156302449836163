import {useState, useRef, useCallback} from "react"
import {t} from "i18next"
import {
    PopupModeEnum,
    RegsMedicalTypeEnum,
    RegsWeekReservationLimitEnum,
    stringIsEmpty,
    objectIsEmpty
} from "edah_utils/dist"

/**
 * @param record {Object} 預約資料
 * @param selected {Boolean} 是否選取
 * @param {Object} baseData 基本資料
 */
const ScheduleAppointment = ({
                                 record,
                                 selected,
                                 baseData,
                                 showPopup
                             }) => {
    // 是否顯示Record資訊
    const [showRecordInfo, setShowRecordInfo] = useState(false)

    // Record資訊的top位置
    const [adjustedRecordInfoTop, setAdjustedRecordInfoTop] = useState(0)

    // Record資訊的left位置
    const [adjustedRecordInfoLeft, setAdjustedRecordInfoLeft] = useState(0)

    // 用於獲取元素位置的ref
    const dataElementRef = useRef(null)

    // 用於延遲隱藏信息框的ref
    const timeoutRef = useRef(null)

    /**
     * 取得Record內容
     * @return {String}
     */
    const getRecordContent = () => {
        if (record !== null && baseData !== null) {
            let doctorContent = ''
            const department = baseData.divisionList.find(department => department.divNo === record.divNo)
            if (!stringIsEmpty(record.locumDoctor)) {
                //代班醫生號碼有值
                const locumDoctor = baseData.doctorList.find(doctor => doctor.userNo === record.locumDoctor)
                doctorContent = `${locumDoctor.userName}[代]`
            } else {
                const doctor = baseData.doctorList.find(doctor => doctor.userNo === record.doctorNo)
                doctorContent = doctor.userName

                if (record.stopClinicFlag !== null && record.stopClinicFlag) {
                    doctorContent = `${doctorContent}[停]`
                }
            }
            return `${doctorContent}/${department.divName}/${record.clinicName}`
        } else {
            return ''
        }
    }

    /**
     * 計算並更新信息框位置
     * @return {void}
     */
    const updateInfoPosition = useCallback(() => {
        if (!dataElementRef.current) return;

        const elementRect = dataElementRef.current.getBoundingClientRect();
        // 信息框的高度
        const infoBoxHeight = 478;
        // 信息框的寬度
        const infoBoxWidth = 260;
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;

        let top = elementRect.top;
        let left = elementRect.right;

        // 調整 top 位置，避免超出螢幕底部
        if (top + infoBoxHeight > screenHeight) {
            top = screenHeight - infoBoxHeight;
        }

        // 調整 left 位置，避免超出螢幕右側
        if (left + infoBoxWidth > screenWidth) {
            left = elementRect.left - infoBoxWidth;
        }

        setAdjustedRecordInfoTop(top);
        setAdjustedRecordInfoLeft(left);
    }, []);

    /**
     * Record滑鼠進入時
     * @param e {Event} 事件
     * @return {void}
     */
    const recordOnMouseEnter = useCallback((e) => {
        // 阻止事件
        e.stopPropagation();

        // 清除之前的隱藏計時器
        clearTimeout(timeoutRef.current);

        // 使用 requestAnimationFrame 來同步狀態更新與瀏覽器重繪
        requestAnimationFrame(() => {
            updateInfoPosition();
            setShowRecordInfo(true);
        });
    }, [updateInfoPosition]);

    /**
     * Record滑鼠離開時
     * @param e {Event} 事件
     * @return {void}
     */
    const recordOnMouseLeave = useCallback((e) => {
        // 設置延遲隱藏，防止快速移入移出造成的問題
        e.stopPropagation();

        // 設置延遲隱藏，防止快速移入移出造成的問題
        timeoutRef.current = setTimeout(() => {
            setShowRecordInfo(false);
        }, 50); // 100ms 延遲，防止快速移入移出
    }, []);

    /**
     * 取得Record資訊
     * @return {JSX.Element}
     */
    const getRecordInfo = () => {
        // 預約最大看診人數
        const limit = record.limit
        // 現場最大看診人次
        const onSiteLimit = record.onsiteLimit
        // 看診時間(分/人)
        const singleViewMin = record.slotMin
        // 預約方式
        const giveNumType = record.giveNumType
        // 預約方式字串
        const giveNumTypeStr = t(`page.MonthMaintenance.ReservationMethod.${giveNumType}`)
        // 調病歷
        const sentChartFlag = record.sentChartFlag
        // 調病歷字串
        const sentChartFlagStr = sentChartFlag ? t('general.yes') : t('general.no')
        // 給號方式
        const noType = record.noType
        // 給號方式字串
        const noTypeStr = baseData.noTypeList.find(no => no.noType === noType).typeName
        // 預約限制類型
        const reserveLimitType = record.reserveLimitType
        // 取得預約限制類型字串
        const reserveLimitTypeStr = (type) => {
            //結果
            let result = ''
            switch (type) {
                //一般
                case RegsWeekReservationLimitEnum.Normal:
                    result = '一般'
                    break
                // 限醫生
                case RegsWeekReservationLimitEnum.DoctorOnly:
                    result = '限醫生'
                    break
                //限醫生本人
                case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                    result = '限醫生本人'
                    break
            }

            return result
        }

        // 診別
        const medicalType = record.clinicType
        // 取得診別字串
        const medicalTypeStr = (type) => {
            let result = ''
            switch (type) {
                //門診
                case RegsMedicalTypeEnum.OutpatientClinic:
                    result = '門診'
                    break
                //外檢
                case RegsMedicalTypeEnum.MedicalCheckup:
                    result = '外檢'
                    break
                //預防保健
                case RegsMedicalTypeEnum.PreventiveHealthcare:
                    result = '預防保健'
                    break
            }

            return result
        }

        // 取得看診項目字串
        const medicalItemStr = (type) => {
            let result = ''
            switch (type) {
                //循環醫療
                case 'A':
                    result = '循環醫療'
                    break
                //視訊問診
                case 'B':
                    result = '視訊問診'
                    break
            }

            return result
        }

        // 開放網掛/APP
        const webAppFlag = record.webapptFlag
        // 開放網掛/APP字串
        const webAppFlagStr = webAppFlag ? t('general.yes') : t('general.no')
        // 網掛/App取消看診進度
        const showProcessFlag = record.showProcessFlag
        // 網掛/App取消看診進度字串
        const showProcessFlagStr = showProcessFlag ? t('general.yes') : t('general.no')
        // 網掛預約期限(天)
        const webAppDay = record.webapptDay
        // 網掛預約期限字串
        const webAppDayStr = `${webAppDay}${t('general.dateTime.day')}`
        // 看診項目
        const mediItem = record.clinicMarkNo
        // 診室地點
        const clinicLocation = record.clinicLocation
        // 取得診室地點字串
        const clinicLocationStr = clinicLocation ? clinicLocation : ''
        //代班醫師
        const locumDoctor = baseData.doctorList.find(doctor => doctor.userNo === record.locumDoctor)
        // 取得代班醫師字串
        const locumDoctorStr = !objectIsEmpty(locumDoctor) ? `${locumDoctor.userName}` : ''
        // 停診
        const stopClinicFlag = record.stopClinicFlag
        // 停診字串
        const stopClinicFlagStr = stopClinicFlag ? t('general.yes') : t('general.no')
        // 停代診公告
        const stopClinicBulletinFlag = record.stopClinicBulletinFlag
        // 停代診公告字串
        const stopClinicBulletinFlagStr = stopClinicBulletinFlag ? t('general.yes') : t('general.no')
        // 週班注意事項
        const weekRemark = record.weekNotice ? record.weekNotice : ''

        return (
            <>
                <p>預約最大看診人次: {limit}</p>
                <p>現場最大看診人次: {onSiteLimit}</p>
                <p>看診時間(分/人): {singleViewMin}</p>
                <p>預約方式: {giveNumTypeStr}</p>
                <p>調病歷: {sentChartFlagStr}</p>
                <p>給號方式: {noTypeStr}</p>
                <p>預約限制: {reserveLimitTypeStr(reserveLimitType)}</p>
                <p>診別: {medicalTypeStr(medicalType)}</p>
                <p>開放掛號/APP: {webAppFlagStr}</p>
                <p>網掛/APP取消看診進度: {showProcessFlagStr}</p>
                <p>網掛預約期限: {webAppDayStr}</p>
                <p>看診項目: {medicalItemStr(mediItem)}</p>
                <p>{t("Regs.general.clinicLocation")}: {clinicLocationStr}</p>
                <p>代班醫師: {locumDoctorStr}</p>
                <p>停診: {stopClinicFlagStr}</p>
                <p>停代診公告: {stopClinicBulletinFlagStr}</p>
                <p className="line-clamp-3 text-wrap break-all">注意事項: {weekRemark}</p>
            </>
        )
    }

    /**
     * 點擊Record時
     */
    const handleOnDoubleClick = () => {
        // 顯示編輯視窗
        showPopup({
            // 診間ID
            clinicId: record.clinicId,
            // 預約最大看診人次
            maxPatient: record.limit,
            // 現場最大看診人次
            onsiteLimit: record.onsiteLimit,
            // 看診時間(人/分)
            consultationTime: record.slotMin,
            // 預約方式
            reservationMethod: parseInt(record.giveNumType),
            // 調病歷
            canGetMedicalRecords: record.sentChartFlag,
            // 給號方式
            numberGivingMethod: record.noType,
            // 預約限制
            reservationLimit: record.reserveLimitType,
            // 收費別
            chargeType: `${record.cashType} ${baseData.cashTypeList.find(cashType => cashType.cashType === `${record.cashType}`).cashName}`,
            // 診別
            medicalType: record.clinicType,
            // 開放網掛/App掛號
            webOrAppBooking: record.webapptFlag,
            // 網掛/App取消看診進度
            webOrAppCancel: record.showProcessFlag,
            // 網路預約期限
            onlineBookingDeadline: record.webapptDay,
            // 看診項目
            mediItem: record.clinicMarkNo,
            // 診間地點
            clinicLocation: record.clinicLocation,
            // 注意事項
            notes: record.weekNotice,
            // 診間標語
            clinicSlogan: record.clinicSlogan,
            // 醫師
            doctor: `${record.doctorNo} ${baseData.doctorList.find(doctor => doctor.userNo === record.doctorNo).userName}`,
            // 科別
            department: `${record.divNo} ${baseData.divisionList.find(department => department.divNo === record.divNo).divName}`,
            // 診間名稱
            clinicName: record.clinicName,
            // 診室號
            clinicNo: record.clinicNo,
            // 時段
            timeslot: record.apn,
            // 當前日期
            date: record.encounterDate ? (record.encounterDate.split(' ')[0]).replaceAll('-', '/') : '',
            // 星期
            week: record.encounterDate ? new Date(record.encounterDate.split(' ')[0]).getDay() : '',
            // 代理醫師
            locumDoctor: stringIsEmpty(record.locumDoctor) ? record.locumDoctor : `${record.locumDoctor} ${baseData.doctorList.find(doctor => doctor.userNo === record.locumDoctor).userName}`,//record.locumDoctor ? `${record.locumDoctor}` : '',
            // 停診
            stopClinicFlag: record.stopClinicFlag,
            // 停診原因
            stopClinicReason: record.stopClinicReason,
            // 停代診公告
            stopClinicBulletinFlag: record.stopClinicBulletinFlag,
            // 假日展班
            holidayexclFlag: record.holidayexclFlag,

            // 建立時間
            createDatetime: record.createDatetime,
            // 建立者
            createUser: record.createUser,
            //修改時間
            modifyDatetime: record.modifyDatetime,
            //修改者
            modifyUser: record.modifyUser,

            weekId: record.weekId,

            //
            lockVersion: record.lockVersion
        }, PopupModeEnum.Modify)
    }

    return (
        <div className="relative" ref={dataElementRef}>
            <div
                className={`${selected ? "selected" : ""} flex flex-row items-center justify-between w-full mx-auto hover:bg-blue-100 h-fit`}
                onMouseEnter={(e) => recordOnMouseEnter(e)}
                onMouseLeave={(e) => recordOnMouseLeave(e)}
                onDoubleClick={handleOnDoubleClick}>
                <h1 className="w-full text-base text-left">
                    {getRecordContent()}
                </h1>
            </div>
            {
                showRecordInfo && (
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="fixed border-4 bg-white z-20 text-left px-4 p-2 w-64 flex flex-col"
                        style={{
                            left: `${adjustedRecordInfoLeft}px`,
                            top: `${adjustedRecordInfoTop}px`,
                        }}>
                        {getRecordInfo()}
                    </div>
                )
            }
        </div>
    )
}
export default ScheduleAppointment

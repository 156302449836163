import React, {useEffect, useState} from "react";
import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import addIcon from "../../../assets/images/icons/add_circle.svg";
import deleteCircle from "../../../assets/images/icons/del_circle.svg";
import {t} from "i18next";

/**
 * 主要照顧者評估
 * @returns {JSX.Element}
 * @constructor
 */
const CareGiver = () => {
    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    const [formData, setFormData] = useState({
        caregiverStatus: '',
        name: '',
        relationship: '',
        age: '',
        gender: '',
        employmentStatus: '',
        careDays: '',
        careHoursPerDay: '',
        careDaysPerMonth: '',
        caregiverHealth: '',
        caregiverPsychological: '',
        familyInteraction: '',
        totalCareScore: '',
        caregiverEvaluation: '',
        otherComments: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process form submission
        console.log(formData);
    };

    const caregiverHealthOptions = [
        '主要照顧者的健康與過去差不多，沒什麼變化',
        '主要照顧者因照顧個案而感到疲累、身體不適，但不需要看醫生',
        '主要照顧者因照顧個案感到疲累、身體不適，而需要看醫生或接受治療，但仍可繼續照顧',
        '主要照顧者因照顧個案而太過疲累而需要住院，或無法繼續照顧，必須換人',
    ];

    const caregiverPsychologicalOptions = [
        '主要照顧者的心理狀況與過去一樣，沒什麼變化',
        '主要照顧者的情緒偶爾會焦慮、擔心、憂鬱，但不至於影響生活作息',
        '主要照顧者會受到個案影響而情緒變化大甚至需要服用鎮定劑、安眠藥',
        '主要照顧者會因個案影響心理狀況產生精神症狀需要常看醫師或住院',
    ];

    const familyInteractionOptions = [
        '家人的互動關係由於個案的相關問題而更能相互關心或沒什麼改變',
        '家人的關係會因為個案的相關問題而起小衝突，但尚能解決問題，維持和諧關係',
        '家人之間會因個案的相關問題而常發生衝突，有些衝突不易解決，但尚不致嚴重破壞家庭的和諧',
        '家人因個案的相關問題常發生嚴重衝突無法解決而嚴重破壞關係，或常處於緊張狀態',
    ];

    const caregiverEvaluationOptions = [
        '無照護負荷問題',
        '照護品質不佳',
        '家庭照護負荷過重',
        '家屬無照護意願',
    ];

    // 計算總分
    const calculateTotalScore = () => {
        const getScore = (value, options) => {
            const index = options.indexOf(value);
            return index !== -1 ? index + 1 : 0;
        };

        const healthScore = getScore(formData.caregiverHealth, caregiverHealthOptions);
        const psychologicalScore = getScore(formData.caregiverPsychological, caregiverPsychologicalOptions);
        const interactionScore = getScore(formData.familyInteraction, familyInteractionOptions);

        return healthScore + psychologicalScore + interactionScore;
    };

    // 更新總分
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            totalCareScore: calculateTotalScore()
        }));
    }, [formData.caregiverHealth, formData.caregiverPsychological, formData.familyInteraction]);



    const labelStyle = () => {
        return 'flex flex-row items-center justify-start mr-4'
    }

    return (
        <div className="w-full h-[calc(100vh-165px)] overflow-y-auto p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-4 pl-2 bg-white sticky top-0 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>主要照顧者評估</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <button
                    className="flex items-center"
                >
                    <div className="py-[10px] h-10 mr-1">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <button className={`${SecondButtonStyle} ml-2`}>上頁</button>
                <button className={`${SecondButtonStyle} ml-2 bg-gray-200}`}>2024/06/28</button>
                <button className={`${SecondButtonStyle} ml-2`}>下頁</button>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <button className="flex flex-row justify-start items-center w-[112px] h-10">
                        <div className="w-5 h-5 mr-1">
                            <img className="w-full h-full" src={deleteCircle} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#E53E3E]">{t('general.delete')}</p>
                    </button>
                </div>
                <div className="flex flex-row flex-shrink-0">
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        Ditto
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                    value={formData.evaluationDate}
                    onChange={(e) => setFormData({...formData, evaluationDate: e.target.value})}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                    value={formData.evaluator}
                    onChange={(e) => setFormData({...formData, evaluator: e.target.value})}
                />
            </div>
            <form className="py-2 px-4 border border-[#1111111]/15" onSubmit={handleSubmit}>
                {/*主要照顧者狀況*/}
                <div
                    className="flex flex-row items-center justify-start h-[42px] box-content py-4 w-full border-b border-[#1111111]/15">
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="無主要照顧者"
                               checked={formData.caregiverStatus === '無主要照顧者'}
                               onChange={handleChange}
                        />
                        無主要照顧者
                    </label>
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="有主要照顧者"
                               checked={formData.caregiverStatus === '有主要照顧者'}
                               onChange={handleChange}
                        />
                        有主要照顧者
                    </label>
                    <label className={labelStyle()}>
                        <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                               style={{accentColor: "#2B6CB0"}}
                               type="radio"
                               name="caregiverStatus"
                               value="有主要照顧者，不與個案同住"
                               checked={formData.caregiverStatus === '有主要照顧者，不與個案同住'}
                               onChange={handleChange}
                        />
                        有主要照顧者，不與個案同住
                    </label>
                </div>

                <div className="py-4 w-full border-b border-[#1111111]/15">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="w-[160px] mr-4 pr-4 text-right">姓名</p>
                        <BaseInput
                            className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                            type={InputTypeEnum.Text}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <p className="w-[112px] mr-4">與個案關係</p>
                        <select className="w-[96px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                                name="vitalSigns.edema.leftUpperLimb"
                                value={formData.relationship}
                                onChange={handleChange}
                        >
                            <option value=""></option>
                        </select>
                        <p className="w-[112px] pr-4 mr-4 text-right">年齡</p>
                        <BaseInput
                            className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                            type={InputTypeEnum.Text}
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                        />
                        <p className="w-[112px] pr-4 mr-4 text-right">性別</p>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="gender"
                                   value="男"
                                   checked={formData.gender === '男'}
                                   onChange={handleChange}
                            />
                            男
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="gender"
                                   value="女"
                                   checked={formData.gender === '女'}
                                   onChange={handleChange}
                            />
                            女
                        </label>
                    </div>
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="w-[160px] mr-4 pr-4 text-right">目前就業狀況</p>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="無"
                                   checked={formData.employmentStatus === '無'}
                                   onChange={handleChange}
                            />
                            無
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="全職"
                                   checked={formData.employmentStatus === '全職'}
                                   onChange={handleChange}
                            />
                            全職
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="employmentStatus"
                                   value="兼職"
                                   checked={formData.employmentStatus === '兼職'}
                                   onChange={handleChange}
                            />
                            兼職(部分時間工作)
                        </label>
                    </div>
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="w-[160px] mr-4 pr-4 text-right">每天照顧時間</p>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="careHoursPerDay"
                                   value="全天"
                                   checked={formData.careDays === '全天'}
                                   onChange={handleChange}
                            />
                            全天
                        </label>
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="careHoursPerDay"
                                   value="部分時間"
                                   checked={formData.careDays === '部分時間'}
                                   onChange={handleChange}
                            />
                            部分時間
                        </label>
                        <label className={labelStyle()}>
                            每日平均
                            <BaseInput
                                className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mx-2"
                                type={InputTypeEnum.Text}
                                name="careDaysPerMonth"
                                value={formData.careHoursPerDay}
                                onChange={handleChange}
                            />
                            小時
                        </label>
                    </div>
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="w-[160px] mr-4 pr-4 text-right">照顧個案月數</p>
                        <BaseInput
                            className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-2"
                            type={InputTypeEnum.Text}
                            name="careDaysPerMonth"
                            value={formData.careDaysPerMonth}
                            onChange={handleChange}
                        />
                        月
                    </div>
                </div>
                <div className="py-4 px-4 w-full border-b border-[#1111111]/15">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者負荷：依照實際情形點選適合選項</p>
                    </div>
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者身體健康：</p>
                    </div>
                    {caregiverHealthOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="caregiverHealth"
                                   value={option}
                                   checked={formData.caregiverHealth === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </div>
                <div className="py-4 px-4 w-full border-b border-[#1111111]/15">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">主要照顧者心理狀況</p>
                    </div>
                    {caregiverPsychologicalOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="caregiverPsychological"
                                   value={option}
                                   checked={formData.caregiverPsychological === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </div>
                <div className="py-4 px-4 w-full border-b border-[#1111111]/15">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 pr-4 text-right">家庭的互動關係</p>
                    </div>
                    {familyInteractionOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{accentColor: "#2B6CB0"}}
                                   type="radio"
                                   name="familyInteraction"
                                   value={option}
                                   checked={formData.familyInteraction === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                    <div className="flex flex-row items-center justify-start h-[42px] box-content w-full">
                        <p className="mr-4 text-right">家庭照顧者負荷總計分：</p>
                        <BaseInput
                            className="w-10 h-10 text-[#C53030]"
                            type={InputTypeEnum.Text}
                            name="totalCareScore"
                            value={formData.totalCareScore}
                            readOnly
                        />
                    </div>
                </div>

                <div className="flex flex-row items-center justify-start py-4 px-4 w-full border-b border-[#1111111]/15">
                    <div className="flex flex-row items-center justify-start h-[42px] box-content">
                        <p className="mr-4 pr-4 text-right">照顧者總評</p>
                    </div>
                    {caregiverEvaluationOptions.map((option, index) => (
                        <label className={`${labelStyle()} h-[42px]`} key={index}>
                            <input className="bg-green-400 checked:bg-green-600 text-green-600 w-5 h-5 mr-2"
                                   style={{accentColor: "#38A169"}}
                                   type="checkbox"
                                   name="caregiverEvaluation"
                                   value={option}
                                   checked={formData.caregiverEvaluation === option}
                                   onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                    <div className="flex flex-row items-center justify-start h-[42px] box-content">
                        <label className={labelStyle()}>
                            <input className="w-5 h-5 text-[#2B6CB0] checked:bg-[#2B6CB0] mr-2"
                                   style={{ accentColor: "#2B6CB0" }}
                                   type="checkbox"
                                   name="caregiverEvaluation"
                                   value="其他"
                                   checked={formData.caregiverEvaluation === "其他"}
                                   onChange={handleChange}
                            />
                            其他
                        </label>
                        <BaseInput
                            className="w-[168px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-2"
                            type={InputTypeEnum.Text}
                            name="otherComments"
                            value={formData.otherComments}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
export default CareGiver

import React, {useEffect, useMemo, useState} from 'react'
import {ApiErrorStatusEnum, enum2Array, generateEnums, objectIsEmpty, time2String} from 'edah_utils/dist'
import {queryTranTypeByEncounterId, trancaseQueryTranInByEncounterId,} from '../../../api/v1/Rmis'
import {BaseInput} from '../../Input/BaseInput'
import {DATE_FORMAT} from '../../../constants/common'
import ReferralHospitalModal from '../ReferralHospitalModal'
import useToast from '../../../hooks/useToast'
import {t} from 'i18next'
import ReferralListModal from '../../../pages/ReferralSystem/ReferralListModal'
import {useSelector} from 'react-redux'
import store from '../../../redux/store'
import {completePrintMode, updatePrintContent,} from '../../../redux/Slice/printSlice'
import {FORM_TYPE} from '../../../constants/referralSystem/print'
import {getBirthDayAndDetailedAge} from '../utils'
import {
    RadioButton,
    AlertTypeEnum,
    Checkbox,
    DatePicker,
    Divider,
    DividerVariantEnum,
    Field,
    RadioGroup,
    SizeEnum,
    TextArea,
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    Select
} from 'edah-component/dist'
import dayjs from "dayjs"

/**
 * 轉入原因: 6.其他
 */
const TRAN_PURPOSE_IN_OTHERS = '6'

/**
 * 轉入單個管作業_維護彈窗
 * @param props {Object}
 * @param props.info {Object} 資訊
 * @param props.onClose {Function} 關閉方法
 * @param props.onConfirm {Function} 存檔方法
 * @param props.inpOpdList {Array} 轉入診別下拉選單
 * @param props.inTrandataAgreeFlagList {Array} 轉診資料同意書下拉選單
 * @param props.tranPurposeInList {Array}  轉入原因下拉選單
 * @param props.inReplyStatusList {Array} 外院回覆狀態清單
 * @param props.zipCodeList {Array} 院所查詢高雄地區下拉選單
 * @return {JSX.Element}
 */
const EditTranInModal = ({
                             info,
                             onClose,
                             onConfirm,
                             inpOpdList = [],
                             inTrandataAgreeFlagList = [],
                             tranPurposeInList = [],
                             inReplyStatusList = [],
                             inUnprintSummaryReasonList = [],
                             inTranPaperReasonList = [],
                             zipCodeList = [],
                         }) => {
    // 轉入單列印列舉
    const InTranPaperFlagEnums = generateEnums({
        Yes: {'label': '有', 'value': 1},
        NoReason: {'label': '無，原因', 'value': 0}
    })

    const defaultCaseDetail = {
        // 刪除此筆資料
        cancelFlag: false,
        // 就醫日期
        encounterDate: '',
        // TODO: 批價序號:門住資料待第二階段
        // payFeeNo: '',
        // 病歷號碼
        patientId: '',
        // 姓名
        patientName: '',
        // 性別
        genderName: '',
        // 生日
        birthDate: '',
        // 身分證號
        idNo: '',
        // 看診科別代碼
        divNo: '',
        // 看診科別
        divName: '',
        // 看診醫師
        doctorName: '',
        // 看診醫師代碼
        doctorNo: '',
        // 回覆單填寫
        inTraninCompleteFlag: false,
        // 病摘完成
        inSummaryCompleteFlag: false,
        // 診斷碼
        treatmentCode: '',
        treatmentName: '',
        treatmentCode1: '',
        treatmentName1: '',
        treatmentCode2: '',
        treatmentName2: '',
        // 轉入院所
        hospNoIn: '',
        // 轉入院所
        hospNameIn: '',
        // 轉入院所層
        hospGradeName: '',
        // 預住院單對應的就醫號
        inpEncounterId: '',
        // 住院日期
        admissionDate: '',
        // 預住院區塊的出院日期
        inpDischargeDate: '',
        // 住院醫生
        inpDoctorName: '',
        // 住院科別代碼
        inpDivNo: '',
        // 住院科別
        inpDivName: '',
        // 住院床位
        inpBedNo: '',
        // 轉入診別
        inpOpd: '',
        // 轉診資料同意書 0.拒絕/1.同意
        inTrandataAgreeFlag: '1',
        // 收到轉診單日期
        tranInReceiveDate: '',
        // 轉入原因待規劃
        tranPurposeIn: '',
        // 床號
        bedNo: '',
        // 出院日期
        dischargeDate: '',
        // 轉介回函回覆日期
        inReceiptDate: '',
        // 病摘回覆日期
        inSummaryReplyDate: '',
        // 回覆單回收日期
        inRecoveryDate: '',
        // 無法列印病摘原因
        inUnprintSummaryReason: '',
        // 對方醫院開單日期
        inOrderDate: '',
        // 收到外院回覆單日期
        inReplyDate: '',
        // 外院回覆狀態
        inReplyStatus: '',
        // 有無轉診單
        inTranPaperFlag: '',
        // 轉診單回覆日期
        replyDate: '',
        // 無轉診單原因
        inTranPaperReason: '',
        // 特殊報告回覆日期
        inSpecialReplyDate: '',
        // 癌症篩檢
        inCancerFlag: false,
        // 備註
        inRemark: '',
        // 轉入原因
        // tranPurposeIn: '',
        // 轉入轉診目(tranPurposeln)值為6時，讓使用登打備註
        tranPurposeRemarkIn: '',
        // 電子轉診單單號
        etranNo: '',
    }
    //資料
    const [caseDetail, setCaseDetail] = useState(defaultCaseDetail)

    // 補列印回覆單 - 列印數量
    const [printSetting, setPrintSetting] = useState({
        copies: 1,
        couplet: 2,
    })

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralListModal, setIsShowReferralListModal] =
        useState(false)

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    // toast message
    const showToast = useToast()

    /**
     * 更新轉入單個管資料
     * @param e {event}
     * @param field {string}
     * @return {void}
     */
    const updateCaseDetail = (e, field) => {
        const value =
            field === 'cancelFlag' || field === 'inCancerFlag'
                ? e.target.checked
                : e.target.value
        if (field === 'tranPurposeIn' && value !== TRAN_PURPOSE_IN_OTHERS) {
            setCaseDetail((prev) => ({
                ...prev,
                tranPurposeRemarkIn: '',
            }))
        }
        setCaseDetail((prev) => ({
            ...prev,
            [field]: value,
        }))
    }

    /**
     * 回覆日期變更事件
     * @param {Dayjs | null} newValue
     * @return {void}
     */
    const handleReplyDateOnChange = (newValue) => {
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : '';
        setCaseDetail((prev) => ({
            ...prev,
            replyDate: formattedDate,
        }));
    };

    /**
     * 轉換日期格式讓 datepicker 能夠顯示
     * @param datetime {String|null}
     * @return {String}
     */
    const generateDate = (datetime) => {
        return datetime ? time2String(datetime, DATE_FORMAT) : ''
    }

    /**
     * 取得元素
     * @return {JSX.Element[]}
     */
    const customElement = () =>
        useMemo(() => enum2Array(InTranPaperFlagEnums).map((item, index) =>
                <div key={index} className="flex items-center spacy-x-2">
                    <RadioButton
                        value={item.value.value}
                        checked={caseDetail.inTranPaperFlag === !!item.value.value}
                    />
                    {
                        // 有就顯示日期
                        item.value.label === InTranPaperFlagEnums.Yes.label ? (
                            <DatePicker
                                size={SizeEnum.Small}
                                label=""
                                value={caseDetail.replyDate ? dayjs(caseDetail.replyDate) : null}
                                onChange={handleReplyDateOnChange}
                            />
                        ) : item.value.label === InTranPaperFlagEnums.NoReason.label ? (
                                // 無就顯示原因
                                <Select
                                    data={{
                                        label: '無就顯示原因',
                                        options: [
                                            {value: '', label: ''},
                                            ...inTranPaperReasonList.map((item) => ({
                                                value: item.inTranPaperReason,
                                                label: item.inTranPaperReasonName
                                            }))
                                        ]
                                    }}
                                    value={caseDetail.inTranPaperReason}
                                    onChange={(e) => updateCaseDetail({target: {value: e}}, 'inTranPaperReason')}
                                    displayEmpty={true}
                                    showLabel={false}
                                />
                            )
                            :
                            null
                    }
                </div>
            ),
            [caseDetail.inTranPaperFlag, caseDetail.replyDate, caseDetail.inTranPaperReason, inTranPaperReasonList, InTranPaperFlagEnums]
        )

    /**
     * 更新有無轉診單 radio button
     * @param e {Event}
     * @return {void}
     */
    const handleInTranPaperFlagOnChange = (e) =>
        setCaseDetail({
            ...caseDetail,
            inTranPaperFlag: !!parseInt(e.target.value),
        })

    /**
     * 查詢轉入單資料(單筆)
     * @return {void}
     */
    const getTranCaseQueryTranInByEncounterId = () => {
        trancaseQueryTranInByEncounterId({
            //就醫號
            encounterId: info.encounterId,
            //就醫日期
            encounterDate: info.encounterDate,
            //門住類別(O門診/E急診/I住院)
            inpOpd: info.inpOpd,
            //病歷號碼
            patientId: info.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const {data} = res
                const newData = {}
                Object.keys(data).forEach((key) => {
                    switch (key) {
                        case 'cancelFlag':
                        case 'inTranPaperFlag':
                        case 'inSummaryCompleteFlag':
                        case 'inTraninCompleteFlag':
                        case 'inCancerFlag':
                            newData[key] = !!data[key]
                            break
                        case 'inTrandataAgreeFlag':
                            newData[key] = data[key] ? '1' : '0'
                            break
                        case 'replyDate':
                        case 'inReceiptDate':
                        case 'inRecoveryDate':
                        case 'inReplyDate':
                        case 'inOrderDate':
                        case 'inSpecialReplyDate':
                        case 'inSummaryReplyDate':
                        case 'dischargeDate':
                        case 'tranInReceiveDate':
                            newData[key] = generateDate(data[key])
                            break
                        default:
                            newData[key] = data[key] || ''
                    }
                })
                setCaseDetail(newData)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 選擇院所
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setCaseDetail({
            ...caseDetail,
            hospNoIn: hospital.hospNo,
            hospNameIn: hospital.hospName,
            hospGradeName: hospital.hospGradeName,
        })
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 確認選擇電子轉診單號
     * @param etranNo {string}  電子轉診單號
     * @return {void}
     */
    const handleSelectEtranNo = (etranNo) => {
        setCaseDetail({
            ...caseDetail,
            etranNo,
        })
        setIsShowReferralListModal(false)
    }

    /**
     * 轉入單列印
     * @return {void}
     */
    const handleTranInForm = () => {
        queryTranTypeByEncounterId({
            encounterId: info.encounterId,
            encounterDate: info.encounterDate,
            inpOpd: info.inpOpd,
            patientId: info.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const printData = res.data
                store.dispatch(
                    updatePrintContent({
                        reportType: FORM_TYPE.tranInForm,
                        printData,
                    })
                )
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     * @return {void}
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 監聽新資料進來時呼叫查詢轉入單資料(單筆)
     * @return {void}
     */
    useEffect(() => {
        //確保資料不為空
        if (!objectIsEmpty(info)) {
            getTranCaseQueryTranInByEncounterId()
        }
    }, [info])

    return (
        <Dialog
            open={true}
            variant={DialogVariant.EDIT}
            size={DialogSizeEnums.LG}
            title={'轉入單個管作業_維護'}
            onClose={onClose}
            onSave={() => onConfirm(caseDetail)}
            muiDialogContentProps={{dividers: true}}
            content={
                <>
                    <div className="flex justify-end items-center">
                        <div className="bg-[#F4F4F5] flex items-center p-1 mr-2 rounded-[6px] gap-2">
                            <Button
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Large}
                                variant={ButtonVariantEnum.Contained}
                                text={'補列印回覆單'}
                                onClick={handleTranInForm}
                                sx={{fontWeight: 'bold'}}
                            />
                            <div className="flex items-center">
                                <TextField
                                    size={SizeEnum.Tiny}
                                    type="number"
                                    inputWidth={SizeEnum.Tiny}
                                    value={printSetting.copies}
                                    onChange={(e) =>
                                        setPrintSetting({
                                            ...printSetting,
                                            copies: e.target.value,
                                        })
                                    }
                                />
                                份
                                <TextField
                                    size={SizeEnum.Tiny}
                                    type="number"
                                    inputWidth={SizeEnum.Tiny}
                                    value={printSetting.couplet}
                                    onChange={(e) =>
                                        setPrintSetting({
                                            ...printSetting,
                                            couplet: e.target.value,
                                        })
                                    }
                                />
                                聯
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <Button
                                text={'電子轉診單'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Large}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={() => setIsShowReferralListModal(true)}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'留言系統'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Large}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={() => {
                                }}
                                sx={{fontWeight: 'bold'}}
                            />
                            {/*<button*/}
                            {/*    className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2">*/}
                            {/*    留言系統*/}
                            {/*</button>*/}
                            <Divider
                                orientation="vertical"
                                variant={DividerVariantEnum.Middle}
                                sx={{mx: 1}}
                            />
                            <Checkbox
                                label="刪除此筆資料"
                                id="cancelFlag"
                                name="cancelFlag"
                                checked={caseDetail.cancelFlag}
                                onChange={(e) => updateCaseDetail(e, 'cancelFlag')}
                            />
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="w-full grid grid-cols-4 mb-2 gap-2">
                            <Field label="就醫日期">
                                {time2String(caseDetail.encounterDate, DATE_FORMAT)}
                            </Field>
                            <Field label="批價序號">
                                {/* TODO:門/住資料是第二階段 */}
                                {/* {caseDetail.payFeeNo} */}
                            </Field>
                            <Field label={t('general.patientNo')}>
                                {caseDetail.patientId}
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4 mb-2 gap-2">
                            <Field label={t('general.username')}>
                                {caseDetail.patientName}
                            </Field>
                            <Field label={t('general.gender.name')}>
                                {caseDetail.genderName}
                            </Field>
                            <Field label={t('general.birthday')}>
                                {getBirthDayAndDetailedAge(caseDetail.birthDate)}
                            </Field>
                            <Field label={t('general.idNumber')}>
                                {caseDetail.idNo}
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4 mb-2 gap-2">
                            <Field label="看診科別">
                                {`${caseDetail.divNo}${caseDetail.divName}`}
                            </Field>
                            <Field label="看診醫師">
                                {`${caseDetail.doctorNo}${caseDetail.doctorName}`}
                            </Field>
                            <div className="flex flex-row items-center justify-start">
                                <Checkbox
                                    label="回覆單填寫"
                                    id="inTraninCompleteFlag"
                                    name="inTraninCompleteFlag"
                                    checked={caseDetail.inTraninCompleteFlag}
                                    readOnly
                                />
                                <Checkbox
                                    label="病摘完成"
                                    id="inSummaryCompleteFlag"
                                    name="inSummaryCompleteFlag"
                                    checked={caseDetail.inSummaryCompleteFlag}
                                    readOnly
                                />
                            </div>
                        </div>
                        <Field label="診斷碼" sx={{mb: 2, alignItems: 'start'}}>
                            <div className="grid grid-cols-5 gap-2 w-full mb-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        sx={{mr: 1}}
                                        value={caseDetail.treatmentCode}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName')
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full mb-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentCode1}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode1')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName1}
                                        disabled
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName1')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-5 gap-2 w-full mb-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentCode2}
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentCode2')
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.treatmentName2}
                                        disabled
                                        onChange={(e) =>
                                            updateCaseDetail(e, 'treatmentName2')
                                        }
                                    />
                                </div>
                            </div>
                        </Field>
                        <Field label="轉入院所">
                            <div className="grid grid-cols-5 gap-[10px] mb-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.hospNoIn}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-3">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.hospNameIn}
                                        disabled
                                    />
                                </div>
                            </div>
                        </Field>
                        <Field label="轉入院所層">
                            <div className="grid grid-cols-5 gap-[10px] mb-2">
                                <div className="col-span-1">
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={caseDetail.hospGradeName}
                                        disabled
                                    />
                                </div>
                            </div>
                        </Field>
                        <Divider sx={{my: 2}}/>
                        <Field
                            label="預住院單對應的就醫號"
                            labelWidth={200}
                            sx={{mb: 2}}
                        >
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.inpEncounterId}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'inpEncounterId')
                                }
                            />
                        </Field>
                        <div className="w-[80%] m-auto">
                            <div className="grid grid-cols-4 mb-4 gap-2">
                                <Field label="住院日期">
                                    {caseDetail.admissionDate}
                                </Field>
                                <Field label="出院日期">
                                    {caseDetail.inpDischargeDate}
                                </Field>
                                <Field label="住院醫生">
                                    {caseDetail.inpDoctorName}
                                </Field>
                            </div>
                            <div className="grid grid-cols-4 mb-4 gap-2 ">
                                <Field label="住院科別">
                                    {caseDetail.inpDivNo || ''}
                                    {caseDetail.inpDivName || ''}
                                </Field>
                                <Field label="住院床位">
                                    {caseDetail.inpBedNo}
                                </Field>
                            </div>
                        </div>
                        <Divider sx={{my: 2}}/>
                        <div className="grid grid-cols-3 gap-2 mb-4">
                            <Field label="轉入診別" labelWidth={160}>
                                <Select
                                    data={{
                                        label: '轉入診別',
                                        options: inpOpdList.map((item) => ({
                                            value: item.inInpOpd,
                                            label: item.inInpOpdName
                                        }))
                                    }}
                                    value={caseDetail.inpOpd}
                                    onChange={(e) => updateCaseDetail({target: {value: e}}, 'inpOpd')}
                                    showLabel={false}
                                    width={SizeEnum.Small}
                                />
                            </Field>
                            <Field label="轉診資料同意書" labelWidth={160}>
                                <Select
                                    data={{
                                        label: '轉診資料同意書',
                                        options: [
                                            {value: '', label: ''},
                                            ...inTrandataAgreeFlagList.map((item) => ({
                                                value: item.inTrandataAgreeFlag,
                                                label: item.inTrandataAgreeFlagName
                                            }))
                                        ]
                                    }}
                                    value={caseDetail.inTrandataAgreeFlag}
                                    onChange={(e) => updateCaseDetail({target: {value: e}}, 'inTrandataAgreeFlag')}
                                    showLabel={false}
                                    width="Small"
                                />
                            </Field>
                            {/*收到轉診單日期*/}
                            <Field label={'收到轉診單日期'} labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.tranInReceiveDate ? dayjs(caseDetail.tranInReceiveDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'tranInReceiveDate')}
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-3 gap-2 mb-4">
                            <Field label="轉入原因" labelWidth={160}>
                                <Select
                                    data={{
                                        label: '轉入原因',
                                        options: tranPurposeInList.map((item) => ({
                                            value: item.tranPurposeIn,
                                            label: item.tranPurposeRemarkIn
                                        }))
                                    }}
                                    value={caseDetail.tranPurposeIn}
                                    onChange={(e) => updateCaseDetail({target: {value: e}}, 'tranPurposeIn')}
                                    showLabel={false}
                                    width='XLarge'
                                />
                            </Field>
                            {caseDetail.tranPurposeIn === TRAN_PURPOSE_IN_OTHERS ? (
                                <Field label="轉入原因備註" labelWidth={160}>
                                    <TextField
                                        size={SizeEnum.Small}
                                        value={caseDetail.tranPurposeRemarkIn}
                                        onChange={(e) =>
                                            updateCaseDetail(
                                                e,
                                                'tranPurposeRemarkIn'
                                            )
                                        }
                                    />
                                </Field>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="w-full grid grid-cols-3 mb-4 gap-2">
                            <Field label="床號" labelWidth={160}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.bedNo}
                                    onChange={(e) => updateCaseDetail(e, 'bedNo')}
                                />
                            </Field>
                            {/*出院日期*/}
                            <Field label="出院日期" labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.dischargeDate ? dayjs(caseDetail.dischargeDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'dischargeDate')}
                                />
                            </Field>
                            {/*轉介回函回覆日期*/}
                            <Field label={'轉介回函回覆日期'} labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inReceiptDate ? dayjs(caseDetail.inReceiptDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inReceiptDate')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-3 mb-4 gap-2">
                            {/*病摘回覆日期*/}
                            <Field label="病摘回覆日期" labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inSummaryReplyDate ? dayjs(caseDetail.inSummaryReplyDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inSummaryReplyDate')}
                                />
                            </Field>
                            {/*回覆單回收日期*/}
                            <Field label={'回覆單回收日期'} labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inRecoveryDate ? dayjs(caseDetail.inRecoveryDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inRecoveryDate')}
                                />
                            </Field>
                            <Field label="電子轉診單號" labelWidth={160}>
                                <BaseInput
                                    className="h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                    type="text"
                                    value={caseDetail.etranNo}
                                    onChange={(e) => updateCaseDetail(e, 'etranNo')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-3 mb-4 gap-2">
                            <Field label="無法列印病摘原因" labelWidth={160}>
                                <div className='-ml-2'>
                                    <Select
                                        data={{
                                            label: '無法列印病摘原因',
                                            options: inUnprintSummaryReasonList.map((item) => ({
                                                value: item.inUnprintSummaryReason,
                                                label: item.inUnprintSummaryReasonName
                                            }))
                                        }}
                                        value={caseDetail.inUnprintSummaryReason}
                                        onChange={(e) => updateCaseDetail({target: {value: e}}, 'inUnprintSummaryReason')}
                                        showLabel={false}
                                        width={SizeEnum.Large.charAt(0).toUpperCase() + SizeEnum.Large.slice(1).toLowerCase()}
                                    />
                                </div>
                            </Field>
                            {/*對方醫院開單日期*/}
                            <Field label="對方醫院開單日期" labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inOrderDate ? dayjs(caseDetail.inOrderDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inOrderDate')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-3 mb-4 gap-2">
                            {/*收到外院回覆單日期*/}
                            <Field label="收到外院回覆單日期" labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inReplyDate ? dayjs(caseDetail.inReplyDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inReplyDate')}
                                />
                            </Field>
                            <Field label="外院回覆狀態" labelWidth={160}>
                                <Select
                                    data={{
                                        label: '外院回復狀態',
                                        options: [
                                            {value: '', label: ''},
                                            ...inReplyStatusList.map((item) => ({
                                                value: item.inReplyStatus,
                                                label: item.inReplyStatusName
                                            }))
                                        ]
                                    }}
                                    value={caseDetail.inReplyStatus}
                                    onChange={(e) => updateCaseDetail({target: {value: e}}, 'inReplyStatus')}
                                    showLabel={false}
                                    displayEmpty={true}
                                    width='Medium'
                                />
                            </Field>
                        </div>
                        <Field label="有無轉診單" labelWidth={160} sx={{ mb: 2}}>
                            <RadioGroup size={SizeEnum.Medium} value={caseDetail.inTranPaperFlag}
                                        optionProps={{options: customElement()}}
                                        onChange={handleInTranPaperFlagOnChange}/>
                        </Field>
                        <div className="w-full grid grid-cols-4 mb-2 gap-2">
                            {/*特殊報告回覆日期*/}
                            <Field label="特殊報告回覆日期" labelWidth={160}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={caseDetail.inSpecialReplyDate ? dayjs(caseDetail.inSpecialReplyDate) : null}
                                    onChange={(newDate) =>
                                        updateCaseDetail({target: {value: newDate ? newDate.format('YYYY-MM-DD') : ''}}, 'inSpecialReplyDate')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4 mb-2 gap-2">
                            <Field label="癌症篩檢" labelWidth={160}>
                                <Checkbox
                                    id="inCancerFlag"
                                    name="inCancerFlag"
                                    checked={caseDetail.inCancerFlag}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'inCancerFlag')
                                    }
                                />
                            </Field>
                        </div>
                        <div className="flex flex-row items-center justify-start">
                            <Field label={'備註'} sx={{alignItems: 'start'}} labelWidth={160}>
                                <TextArea
                                    inputSize={SizeEnum.Fill}
                                    value={caseDetail.inRemark}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'inRemark')
                                    }
                                />
                            </Field>
                        </div>
                    </div>
                    {isShowReferralHospitalModal && (
                        <ReferralHospitalModal
                            zipCodeList={zipCodeList}
                            onConfirm={onSelectHospital}
                            closePopupButtonOnClick={() =>
                                setIsShowReferralHospitalModal(false)
                            }
                        />
                    )}
                    {
                        isShowReferralListModal && (
                            <ReferralListModal
                                info={info}
                                onClose={() => setIsShowReferralListModal(false)}
                                onConfirm={handleSelectEtranNo}
                            />
                        )
                    }
                </>
            }
        />
    )
}

export default EditTranInModal

import {useEffect, useMemo, useRef, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String
} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {queryAllDivision} from "../../api/v1/Menu"
import {regsQueryClinicTypeList, regsQueryReg, regsReportGetFile, regsReportPappt} from "../../api/v1/Regs";
import useOutsideClick from "../../hooks/useOutsideClick"
import PDFPreviewPopup from "../../components/Regs/Popup/PDFPreviewPopup";
import dayjs from 'dayjs';
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    ColorEnum,
    DateRangePicker,
    Divider,
    DividerOrientationEnum,
    DividerVariantEnum,
    IconEnum,
    RadioGroup,
    Search,
    Select,
    SizeEnum,
    TextField,
    SelectWidthEnum,
    Pagination,
    AdvancedDataGrid
} from 'edah-component/dist'
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 列印作業頁面
 * @return {JSX.Element}
 */
const PrintOperation = () => {

    //時段列表
    const apnList = [
        {justnameNo: "1,2,3", justnameName: "全部"},
        {justnameNo: "1", justnameName: "早"},
        {justnameNo: "2", justnameName: "午"},
        {justnameNo: "3", justnameName: "晚"},
    ]

    //掛號別列表
    const reserveTypeList = [
        {justnameNo: "1,2,3,4,5,6,9", justnameName: "全部"},
        {justnameNo: "1", justnameName: "現掛"},
        {justnameNo: "2", justnameName: "預約"},
        {justnameNo: "3", justnameName: "電話"},
        {justnameNo: "4", justnameName: "診間"},
        {justnameNo: "5", justnameName: "網掛"},
        {justnameNo: "6", justnameName: "手機APP"},
        {justnameNo: "9", justnameName: "其他系統"},
    ]

    //列印方式列表
    const printTypeList = [
        {justnameNo: "0", justnameName: "列印"},
        {justnameNo: "1", justnameName: "預覽"},
    ]

    // 預設建立時間起迄:起-今日
    const today = new Date()
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, 'yyyy-MM-DD'),
        //終止時間迄
        dateEnd: time2String(today, 'yyyy-MM-DD'),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
        //診別
        clinicType: '',
        //掛號別：1.現掛, 2.預約, 3.電話, 4.診間, 5.網掛, 6.手機APP, 9.其他系統
        reserveType: reserveTypeList[0].justnameNo,
        // 科別代碼
        departmentCode: '',
        //診室名稱
        clinicName: '',
        //病歷號
        patientId: '',
        //列印
        printType: printTypeList[0].justnameNo,
    }
    // 診間類別選項
    const [allClinicType, setAllClinicType] = useState(null)
    // 所有科別選項
    const [allDepartment, setAllDepartment] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    // 掛號資料
    const [registrationData, setRegistrationData] = useState(null)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState('')
    // 進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState(null)
    // 被選取的資料
    const [selectedData, setSelectedData] = useState(null)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    // 列印勾選選項
    const [checkedValues, setCheckedValues] = useState(
        {
            registration: false,
            emergencyTag: false,
            emergencyTagWristband: false
        }
    )

    //Toast Message Hooks
    const showToast = useToast()
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });
    //顯示PDF彈窗
    const [showPdfPopup, setShowPdfPopup] = useState(false)
    // PDF Blob
    const [pdfBlob, setPdfBlob] = useState(null)

    /**
     * 取得科別代碼下拉選項
     * @return {Array<Object>}
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode ? displayQueryInputData.departmentCode.split(" ") : []
        if (splitArray.length > 2) {
            return []
        }

        if (splitArray.length === 2) {
            return allDepartment.filter(doctor => doctor.divNo.includes(splitArray[0]) && doctor.divName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(allDepartment, displayQueryInputData.departmentCode)
        }
    }

    /**
     * 日期範圍變更事件
     * @param newValue {Array} 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeOnChange = (newValue) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: newValue[0] ? dayjs(newValue[0]).format('YYYY-MM-DD') : null,
            dateEnd: newValue[1] ? dayjs(newValue[1]).format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 時段下拉選單選擇事件
     * @param value {String} 選單內容文字
     */
    const handleSelectApnOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: value
        })
    }

    /**
     * 診別下拉選單選擇事件
     * @param value {String} 選單內容文字
     */
    const handleInputClinicTypeOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            clinicType: value
        })
    }

    /**
     * 掛號別改變時
     * @param event {Event} - 事件
     */
    const handleRegTypeModeOnChange = (event) => {
        const selectedReserveType = event.target.value;
        setDisplayQueryInputData({
            ...displayQueryInputData,
            reserveType: selectedReserveType  // 更新 reserveType
        });
    };

    /**
     * 病歷號輸入框值變更事件
     * @param e {Event} 事件
     */
    const handleInputPatientIdOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, patientId: e.target.value});
    }

    /**
     * 診室名稱輸入框值變更事件
     * @param e {Event} 事件
     */
    function handleInputClinicNameOnChange(e) {
        setDisplayQueryInputData({...displayQueryInputData, clinicName: e.target.value})
    }

    /**
     * 列印方式改變時
     * @param e {Event}
     */
    const handlePrintTypeModeOnChange = (e) => {
        const selectedPrintType = e.target.value
        setDisplayQueryInputData({
            ...displayQueryInputData,
            printType: selectedPrintType
        })
    }

    /**
     * 科別代碼方框的值變動時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, departmentCode: e.target.value});
    }

    /**
     * 科別代碼方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList())
        setShowDepartmentDropDown(true)
    }

    /**
     * 選取科別下拉選項時
     * @param department {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (department) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: `${department.divNo} ${department.divName}`
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({message: "起始日期不可大於結束日期", type: AlertTypeEnum.Error})
            return
        }

        //查詢紀錄
        querySearch(displayQueryInputData, 1, paginationProps.pageSize)
    }

    /**
     * 檢查資料是否被選擇
     * @param data {Object} 資料
     * @returns
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(selectedData)) {
            return false
        }
        return selectedData === data
    }

    /**
     * 取得時段的名稱
     * @param id {Number} 時段ID
     * @returns
     */
    const getApnNameByApnId = (id) => {
        if (id === null) {
            return ''
        }
        switch (id) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return ''
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        querySearch(queryInputData, page, pageSize)
        setPaginationProps({
            ...paginationProps,
            currentPage: page,
            pageSize: pageSize
        })
    }


    /**
     * 資料點選事件
     * @param item {item} 資料
     * @return {void}
     */
    const handleDataOnClick = (item) => {
        const data = advSearchDataList.find(d => d === item)
        // 找到資料
        if (data) {
            setSelectedData(item)
        }
    }

    /**
     * 多選框點擊事件
     * @param name {String} 名稱
     */
    const handleCheckboxChange = (name) => {
        setCheckedValues(prevState => ({
            ...prevState,
            [name]: !prevState[name]
        }))
    }

    /**
     * 列印作業資料匯出按鈕點擊事件
     * @retuen {void}
     */
    const handleExportPrintOperationQueryExcel = () => {
        // 資料不存在
        if (arrayIsEmpty(registrationData)) {
            // 顯示錯誤訊息
            showToast({message: "資料不存在", type: AlertTypeEnum.Warning})
            return
        }
        // 取得要輸出的資料
        const data = registrationData.map(item => {
            return [
                item.zoneName,
                stringIsEmpty(item.encounterDate) ? '' : time2String(item.encounterDate, "YYYY-MM-DD"),
                item.patientId,
                item.patientName,
                getViewNoFieldContent(item),
                item.divName,
                item.doctorName,
                item.clinicName,
                item.apnName,
                item.fvRvName,
                stringIsEmpty(item.createDatetime) ? '' : time2String(item.createDatetime, "YYYY-MM-DD"),
                item.encounterId, //看診否暫無功能
                item.cancelRegDatetime === null ? '否' : '是'  // 根據 cancelRegDatetime 判斷顯示 "否" 或 "是"
            ]
        })

        // 取得標題
        const titles = [
            "院區",
            "門診日期",
            "病歷號",
            "姓名",
            "診號",
            "科別",
            "醫師",
            "診室名稱",
            "時段",
            "初/複診",
            "掛號日期時間",
            "看診否",
            "退掛",
        ]

        // 取得Sheets
        const sheets = [{
            titles: titles,
            data: data
        }]

        // 取得日期時間
        const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')
        // 檔案名稱
        const fileName = `列印作業報表_${current}`
        // 取得excel
        const excel = exportExcelAsBlob(sheets)
        // 下載檔案
        downloadFile(excel, fileName)
    }

    /**
     * 列印按鈕點擊事件
     * @return {void}
     */
    const handlePrintOnClick = () => getRegsReportPappt()

    /**
     * PDF彈窗關閉時
     * @return {void}
     */
    const handlePdfPopupOnClose = () => {
        //清除資料
        setShowPdfPopup(false)
        setSelectedData(null)
        setPdfBlob(null)
    }

    /**
     * @param queryInputObj {Object} 查詢條件
     * @param page {Number} 第幾頁
     * @param pageSize {Number} 每頁筆數
     * @return {void}
     */
    const querySearch = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)

        regsQueryReg({
            //起始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            // 科別代碼
            divNo: queryInputObj.departmentCode.indexOf(' ') > 0 ? queryInputObj.departmentCode.split(' ')[0] : queryInputObj.departmentCode,
            // 掛號別
            reserveType: ['1', '2', '3', '4', '5', '6', '9'].includes(queryInputObj.reserveType)
                ? parseInt(queryInputObj.reserveType)
                : null,
            // 診室名稱
            clinicName: queryInputObj.clinicName,
            //病歷號
            patientId: queryInputObj.patientId,
            //診別
            clinicType: queryInputObj.clinicType === "ALL" ? "" : queryInputObj.clinicType,
            //時段
            apn: ['1', '2', '3'].includes(queryInputObj.apn)
                ? parseInt(queryInputObj.apn)
                : null,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 在資料中加入key值
                const dataWithKeys = data.dataList ? data.dataList.map((item, index) => ({
                    ...item,
                    key: index,
                    doctorDisplayName: item.locumDoctorName ? `${item.locumDoctorName}[代]` : item.doctorName
                })) : [];

                setRegistrationData(dataWithKeys)

                // auto-select the first data item after fetching
                setSelectedData(arrayIsEmpty(dataWithKeys) ? null : dataWithKeys[0])

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })
            } else { // 取得資料失敗
                const msg = `取得列印資料失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
                setRegistrationData(null)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })
            }
        })
    }

    /**
     * 取得診間類別陣列
     * @return {void}
     */
    const getClinicTypeArray = () => {
        regsQueryClinicTypeList({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定診間類別資料
                const allObj = [{justnameNo: 'ALL', justnameName: '全部'}]
                const updateData = !arrayIsEmpty(data) ? [...allObj, ...data] : [...allObj]
                setAllClinicType(updateData)
                setDisplayQueryInputData({
                    ...displayQueryInputData,
                    clinicType: 'ALL'
                })
            } else { // 取得資料失敗
                setAllClinicType(null)
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getQueryAllDepartment = () => {
        queryAllDivision({}).then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定所有科別選項
                setAllDepartment(data)
            }
        })
    }

    /**
     * 取得預覽預約單
     * @return {void}
     */
    const getRegsReportPappt = () => {
        //資料不存在時
        if (objectIsEmpty(selectedData)) {
            showToast({message: '尚未選取資料', type: AlertTypeEnum.Warning})
            return
        }

        regsReportPappt({regId: selectedData.regId}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得檔案Url
                const {fileUrl} = data
                //檔案URL存在時
                if (fileUrl) {
                    // 取得檔案名稱
                    const fileName = fileUrl.split('?fileName=')[1]
                    //取得檔案Blob
                    regsReportGetFile({fileName: fileName}).then(res => {
                        //取得成功
                        if (res) {
                            //設定PDF Blob
                            setPdfBlob(res)
                            //顯示PDF Popup
                            setShowPdfPopup(true)
                        } else { //取得失敗
                            showToast({message: "請稍後重新嘗試取得檔案", type: AlertTypeEnum.Warning})
                            //清除資料
                            setPdfBlob(null)
                            //隱藏PDF Popup
                            setShowPdfPopup(false)
                        }
                    })
                }
            } else { //取得失敗
                //顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
                //清除資料
                setPdfBlob(null)
                //隱藏PDF Popup
                setShowPdfPopup(false)
            }
        })
    }

    /**
     * 取得給診號欄位內容
     * @param registrationItem {Object} 掛號項目
     * @returns
     */
    const getViewNoFieldContent = (registrationItem) => {
        if (objectIsEmpty(registrationItem)) {
            return ""
        }

        if (registrationItem.giveNumType === "1") {
            //依診號
            return `${registrationItem.viewNo}`
        } else if (registrationItem.giveNumType === "2") {
            //依時間
            if (!stringIsEmpty(registrationItem.startViewDatetime) && !stringIsEmpty(registrationItem.endViewDatetime)) {
                const start = time2String(registrationItem.startViewDatetime, "HH:mm")
                const end = time2String(registrationItem.endViewDatetime, "HH:mm")

                return `${start} ~ ${end}`
            }
        }
        return ""
    }

    /**
     * 進階搜尋輸入框文字變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvSearchTextOnChange = (e) => {
        setAdvSearchText(e.target.value)
        // 清空選擇要列印的資料
        setSelectedData(null)
    }

    /**
     * 取得進階搜尋後的資料
     * @return {void}
     */
    const getAdvanceSearchData = () => {
        const tmpArray =
            arrayIsEmpty(registrationData) ? [] :
                registrationData.map((item) => (
                    {
                        //reg ID
                        regId: item.regId,
                        //只copy要進階搜尋的欄位
                        //院區
                        zoneName: item.zoneName,
                        //門診日期
                        encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                        //病歷號
                        patientId: item.patientId,
                        //病人姓名
                        patientName: item.patientName,
                        //看診號字串
                        viewNoText: getViewNoFieldContent(item),
                        //科別
                        divName: item.divName,
                        //醫師
                        doctorName: item.doctorName,
                        //診室名稱
                        clinicName: item.clinicName,
                        //時段
                        apnName: item.apnName,
                        //初/複診
                        fvRvName: item.fvRvName,
                        //掛號日期時間
                        createDatetime: time2String(item.createDatetime, "YYYY-MM-DD"),
                        //看診否
                        alreadyView: 'N',
                        //退掛
                        isCancelReg: item.cancelRegDatetime === null ? '否' : '是',
                        //代班醫師
                        locumDoctorName: item.locumDoctorName
                    })
                )

        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["regId"])
        setAdvSearchDataList(findedData)
    }

    /**
     * 取得table醫師欄位內容
     * @param item {Object} 資料
     * @returns {String} 醫師欄位的顯示內容
     */
    const getTableDoctorFieldContent = (item) => {
        let doctorContent = '';
        // 當代班醫師 locumDoctorName 不為空時，顯示代班醫師 [代]
        if (!stringIsEmpty(item.locumDoctorName)) {
            doctorContent = `${item.locumDoctorName}[代]`;
        }
        // 當 locumDoctorName 為空，顯示主治醫師 doctorName
        else if (!stringIsEmpty(item.doctorName)) {
            doctorContent = item.doctorName;
        }

        return doctorContent;
    };

    /**
     * 表格欄位
     */
    const header = [
        { field: 'zoneName', headerName: t("Regs.general.campus"), flex: 1 },
        { field: 'encounterDate', headerName: t("Regs.general.clinicDate"), flex: 1 },
        { field: 'patientId', headerName: t('general.patientNo'), flex: 1 },
        { field: 'patientName', headerName: t('general.username'), flex: 1 },
        { field: 'viewNoText', headerName: t('Regs.general.viewNumber'), flex: 1 },
        { field: 'divName', headerName: t("general.department"), flex: 1 },
        { field: 'doctorName', headerName: t('general.doctor'), flex: 1,
            renderCell: (params) => {
                return getTableDoctorFieldContent(params.row)
            }
        },
        { field: 'clinicName', headerName: t("Regs.general.clinicName"), flex: 1 },
        { field: 'apnName', headerName:t('Regs.general.timeSlot'), flex: 1 },
        { field: 'fvRvName', headerName: '初/複診', flex: 1 },
        { field: 'createDatetime', headerName:'掛號日期時間', flex: 1 },
        { field: 'alreadyView', headerName: '看診否', flex: 1 },
        { field: 'isCancelReg', headerName:'退掛', flex: 1 },
    ]


    /**
     * 第一次執行時
     */
    useMemo(() => {
        if (arrayIsEmpty(allDepartment)) {
            getQueryAllDepartment()
        }
        if (arrayIsEmpty(allClinicType)) {
            getClinicTypeArray()
        }

        handleSearchOnClick()
    }, [])

    /**
     * 監聽科別代碼輸入框變化
     * @return {void}
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode])

    /**
     * 監聽advSearchText&filteredDataList的變化
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchData()
    }, [advSearchText, registrationData])


    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊-上*/}
            <div className=" flex flex-row items-center justify-start space-x-3 text-left">
                {/* 選擇日期區間 */}
                <DateRangePicker
                    size="small"
                    value={[
                        displayQueryInputData.dateStart ? dayjs(displayQueryInputData.dateStart) : null,
                        displayQueryInputData.dateEnd ? dayjs(displayQueryInputData.dateEnd) : null
                    ]}
                    required
                    onChange={handleDateRangeOnChange}
                />
                {/* 選擇時段*/}
                <Select
                    data={{
                        label: '時段',
                        options: apnList?.map((apn) => ({
                            label: apn.justnameName,
                            value: apn.justnameNo
                        })) || []
                    }}
                    onChange={handleSelectApnOnChange}
                    notched={true}
                    displayEmpty={true}
                    width={SelectWidthEnum.Small}
                    value={displayQueryInputData.apn}
                />
                {/* 選擇診別*/}
                <Select
                    data={{
                        label: '診別',
                        options: allClinicType?.map((clinicType) => ({
                            label: clinicType.justnameName,
                            value: clinicType.justnameNo
                        })) || []
                    }}
                    onChange={handleInputClinicTypeOnChange}
                    notched={true}
                    displayEmpty={true}
                    width={SelectWidthEnum.Small}
                    value={displayQueryInputData.clinicType}
                />
                {/*掛號別*/}
                <RadioGroup
                    labelProps={{text: '掛號別'}}
                    row
                    value={displayQueryInputData.reserveType}
                    optionProps={{
                        options: reserveTypeList.map(item => ({
                            label: item.justnameName, // 顯示在 radio 的標籤
                            value: item.justnameNo    // 對應的值
                        }))
                    }}
                    size={SizeEnum.Medium}
                    onChange={handleRegTypeModeOnChange}
                />
            </div>
            {/*Filter區塊-下*/}
            <div className="flex flex-row flex-wrap items-center justify-start space-x-2">
                {/*科別*/}
                <Search
                    inputLabel={t('general.department')}
                    options={departmentOptionList || []}
                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                    onChange={(_e, value) => {
                        if (value) {
                            handleDepartmentOptionOnClick(value)
                        }
                    }
                    }
                    onInputChange={(_e, newInputValue) => {
                        handleInputDepartmentNoOnChange({target: {value: newInputValue}});
                    }}
                    onOpen={handleInputDepartmentNoOnFocus}
                />
                {/*診室名稱*/}
                <TextField
                    label={t('Regs.general.clinicName')}
                    value={displayQueryInputData.clinicName}
                    onChange={handleInputClinicNameOnChange}
                />
                {/*病歷號*/}
                <TextField
                    label={t('general.patientNo')}
                    value={displayQueryInputData.patientId}
                    onChange={handleInputPatientIdOnChange}
                />
                {/*查詢按鈕*/}
                <Button
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </Button>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-2">
                {/*列印單據*/}
                <Card title='列印單據'>
                    <div className="flex items-center">
                        <div className="flex ml-2.5 items-center">
                            <Checkbox
                                label="掛號單"
                                checked={checkedValues.registration}
                                onChange={() => handleCheckboxChange('registration')}
                            />
                            <Checkbox
                                label="急診標籤"
                                checked={checkedValues.emergencyTag}
                                onChange={() => handleCheckboxChange('emergencyTag')}
                            />
                            <Checkbox
                                label="急診標籤(手環)"
                                checked={checkedValues.emergencyTagWristband}
                                onChange={() => handleCheckboxChange('emergencyTagWristband')}
                            />
                        </div>
                        {/*分隔線*/}
                        <Divider
                            sx={{mr: 2}}
                            variant={DividerVariantEnum.Middle}
                            orientation={DividerOrientationEnum.Vertical}
                        />
                        {/*列印方式*/}
                        <RadioGroup
                            row size={SizeEnum.Medium} labelProps={{text: '列印方式'}}
                            value={displayQueryInputData.printType}
                            optionProps={{
                                options: printTypeList.map(item => ({
                                    label: item.justnameName, // 顯示在 radio 的標籤
                                    value: item.justnameNo    // 對應的值
                                }))
                            }}
                            onChange={handlePrintTypeModeOnChange}
                        />
                        {/*列印按鈕*/}
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={handlePrintOnClick}>
                            {t('general.print')}
                        </Button>
                    </div>
                    <div className="searchGroup h-10 flex flex-row items-center justify-between mb-4">
                        <div className="flex flex-row items-center">
                            {/*進階搜尋*/}
                            <SearchTextField
                                size={SizeEnum.XLarge}
                                placeholder={t('general.advancedSearch')}
                                value={advSearchText}
                                onChange={(e) => handleAdvSearchTextOnChange(e)}
                            />
                        </div>
                        {/*匯出按鈕*/}
                        <Button
                            color={ColorEnum.Success} variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large} icon={IconEnum.Print}
                            onClick={handleExportPrintOperationQueryExcel}>
                            {t('general.export')}
                        </Button>
                    </div>
                    {/*Table*/}
                    <AdvancedDataGrid
                        rows={advSearchDataList || []}
                        columns={header}
                        checkboxSelection={false}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        disableDensitySelector
                        disableRowSelectionOnClick
                        height={'620px'}
                        getRowId={(row) => row.regId}
                        getRowHeight={(params) => 56}
                        onRowClick={( item) => handleDataOnClick(item.row)}
                    />

                    {/*分頁*/}
                    <div className="flex justify-end w-full mt-1.5">
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            page={paginationProps.currentPage}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            onPageOnChange={(page, pageSize) => onPaginationPageOnChange(page, pageSize)}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </Card>
            </div>
            {/*PDF Preview Popup*/}
            <PDFPreviewPopup showPopup={showPdfPopup}
                             blob={pdfBlob}
                             closePopupButtonOnClick={handlePdfPopupOnClose}/>
        </div>
    )
}

export default PrintOperation

import React, {useEffect, useMemo, useState} from 'react'
import {TRAN_BACK_LIST_FIELDS} from '../../../constants/referralSystem/tranInCase'
import CustomTable from '../CustomTable'
import {Pagination} from '../../Pagination/Pagination'
import {trancaseQueryTranBackCaseBySearch} from '../../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty,
    time2String,
    validDateFormat,
} from 'edah_utils/dist'
import {DATE_FORMAT, DATETIME_FORMAT} from '../../../constants/common'
import useToast from '../../../hooks/useToast'
import {FORM_TYPE} from '../../../constants/referralSystem/print'
import '../../Print/print.scss'
import store from '../../../redux/store'
import {completePrintMode, updatePrintContent,} from '../../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import {t} from 'i18next'
import ReferralHospitalModal from '../ReferralHospitalModal'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    TextField,
    DateRangePicker,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist";
import dayjs from 'dayjs'

/**
 * 列印轉回原醫療院所名單視窗
 * @param inReplyStatusList {Array} 追蹤狀態
 * @param zipCodeList {Array} 郵遞區號
 * @return {JSX.Element}
 */
const TranBackCaseListModal = ({inReplyStatusList = [], zipCodeList = [],}) => {
    // 查詢條件
    const [searchParams, setSearchParams] = useState({
        encounterStartDate: '',
        encounterEndDate: '',
        zoneNo: '',
        hospName: '',
    })
    // 是否顯示院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] = useState(false)
    //轉回原醫療院所清單
    const [dataList, setDataList] = useState([])
    //頁碼
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })
    //輸入框檢核狀態
    const [inputState, setInputState] = useState({encounterStartDate: null, encounterEndDate: null,})
    //列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)
    //顯示訊息
    const showToast = useToast()

    /**
     * 追蹤狀態 radio button
     * @param tranBackCase {Object} 轉回原醫療院所名單
     * @return {JSX.Element}
     */
    const InReplyStatusRadioButtons = (tranBackCase) =>
        <div className="flex space-x-4">
            <RadioGroup size={SizeEnum.Medium} value={tranBackCase.inReplyStatus} optionProps={{
                options: inReplyStatusList.map(({inReplyStatusName: label, inReplyStatus: value}) => ({
                    label,
                    value,
                    disabled: true,
                })),
            }}/>
        </div>

    /**
     * 轉回原醫療院所名單 table header
     * @return {Array<Object>}
     */
    const renderFields = () => useMemo(() => TRAN_BACK_LIST_FIELDS.map((item) =>
        item.key === 'inReplyStatus' ? {...item, render: InReplyStatusRadioButtons} : item
    ), [])

    /**
     * 取得轉回原醫療院所名單
     * @param pageNum {number} 頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const getTranBackList = (pageNum, pageSize) => {
        // 檢核時間欄位
        const encounterStartDateIsValid = validDateFormat(
            searchParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            searchParams.encounterEndDate
        )
        setInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        trancaseQueryTranBackCaseBySearch({
            encounterStartDate: time2String(
                searchParams.encounterStartDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.encounterEndDate,
                DATETIME_FORMAT
            ),
            hospNo: searchParams.zoneNo,
            pageNum,
            pageSize,
        }).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.dataList.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 點選查詢
     * @return {void}
     */
    const handleQueryOnClick = () => {
        getTranBackList(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param page {String} 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        setFilter({
            ...filter,
            currentPage,
        })
    }

    /**
     * 變更每頁筆數
     * @param event {Event}
     * @return {void}
     */
    const onPageSizeChange = (event) => setFilter({
        ...filter,
        pageSize: parseInt(event.target.value),
    })

    /**
     * 點選上一頁
     * @return {void}
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        setFilter({
            ...filter,
            currentPage: page,
        })
    }

    /**
     * 更新搜尋條件
     * @param e {Event} 事件
     * @param field {String} 欄位
     * @return {void}
     */
    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 就醫日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            encounterStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            encounterEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 全選資料
     * @param isAllSelected {boolean}
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param isChecked {Boolean}  是否勾選
     * @param selectedItem {Object}  勾選的資料
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 匯出 Excel
     * @return {void}
     */
    const exportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = TRAN_BACK_LIST_FIELDS.map((item) => item.key)
        const titles = TRAN_BACK_LIST_FIELDS.map((item) => item.name)
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'encounterDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else if (key === 'inReplyStatus') {
                    const statusName = inReplyStatusList.filter(
                        (item) => item.inReplyStatus === item.inReplyStatus
                    )[0].inReplyStatusName
                    data.push(statusName)
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '轉回原醫療院所名單',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 列印
     * @return {void}
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranBackCaseList,
                printData,
            })
        )
    }

    /**
     * 選擇院所
     * @param hospital {Object} 院所
     * @return {void}
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setSearchParams({
            ...searchParams,
            zoneNo: hospital.hospNo,
            hospName: hospital.hospName,
        })
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 選擇院所代碼欄位失焦時，若無輸入則清空院所名稱
     * @param e {Event} 事件
     * @return {void}
     */
    const handleZoneNoOnBlur = (e) => {
        if (!e.target.value) {
            setSearchParams({...searchParams, hospName: '', hospNo: ''})
        }
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     * @return {void}
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <>
            <div className="p-4 no-print">
                <div className="flex justify-between mb-2">
                    <div className="flex">
                        <div className="date flex flex-row items-center justify-start mb-4 gap-2">
                            {/* 就醫日期起訖 */}
                            <DateRangePicker
                                size={SizeEnum.Small}
                                required
                                localeText={{
                                    start: '就醫開始日期',
                                    end: '就醫結束日期',
                                }}
                                value={[
                                    searchParams.encounterStartDate ? dayjs(searchParams.encounterStartDate) : null,
                                    searchParams.encounterEndDate ? dayjs(searchParams.encounterEndDate) : null,
                                ]}
                                onChange={handleDateRangeChange}
                            />
                            {/* 院所查詢按鈕 */}
                            <Button
                                text={'院所查詢'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={() => setIsShowReferralHospitalModal(true)}
                                sx={{fontWeight: 'bold', height: '40px'}}
                            />

                            {/*<Button*/}
                            {/*    classNames="bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"*/}
                            {/*    text={'院所查詢'}*/}
                            {/*    onClickFn={() => setIsShowReferralHospitalModal(true)}/>*/}
                            <TextField
                                label='院所代碼'
                                value={searchParams.zoneNo}
                                onChange={(e) => updateSearchParams(e, 'zoneNo')}
                                onBlur={(e) => handleZoneNoOnBlur(e)}/>
                            <TextField
                                inputWidth={SizeEnum.Large}
                                value={searchParams.hospName}
                                disabled/>
                            <Button
                                text={t('general.query')}
                                color={ButtonColorEnum.Primary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Contained}
                                onClick={handleQueryOnClick}
                                sx={{fontWeight: 'bold', height: '40px'}}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <Button
                            text={t('general.print')}
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            onClick={handlePrint}
                            sx={{fontWeight: 'bold', height: '40px'}}
                        />
                        <Button
                            text={t('general.export')}
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            onClick={exportExcel}
                            sx={{fontWeight: 'bold', height: '40px'}}
                        />
                    </div>
                </div>
                <CustomTable
                    isSelectable={true}
                    fields={renderFields()}
                    dataList={dataList}
                    toggleAll={toggleAll}
                    toggleItem={toggleItem}/>
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.totalItemSize}
                        currentPage={filter.pageNum}
                        totalPageSize={filter.totalPageSize}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}/>
                </div>
            </div>
            {
                isShowReferralHospitalModal && (
                    <ReferralHospitalModal
                        zipCodeList={zipCodeList}
                        onConfirm={onSelectHospital}
                        closePopupButtonOnClick={() => setIsShowReferralHospitalModal(false)}/>
                )
            }
        </>
    )
}

export default TranBackCaseListModal

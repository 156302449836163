import React, {useEffect, useMemo, useState} from 'react'
import {deleteItem} from '../utils';
import {
    ApiErrorStatusEnum,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty, RsisStepStatusEnum,
    stringIsEmpty,
    time2String,
} from 'edah_utils/dist';
import {usePopup} from '../PopupProvider';
import CustomTable from '../RSISCustomTable';
import DeletePopup from '../Popup/DeletePopup';
import QueryPersonList from "./QueryPersonList";
import SupportDateChoice from "./SupportDateChoice";
import '../../TableList/tableList.scss'
import {t} from 'i18next'

// import API
import {rsisAddMaster, rsisQueryAgentByUserNo, rsisQueryBaseData, rsisQueryCodeValue,} from '../../../api/v1/RSIS';
import QuerySupportOrg from "./QuerySupportOrg";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {USER_ROLE} from "../userRole";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    Dialog,
    DialogVariant,
    Divider,
    Field,
    IconEnum,
    Search,
    Select,
    SelectWidthEnum,
    SizeEnum,
    TextField
} from "edah-component/dist"

// 表格欄位
const fields = [
    "",
    "開始日期",
    "結束日期",
    "開始時間",
    "結束時間",
]

/**
 * 列表項目
 * @param item {Object} 列表資料
 * @param index {Number} 索引
 * @param {function} handleOnCloseDeletePopup 關閉刪除彈窗
 * @return {JSX.Element}
 */
const ListItem = ({item, index, handleOnCloseDeletePopup}) => {
    // 使用上下文
    const {focusItem, setFocusItem} = usePopup()

    /**
     * 點擊事件
     * @return {void}
     */
    const handleOnClick = () => {
        if (focusItem !== item) {
            // 設定待刪除的項目
            setFocusItem(item)
        }
    }

    return (
        <li key={index} onClick={handleOnClick}>
            <div>
                <Button sx={{whiteSpace: 'nowrap'}} color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Text} icon={IconEnum.Delete} text={t('general.delete')}
                        onClick={handleOnCloseDeletePopup}/>
            </div>
            <div>{item.startDate}</div>
            <div>{item.endDate}</div>
            <div>{item.startTime}</div>
            <div>{item.endTime}</div>
        </li>
    )
}


/**
 * 報備支援複製新增Popup
 * @param data {object} 報備支援資料
 * @param doctorList {array} 醫師清單
 * @param orgList {array} 機構清單
 * @param deptList {array} 承辦部門清單
 * @param handleOnUpdate {function} 更新列表事件
 * @param handleOnClose {function} 關閉彈窗事件
 * @return {JSX.Element}
 */
const CopyModal = ({
                       data = null,
                       doctorList,
                       orgList,
                       deptList,
                       handleOnUpdate,
                       handleOnClose
                   }) => {
    // 資料集
    const [dataList, setDataList] = useState([])
    // 所有科別清單
    const [allDivisionList, setAllDivisionList] = useState([])
    // 支援目的選項清單
    const [purposeOptions, setPurposeOptions] = useState([]);
    // 子支援目的選項清單
    const [subPurposeOptions, setSubPurposeOptions] = useState([]);
    // 性質清單
    const [kindList, setKindList] = useState([])

    /** 支援人員欄位 */
        // 員工/醫師
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo)
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName)
    // 執登科別編號
    const [divNo, setDivNo] = useState(data.divNo)
    // 執登科別名稱
    const [divName, setDivName] = useState(data.divName)
    // 證書字號
    const [licenceId, setLicenceId] = useState(data.licenceId)
    // 執登機構編號
    const [licenceKind, setLicenceKind] = useState(data.licenceKind)
    // 執登機構名稱
    const [licenceKindName, setLicenceKindName] = useState(data.licenceKindName)
    // 職類別
    const [hospNo, setHospNo] = useState(data.hospNo)
    // 職類別名稱
    const [hospName, setHospName] = useState(data.hospName)

    /** 報備支援內容欄位 */
        // 機構Pkey
    const [supportOrgPkey, setSupportOrgPkey] = useState(data.supportOrgPkey ? data.supportOrgPkey : '')
    // 機構字串
    const [supportOrgStr, setSupportOrgStr] = useState('')
    // 機構代碼/名稱
    const [supportOrgNo, setSupportOrgNo] = useState(data.supportOrgNo ? data.supportOrgNo : '')
    // 機構名稱
    const [supportOrgName, setSupportOrgName] = useState(data.supportOrgName ? data.supportOrgName : '')
    // 申請單號
    const [supportNo, setSupportNo] = useState('')
    // 支援科別字串
    const [supportDivStr, setSupportDivStr] = useState('')
    // 支援科別
    const [supportDivNo, setSupportDivNo] = useState(data.supportDivNo ? data.supportDivNo : '')
    // 支援科別名稱
    const [supportDivName, setSupportDivName] = useState(data ? data.supportDivName : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode.split(',').filter(Boolean) : [])
    // 支援目的門住
    const [purposeCodeExt, setPurposeCodeExt] = useState(!stringIsEmpty(data.purposeCodeExt) ? data.purposeCodeExt : '1')
    // 承辦部門字串
    const [ownerDeptStr, setOwnerDeptStr] = useState('')
    // 承辦部門代號
    const [ownerDeptNo, setOwnerDeptNo] = useState(data ? data.ownerDeptNo : '');
    // 承辦部門名稱
    const [ownerDeptName, setOwnerDeptName] = useState(data ? data.ownerDeptName : '');
    // 代理人字串
    const [agentUserStr, setAgentUserStr] = useState('')
    // 代理人員代號
    const [agentUserNo, setAgentUserNo] = useState(data.agentUserNo ? data.agentUserNo : '')
    // 代理人員姓名
    const [agentUserName, setAgentUserName] = useState(data.agentUserName ? data.agentUserName : '')
    // 性質
    const [supportProperties, setSupportProperties] = useState(data.supportProperties ? data.supportProperties : '')
    // 執行內容
    const [executeContent, setExecuteContent] = useState(data.executeContent ? data.executeContent : '')
    // 備註
    const [memo, setMemo] = useState(data.memo ? data.memo : '')
    // 支援起始日期
    const [itemStartDate, setItemStartDate] = useState(data.itemStartDate ? data.itemStartDate : '')
    // 支援起始時間
    const [itemStartTime, setItemStartTime] = useState(data.itemStartTime ? data.itemStartTime : '')
    // 支援結束日期
    const [itemEndDate, setItemEndDate] = useState(data.itemEndDate ? data.itemEndDate : '')
    // 支援結束時間
    const [itemEndTime, setItemEndTime] = useState(data.itemEndTime ? data.itemEndTime : '')
    // 登入院區代號
    const [zone, setZone] = useState(data.zone ? data.zone : '')
    // 顯示醫師人員搜尋彈窗
    const [showDoctorPopup, setShowDoctorPopup] = useState(false)
    // 顯示支援機構彈窗
    const [showOrgDropdown, setShowOrgDropdown] = useState(false)

    // 使用上下文
    const {
        setShowSupportDateChoice,
        showSupportDateChoice,
        focusItem,
        setFocusItem,
        showToast,
    } = usePopup()

    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })
    // 刪除彈窗開關
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // 代理人員清單
    const [agentUserList, setAgentUserList] = useState([])

    /**
     * 渲染性質項目
     * @return {any | string}
     */
    const validSupportProperties = kindList.some(item => item.codeNo === supportProperties) ? supportProperties : "";

    /**
     * 建構送出資料
     * @return {Array<Object>} 送出資料
     */
    const getSendData = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請新增報備支援內容', type: AlertTypeEnum.Error})
        } else {
            // 根據支援明細資料產生對應比數的資料
            const sendData = dataList.map(item => {
                // 取得機構物件
                const org = orgList.find(o => {
                    if (o.orgNo === supportOrgNo) return o
                    else if (o.orgPkey === supportOrgPkey) return o
                })
                if (org) {
                    return {
                        // 申請單號
                        supportNo: '',
                        // 變更版號
                        itemVersion: '1',
                        // 支援醫師編號
                        supportUserNo: supportUserNo,
                        // 支援機構Pkey
                        supportOrgPkey: org.orgPkey,
                        // 支援機構名稱
                        supportOrgName: supportOrgName,
                        // 支援科別代號
                        supportDivNo: supportDivNo,
                        // 支援科別名稱
                        supportDivName: supportDivName,
                        // 支援目的代碼
                        purposeCode: Array.isArray(purposeCode) ? purposeCode.join(',') : purposeCode, // 是否為Array，若是則轉換為字串
                        // 支援目的說明
                        purposeCodeExt: purposeCodeExt,
                        // 支援起始日期
                        itemStartDate: `${item.startDate} 00:00:00`,
                        // 支援起始時間
                        itemStartTime: item.startTime.includes(':') ? item.startTime.split(':')[0] + item.startTime.split(':')[1] : item.startTime,
                        // 支援結束日期
                        itemEndDate: `${item.endDate} 00:00:00`,
                        // 支援結束時間
                        itemEndTime: item.endTime.includes(':') ? item.endTime.split(':')[0] + item.endTime.split(':')[1] : item.endTime,
                        // 支援性質
                        supportProperties: supportProperties,
                        // 執行內容
                        executeContent: executeContent,
                        // 備註
                        memo: memo,
                        // 代理人員編號
                        agentUserNo: agentUserNo,
                        // 狀態(1申請/2確認/3送審/9失效)
                        stepStatus: RsisStepStatusEnum.Apply,
                        // 變更/註銷狀態(null空:無/M: 變更/D:刪除)
                        supportModifyStatus: '',
                        // 變更/註銷日期
                        supportModifyDatetime: '',
                        // 變更/註銷原因代號
                        supportModifyReasonCode: '',
                        // 變更/註銷原因說明
                        supportModifyReasonDoc: "",
                        // 匯出編號
                        sendFileName: '',
                        // 衛福部案號
                        sendCaseNo: '',
                        // 送審日期
                        sendDatetime: '',
                        // 申請方式(N:一般 / E:急件申請)
                        applyType: 'N',
                        // 院區
                        zone: getLocalStorage('campus'),
                        // 有效否0/1
                        effFlag: true,
                        // 承辦部門代號
                        ownerDeptNo: ownerDeptNo,
                        // 欄位變更說明, 以中文標記欄位名稱,欄位間用","區隔
                        changeFieldsDoc: '',
                        // 收案確認人員代號
                        confirmUserNo: '',
                        // 收案確認日期時間
                        confirmDatetime: ''
                    }
                }
            })
            return sendData
        }
    }

    /**
     * 支援科別變更時
     * @param item {string} 選取的支援科別
     * @return {void}
     */
    const handleSupportDivOnChange = (item) => {
        // 設定支援科別顯示字串
        setSupportDivStr(item)
        // 設定支援科別編號
        setSupportDivNo(item.split(' ')[0])
        // 設定支援科別名稱
        setSupportDivName(item.split(' ')[1])
    }

    /**
     * 新增報備支援內容
     * @return {void}
     */
    const handleFormSave = () => {
        // 當支援明細資料為空時，顯示錯誤訊息
        if (dataList.length === 0) {
            showToast({message: '請增加支援期間時間', type: AlertTypeEnum.Warning})
            return//
        } else if (supportUserNo === data.supportUserNo // 當員工/醫師代碼相同時，不得新增同樣的支援期間
            && itemStartDate === data.itemStartDate.split(' ')[0]
            && itemEndDate === data.itemEndDate.split(' ')[0]) {
            showToast({message: '當員工/醫師代碼相同時，不得新增同樣的支援期間', type: AlertTypeEnum.Warning})
            return
        }

        // 檢查支援機構是否有值
        if (!supportOrgNo || supportOrgNo === '') {
            showToast({
                message: `支援者：${supportUserName}，「支援機構」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        // 檢查支援科別是否有值
        if (!supportDivNo || supportDivNo === '') {
            showToast({
                message: `支援者：${supportUserName}，「支援科別」為必填欄位，請重新確認！`,
                type: AlertTypeEnum.Warning
            })
            return
        }

        const sendData = getSendData()
        saveAddMaster(sendData)
    }

    /**
     * 承辦部門變更時
     * @param item {string} 選取的承辦部門
     * @return {void}
     */
    const handleOwnerDeptOnChange = (item) => {
        // 設定承辦部門顯示字串
        setOwnerDeptStr(item)
        // 設定承辦部門編號
        setOwnerDeptNo(item.split(' ')[0])
        // 設定承辦部門名稱
        setOwnerDeptName(item.split(' ')[1])
    }

    /**
     * 代理人員變更時
     * @param item {string} 選取的代理人員
     * @return {void}
     */
    const handleAgentUserNoChange = (item) => {
        // 設定代理人員顯示字串
        setAgentUserStr(item)
        // 設定代理人員編號
        setAgentUserNo(item.split(' ')[0])
        // 設定代理人員名稱
        setAgentUserName(item.split(' ')[1])
    }

    /**
     * 儲存修改後的報備支援內容
     * @param data {Array<Object>} 報備支援內容
     * @return {void}
     */
    const saveAddMaster = (data) => {
        // 執行儲存報備支援內容
        rsisAddMaster(data).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '新增成功', type: AlertTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 更新列表項目
     * @param data {Array<Object>} 更新的列表資料
     * @return {void}
     */
    const handleUpdateDataList = (data) => {
        // 設定dataList
        setDataList(data)
    }

    /**
     * 刪除列表項目
     * @return {void}
     */
    const deleteRow = (item) => {
        // 刪除指定列表項目
        setDataList(dataList.filter((data) => data !== item))
        // 關閉刪除彈窗
        setShowDeletePopup(false)
        // 重置focusItem
        setFocusItem(null)
    }

    /**
     * 選取員工/醫師代碼選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        // 取得醫師清單
        getDoctorData(item.userNo)
        // 取得代理人員
        getAgentUserList(item.userNo)
        // 設定支援醫師代碼
        setSupportUserNo(item.userNo)
        //設定支援醫師名稱
        setSupportUserName(item.userName)
        //關閉彈窗
        setShowDoctorPopup(false)
    }

    /**
     * 支援機構選取時
     * @param item {Object} 選取的機構
     * @return {void}
     */
    const handleOrgOptionOnClick = (item) => {
        // 設定支援機構代碼
        setSupportOrgNo(item.orgNo)
        // 設定支援機構名稱
        setSupportOrgName(item.orgName)
        // 關閉彈窗
        setShowOrgDropdown(false)
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        // 過濾重複的選項
        const uniquePurposeOptions = Array.from(new Set(purposeOptions.map(item => item.codeValue1)))
            .filter(codeValue1 => codeValue1 != null)
            .map(codeValue1 => purposeOptions.find(item => item.codeValue1 === codeValue1))
            .filter(Boolean)
            .sort((a, b) => parseInt(a.codeNo) - parseInt(b.codeNo)); // 根據 codeNo 排序

        // 處理支援目的選項變更
        const handlePurposeChange = (codeNo) => {
            setPurposeCode(prevCodes => {
                if (prevCodes.includes(codeNo)) {
                    return prevCodes.filter(code => code !== codeNo);
                } else {
                    return [...prevCodes, codeNo];
                }
            });
        };

        return (
            <div className="grid grid-cols-5 gap-1 w-full">
                {uniquePurposeOptions.map((item, index) => {
                    if (item.codeNo == null) {
                        return null; // 如果 codeNo 為 null，不渲染任何內容
                    }

                    if (item.codeNo === '10') {
                        return (
                            <div key={index} className="flex flex-row items-center gap-2 col-span-1">
                                <Checkbox
                                    key={item.codeNo}
                                    label={item.codeValue1}
                                    checked={purposeCode.includes(item.codeNo)}
                                    onChange={() => handlePurposeChange(item.codeNo)}
                                />
                                <Select
                                    data={{
                                        label: '',
                                        options: subPurposeOptions.map((item) => ({
                                            value: item.codeNo,
                                            label: item.codeValue1
                                        }))
                                    }}
                                    value={purposeCodeExt}
                                    onChange={setPurposeCodeExt}
                                    width={SelectWidthEnum.Tiny}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <Checkbox
                                key={item.codeNo}
                                label={item.codeValue1}
                                checked={purposeCode.includes(item.codeNo)}
                                onChange={() => handlePurposeChange(item.codeNo)}
                            />
                        );
                    }
                })}
            </div>
        );
    };

    /**
     * 依照員工/醫師代碼取得代理人員代號姓名
     * @param userNo {string} 員工/醫師代碼
     * @return {void}
     */
    const getAgentUserList = (userNo) => {
        // 根據員工/醫師代碼查詢代理人員代號姓名
        rsisQueryAgentByUserNo({
            userNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setAgentUserList(data)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 取得codeType為PURPOSE的資料，支援目的
                const purposeData = data.filter(item => item.codeType === 'PURPOSE')
                // 取得codeType為SUBPURPOSE的資料，子支援目的(門柱)
                const subPurposeData = data.filter(item => item.codeType === 'SUBPURPOSE')
                // 取得codeType為KIND的資料，性質
                const natureData = data.filter(item => item.codeType === 'KIND')
                // 設定支援目的選項
                setPurposeOptions(purposeData)
                // 設定子支援目的選項
                setSubPurposeOptions(subPurposeData)
                // 設定性質清單
                setKindList(natureData)
            } else {
                setPurposeOptions([])
                setSubPurposeOptions([])
                setKindList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師執登資料
     * @param userNo {string} 醫師代碼
     * @retunr {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 若有執登資料，則設定執登資料
                if (data) {
                    // 設定執登科別編號
                    setDivNo(data.divNo)
                    // 設定執登科別名稱
                    setDivName(data.divName)
                    // 設定證書字號
                    setLicenceId(data.licenceId)
                    // 設定執類別
                    setLicenceKind(data.licenceKind)
                    // 設定執類別名稱
                    setLicenceKindName(data.licenceKindName)
                    // 設定執登機構代號
                    setHospNo(data.hospNo)
                    // 設定執登機構名稱
                    setHospName(data.hospName)
                } else {// 若無執登資料，則設定執豋資料為空
                    setDivNo('')
                    setDivName('')
                    setLicenceId('')
                    setLicenceKind('')
                    setLicenceKindName('')
                    setHospNo('')
                    setHospName('')
                }
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得所有科別
     * @return {void}
     */
    const getAllDivision = () => {
        rsisQueryBaseData({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援科別清單
                setAllDivisionList(data.supportDivList)
            } else {
                setAllDivisionList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設支援科別
     * @return {Object|null} 支援科別資料
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = allDivisionList?.find(option => supportDivNo?.includes(option?.supportDivNo || ''))
            return data && supportDivNo ? data : null
        }, [allDivisionList, supportDivNo]
    )

    /**
     * 取得預設部門值
     * @return {Object|null} 部門資料
     */
    const getDefaultDepNoValue = useMemo(() => {
            const data = deptList?.find(option => ownerDeptNo?.includes(option?.depNo || ''))
            return data && ownerDeptNo ? data : null
        }, [deptList, ownerDeptNo]
    )

    /**
     * 取得預設代理人員值
     * @return {Object|null} 代理人員資料
     */
    const getDefaultAgentUserValue = useMemo(() => {
            const data = agentUserList?.find(option => agentUserNo?.includes(option?.agentUserNo || ''))
            return data && agentUserNo ? data : null
        }, [agentUserList, agentUserNo]
    )

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        // 取得所有科別
        getAllDivision()
        // 設定報備支援資料
        if (!objectIsEmpty(data)) {
            // 設定承辦部門顯示字串
            setOwnerDeptStr(`${ownerDeptNo} ${ownerDeptName}`)
            // 設定支援科別顯示字串
            setSupportDivStr(`${supportDivNo} ${supportDivName}`)
            // 設定支援機構顯示字串
            setSupportOrgStr(`${supportOrgNo} ${supportOrgName}`)
            // 設定代理人員顯示字串
            setAgentUserStr(`${agentUserNo} ${agentUserName}`)
            // 取得醫師執登資料
            getDoctorData(data.supportUserNo)
            // 取得支援日期資料
            if (!objectIsEmpty(data)) {
                const newDataItems = [{
                    ...data,
                    // 將支援日期資料轉為符合規範的顯示格式
                    startDate: time2String(data.itemStartDate, 'YYYY-MM-DD'),
                    endDate: time2String(data.itemEndDate, 'YYYY-MM-DD'),
                    startTime: data.itemStartTime,
                    endTime: data.itemEndTime,
                }]
                setDataList(newDataItems)
            }
        }
        // 取得支援目的選項
        getPurposeOptions()
        // 取得代理人員清單
        getAgentUserList(data.supportUserNo)
    }, [])

    /**
     * 監聽當支援期間明細日期資料更新時
     * @param dataList {Array<Object>} 支援期間明細日期資料
     * @return {void}
     */
    useEffect(() => {
        if (dataList.length > 0) {
            // 設定支援期間明細起始日期資料
            setItemStartDate(dataList[0].startDate)
            // 設定支援期間明細結束日期資料
            setItemEndDate(dataList[0].endDate)
            // 設定支援期間明細起始時間資料
            setItemStartTime(dataList[0].startTime)
            // 設定支援期間明細結束時間資料
            setItemEndTime(dataList[0].endTime)
        } else {
            setItemStartDate('')
            setItemEndDate('')
            setItemStartTime('')
            setItemEndTime('')
        }
    }, [dataList])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.CopyModal')}
            content={
                <div className='px-6'>
                    <div className='flex flex-row justify-between w-full gap-2'>
                        <div>
                            <Field label='員工/醫師代碼'>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={`${supportUserNo} ${supportUserName}`}
                                    onClick={() => setShowDoctorPopup(true)}
                                />
                            </Field>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <Select
                                data={{
                                    label: '院區',
                                    options: [{
                                        value: getLocalStorage('campus'),
                                        label: t(`campus.${getLocalStorage('campus')}`)
                                    }]
                                }}
                                value={getLocalStorage('campus')}
                                disabled={true}
                                width={SelectWidthEnum.Small}
                                displayEmpty={true}
                                notched={true}
                            />
                            <span
                                className='flex items-center text-[#38A169]'>{`${t('RSIS.StepStatusEnum.1')}狀態`}</span>
                        </div>
                    </div>
                    {/* 第二列資訊 */}
                    <div className='flex flex-row justify-between'>
                        <div className='grid grid-cols-4'>
                            {/* 執登機構 */}
                            <Field label="執登機構">
                                {!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                            </Field>
                            {/* 職類別 */}
                            <Field label="職類別">
                                {!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                            </Field>
                            {/* 執登科別 */}
                            <Field label="執登科別">
                                {!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                            </Field>
                            {/* 證書字號 */}
                            <Field label="證書字號">
                                {!stringIsEmpty(licenceId) ? licenceId : ''}
                            </Field>
                        </div>
                        {/* 刪除、存檔按鈕 */}
                        <div className='flex flex-row gap-2'>
                            <div className='flex flex-row items-center'>
                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                        size={ButtonSizeEnum.Medium} text={t('general.delete')} disabled={true}
                                        onClick={() => {
                                        }}/>
                            </div>
                            <div className='flex my-2'>
                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                        size={ButtonSizeEnum.Medium} text={t('general.save')}
                                        onClick={handleFormSave}/>
                            </div>
                        </div>
                    </div>
                    <Card title={"報備支援內容"}>
                        {/* 報備支援內容 */}
                        <div className='flex flex-col mb-6'>
                            <div className='w-full flex flex-col gap-2'>
                                <div className='flex justify-between items-center'>
                                    <Field label="機構代碼/名稱">
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.XLarge}
                                            value={`${supportOrgNo} ${supportOrgName}`}
                                            onClick={() => setShowOrgDropdown(true)}
                                        />
                                    </Field>
                                    <Field label="申請單號">
                                        {supportNo}
                                    </Field>
                                </div>
                                <Field label={"支援科別"}>
                                    <Search
                                        freeSolo
                                        value={getDefaultDivNoValue}
                                        onChange={(_e, value) => {
                                            const newValue = value?.supportDivNo ? `${value?.supportDivNo} ${value?.supportDivName}` : ''
                                            handleSupportDivOnChange(newValue)
                                        }}
                                        filterOptions={(options, {inputValue}) => {
                                            if (!inputValue) {
                                                return getDefaultDivNoValue || options
                                            }
                                            return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                        }}
                                        options={allDivisionList}
                                        getOptionLabel={(option) => `${option.supportDivNo} ${option.supportDivName}`}
                                    />
                                </Field>
                                <Field label="支援目的" sx={{display: 'flex', alignItems: 'start'}}>
                                    {renderPurposeOptions()}
                                </Field>
                            </div>
                        </div>
                        {/* 分隔線 */}
                        <Divider/>
                        {/* 第二列表 */}
                        <div className='flex flex-col gap-2 my-4 mb-4'>
                            <div className='flex flex-row items-center justify-between gap-4'>
                                <div className='flex flex-row items-center gap-4'>
                                    <Field label={"承辦部門"}>
                                        <Search
                                            freeSolo
                                            value={getDefaultDepNoValue}
                                            onChange={(_e, value) => {
                                                const newValue = value?.deptNo ? `${value?.deptNo} ${value?.deptName}` : ''
                                                handleOwnerDeptOnChange(newValue)
                                            }}
                                            filterOptions={(options, {inputValue}) => {
                                                if (!inputValue) {
                                                    return getDefaultDepNoValue || options
                                                }
                                                return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                            }}
                                            options={deptList}
                                            getOptionLabel={(option) => `${option.deptNo} ${option.deptName}`}
                                        />
                                    </Field>

                                    <Field label={'代理人員代號/姓名'} labelWidth={150}>
                                        <Search
                                            freeSolo
                                            value={getDefaultAgentUserValue}
                                            onChange={(_e, value) => {
                                                const newValue = value?.agentUserNo ? `${value?.agentUserNo} ${value?.agentUserName}` : ''
                                                handleAgentUserNoChange(newValue)
                                            }}
                                            filterOptions={(options, {inputValue}) => {
                                                if (!inputValue) {
                                                    return getDefaultAgentUserValue || options
                                                }
                                                return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                            }}
                                            options={agentUserList}
                                            getOptionLabel={(option) => `${option.agentUserNo} ${option.agentUserName}`}
                                        />
                                    </Field>
                                </div>
                            </div>
                            <Field label="性質">
                                <Select
                                    data={{
                                        label: '性質',
                                        options: [
                                            ...kindList?.map((item) => ({
                                                label: item.codeValue1,
                                                value: item.codeNo
                                            }))
                                        ]
                                    }}
                                    value={validSupportProperties}
                                    onChange={setSupportProperties}
                                    showLabel={false}
                                    width={SelectWidthEnum.XLarge}
                                />
                            </Field>
                            <Field label="執行內容">
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={executeContent}
                                    onChange={(e) => setExecuteContent(e.target.value)}
                                />
                            </Field>
                            <Field label="備註">
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={memo}
                                    onChange={(e) => setMemo(e.target.value)}
                                    disabled={data.role !== USER_ROLE.DEPT_USER}
                                />
                            </Field>
                        </div>
                        {/* 分隔線 */}
                        <Divider/>
                        <div className='flex flex-row justify-start my-2'>
                            <p className='px-2 py-[10px] text-[#71717A] text-sm'>支援期間明細</p>
                            <span>
                                    <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium} text={t('general.edit')}
                                            onClick={() => setShowSupportDateChoice(true)}/>
                                </span>
                        </div>
                        <CustomTable
                            width="1653px"
                            advancedSearch={true}
                            fields={fields}
                            dataList={dataList}
                            ListItem={(props) => (
                                <ListItem
                                    {...props}
                                    handleOnCloseDeletePopup={() => setShowDeletePopup(true)}
                                />
                            )}
                            type={"RSISAddModalTable"}
                            paginationProps={paginationProps}
                        />
                    </Card>
                    {/* 刪除彈窗 */}
                    {showDeletePopup && (
                        <DeletePopup
                            handleDeletePopupClose={() => setShowDeletePopup(false)}
                            handleNotificationDelete={deleteRow}
                            currentKey={focusItem}
                        />
                    )}
                    {/* 報備支援-支援日期時間選擇 */}
                    {showSupportDateChoice && (
                        <SupportDateChoice
                            userName={supportUserName}
                            data={dataList}
                            supportUserNo={supportUserNo}
                            handleUpdateDataList={handleUpdateDataList}
                            handleOnClose={() => setShowSupportDateChoice(false)}/>
                    )}
                    {/* 醫師搜尋彈窗 */}
                    {showDoctorPopup && (
                        <QueryPersonList
                            filterEmp={true}
                            doctorList={doctorList}
                            handleOnClose={() => setShowDoctorPopup(false)}
                            handleOnUpdate={handleDoctorOptionOnClick}
                        />
                    )}
                    {/* 支援機構彈窗 */}
                    {showOrgDropdown && (
                        <QuerySupportOrg
                            filterOrg={true}
                            orgList={orgList}
                            handleOnClose={() => setShowOrgDropdown(false)}
                            handleOnUpdate={handleOrgOptionOnClick}
                        />
                    )}
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '1653px'}}
            onClose={handleOnClose}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default CopyModal

import React, {useMemo, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {usePopup} from '../PopupProvider'
import ListItem from './ListItem'
import {isOwnerUser, Layout} from '../utils'
import DeletePopup from '../Popup/DeletePopup'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    convertMinGuoToGregorian,
    getLocalStorage,
    RsisStepStatusEnum,
    stringIsEmpty,
    time2String,
    uploadExcel2Data,
    validDateFormat
} from "edah_utils/dist"
import {
    rsisAddMaster,
    rsisCheckTimeValid,
    rsisQueryBaseData,
    rsisQueryOrganization,
    rsisQueryOwnerUser
} from "../../../api/v1/RSIS"
import EditModal from "../Modal/EditModal"
import {USER_ROLE} from "../userRole"
import {t} from "i18next"
import {zoneSetQueryAll} from "../../../api/v1/Menu"
import {AlertTypeEnum, Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum} from 'edah-component/dist'

/**
 * 報備支援-整批匯入作業
 * @return {JSX.Element}
 * */
const ImportResource = () => {
    // 匯入Excel種類
    const IMPORT_TYPE_ENUMS = {
        /**
         * 一般整批
         */
        GENERAL: 0,
        /**
         * 規則性
         */
        REGULARITY: 1,
    }
    // 欄位陣列
    const fields = [
        ['申請單號', '院區名稱'],
        ["申請人代號", '姓名'],
        ['支援醫師代號', '名稱'],
        ['支援機構代號', '名稱'],
        ['支援科別代號', '名稱'],
        "開始日期",
        "開始時間",
        "結束日期",
        "結束時間",
        "狀態別",
        "衛福部案號版次",
        "報備為一般目的",
        "報備為負責人",
        "報備為糖尿病共同維護",
        "整合式健康檢查",
        "預防針接種",
        "備註"
    ]
    //取得分院機構清單
    const [zoneSetList, setZoneSetList] = useState([])
    //取得當前院區
    const currentZone = getLocalStorage('campus')
    // 當前匯入的Excel種類
    const [importType, setImportType] = useState(IMPORT_TYPE_ENUMS.GENERAL)
    // 使用角色
    const [userRole, setUserRole] = useState(USER_ROLE.DEPT_USER)
    // 匯入Excel資料集
    const [dataList, setDataList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 承辦部門清單
    const [deptList, setDeptList] = useState([])
    // 承辦人員清單
    const [ownerList, setOwnerList] = useState([])

    // 機構清單
    const [organizationList, setOrganizationList] = useState([])

    // 分頁組件
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })
    // 列表組件
    const List = ListItem
    // 使用上下文
    const {
        showEditModal,
        setShowEditModal,
        showDeletePopup,
        setShowDeletePopup,
        focusItem,
        setFocusItem,
        excelFocusIndex,
        showToast,
    } = usePopup()

    /**
     * 解析工作日
     * @param v {String} 工作日
     * @return {Number}
     */
    const getDayNumber = (v) => ({
        '星期一': 1,
        '星期二': 2,
        '星期三': 3,
        '星期四': 4,
        '星期五': 5,
        '星期六': 6,
        '星期日': 7,
    }[v])

    /**
     * 取得終止日期與時間
     * @param endDate {String|null} 終止日期
     * @param endTime {String|null} 終止時間
     * @return {Object}
     */
    const getEndDateAndTime = (endDate, endTime) => {
        //取得結束時間
        endTime = endTime ? endTime : ''
        //取得終止時間
        endDate = endDate ? `${convertMinGuoToGregorian(endDate)} 00:00:00` : ''

        //是否超過2400
        if (endDate && endTime && endTime === '2400') {
            //隔天
            const newDate = new Date(endDate)
            newDate.setDate(newDate.getDate() + 1)
            //將終止日期設定為隔天
            endDate = `${time2String(newDate, 'YYYY-MM-DD')} 00:00:00`
            //將終止時間設定為隔天0000
            endTime = '0000'
        }

        return {
            endTime: endTime,
            endDate: endDate
        }
    }

    /**
     * 檢查必填欄位
     * @param index {Number} 索引
     * @param rowData {Array<Object>} 資料
     * @param type {Number} 種類
     * @return {String|null}
     */
    const validRequiredFields = (index, rowData, type) => {
        // Excel行數
        const line = `Excel第${index + 3}行`

        // 支援者
        const currentUser = rowData[1]

        //支援機構代碼是否不存在
        if (!rowData[8]) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "「支援機構代碼」為必填欄位，請重新確認!"
            })
        }

        //支援醫事機構名稱是否不存在
        if (!rowData[9]) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "「支援醫事機構名稱」為必填欄位，請重新確認!"
            })
        }

        //支援科別名稱是否不存在
        if (!rowData[6]) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "「支援科別名稱」為必填欄位，請重新確認!"
            })
        }

        //支援科別代碼是否不存在
        if (!rowData[7]) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "「支援科別代碼」為必填欄位，請重新確認!"
            })
        }

        //支援開始日期
        const supportStartDate = rowData[2]
        //支援結束日期
        const supportEndDate = rowData[3]

        // 支援者：XXX，支援日期不符合，請重新填寫!
        if (
            //支援開始日期為空或日期不正確
            (!supportStartDate || !validDateFormat(convertMinGuoToGregorian(supportStartDate))) ||
            //支援結束日期為空或日期不正確
            (!supportEndDate || !validDateFormat(convertMinGuoToGregorian(supportEndDate)))
        ) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "支援日期不符合，請重新填寫!"
            })
        }

        /**
         * 檢查24小時時間格式是否正確
         * @param time {String}
         * @return {boolean}
         */
        const isValid24HourTime = (time) => /^(?:[01]\d|2[0-3]|24)([0-5]\d)$/.test(time)

        //支援開始時間
        const supportStartTime = rowData[4]
        //支援結束時間
        const supportEndTime = rowData[5]

        // 支援者：XXX，支援日期不符合，請重新填寫!
        if (
            //支援開始時間為空或時間格式限制為0000-2400內
            // console.log(isValid24HourTime("1430")); // true
            // console.log(isValid24HourTime("2359")); // true
            // console.log(isValid24HourTime("2400")); // true
            // console.log(isValid24HourTime("1260")); // false
            // console.log(isValid24HourTime("0930"));  // true
            // console.log(isValid24HourTime("2401")); // false
            (!supportStartTime || !isValid24HourTime(supportStartTime)) ||
            //支援結束時間為空或時間格式限制為0000-2400內
            (!supportEndTime || !isValid24HourTime(supportEndTime))
        ) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "請填入完整的支援時間!"
            })
        }

        /**
         * 格式化時間
         * @param time {String}
         * @return {String}
         */
        const formatTime = (time) => stringIsEmpty(time) ? '' : `${time.slice(0, 2)}:${time.slice(2)}:00`

        // 支援開始日期與時間
        const startDateTime = new Date(`${convertMinGuoToGregorian(rowData[2])} ${formatTime(rowData[4])}`)
        // 支援終止日期與時間
        let endDateTime = getEndDateAndTime(rowData[3], rowData[5])
        endDateTime = new Date(`${endDateTime.endDate} ${formatTime(endDateTime.endTime)}`)

        // 開始時間小於終止時間時
        // 支援者：XXX，支援時間不符合，請重新填寫! 如需選擇半夜12點，請點選24:00!
        if (startDateTime.getTime() <= endDateTime.getTime()) {
            return t('RSIS.Page.ImportResource.Tips.Wrong', {
                //支援者
                currentUser: currentUser,
                // Excel行數
                line: line,
                // 訊息
                message: "支援時間不符合，請重新填寫! 如需選擇半夜12點，請點選24:00!"
            })
        }

        //機構清單大於0
        if (zoneSetList.length > 0) {
            //取得當前院區的物件
            const zoneObj = zoneSetList.find(s => s.zone === currentZone.toUpperCase())
            //錯誤訊息
            const message = `一般性：${line}，請重新確認機構代碼，或請確認登入院所是否正確!`
            //院區物件存在的話
            if (zoneObj) {
                // 報備支援機構代碼不得與當前登入的院區支援機構代碼相同，也不得與支援機構名稱相同
                if (rowData[8] === zoneObj.zoneNo || rowData[9].indexOf(zoneObj.zoneName) > -1) {
                    return message
                }
            } else { //該報備支援機構不符合當前的機構代碼
                return message
            }
        }

        //至少一項為 Y，如果全部都沒有則顯示 "第 XXX 列，請設定至少一項報備支援目的"
        const is5N = type === IMPORT_TYPE_ENUMS.GENERAL ?
            //一般匯入
            [...rowData].slice(10, 15).join('').length === 5 && [...rowData].slice(10, 15).every(char => char.toUpperCase() === 'N') :
            //整批匯入
            [...rowData].slice(12, 17).join('').length === 5 && [...rowData].slice(12, 17).every(char => char.toUpperCase() === 'N')

        //如果都沒有填寫預設選項的話
        if (is5N) {
            return `${line}，請設定至少一項報備支援目的!`
        }

        // 當天日期
        const currentDate = new Date(`${time2String(new Date(), 'YYYY-MM-DD')} 00:00:00`)
        // 支援起始日期
        const supportStartDateTime = new Date(`${convertMinGuoToGregorian(supportStartDate)} 00:00:00`)
        // 支援終止日期
        const supportEndDateTime = new Date(`${convertMinGuoToGregorian(supportEndDate)} 00:00:00`)
        // 當前日期大於支援起始日期或
        // 當前日期大於支援終止日期
        if (currentDate.getTime() > supportStartDateTime.getTime() || currentDate.getTime() > supportEndDateTime.getTime()) {
            return `${line}，支援日不可是過往日期!`
        }

        return null
    }

    /**
     * 匯入規則性整批Excel上傳資料到表格內
     * @return {void}
     */
    const handleOnImportRegularityExcel = () => {
        // 創建一個檔案輸入元素
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.xlsx, .xls'
        //表單名稱
        const sheetName = '規則性整批上傳sample'
        // 為檔案選擇添加事件監聽器
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0]
            if (file) {
                uploadExcel2Data(file, (result) => {
                    // 假設我們只處理 Sheet1 的資料
                    const sheetData = result[sheetName]

                    // 找不到分頁
                    if (!sheetData) {
                        showToast({message: '找不到Excel對應分頁', type: AlertTypeEnum.Error})
                        return
                    }

                    // 取得資料不取標題及第一筆模板
                    const data = sheetData.slice(2)
                    // 沒有資料的話
                    if (data.length === 0) {
                        showToast({message: 'Excel內沒有資料', type: AlertTypeEnum.Error})
                        return
                    }

                    // 取得分院
                    const zone = getLocalStorage('campus')
                    //當前使用者ID
                    const currentUser = getLocalStorage('userno')
                    //錯誤訊息陣列
                    const wrongMsgArray = []
                    // 處理內容資料
                    let processedData = data.map((row, index) => {
                        // 如果第一欄或第二欄為空視為沒有填
                        if (!row[0] || !row[1]) {
                            return
                        }
                        //取得錯誤訊息
                        const msg = validRequiredFields(index, row, IMPORT_TYPE_ENUMS.REGULARITY)
                        //有錯誤訊息的話
                        if (msg) {
                            //將訊息加入陣列
                            wrongMsgArray.push(msg)
                            return
                        }

                        // 映射 Y/N 為 10/20/30/40/50 並過濾空值
                        const filterPurposeCode = []
                        for (let i = 12; i <= 16; i++) {
                            if (!stringIsEmpty(row[i]) && row[i].toUpperCase() === 'Y') {
                                filterPurposeCode.push((i - 12 + 1) * 10)
                            }
                        }

                        //找尋對應的機構名稱
                        const hasOrg = organizationList.some(o => o.orgNo === row[8])
                        // 機構為空時
                        if (!hasOrg) {
                            wrongMsgArray.push("找不到對應的機構代號")
                            return
                        }

                        //取得星期幾
                        const day = getDayNumber(row[10])
                        //取得結束日期與時間
                        const {endTime, endDate} = getEndDateAndTime(row[3], row[5])

                        return {
                            // 申請人
                            applicant: currentUser,
                            supportNo: '',
                            itemVersion: 1,
                            //星期幾要重複
                            day: day,
                            // 支援醫師代號
                            supportUserNo: row[0],
                            // 支援醫師名稱
                            supportUserName: row[1] ? row[1] : '',
                            // 支援機構代號
                            supportOrgNo: row[8] ? row[8] : '',
                            // 支援機構名稱
                            supportOrgName: row[9] ? row[9] : '',
                            // 支援科別代號
                            supportDivNo: row[7] ? row[7] : '',
                            // 支援科別名稱
                            supportDivName: row[6] ? row[6] : '',
                            // 報備支援目的
                            purposeCode: filterPurposeCode.join(','),
                            purposeCodeExt: '',
                            // 開始日期
                            itemStartDate: row[2] ? `${convertMinGuoToGregorian(row[2])} 00:00:00` : '',
                            // 開始時間
                            itemStartTime: row[4] ? row[4] : '',
                            // 結束日期
                            itemEndDate: endDate,
                            // 結束時間
                            itemEndTime: endTime,
                            supportProperties: '',
                            executeContent: '',
                            // 備註
                            memo: row[17] ? row[17] : '',
                            agentUserNo: '',
                            // 狀態別
                            stepStatus: RsisStepStatusEnum.Apply,
                            supportModifyStatus: '',
                            supportModifyDatetime: '',
                            supportModifyReasonCode: '',
                            supportModifyReasonDoc: '',
                            sendFileName: '',
                            // 衛福部案號版次
                            sendCaseNo: '',
                            sendDatetime: '',
                            //申請種類
                            applyType: 'N',
                            // 院區
                            zone: zone,
                            // 有效是否
                            effFlag: 1,
                            ownerDeptNo: '',
                            changeFieldsDoc: '',
                            confirmUserNo: '',
                            confirmDatetime: '',
                        }
                    })

                    // 過濾掉 undefined 的項目
                    processedData = processedData.filter(item => item !== undefined)

                    //錯誤訊息大於0時
                    if (wrongMsgArray.length > 0) {
                        const totalMessage = wrongMsgArray.join('\n')
                        showToast({message: totalMessage, type: AlertTypeEnum.Warning})
                        return
                    }

                    /**
                     * 取得開始到結束時間特定的星期幾日期
                     * @param {String} startDate 開始日期
                     * @param {String} stopDate 結束日期
                     * @param {number} dayOfWeek 目標星期幾 (0: 週日, 1: 週一, ..., 6: 週六)
                     * @return {Array<String>} 符合條件的日期陣列
                     */
                    const getDatesForSpecificDay = (startDate, stopDate, dayOfWeek) => {
                        const dates = []
                        // 起始日期
                        const currentDate = new Date(startDate)
                        // 終止日期
                        const endDate = new Date(stopDate)

                        // 將 currentDate 設定到最近的目標星期幾
                        currentDate.setDate(currentDate.getDate() + (dayOfWeek - currentDate.getDay() + 7) % 7)
                        //當前日期小於終止日期
                        while (currentDate <= endDate) {
                            // 新增進去
                            dates.push(time2String(new Date(currentDate), 'YYYY-MM-DD HH:mm:ss'))
                            // 更新當前日期
                            currentDate.setDate(currentDate.getDate() + 7)
                        }

                        return dates
                    }

                    // 取得根據重覆每週星期幾產生出來的資料
                    const tempData = []
                    processedData.forEach(item => {
                        // 取得產生出來的日期
                        getDatesForSpecificDay(item.itemStartDate, item.itemEndDate, item.day).forEach(day =>
                            tempData.push({
                                ...item,
                                //起始日期
                                itemStartDate: day,
                                //終止日期
                                itemEndDate: day,
                                // 將顯示機構代號轉為PKey值
                                supportOrgPkey: organizationList.find(o => o.orgNo === item.supportOrgNo).orgPkey
                            })
                        )
                    })

                    //設定當前的匯入類型
                    setImportType(IMPORT_TYPE_ENUMS.REGULARITY)

                    // 更新 dataList 狀態
                    setDataList(tempData)

                    // 更新分頁屬性
                    setPaginationProps(prevProps => ({
                        ...prevProps,
                        totalItemSize: processedData.length,
                        totalPageSize: Math.ceil(processedData.length / prevProps.pageSize),
                        currentPageItemSize: Math.min(processedData.length, prevProps.pageSize)
                    }))
                })
            }
            //移除Dom
            fileInput.remove()
        })

        // 觸發檔案輸入點擊
        fileInput.click()
    }

    /**
     * 清空匯入資料按鈕點擊時
     * @return {void}
     */
    const handleCleanFormData = () => setDataList([])

    /**
     * 匯入一般整批Excel資料到表格內
     * @return {void}
     */
    const handleOnImportGeneralExcel = () => {
        // 創建一個檔案輸入元素
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.xlsx, .xls'
        //表單名稱
        const sheetName = '一般整批sample'
        // 為檔案選擇添加事件監聽器
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0]
            if (file) {
                uploadExcel2Data(file, (result) => {
                    // 假設我們只處理 Sheet1 的資料
                    const sheetData = result[sheetName]
                    // 找不到分頁
                    if (!sheetData) {
                        showToast({message: '找不到Excel對應分頁', type: AlertTypeEnum.Error})
                        return
                    }

                    // 取得資料不取標題及第一筆模板
                    const data = sheetData.slice(2)

                    // 沒有資料的話
                    if (data.length === 0) {
                        showToast({message: 'Excel內沒有資料', type: AlertTypeEnum.Error})
                        return
                    }

                    // 取得分院
                    const zone = getLocalStorage('campus')
                    //當前使用者ID
                    const currentUser = getLocalStorage('userno')
                    //錯誤訊息陣列
                    const wrongMsgArray = []

                    // 處理內容資料
                    let processedData = data.map((row, index) => {
                        // 如果第一欄或第二欄為空視為沒有填
                        if (!row[0] || !row[1]) {
                            return
                        }
                        //取得錯誤訊息
                        const msg = validRequiredFields(index, row, IMPORT_TYPE_ENUMS.GENERAL)
                        //有錯誤訊息的話
                        if (msg) {
                            //將訊息加入陣列
                            wrongMsgArray.push(msg)
                            return
                        }

                        // 映射 Y/N 為 10/20/30/40/50 並過濾空值
                        const filterPurposeCode = []
                        for (let i = 10; i <= 14; i++) {
                            if (!stringIsEmpty(row[i]) && row[i].toUpperCase() === 'Y') {
                                filterPurposeCode.push((i - 10 + 1) * 10)
                            }
                        }

                        //找尋對應的機構名稱
                        const hasOrg = organizationList.some(o => o.orgNo === row[8])
                        // 機構為空時
                        if (!hasOrg) {
                            wrongMsgArray.push("找不到對應的機構代號")
                            return
                        }

                        //取得結束日期與時間
                        const {endTime, endDate} = getEndDateAndTime(row[3], row[5])

                        return {
                            // 申請人
                            applicant: currentUser,
                            supportNo: '',
                            itemVersion: 1,
                            // 支援醫師代號
                            supportUserNo: row[0],
                            // 支援醫師名稱
                            supportUserName: row[1] ? row[1] : '',
                            // 支援機構代號
                            supportOrgNo: row[8] ? row[8] : '',
                            // 支援機構名稱
                            supportOrgName: row[9] ? row[9] : '',
                            // 支援科別代號
                            supportDivNo: row[7] ? row[7] : '',
                            // 支援科別名稱
                            supportDivName: row[6] ? row[6] : '',
                            // 報備支援目的
                            purposeCode: filterPurposeCode.join(','),
                            purposeCodeExt: '',
                            // 開始日期
                            itemStartDate: row[2] ? `${convertMinGuoToGregorian(row[2])} 00:00:00` : '',
                            // 開始時間
                            itemStartTime: row[4] ? row[4] : '',
                            // 結束日期
                            itemEndDate: endDate,
                            // 結束時間
                            itemEndTime: endTime,
                            supportProperties: '',
                            executeContent: '',
                            // 備註
                            memo: row[15] ? row[15] : '',
                            agentUserNo: '',
                            // 狀態別
                            stepStatus: RsisStepStatusEnum.Apply,
                            supportModifyStatus: '',
                            supportModifyDatetime: '',
                            supportModifyReasonCode: '',
                            supportModifyReasonDoc: '',
                            sendFileName: '',
                            // 衛福部案號版次
                            sendCaseNo: '',
                            sendDatetime: '',
                            //申請種類
                            applyType: 'N',
                            // 院區
                            zone: zone,
                            // 有效是否
                            effFlag: 1,
                            ownerDeptNo: '',
                            changeFieldsDoc: '',
                            confirmUserNo: '',
                            confirmDatetime: '',
                        }
                    })

                    // 過濾掉 undefined 的項目
                    processedData = processedData.filter(item => item !== undefined)

                    //錯誤訊息大於0時
                    if (wrongMsgArray.length > 0) {
                        const totalMessage = wrongMsgArray.join('\n')
                        showToast({message: totalMessage, type: AlertTypeEnum.Warning})
                        return
                    }


                    //設定當前的匯入類型
                    setImportType(IMPORT_TYPE_ENUMS.GENERAL)

                    // 更新 dataList 狀態
                    setDataList(processedData)

                    // 更新分頁屬性
                    setPaginationProps(prevProps => ({
                        ...prevProps,
                        totalItemSize: processedData.length,
                        totalPageSize: Math.ceil(processedData.length / prevProps.pageSize),
                        currentPageItemSize: Math.min(processedData.length, prevProps.pageSize)
                    }))
                })
            }
            //移除Dom
            fileInput.remove()
        })

        // 觸發檔案輸入點擊
        fileInput.click()
    }

    /**
     * 編輯列表更新時
     * @return {void}
     */
    const handleOnUpdate = (data) => {
        if (data.supportUserNo.toString().indexOf(' ') > 0) {
            // 更新 dataList 過濾掉空白字串及無意義字串
            data.supportUserNo = data.supportUserNo.split(' ')
                .filter(part => part !== 'undefined' && part !== null && part !== '')
                .join(' ');
        } else if (stringIsEmpty(data.supportUserNo.toString())) {
            data.supportUserNo = ''
        }
        data.applicant = getLocalStorage('userno')
        setDataList(prevDataList => {
            const newDataList = [...prevDataList]
            newDataList[excelFocusIndex] = data
            return newDataList
        })
        setShowEditModal(false);
    };

    /**
     * 確定轉入系統
     * @return {void}
     */
    const handleOnSubmit = async () => {
        const list = dataList.map(item => {
            // 創建一個新對象來存儲處理後的數據，新對象不包含applicant申請人
            const {applicant, ...processedItem} = item
            if (item && item.itemStartDate) {
                processedItem.itemStartDate = `${item.itemStartDate} 00:00:00`
            }
            if (item && item.itemEndDate) {
                processedItem.itemEndDate = `${item.itemEndDate} 00:00:00`
            }
            if (item && item.itemStartTime) {
                processedItem.itemStartTime = item.itemStartTime.split(':').join('')
            }
            if (item && item.itemEndTime) {
                processedItem.itemEndTime = item.itemEndTime.split(':').join('')
            }
            return processedItem
        })
        await addMaster()
    }

    /**
     * 刪除列表事件
     * @return {void}
     */
    const handleListItemOnDelete = () => {
        // 比對資料內容刪除對應的資料並返回刪除後的資料集給dataList
        setDataList(prevDataList => {
            return prevDataList.filter(item => {
                // 假設每個項目都有唯一的 supportNo
                return item !== focusItem;
            });
        });

        // 重置 focusItem
        setFocusItem(null);

        // 關閉刪除彈窗
        setShowDeletePopup(false);

        // 更新分頁屬性
        setPaginationProps(prevProps => ({
            ...prevProps,
            totalItemSize: prevProps.totalItemSize - 1,
            totalPageSize: Math.ceil((prevProps.totalItemSize - 1) / prevProps.pageSize),
            currentPageItemSize: Math.min(prevProps.totalItemSize - 1, prevProps.pageSize)
        }));

        // 顯示刪除成功的提示
        showToast({message: '刪除成功', type: AlertTypeEnum.Success});
    }

    /**
     * 報備支援時段檢核
     * @param sendData {Array<Object>}
     * @return {Object}
     */
    const validTime = async (sendData) => {
        // 結果
        const result = {
            //訊息
            message: [],
            //API是否有錯誤
            err: false,
            //錯誤訊息
            errMsg: ''
        }
        /**
         * 取得時間數據
         * @param time {String}
         * @return {String}
         */
        const convertTime = (time) => {
            //時間
            const hours = time.slice(0, 2)
            //分鐘
            const minutes = time.slice(2, 4)

            return time ? `${hours}:${minutes}:00` : '00:00:00'
        }

        //走訪資料
        for (const obj of sendData) {
            // 資料 / 錯誤 / 訊息
            const {data, err, msg} = await rsisCheckTimeValid(obj)

            //API運行成功
            if (err === ApiErrorStatusEnum.Success) {
                data.forEach(item => {
                    const {itemStartDate, itemStartTime, itemEndDate, itemEndTime} = item
                    //當前有時間段重疊問題
                    if (item.overlapFlag) {
                        result.message.push(`${itemStartDate.split(' ')[0]} ${convertTime(itemStartTime)}~${itemEndDate.split(' ')[0]} ${convertTime(itemEndTime)}支援時間段重疊，支援人員：${obj.supportUserName} 已於${itemStartDate.split(' ')[0]} ${convertTime(itemStartTime)}~${itemEndDate.split(' ')[0]} ${convertTime(itemEndTime)}申請報備支援。`)
                    }
                })
            } else { //API錯誤
                return {message: [], err: true, errMsg: msg}
            }
        }

        return result
    }

    /**
     * 新增報備支援
     * @return {void}
     */
    const addMaster = async () => {
        //資料為空
        if (arrayIsEmpty(dataList)) {
            showToast({message: '資料為空', type: AlertTypeEnum.Error})
            return
        }

        // 取得群組化的資料
        let groupedData = dataList.reduce((acc, current) => {
            // 取得支援使用者編號
            const key = current.supportUserNo

            // 如果資料不存在的話
            if (!acc[key]) {
                acc[key] = {
                    // 支援人員代號
                    supportUserNo: key,
                    // 支援人員姓名
                    supportUserName: current.supportUserName,
                    timeList: []
                }
            }

            acc[key].timeList.push({
                // 項目開始日期 yyyy-MM-dd HH:mm:ss
                itemStartDate: current.itemStartDate,
                // 項目結束日期"yyyy-MM-dd HH:mm:ss
                itemEndDate: current.itemEndDate,
                // 開始時間(24HHMM)
                itemStartTime: current.itemStartTime,
                //結束時間(24HHMM)
                itemEndTime: current.itemEndTime
            })

            return acc
        }, {})
        //轉換為array
        groupedData = Object.values(groupedData)
        //訊息 / API是否有錯誤 / 錯誤訊息
        const {message, err, errMsg} = await validTime(groupedData)
        //API發生錯誤
        if (err) {
            // 顯示錯誤訊息
            showToast({message: errMsg, type: AlertTypeEnum.Error})
            return
        }

        //有時間段重疊問題
        if (message.length > 0) {
            // 顯示警示訊息
            showToast({message: message.join('\n'), type: AlertTypeEnum.Warning})
            return
        }

        // 取得要發送的資料
        const sendData = dataList.map(item => ({
            ...item,
            // 將顯示機構代號轉為PKey值
            supportOrgPkey: organizationList.find(o => o.orgNo === item.supportOrgNo).orgPkey
        }))

        if (sendData.length === 0) {
            showToast({message: '傳送資料為空，請檢查規則性整批匯入時間格式是否符合', type: AlertTypeEnum.Warning})
        } else {
            rsisAddMaster(sendData).then(res => {
                // 狀態 / 訊息
                const {err, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '新增成功', type: AlertTypeEnum.Success})
                    //清空資料
                    setDataList([])
                } else {
                    showToast({message: `新增失敗, ${msg}`, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 取得承辦人員
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 過濾清單只取得userName
                const list = data.map(item => item.userNo + ' ' + item.userName)
                // 設定承辦人員清單
                setOwnerList(list)
                setUserRole(isOwnerUser(getLocalStorage('userno'), list) ? USER_ROLE.DEPT_USER : getLocalStorage('userno'))
            } else { // 取得失敗
                // 清空承辦人員清單
                setOwnerList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基本資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        rsisQueryBaseData({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setDoctorList(data.doctorList)
                // 設定承辦部門清單資料
                setDeptList(data.deptList)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 清空承辦部門清單
                setDeptList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得支援機構所有資料
     * @return {void}
     */
    const getOrganization = () => {
        rsisQueryOrganization({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單
                setOrganizationList(data)
            } else {
                // 清空機構清單
                setOrganizationList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得分院機構清單
     * @return {void}
     */
    const getZoneSetQueryAll = () => {
        zoneSetQueryAll({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定機構清單
                setZoneSetList(data)
            } else {
                // 清空機構清單
                setZoneSetList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得基本資料
        getQueryBaseData()
        // 取得承辦人員清單
        getOwnerUserList()
        // 取得支援機構所有資料
        getOrganization()
        //取得分院機構清單
        getZoneSetQueryAll()
    }, [])

    return (
        <Layout>
            <div className='w-full flex flex-col gap-4 px-4'>
                <div className='flex flex-row justify-end items-center mt-2 gap-2'>

                    {/*匯入一般整批Excel*/}
                    <Button text={t('RSIS.Page.ImportResource.ImportGeneralExcel')} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                            onClick={handleOnImportGeneralExcel}/>
                    {/*匯入規則性整批Excel*/}
                    <Button text={t('RSIS.Page.ImportResource.ImportRegularityExcel')} color={ButtonColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                            onClick={handleOnImportRegularityExcel}/>
                    {/*清空匯入資料*/}
                    <Button text={t('RSIS.Page.ImportResource.CleanFormData')} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                            onClick={handleCleanFormData}/>
                    {/*確定轉入系統*/}
                    <Button text='確定轉入系統' color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            onClick={handleOnSubmit}/>
                </div>
                <CustomTable
                    fields={fields}
                    dataList={dataList}
                    ListItem={List}
                    advancedSearch={true}
                    type="RSISImportResourceTable"
                    paginationProps={paginationProps}/>
                {
                    showDeletePopup && (
                        <DeletePopup
                            handleDeletePopupClose={() => setShowDeletePopup(false)}
                            handleNotificationDelete={handleListItemOnDelete}
                            currentKey={focusItem}
                        />
                    )
                }
            </div>
            {
                showEditModal && (
                    <EditModal
                        role={userRole}
                        type='IMPORT'
                        data={focusItem}
                        doctorList={doctorList}
                        deptList={deptList}
                        orgList={organizationList}
                        handleOnClose={() => setShowEditModal(false)}
                        handleOnUpdate={handleOnUpdate}
                    />
                )
            }
        </Layout>
    )
}

export default ImportResource

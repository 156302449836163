import React, {useEffect, useState} from 'react'
import '../../components/TableList/tableList.scss'
import {Pagination} from "../../components/Pagination/Pagination"
import {
    queryCvisNotifyrecById,
    queryCvisNotifyrecByStatus,
    queryCvisNotifyUnit,
    queryCvisRenotify
} from "../../api/v1/Critical"
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, objectIsEmpty} from "edah_utils/dist"
import CvisListItem from "../../components/CVIS/CvisListItem"
import {cvisNotifyUnitAdapter} from "../../components/CVIS/utils"
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    RadioGroup,
    Select,
    SizeEnum,
    Tabs,
    TabsEnum
} from "edah-component/dist";
import {t} from "i18next";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField"

/**
 * 危急值通報閉環監控
 * @return {JSX.Element}
 */
const CrisisAlertClosureMonitor = () => {

    //查詢類型列表
    const queryTypeOptions = [
        {value: '0', label: '全部', id: 'All'},
        {value: '1', label: '未回覆查詢', id: 'unReplySearch'},
        {value: '2', label: 'A級危急值逾時回覆', id: 'LevelATimeoutReply'},
        {value: '3', label: 'A級危急值逾時再通知未回覆', id: 'LevelATimeoutAssignmentUnReply'},
    ]
    // 通報單位選單
    const [unit, setUnit] = useState([])
    // 預設查詢條件
    const [cvisQueryData, setCvisQueryData] = useState({
        cvisNotifyUnit: '',
        cvisNotifyQueryType: '0'
    })

    // 閉環通報查詢結果
    const [showQueryResult, setShowQueryResult] = useState(false)
    // 查詢狀態
    const [queryString, setQueryString] = useState('')
    // 閉環監控查詢資料清單
    const [cvisNotifyRecordList, setCvisNotifyRecordList] = useState([])
    // 過濾閉環監控查詢資料清單
    const [filteredCvisNotifyRecordList, setFilteredCvisNotifyRecordList] = useState([])
    // 彈出視窗
    const [isShowReportData, setIsShowReportData] = useState(false)
    // 彈出視窗頁籤
    const [activeTab, setActiveTab] = useState('ReportData')
    // 彈出視窗-通報資料
    const [popCvisNotifyData, setPopCvisNotifyData] = useState()
    // 彈出視窗-逾時回覆再通知紀錄
    const [popCvisRenotifyList, setPopCvisRenotifyList] = useState([])
    // 彈出視窗-過濾逾時回覆再通知紀錄
    const [filteredPopCvisRenotifyList, setFilteredPopCvisRenotifyList] = useState([])
    // 彈出視窗-逾時回覆搜尋字串
    const [popQueryString, setPopQueryString] = useState('')
    // 列表焦點索引
    const [focusIndex, setFocusIndex] = useState()
    // pagination 控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,   //default
        pageSize: 10, //default
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: ''
    })
    // 逾時回覆再通知紀錄分頁控制變數
    const [renotifyPaginationProps, setRenotifyPaginationProps] = useState({
        pageNum: 1,   //default
        pageSize: 10, //default
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: ''
    })

    /**
     * 通報單位變動事件
     * @param value {String}
     * @returns {void}
     */
    const handleCvisNotifyUnitOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: value
        })
    }

    /**
     * 查詢類型更改事件
     * @param {Event} event - React change event
     */
    const handleQueryTypeOnChange = (event) => {
        const selectedQueryType = event.target.value;
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyQueryType: selectedQueryType,
        });
    };

    /**
     * 控制Query輸入框內的值變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列
     * @param {Object} event
     */
    const handleQueryInputOnChange = (event) => {
        setQueryString(event.target.value)
        setFilteredCvisNotifyRecordList(getFilterData(cvisNotifyRecordList, event.target.value))
    }

    /**
     * 控制Popup輸入框內的值變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列
     * @param {Object} event
     * @returns {void}
     * */
    const handlePopQueryInputOnChange = (event) => {
        setPopQueryString(event.target.value);
        const data = getFilterData(popCvisRenotifyList, event.target.value)
        setFilteredPopCvisRenotifyList(data)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (array, string) => fuzzySearchObjects(array, string)

    /**
     * 閉環監控表格指定頁碼變動事件
     * @param {number} page
     * @returns {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }

    /**
     * 閉環監控表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        queryCvisList(paginationProps.pageNum, newSize);
    }

    /**
     * 閉環監控表格上一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }

    /**
     * 閉環監控表格下一頁按鈕事件
     * @returns {void}
     * */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisList(page, paginationProps.pageSize);
    }

    /**
     * 逾時回覆再通知表格指定頁碼變動事件
     * @param {number} page
     * @returns {void}
     */
    const onRenotifyPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 逾時回覆再通知表格指定每頁筆數變動事件
     * @param {Object} e
     * @returns {void}
     * */
    const onRenotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisRenotify(focusIndex, paginationProps.pageNum, newSize);
    }

    /**
     * 逾時回覆再通知表格上一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }

    /**
     * 逾時回覆再通知表格下一頁按鈕事件
     * @returns {void}
     * */
    const onRenotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisRenotify(focusIndex, page, paginationProps.pageSize);
    }
    /**
     * 取得閉環監控資料
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @returns {void}
     */
    const queryCvisList = (page, pageSize) => {
        queryCvisNotifyrecByStatus({
            cvisNotifyUnit: cvisQueryData.cvisNotifyUnit,
            cvisNotifyQueryType: cvisQueryData.cvisNotifyQueryType,
            pageNum: page,
            pageSize: pageSize
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                setCvisNotifyRecordList(data)
                setFilteredCvisNotifyRecordList(data)
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.dataList.length,
                    pageNum: page,
                    pageSize: pageSize
                })
                setShowQueryResult(true)
            } else {
                if (res.err === 1) {
                    setCvisNotifyRecordList([])
                    setFilteredCvisNotifyRecordList([])
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: 0,
                        totalPageSize: 0,
                        currentPageItemSize: 0,
                        pageNum: page,
                        pageSize: pageSize
                    })
                    setShowQueryResult(false)
                }
                console.log(res)
            }
        }))
    }

    /**
     * 取得逾時回覆再通知紀錄
     * @param {number} index 當前焦點索引
     * @param {number} page 指定頁碼
     * @param {number} pageSize 指定每頁筆數
     * @returns {void}
     * */
    const handleQueryCvisRenotify = (index, page, pageSize) => {
        //取得逾時回覆再通知紀錄
        queryCvisRenotify({
            cvisNotifyId: cvisNotifyRecordList[index].cvisNotifyId,
            pageNum: page,
            pageSize: pageSize
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                setPopCvisRenotifyList(data)
                setFilteredPopCvisRenotifyList(data)
                setRenotifyPaginationProps({
                    ...renotifyPaginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPageItemSize: res.data.dataList.length,
                    pageNum: page,
                    pageSize: pageSize
                })
            } else {
                setFilteredPopCvisRenotifyList([])
            }
        })
    }

    /**
     * 控制查詢事件
     * @returns {void}
     */
    const handleQuerySubmit = () => {
        queryCvisList(paginationProps.pageNum, paginationProps.pageSize)
    }

    /**
     * 根據List id取得對應流水號，呼叫api popup視窗
     * @param {String} id
     * @return {void}
     */
    const handlePopupOnClick = (id) => {
        setFocusIndex(id)
        //取得通報資料
        queryCvisNotifyrecById({
            cvisNotifyId: cvisNotifyRecordList[id].cvisNotifyId
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setPopCvisNotifyData(res.data)
            } else {
                console.log(res)
            }
        })
        //取得逾時回覆再通知紀錄
        handleQueryCvisRenotify(id, paginationProps.pageNum, paginationProps.pageSize)

        setIsShowReportData(true)
    }

    /**
     * 按下關閉按鈕
     */
    const handlePopupOnClose = () => {
        setIsShowReportData(false)
        setActiveTab('ReportData')
    }

    /**
     * 取得分頁頁籤內容
     * return {Object[]}
     */
    const getTabsContent = () => {
        return (
            [
                {
                    id: 'ReportData',
                    title: '通報資料',
                    content: (
                        <div className="w-full mt-[29px] mb-[26px] space-y-3">
                            <div className="grid grid-cols-2">
                                <Field label="通報單位">
                                    {cvisNotifyUnitAdapter(popCvisNotifyData?.cvisNotifyUnit)}
                                </Field>
                            </div>
                            <div className="grid grid-cols-2">
                                <Field label="通報項目">
                                    {popCvisNotifyData?.cvisNotifyItem}
                                </Field>
                                <Field label="事件等級">
                                    {popCvisNotifyData?.cvisNotifyClass}
                                </Field>
                            </div>
                            <div className="grid grid-cols-2">
                                <Field label="病歷號">
                                    {popCvisNotifyData?.patientId}
                                </Field>
                                <Field label="通報人員">
                                    {popCvisNotifyData?.cvisNotifyUser}
                                </Field>
                            </div>
                            <div className="grid grid-cols-2">
                                <Field label="通報對象">
                                    {popCvisNotifyData?.cvisNotifyObject}
                                </Field>
                                <Field label="通報電話">
                                    {/* TODO: 因api尚未提供所以先hardcode */}
                                    0987654232
                                </Field>
                            </div>
                            <div>
                                <Field label="通報內容">
                                    {popCvisNotifyData?.cvisNotifyContent}
                                </Field>
                            </div>
                        </div>
                    )
                },
                {
                    id: 'TimeoutReplyAssignmentRecord',
                    title: '逾時回覆再通知資料',
                    content: (
                        <Card sx={{pt: 1}}>
                            {/* 搜尋欄 */}
                            <div className='flex justify-start mb-4'>
                                <SearchTextField
                                    value={popQueryString}
                                    onChange={handlePopQueryInputOnChange}
                                    placeholder="進階搜尋"
                                />
                            </div>
                            {/* 清單表格 */}
                            <div className='min-w-[850px]'>
                                <ul className='tableList crisisAlertPopUpTable max-h-[736px]'>
                                    <li className='title'>
                                        <div>通報日期時間</div>
                                        <div>單號</div>
                                        <div>通報人員</div>
                                        <div>通報對象</div>
                                    </li>
                                    {!arrayIsEmpty(filteredPopCvisRenotifyList) && (
                                        filteredPopCvisRenotifyList.map((item, index) => {
                                            return (
                                                <CvisListItem
                                                    key={index}
                                                    index={index}
                                                    type="ClosureMonitorPopupList"
                                                    item={item}/>
                                            )
                                        })
                                    )}
                                </ul>
                            </div>
                            <Pagination
                                totalPageSize={renotifyPaginationProps.totalPageSize}
                                pageSize={renotifyPaginationProps.pageSize}
                                totalSize={renotifyPaginationProps.totalItemSize}
                                currentPage={renotifyPaginationProps.pageNum}
                                onPageOnChange={onRenotifyPaginationPageOnChange}
                                onPageSizeChange={onRenotifyPaginationPageSizeOnChange}
                                onPrevPageOnClick={onRenotifyPaginationPreviousOnClick}
                                onNextPageOnClick={onRenotifyPaginationNextOnClick}
                            />
                        </Card>
                    )
                }
            ]
        )
    }

    /**
     * 彈出視窗頁籤變更事件
     * @param {String} tab
     */
    const handlePopUpTabOnChange = (tab) => {
        setActiveTab(tab)
    }

    // 第一次執行時
    useEffect(() => {
        // 取得通報單位
        queryCvisNotifyUnit().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定通報單位
                setUnit(res.data)
                if (res.data.length > 0) {
                    setCvisQueryData(prev => ({
                        ...prev,
                        cvisNotifyUnit: res.data[0].cvisNotifyUnitNo
                    }))
                }
            } else {
                console.log(res)
            }
        })
    }, [])

    return (
        <div className='w-full p-4 relative'>
            {/* 主要內容 */}
            <div className='w-full flex flex-col gap-4'>
                {/* 醫生及回覆資訊 */}
                <div className='w-full flex flex-row justify-start items-center'>
                    <div className='w-[210px] h-10 flex flex-row justify-start items-center text-left'>
                        <Select
                            data={{
                                label: '通報單位',
                                options: unit?.map((item) => ({
                                    value: item.cvisNotifyUnitNo,
                                    label: item.cvisNotifyUnitName
                                }))
                            }}
                            value={cvisQueryData.cvisNotifyUnit}
                            onChange={handleCvisNotifyUnitOnChange}
                            displayEmpty={true}
                            notched={true}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-center px-3">
                        <RadioGroup
                            value={cvisQueryData.cvisNotifyQueryType}
                            optionProps={{
                                options: queryTypeOptions.map(option => ({
                                    label: option.label,  // 顯示在 radio 的標籤
                                    value: option.value,  // 對應的值
                                    id: option.id         // 對應的 id
                                }))
                            }}
                            onChange={handleQueryTypeOnChange}
                            row
                            size={SizeEnum.Small}
                            labelProps={{text: '查詢類型'}}
                        />
                    </div>
                    {/* 查詢按鈕 */}
                    <Button sx={{marginLeft: '1rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            text={t('general.query')} onClick={handleQuerySubmit}/>
                </div>
                {showQueryResult && (
                    <>
                        {/* 危急值通報閉環監控 */}
                        <Card sx={{pt: 1}}>
                            {/* 搜尋欄 */}
                            <div className='flex justify-start mb-4'>
                                <SearchTextField
                                    value={queryString}
                                    onChange={(event) => handleQueryInputOnChange(event)}
                                    placeholder="進階搜尋"
                                />
                            </div>
                            {/* 清單表格 */}
                            <div className='min-w-[1632px]'>
                                <ul className='tableList crisisAlertClosureMonitor max-h-[736px]'>
                                    <li className='title' key={0}>
                                        <div>通報單位</div>
                                        <div>通報項目</div>
                                        <div>事件等級</div>
                                        <div>通報日期時間</div>
                                        <div>病歷號</div>
                                        <div>姓名</div>
                                        <div>閉環狀態</div>
                                        <div>查看</div>
                                    </li>
                                    {!objectIsEmpty(filteredCvisNotifyRecordList) && filteredCvisNotifyRecordList.map((item, index) => {
                                        return (
                                            <CvisListItem
                                                key={index}
                                                index={index}
                                                type="ClosureMonitorList"
                                                item={item}
                                                handleItemOnClick={handlePopupOnClick}
                                            />
                                        )
                                    })}
                                </ul>
                            </div>
                            {/* 分頁 */}
                            <Pagination
                                totalPageSize={paginationProps.totalPageSize}
                                pageSize={paginationProps.pageSize}
                                totalSize={paginationProps.totalItemSize}
                                currentPage={paginationProps.pageNum}
                                onPageOnChange={onPaginationPageOnChange}
                                onPageSizeChange={onPaginationPageSizeOnChange}
                                onPrevPageOnClick={onPaginationPreviousOnClick}
                                onNextPageOnClick={onPaginationNextOnClick}
                            />
                        </Card>
                    </>
                )}
            </div>
            {/* 危急值通報-彈出視窗 */}
            <Dialog
                open={isShowReportData}
                title={t('CVIS.page.CrisisAlertReport')}
                content={
                    <Tabs
                        variant={TabsEnum.INDICATOR_TAB}
                        activeTabId={activeTab}
                        items={getTabsContent()}
                        onTabChange={handlePopUpTabOnChange}
                    />
                }
                variant={DialogVariant.CONFIRM}
                paperStyleProps={{width: DialogSizeEnums.MD}}
                onClose={handlePopupOnClose}
                onConfirm={handlePopupOnClose}
                muiDialogContentProps={{dividers: true}}
            />
        </div>
    )
}

export default CrisisAlertClosureMonitor;

import React, {useEffect, useMemo, useState} from 'react'
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    TimeslotEnum,
} from 'edah_utils/dist'
import {rsisAddSpecialRole, rsisEditSpecialRole, rsisQueryClinicForSpacialAuth} from "../../../api/v1/RSIS"
import {usePopup} from "../PopupProvider"
import {t} from "i18next"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    Search,
    Select,
    SizeEnum,
    SelectWidthEnum
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 報備支援-特殊權限維護彈出視窗
 * @param doctorList {Array<Object>}
 * @param divList {Array<Object>}
 * @param item {Object|Null}
 * @param handleOnClose {function} 彈出視窗關閉方法
 * @param handleOnUpdate {function} 帶入父層傳入的欄位參數資料
 * @return {JSX.Element}
 */
const SpecialPermissionMaintain = ({doctorList, divList, item = null, handleOnClose, handleOnUpdate}) => {
    // 權限ID
    const [specialRoleId, setSpecialRoleId] = useState('')
    // 科別代號
    const [divNo, setDivNo] = useState('')
    // 醫師代號
    const [userNo, setUserNo] = useState('')
    // 時段
    const [mTimeSlot, setMTimeSlot] = useState('')
    // 看診日期
    const [mEncounterDate, setMEncounterDate] = useState('')
    // 診室號
    const [mClinicNo, setMClinicNo] = useState('')
    // 診室清單
    const [clinicList, setClinicList] = useState([])
    // 使用上下文
    const {showToast} = usePopup()

    /**
     * 取得診室名稱
     * @return {void}
     */
    const getQueryClinicForSpacialAuth = () => {
        if (mEncounterDate === '') return
        rsisQueryClinicForSpacialAuth({
            // 看診日期 require
            encounterDate: `${mEncounterDate} 00:00:00`,
            // 醫師代號 option
            doctorNo: userNo.indexOf(' ') > 0 ? userNo.split(' ')[0] : '',
            // 科別代號 option
            divNo: divNo.indexOf(' ') > 0 ? divNo.split(' ')[0] : '',
            // 時段 option，ALL不用填
            apn: mTimeSlot,
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // EDAQA-421 診室資料加入一筆空資料置最上方
                data.unshift({
                    clinicNo: '',
                    clinicName: '',
                    apn: '',
                    doctor: '',
                    divNo: '',
                })
                //診室代號存在
                if (mClinicNo) {
                    //診室物件
                    const clinicObj = data.find(d =>
                        // 文字上有是否空白部分
                        mClinicNo.indexOf(' ') > 0 ?
                            // 有空白部分
                            d.clinicNo === mClinicNo.split(' ')[0] && d.clinicName === mClinicNo.split(' ')[1] :
                            //沒空白部分
                            d.clinicNo === mClinicNo || d.clinicName === mClinicNo)
                    //物件存在時
                    setMClinicNo(clinicObj ? `${clinicObj.clinicNo} ${clinicObj.clinicName}` : '')
                }
                setClinicList(data)
            } else {
                setClinicList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 送出
     * @return {void}
     */
    const handleOnSubmit = () => {
        //判斷item是否有帶入資料，若有帶入則為編輯，若無則為新增
        if (item === null) addSpecialRole()
        else editSpecialRole()
    }

    /**
     * 時段改變時
     * @param value {String}
     * @return {void}
     */
    const handleTimeslotOnChange = (value) => setMTimeSlot(value)

    /**
     * 時間改變時
     * @param newValue {dayjs}
     * @return {void}
     */
    const handleEncounterDateOnChange = (newValue) => {
        setMEncounterDate(dayjs(newValue).format('YYYY-MM-DD'))
    }

    /**
     * 取得提交資料
     * @return {Object}
     */
    const getSubmitData = () => ({
        // special role id
        specialRoleId: specialRoleId,
        // 部門代號
        divNo: divNo.indexOf(' ') > 0 ? divNo.split(' ')[0] : divNo,
        // 人員代號
        userNo: userNo.indexOf(' ') > 0 ? userNo.split(' ')[0] : userNo,
        // 時段
        apn: mTimeSlot,
        // 門診日期
        encountDate: mEncounterDate ? `${mEncounterDate} 00:00:00` : '',
        // 診間代號
        clinicNo: stringIsEmpty(mClinicNo) ? '' : (mClinicNo.indexOf(' ') > 0 ? mClinicNo.split(' ')[0] : mClinicNo),
        // 診間名稱 EDAQA-421 沒有存入診室名稱導致顯示錯誤
        clinicName: stringIsEmpty(mClinicNo) ? '' : (mClinicNo.indexOf(' ') > 0 ? mClinicNo.split(' ')[1] : mClinicNo)
    })

    /**
     * 新增特殊設定權限
     * @return {void}
     */
    const addSpecialRole = () => {
        const data = getSubmitData()
        if (!validData(data)) {
            showToast({message: '至少要選擇一個條件', type: AlertTypeEnum.Warning})
            return
        }
        rsisAddSpecialRole(data).then((res) => {
            //狀態/訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                showToast({message: msg, type: AlertTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 編輯特殊設定權限
     * @return {void}
     */
    const editSpecialRole = () => {
        const data = getSubmitData()
        if (!validData(data)) {
            showToast({message: '至少要選擇一個條件', type: AlertTypeEnum.Warning})
            return
        }
        // 傳送array資料
        rsisEditSpecialRole([data]).then((res) => {
            //狀態/訊息
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得列表資料
                showToast({message: msg, type: AlertTypeEnum.Success})
                handleOnClose()
                handleOnUpdate()
            } else {
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 資料是否有填最少一筆
     * @param data {Object}
     * @return {Boolean}
     */
    const validData = (data) => !stringIsEmpty(data.divNo) || !stringIsEmpty(data.userNo)
        || !stringIsEmpty(data.encountDate) || !stringIsEmpty(data.clinicNo)

    /**
     * 科別改變時
     * @param div {String} 科別
     * @return {void}
     */
    const handleOnDivOnChange = (div) => setDivNo(div)

    /**
     * 醫師改變時
     * @param doc {String} 科別
     * @return {void}
     */
    const handleDoctorOnChange = (doc) => setUserNo(doc)

    /**
     * 診室名稱改變時
     * @param value {String}
     * @return {void}
     */
    const handleClinicNoOnChange = (value) => setMClinicNo(value)

    /**
     * 取得預設科別代號
     */
    const getDefaultDivNoValue = useMemo(() => {
            const data = divList?.find(option => divNo?.includes(option?.divNo || ''))
            return data && divNo ? data : null
        }, [divList, divNo]
    )

    /**
     * 取得預設醫師代號
     */
    const getDefaultDoctorValue = useMemo(() => {
            const data = doctorList?.find(option => userNo?.includes(option?.userNo || ''))
            return data && userNo ? data : null
        }, [doctorList, userNo]
    )

    /**
     * 監聽encountDate, userNo, divNo, apn改變時重新取得診室資料
     * @return {void}
     */
    useEffect(() => getQueryClinicForSpacialAuth(), [mEncounterDate, userNo, divNo, mTimeSlot])

    /**
     * 第一次執行時
     * @return {void}
     */
    useEffect(() => {
        //資料不為空
        if (!objectIsEmpty(item)) {
            setSpecialRoleId(item.specialRoleId)
            if (item.divNo && item.divName) {
                setDivNo(`${item.divNo} ${item.divName}`)
            } else {
                setDivNo("")
            }
            if (item.userNo && item.userName) {
                setUserNo(`${item.userNo} ${item.userName}`)
            } else {
                setUserNo("")
            }

            // 避免編輯時時段因全部為null而導致沒辦法顯示問題
            // 另外也不影響新增模式
            if (item.apn) {
                setMTimeSlot(item.apn)
            }

            if (item.encountDate) {
                setMEncounterDate(item.encountDate.split(' ')[0])
            } else {
                setMEncounterDate("")
            }

            if (item.clinicNo) {
                // 設定診間值
                setMClinicNo(item.clinicNo)
            } else {
                setMClinicNo("")
            }
        }
    }, [])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.SpecialPermissionMaintain')}
            content={
                <div className='px-4 py-[10px]'>
                    <div className='w-full space-y-2'>
                        {/*第一列*/}
                        <div className='grid grid-cols-2 w-full'>
                            <Field label={"科別代號"}>
                                <Search
                                    inputSize={SizeEnum.Small}
                                    freeSolo
                                    value={getDefaultDivNoValue}
                                    onChange={(_e, value) => {
                                        const newValue = value?.divNo ? `${value?.divNo} ${value?.divName}` : ''
                                        handleOnDivOnChange(newValue)
                                    }}
                                    filterOptions={(options, {inputValue}) => {
                                        if (!inputValue) {
                                            return getDefaultDivNoValue || options
                                        }
                                        return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                    }}
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                />
                            </Field>
                            <Field label={'醫師代號'}>
                                <Search
                                    inputSize={SizeEnum.Small}
                                    freeSolo
                                    value={getDefaultDoctorValue}
                                    onChange={(_e, value) => {
                                        const newValue = value?.userNo ? `${value?.userNo} ${value?.userName}` : ''
                                        handleDoctorOnChange(newValue)
                                    }}
                                    filterOptions={(options, {inputValue}) => {
                                        if (!inputValue) {
                                            return getDefaultDoctorValue || options
                                        }
                                        return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                                    }}
                                    options={doctorList}
                                    getOptionLabel={(option) => `${option.userNo} ${option.userName}`}
                                />
                            </Field>
                        </div>
                        {/*第二列*/}
                        <div className='grid grid-cols-2 w-full'>
                            <Field label={'時段'}>
                                <Select
                                    data={{
                                        label: '時段',
                                        options: [
                                            {label: '全部', value: ''},
                                            {
                                                label: t('general.dateTime.timeslot.short.morning'),
                                                value: TimeslotEnum.Morning
                                            },
                                            {
                                                label: t('general.dateTime.timeslot.short.afternoon'),
                                                value: TimeslotEnum.Afternoon
                                            },
                                            {
                                                label: t('general.dateTime.timeslot.short.night'),
                                                value: TimeslotEnum.Night
                                            },
                                        ]
                                    }}
                                    showLabel={false}
                                    value={mTimeSlot}
                                    onChange={handleTimeslotOnChange}
                                    width={SelectWidthEnum.Medium}
                                    displayEmpty={true}
                                />
                            </Field>
                            <Field label={'看診日期'}>
                                <DatePicker
                                    label=""
                                    size={SizeEnum.Small}
                                    value={mEncounterDate ? dayjs(mEncounterDate) : null}
                                    onChange={handleEncounterDateOnChange}
                                />
                            </Field>
                        </div>
                        {/*第三列*/}
                        <div className='flex flex-row'>
                            <Field label={'診室名稱'}>
                                <Select
                                    data={{
                                        label: '診室名稱',
                                        options: arrayIsEmpty(clinicList)
                                            ? [{label: '', value: ''}]
                                            : clinicList.map((item) => ({
                                                value: `${item.clinicNo} ${item.clinicName}`,
                                                label: `${item.clinicNo} ${item.clinicName}`
                                            }))
                                    }}
                                    value={mClinicNo}
                                    onChange={handleClinicNoOnChange}
                                    width={SelectWidthEnum.Medium}
                                    showLabel={false}
                                    displayEmpty={true}
                                />
                            </Field>
                        </div>
                    </div>
                </div>
            }
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{width: DialogSizeEnums.MD}}
            onClose={handleOnClose}
            footerContent={
                <>
                    <Button
                        text={t('general.cancel')}
                        onClick={handleOnClose}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        color={ButtonColorEnum.Primary}
                    />
                    <Button
                        text={t('general.submit')}
                        onClick={handleOnSubmit}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        color={ButtonColorEnum.Primary}
                    />
                </>
            }
        />
    )
}

export default SpecialPermissionMaintain

import React, {useEffect, useState} from 'react'
import {t} from "i18next";
import {
    addTranForeign,
    deleteTranForeign,
    editTranForeign,
    queryTranForeignByApplicantNo
} from "../../../api/v1/Rmis";
import {ApiErrorStatusEnum, InputTypeEnum, time2String} from "edah_utils/dist";
import useToast from "../../../hooks/useToast";
import {BaseInput} from "../../Input/BaseInput";
import {
    AlertTypeEnum,
    Field,
    SizeEnum,
    TextArea,
    IconEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Checkbox,
    Divider,
    Search,
    TextField,
    DatePicker
} from "edah-component/dist"
import dayjs from "dayjs"
import DoctorDatalist from "../DoctorDatalist";

/**
 * 病症暨失能診斷證明書_維護
 * @param {object} Props
 * @param {function} Props.onConfirm
 * @param {object|null} Props.caseInfo
 * @param {boolean} Props.isEdit 編輯or新增
 * @returns {JSX.Element}
 */
const CertificateEditorModal = ({popupOnclose, onClose, isEdit = false, applicantNo, divList, doctorList}) => {
    const [caseDetail, setCaseDetail] = useState({
        patientId: '',
        patientName: '',
        birthDate: '',
        idNo: '',
        doctorNo: '',
        doctorName: '',
        divNo: '',
        divName: '',
        cancelFlag: '',
        barthelFlag: '',
        barthelScore: '',
        cdrFlag: '',
        hospNoCare: '',
        hospTel: '',
        applicantsUserName: '',
        applicantAdress: '',
        recipientDate: '',
        returnTime: '',
        returnReason: '',
        returnReason2: '',
        returnReason3: '',
        returnReason4: '',
        returnReasonName: '',
        remark: '',
        mpUser: '',
    })

    const [isSaved, setIsSaved] = useState(false)

    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)

    // 儲存成功 or 失敗 Toast
    const showToast = useToast()

    const updateCaseDetail = (e, field) => {
        const value = e.target.value
        setCaseDetail({
            ...caseDetail,
            [field]: value,
        })
    }

    /**
     * 日期改變時通用處理
     * @param {Dayjs | null} newValue - 選中的新日期
     * @param {string} field - caseDetail 中的字段名稱 (ex 'birthDate', 'diagDate', 'recipientDate')
     * @return {void}
     */
    const handleDateChange = (newValue, field) => {
        const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : ''
        setCaseDetail({
            ...caseDetail,
            [field]: formattedDate // 使用動態字段名來更新相應的日期
        })
    }


    const handleSaveData = async () => {
        const validateKey = ['applicantDate', 'patientId', 'diagDate', 'recipientDate', 'sendDate', 'mpUser', 'tranUser', 'barthelScore', 'hospNoCare']
        setIsSaved(true)
        if (validateKey.every((item) => validValue(item))) {
            const row = {
                ...caseDetail,
                diagDate: caseDetail.diagDate ? `${caseDetail.diagDate} 00:00:00` : '',
                recipientDate: caseDetail.recipientDate ? `${caseDetail.recipientDate} 00:00:00` : '',
                sendDate: caseDetail.sendDate ? `${caseDetail.sendDate} 00:00:00` : '',
                applicantDate: caseDetail.applicantDate ? `${caseDetail.applicantDate} 00:00:00` : '',
            }
            const targetApi = isEdit ? editTranForeign : addTranForeign
            const res = await targetApi(row)
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                onClose && onClose()
            } else {
                const msg = `儲存失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }
    }

    const validValue = (key) => {
        return !!caseDetail[key]
    }

    // 刪除單筆資料
    const handleConfirmDelete = () => {
        const applicantNoString = JSON.stringify([{applicantNo: applicantNo, lockVersion: caseDetail.lockVersion}])
        deleteTranForeign({
            tableName: "tw.com.hismax.his.entity.rmis.RmisTranForeign",
            tableData: applicantNoString
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                onClose && onClose()
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }

            setShowDeletePopup(false)
        })
    }
    // 開啟刪除彈窗
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }
    // 關閉刪除彈窗
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 選擇科別
     * @param {object} division 選擇的科別
     * @returns
     */
    const selectDivision = (division) => {
        if (!division) {
            return
        }
        setCaseDetail({
            ...caseDetail,
            divName: division.divName,
            divNo: division.divNo
        })
    }

    /**
     * 模糊搜尋選單-選擇的醫師
     * @param {object} doctor
     * @return {void}
     */
    const selectDoctor = (doctor) => {
        if (!doctor) {
            return
        }
        setCaseDetail({
            ...caseDetail,
            doctorNo: doctor ? doctor.doctorNo : '',
            doctorName: doctor ? doctor.doctorName : '',
        })
    }


    useEffect(() => {
        if (isEdit) {
            queryTranForeignByApplicantNo({applicantNo}).then(
                (res) => {
                    setCaseDetail((prevCaseDetail) => ({
                        ...prevCaseDetail,
                        ...res.data,
                    }))
                }
            )
        }
    }, [isEdit])

    return (
        <Dialog
            open={true}
            title={'病症暨失能診斷證明書_維護'}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '80%', maxHeight: 'calc(100vh-140px)', padding: '0.5rem'}}
            onClose={popupOnclose}
            onSave={handleSaveData}
            content={
                <div>
                    {/*刪除確認popup*/}
                    <Dialog
                        open={showDeletePopup}
                        title="提醒"
                        variant={DialogVariant.DELETE}
                        onClose={handleDeletePopupClose}
                        onDelete={handleConfirmDelete}
                        paperStyleProps={{width: DialogSizeEnums.SM}}
                        content='是否確定刪除？'
                        muiDialogContentProps={{sx: {height: '110px', display: 'flex', alignItems: 'center'}}}
                    />
                    <div className="flex justify-end items-center">
                        <Button
                            text='刪除此筆資料'
                            color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Large}
                            variant={ButtonVariantEnum.Text}
                            icon={IconEnum.Delete}
                            onClick={handleDeletePopupOpen}
                            sx={{fontWeight: 'bold'}}
                        />
                    </div>
                    <div className="p-3">
                        <div className="w-full grid grid-cols-4 mb-2">
                            {/*申請日期*/}
                            <Field label="申請日期"
                                   isRequired={!isEdit}>
                                {
                                    isEdit ? <>{time2String(caseDetail.applicantDate, 'YYYY-MM-DD')}</> : (
                                        <DatePicker
                                            size={SizeEnum.Small}
                                            label=""
                                            value={caseDetail.applicantDate ? dayjs(caseDetail.applicantDate) : null}
                                            onChange={(newValue) => handleDateChange(newValue, 'applicantDate')}
                                        />
                                    )
                                }

                            </Field>
                            {/*病歷號*/}
                            <Field label={t('general.patientNo')} isRequired={!isEdit}>
                                {
                                    isEdit ? <>{caseDetail.patientId}</> : (
                                        <TextField
                                            size={SizeEnum.Small}
                                            value={caseDetail.patientId}
                                            onChange={(e) => updateCaseDetail(e, 'patientId')}
                                            error={isSaved && !validValue("patientId")}
                                        />
                                    )
                                }

                            </Field>
                            {/*姓名*/}
                            <Field label={t('general.username')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.patientName}
                                    onChange={(e) => updateCaseDetail(e, 'patientName')}
                                />
                            </Field>
                            {/*身分證號*/}
                            <Field label={t('general.idNumber')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.idNo}
                                    onChange={(e) => updateCaseDetail(e, 'idNo')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4 mb-2">
                            {/*就醫科別*/}
                            <Field label="就醫科別">
                                <Search
                                    options={divList}
                                    getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                    value={{
                                        divNo: caseDetail.divNo || '',
                                        divName: caseDetail.divName || '',
                                    }}
                                    onChange={(_e, newValue) => selectDivision(newValue)}
                                />
                            </Field>
                            {/*醫師*/}
                            <Field label={t('general.doctor')}>
                                <DoctorDatalist
                                    doctorList={doctorList}
                                    onSelect={selectDoctor}
                                    value={{
                                        doctorNo: caseDetail.doctorNo || '',
                                        doctorName: caseDetail.doctorName || '',
                                    }}
                                />
                            </Field>
                            {/*出生日期*/}
                            <Field label={t('general.birthday')}>
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={caseDetail.birthDate ? dayjs(caseDetail.birthDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'birthDate')}
                                    disableFuture
                                />
                            </Field>
                            {/*註銷*/}
                            <Field label={'註銷'}>
                                <Checkbox
                                    value={caseDetail.cancelFlag}
                                    onChange={(e) => updateCaseDetail(e, 'cancelFlag')}
                                />
                            </Field>
                        </div>

                        <Divider sx={{my: 2}}/>

                        <div className="w-full grid grid-cols-4">
                            {/*證書日期*/}
                            <Field label="證書日期" isRequired>
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={caseDetail.diagDate ? dayjs(caseDetail.diagDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'diagDate')}
                                />
                            </Field>
                            {/*收件日期*/}
                            <Field label="收件日期" isRequired>
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={caseDetail.recipientDate ? dayjs(caseDetail.recipientDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'recipientDate')}
                                />
                            </Field>
                            {/*寄件日期*/}
                            <Field label="寄件日期">
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={caseDetail.sendDate ? dayjs(caseDetail.sendDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'sendDate')}
                                />
                            </Field>
                        </div>
                        <div className="w-full grid grid-cols-4 mb-2">
                            {/*醫事課人員*/}
                            <Field label="醫事課人員" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.mpUser}
                                    onChange={(e) => updateCaseDetail(e, 'mpUser')}
                                    error={isSaved && !validValue("mpUser")}
                                />
                            </Field>
                            {/*轉診櫃台人員*/}
                            <Field label="轉診櫃台人員" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.tranUser}
                                    onChange={(e) => updateCaseDetail(e, 'tranUser')}
                                    error={isSaved &&!validValue("tranUser")}
                                />
                            </Field>
                            {/*巴氏量表分數*/}
                            <Field label="巴氏量表分數" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.barthelScore}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'barthelScore')
                                    }
                                    error={isSaved &&!validValue("barthelScore")}
                                />
                            </Field>
                            <div className="flex flex-row items-center justify-center mb-2">
                                {/*巴0醫師勾選*/}
                                <Checkbox
                                    label="巴0醫師勾選"
                                    value={caseDetail.barthelFlag}
                                    onChange={(e) => updateCaseDetail(e, 'barthelFlag')}
                                />
                                {/*CDR表*/}
                                <Checkbox
                                    label="CDR表"
                                    value={caseDetail.cdrFlag}
                                    onChange={(e) => updateCaseDetail(e, 'cdrFlag')}
                                />
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-4">
                            {/*長照中心*/}
                            <Field label="長照中心" isRequired>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.hospNoCare}
                                    onChange={(e) => updateCaseDetail(e, 'hospNoCare')}
                                    error={isSaved && !validValue("hospNoCare")}
                                />
                            </Field>
                            <Field label={t('general.telephone')}>
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.hospTel}
                                    onChange={(e) => updateCaseDetail(e, 'hospTel')}
                                />
                            </Field>
                        </div>

                        <Divider sx={{my: 2}}/>
                        {/*就醫科別*/}
                        <Field label="申請人姓名" className="w-full flex mb-2">
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.applicantsUserName}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'applicantsUserName')
                                }
                            />
                        </Field>
                        {/*申請人地址*/}
                        <Field label="申請人地址" className="mb-2">
                            <TextField
                                size={SizeEnum.Small}
                                value={caseDetail.applicantAdress}
                                onChange={(e) => updateCaseDetail(e, 'applicantAdress')}
                            />
                        </Field>
                        <div className="grid grid-cols-4 mb-2">
                            {/*收到退件日*/}
                            <Field label="收到退件日">
                                <DatePicker
                                    size={SizeEnum.Small}
                                    label=""
                                    value={caseDetail.recipientDate ? dayjs(caseDetail.recipientDate) : null}
                                    onChange={(newValue) => handleDateChange(newValue, 'recipientDate')}
                                />
                            </Field>
                            {/*退件次數*/}
                            <Field label="退件次數">
                                <TextField
                                    size={SizeEnum.Small}
                                    value={caseDetail.returnTime}
                                    onChange={(e) => updateCaseDetail(e, 'returnTime')}
                                />
                            </Field>
                        </div>
                        {/*退件原因*/}
                        <Field label="退件原因" sx={{mb: 2, alignItems: "start"}}>
                            <div className="space-y-2">
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason')
                                    }
                                />
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason2}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason2')
                                    }
                                />
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason3}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason3')
                                    }
                                />
                                <TextField
                                    size={SizeEnum.Small}
                                    inputWidth={SizeEnum.Fill}
                                    value={caseDetail.returnReason4}
                                    onChange={(e) =>
                                        updateCaseDetail(e, 'returnReason4')
                                    }
                                />
                            </div>
                        </Field>
                        <Field label={"退件原因說明"} sx={{mb: 2, alignItems: "start"}}>
                            <TextArea
                                inputSize={SizeEnum.Fill}
                                value={caseDetail.returnReasonName}
                                onChange={(e) =>
                                    updateCaseDetail(e, 'returnReasonName')
                                }
                            />
                        </Field>
                        <Field label={"備註"} sx={{mb: 2, alignItems: "start"}}>
                            <TextArea
                                inputSize={SizeEnum.Fill}
                                value={caseDetail.remark}
                                onChange={(e) => updateCaseDetail(e, 'remark')}
                            />
                        </Field>
                    </div>
                </div>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}
export default CertificateEditorModal

import {BaseInput} from '../../components/Input/BaseInput'
import Button from '../../components/Button/Button'
import {
    InputTypeEnum,
    ApiErrorStatusEnum,
    time2String,
    stringIsEmpty,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
    objectIsEmpty
} from 'edah_utils/dist'
import React, {useEffect, useState} from 'react'
import {PERMISSION_SETTING_HEADER} from '../../constants/referralSystem/maintainAreaHeader'
import {ReactComponent as ExportText} from '../../assets/images/icons/text.svg'
import {Pagination} from '../../components/Pagination/Pagination'
import {BasePopup} from '../../components/Popup/BasePopup'
import {queryTranUser, saveTranUser, deleteTranUser} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import useDataGrid from '../../hooks/useDataGrid'
import {DATETIME_FORMAT, DATE_FORMAT} from '../../constants/common'
import {
    updatePrintContent,
    completePrintMode,
} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import store from '../../redux/store'
import {debounce} from '../../components/ReferralSystem/utils'
import {queryByHospNo} from '../../api/v1/Menu'
import {AlertTypeEnum, TextField} from "edah-component/dist";

const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({children, extendClass}) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

const InputStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'ml-4 flex items-center justify-center font-bold px-4 h-10 mr-10 rounded-[6px] bg-[#2B6CB0] text-white'
/**
 * 權限設定(轉診外網)
 * @returns {JSX.Element}
 */
const PermissionSetting = () => {
    const {
        updateDataList,
        handleEdit,
        handleSave,
        handleCancel,
        addNewData,
        handleToggleItem,
        handleToggleAll,
        deleteRow,
        dataList,
        isEditMode,
        dataOnChange,
    } = useDataGrid()

    // pagination
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })
    // 搜尋欄位
    // 院所代碼
    const [hospitalCode, setHospitalCode] = useState('')
    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')
    // 欲刪除的資料
    const [activeRow, setActiveRow] = useState({})
    // 刪除確認popup控制
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    // toast message
    const showToast = useToast()

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const handleHospitalCodeChange = (e) => setHospitalCode(e.target.value)
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    /**
     * 刪除單筆資料
     */
    const handleConfirmDelete = () => {
        deleteTranUser({hospNo: activeRow.hospNo}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                deleteRow(activeRow)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }

            setShowDeletePopup(false)
        })
    }
    /**
     * 開啟刪除彈窗
     * @param { object }tableRow
     */
    const handleDeletePopupOpen = (tableRow) => {
        setShowDeletePopup(true)
        setActiveRow(tableRow)
    }
    /**
     * 關閉刪除彈窗
     */
    const handleDeletePopupClose = () => {
        setShowDeletePopup(false)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        fetchTranUserList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        fetchTranUserList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        fetchTranUserList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        fetchTranUserList(page, filter.pageSize)
    }

    /**
     * 查詢按鈕
     */
    const handleClickQuery = () => {
        fetchTranUserList(1, filter.pageSize)
    }

    const handleInputOnBlur = (e, row, key) => {
        if (key === 'hospNo' && !stringIsEmpty(row.hospNo)) {
            const value = e.target.value
            getHospNameByHospNo(value, row)
        }
    }

    /**
     *  以院所代碼取得院所名稱
     */
    const getHospNameByHospNo = debounce((value, row) => {
        queryByHospNo({hospNo: value}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (objectIsEmpty(res.data)) {
                    showToast({
                        message: '查無相關院所代碼',
                        type: AlertTypeEnum.Error,
                    })
                    dataOnChange(row, 'hospName', '')
                    dataOnChange(row, 'hospNo', '')
                } else {
                    dataOnChange(row, 'hospName', res.data.hospName || '')
                    dataOnChange(row, 'hospNo', value)
                }
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
                dataOnChange(row, 'hospName', '')
                dataOnChange(row, 'hospNo', '')
            }
        })
    })

    /**
     * 取得權限設定清單
     * @returns
     * @param pageNum {number}
     * @param pageSize {number}
     */
    const fetchTranUserList = (pageNum, pageSize) => {
        queryTranUser({
            hospName: hospitalName,
            hospNo: hospitalCode,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.dataList.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []

                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                updateDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    const handleExportExcel = () => {
        const selectedData = dataList.filter((item) => item.isSelected)
        // 未選取不能匯出 excel
        if (selectedData.length === 0) {
            return
        }

        const keys = PERMISSION_SETTING_HEADER.map((item) => item.key)
        const titles = PERMISSION_SETTING_HEADER.map((item) => item.name)
        const dataArray = []
        selectedData.forEach((item) => {
            const data = []
            keys.forEach((key) => {
                if (key === 'applicantDate') {
                    data.push(time2String(item[key], DATE_FORMAT))
                } else {
                    data.push(item[key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles,
                data: dataArray,
            },
        ]
        // 匯出Excel
        downloadFile(
            exportExcelAsBlob(sheets),
            '權限設定(轉診外網)',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={handleExportExcel}
            >
                <ExportText className="mr-1"/>
                匯出
            </button>
        )
    }

    // 新增列
    const addNewPermission = () => {
        const newData = {
            lockVersion: 0,
            hospNo: '',
            hospAccount: '',
            hospPasswd: '',
            applicantName: '',
            applicantTel: '',
            applicantFax: '',
            applicantEmail: '',
            applicantDate: '',
            seqNo: '',
            sortBy: '',
            hospName: '',
        }
        addNewData(newData)
    }

    // 編輯該列資料
    const editPermission = (row) => {
        handleEdit({
            ...row,
            applicantDate: time2String(row.applicantDate, DATE_FORMAT) || '',
            applicantEmail: row.applicantEmail || '',
            applicantName: row.applicantName || '',
            hospAccount: row.hospAccount || '',
            hospName: row.hospName || '',
            hospNo: row.hospNo || '',
            hospPasswd: row.hospPasswd || '',
        })
    }

    // 儲存該列資料
    const savePermissionData = (tableRow) => {
        if (
            stringIsEmpty(tableRow.hospNo) ||
            stringIsEmpty(tableRow.hospName) ||
            stringIsEmpty(tableRow.hospAccount) ||
            stringIsEmpty(tableRow.hospPasswd) ||
            stringIsEmpty(tableRow.applicantName) ||
            stringIsEmpty(tableRow.applicantDate)
        ) {
            return
        }

        const data = {
            lockVersion: tableRow.lockVersion,
            hospNo: tableRow.hospNo,
            hospAccount: tableRow.hospAccount,
            hospPasswd: tableRow.hospPasswd,
            applicantName: tableRow.applicantName,
            applicantTel: tableRow.applicantTel,
            applicantFax: tableRow.applicantFax,
            applicantEmail: tableRow.applicantEmail,
            applicantDate: time2String(tableRow.applicantDate, DATETIME_FORMAT),
            seqNo: tableRow.seqNo,
            sortBy: tableRow.sortBy,
            hospName: tableRow.hospName,
        }
        saveTranUser(data).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                handleSave(tableRow)
                fetchTranUserList(filter.pageNum, filter.pageSize)
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    // 列印
    const handlePrintList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.permissionSettingList,
                printData,
            })
        )
    }

    // 列印
    const handlePrintLabelList = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.permissionLabel,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <Layout>
            {/*刪除確認popup*/}
            {showDeletePopup && (
                <BasePopup
                    title="提醒"
                    closePopupButtonOnClick={handleDeletePopupClose}
                    width="559px"
                    content={
                        <div>
                            <p className="h-[110px] px-6 py-9 text-[18px] text-left border-b-[1px] border-[rgba(0,0,0,0.15)]">
                                是否確定刪除？
                            </p>
                            <div className="h-[60px] flex flex-row items-center justify-end">
                                <button
                                    onClick={handleConfirmDelete}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]"
                                >
                                    是
                                </button>
                                <button
                                    onClick={handleDeletePopupClose}
                                    className="flex items-center justify-center h-10 px-4 mr-4 border-[2px] bg-white text-[#2B6CB0] border-[#2B6CB0] rounded-[6px]"
                                >
                                    否
                                </button>
                            </div>
                        </div>
                    }
                />
            )}
            {/*院所代碼*/}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center space-x-2">
                    <TextField
                        label="院所代碼"
                        value={hospitalCode}
                        onChange={handleHospitalCodeChange}
                    />
                    <TextField
                        label="院所名稱"
                        value={hospitalName}
                        onChange={handleHospitalNameChange}
                    />
                    <Button
                        classNames={ButtonStyle}
                        text={'查詢'}
                        onClickFn={handleClickQuery}
                    />
                </div>
                <div className="flex">
                    <button
                        className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                        onClick={handlePrintLabelList}
                    >
                        標籤列印
                    </button>
                    <button
                        className="flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2 mr-2"
                        onClick={handlePrintList}
                    >
                        列印
                    </button>
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={PERMISSION_SETTING_HEADER}
                dataList={dataList}
                toggleAll={handleToggleAll}
                toggleItem={handleToggleItem}
                isCrud={true}
                slotButton={<ExportExcelBtn/>}
                handleAddRow={addNewPermission}
                isEditMode={isEditMode}
                handleEdit={editPermission}
                handleSave={savePermissionData}
                handleCancel={handleCancel}
                addNewData={addNewData}
                deleteRow={handleDeletePopupOpen}
                handleInputOnBlur={handleInputOnBlur}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
        </Layout>
    )
}

export default PermissionSetting

import React, {useEffect, useMemo, useState} from 'react'
import {NO_RECEIPT_LETTER_FIELDS} from '../../../constants/referralSystem/tranOutCase'
import {DATETIME_FORMAT} from '../../../constants/common'
import CustomTable from '../CustomTable'
import {Pagination} from '../../Pagination/Pagination'
import {trancaseQueryNoReplyLetterCaseBySearch} from '../../../api/v1/Rmis'
import {ApiErrorStatusEnum, objectIsEmpty, time2String, validDateFormat,} from 'edah_utils/dist'
import useToast from '../../../hooks/useToast'
import store from '../../../redux/store'
import {completePrintMode, updatePrintContent,} from '../../../redux/Slice/printSlice'
import {FORM_TYPE} from '../../../constants/referralSystem/print'
import {useSelector} from 'react-redux'
import {t} from 'i18next'
import ReferralHospitalModal from '../ReferralHospitalModal'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    TextField,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    DateRangePicker
} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 列印尚未收到接受轉診醫院回覆名單
 * @param {object} props
 * @return {JSX.Element}
 */
const TranOutNoReplyLetterListModal = ({
                                           outStatusList = [],
                                           zipCodeList = [],
                                       }) => {
    const [searchParams, setSearchParams] = useState({
        tranStartDate: '',
        tranEndDate: '',
        zoneNo: '',
        hospName: '',
    })
    const [dataList, setDataList] = useState([])
    /**
     * pagination
     */
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    const showToast = useToast()

    const [inputState, setInputState] = useState({
        tranStartDate: null,
        tranEndDate: null,
    })

    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    /**
     * 追蹤狀態 Radio buttons
     * @param noReplyCase {object}
     * @return {JSX.Element}
     */
    const StatusRadioButtons = (noReplyCase) =>
        useMemo(() => (
            <RadioGroup size={SizeEnum} defaultValue={noReplyCase.outStatus} optionProps={{
                options: outStatusList.map((item) => ({
                    label: item.outStatusName,
                    value: item.outStatus,
                    disabled: true,
                })),
            }}/>), [outStatusList, noReplyCase.outStatus])

    /**
     * 轉回原醫療院所名單 table header
     */
    const renderFields = useMemo(() => {
        return NO_RECEIPT_LETTER_FIELDS.map((item) => {
            // 追蹤狀態以 radio button 呈現
            if (item.key === 'outStatus') {
                return {
                    ...item,
                    render: StatusRadioButtons,
                }
            } else {
                return item
            }
        })
    }, [])

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getNoReplyLetterList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getNoReplyLetterList(1, pageSize)
    }
    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getNoReplyLetterList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getNoReplyLetterList(page, filter.pageSize)
    }

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    /**
     * 轉出日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;
        setSearchParams({
            ...searchParams,
            tranStartDate: newStartDate ? newStartDate.format('YYYY-MM-DD') : '',
            tranEndDate: newEndDate ? newEndDate.format('YYYY-MM-DD') : '',
        })
    }

    /**
     * 查詢尚未收到接受轉診醫院回復名單
     * @return {Promise<void>}
     */
    const getNoReplyLetterList = (page, pageSize) => {
        // 欄位驗證
        const tranStartDateIsValid = validDateFormat(searchParams.tranStartDate)
        const tranEndDateIsValid = validDateFormat(searchParams.tranEndDate)
        setInputState({
            tranStartDate: tranStartDateIsValid,
            tranEndDate: tranEndDateIsValid,
        })
        if (!tranStartDateIsValid || !tranEndDateIsValid) {
            return
        }

        trancaseQueryNoReplyLetterCaseBySearch({
            tranStartDate: time2String(
                searchParams.tranStartDate,
                DATETIME_FORMAT
            ),
            tranEndDate: time2String(searchParams.tranEndDate, DATETIME_FORMAT),
            hospNo: searchParams.zoneNo,
            pageNum: page,
            pageSize,
        }).then((response) => {
            if (response.err === ApiErrorStatusEnum.Success) {
                if (response.data.dataList.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                const list =
                    response.data.dataList.map((item) => ({
                        ...item,
                        isSelected: false,
                    })) || []
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: response.data.totalItemSize,
                    totalPageSize: response.data.totalPageSize,
                    pageNum: page,
                    pageSize,
                })
            } else {
                showToast({message: response.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢
     */
    const handleQueryOnClick = () => {
        getNoReplyLetterList(1, filter.pageSize)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 列印
     */
    const handlePrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        if (!objectIsEmpty(printData)) {
            store.dispatch(
                updatePrintContent({
                    reportType: FORM_TYPE.tranOutNoReplyLetterList,
                    printData,
                })
            )
        }
    }

    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setSearchParams({
            ...searchParams,
            zoneNo: hospital.hospNo,
            hospName: hospital.hospName,
        })
        setIsShowReferralHospitalModal(false)
    }

    const handleZoneNoOnBlur = (e) => {
        if (!e.target.value) {
            setSearchParams({...searchParams, hospName: '', hospNo: ''})
        }
    }

    /**
     * 標籤列印
     */
    const handleLabelPrint = () => {
        const printData = dataList.filter((item) => item.isSelected)
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranOutNoReplyLetterLabel,
                printData,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div className="flex space-x-2">
                    <div className="date flex flex-row items-center justify-start space-x-2 mb-5">
                        {/* 轉出日期起訖 */}
                        <DateRangePicker
                            size={SizeEnum.Small}
                            required
                            localeText={{
                                start: '轉出開始日期',
                                end: '轉出結束日期',
                            }}
                            value={[
                                searchParams.tranStartDate ? dayjs(searchParams.tranStartDate) : null,
                                searchParams.tranEndDate ? dayjs(searchParams.tranEndDate) : null,
                            ]}
                            onChange={handleDateRangeChange}
                        />
                        {/*院所查詢按鈕*/}
                        <Button
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            text={'院所查詢'}
                            onClick={() => setIsShowReferralHospitalModal(true)}
                            sx={{fontWeight: 'bold', height: '40px'}}
                        />
                        <TextField
                            label='院所代碼'
                            value={searchParams.zoneNo}
                            onChange={(e) => updateSearchParams(e, 'zoneNo')}
                            onBlur={(e) => handleZoneNoOnBlur(e)}
                        />
                        <TextField
                            inputWidth={SizeEnum.Large}
                            value={searchParams.hospName}
                            disabled
                        />
                        {/*查詢按鈕*/}
                        <Button
                            color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Medium}
                            text={t('general.query')}
                            onClick={handleQueryOnClick}
                            sx={{fontWeight: 'bold', height: '40px'}}
                        />
                    </div>
                </div>
                <div className="flex gap-2">
                    {/*列印按鈕*/}
                    <Button
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        text={t('general.print')}
                        onClick={handlePrint}
                        sx={{fontWeight: 'bold', height: '40px'}}
                    />
                    {/*標籤列印按鈕*/}
                    <Button
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        text={t('general.labelPrint')}
                        onClick={handleLabelPrint}
                        sx={{fontWeight: 'bold', height: '40px'}}
                    />
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                totalPage={filter.totalPageSize}
                total={filter.totalItemSize}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>

            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    zipCodeList={zipCodeList}
                    onConfirm={onSelectHospital}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </div>
    )
}

export default TranOutNoReplyLetterListModal

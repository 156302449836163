import React, {useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import {MOHWgovFeedbackTable} from '../Constant'
import ListItem from './ListItem'
import {t} from "i18next"
import {rsisQueryOwnerUser, rsisQuerySendLog} from "../../../api/v1/RSIS"
import {ApiErrorStatusEnum, getLocalStorage} from "edah_utils/dist"
import {usePopup} from "../PopupProvider"
import {USER_ROLE} from "../userRole";
import {Button, ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum, TextField, AlertTypeEnum} from "edah-component/dist"

/**
 * 衛福部案號回饋作業
 * @return {JSX.Element}
 */
const MOHWgovFeedback = ({}) => {

    // 表格分頁參數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    })

    // 取得彈窗
    const {showToast} = usePopup()

    // 表格資料
    const [dataList, setDataList] = useState([])

    // 匯出編號
    const [sendFileName, setSendFileName] = useState('')

    // 備註
    const [memo, setMemo] = useState('')

    // 衛福部案號
    const [sendCaseNo, setSendCaseNo] = useState('')

    // 是否為承辦人員
    const [isOwner, setIsOwner] = useState(false)

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleQueryButtonOnClick = () => getQuerySendLog()

    /**
     * 取得查詢衛褔部案號回饋作業
     * @return {void}
     */
    const getQuerySendLog = () => {
        rsisQuerySendLog({
            sendFileName: sendFileName,
            memo: memo,
            sendCaseNo: sendCaseNo,
        }).then(res => {
            // 取得回傳資料
            const {err, msg, data} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(data)
            } else { //取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得承辦人員清單並判斷登入者是否為承辦人員
     * @return {void}
     */
    const getOwnerUserList = () => {
        rsisQueryOwnerUser({}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 取得登入者
                const loginUser = getLocalStorage('userno')
                // 判斷是否為承辦人員
                setIsOwner(data.some(item => item.userNo === loginUser))

            } else { // 取得失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 第一次執行時取得查詢衛褔部案號回饋作業
     */
    useEffect(() => {
        // 取得承辦人員清單
        getOwnerUserList()
        // 取得查詢衛褔部案號回饋作業
        getQuerySendLog()
    }, [])

    return (
        <div className="flex flex-col justify-start px-4 py-2 gap-4">
            <div className='flex flex-row items-center justify-start gap-6'>
                {/* 匯出編號 */}
                <TextField
                    label="匯出編號"
                    onChange={(e) => setSendFileName(e.target.value)}
                    value={sendFileName}/>
                {/* 備註 */}
                <TextField
                    label="備註"
                    onChange={(e) => setMemo(e.target.value)}
                    value={memo}/>
                {/* 衛福部案號 */}
                <TextField
                    label="衛福部案號"
                    onChange={(e) => setSendCaseNo(e.target.value)}
                    value={sendCaseNo}/>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start">
                    <Button text={t('general.query')} size={ButtonSizeEnum.Medium}
                            color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            onClick={handleQueryButtonOnClick}/>
                </div>
            </div>
            {/* 表格 */}
            <CustomTable
                advancedSearch={true}
                fields={MOHWgovFeedbackTable.fields}
                dataList={dataList}
                ListItem={ListItem}
                role={isOwner}
                hasSelectAll={false}
                type="RSISMOHWgovFeedbackTable"
                saveSlotBtn={false}
                exportSlotBtn={false}
                paginationProps={paginationProps}
                handleOnUpdateCallback={getQuerySendLog}
            />
        </div>
    )
}

export default MOHWgovFeedback

import {BaseInput} from "../../Input/BaseInput";
import {InputTypeEnum} from "edah_utils/dist";
import React from "react";
import addIcon from "../../../assets/images/icons/add_circle.svg";
import deleteCircle from "../../../assets/images/icons/del_circle.svg";
import {t} from "i18next";
import { TextArea, SizeEnum, Field } from "edah-component/dist";

/**
 * 補述B表紀錄
 * @return {JSX.Element}
 */
const Supplement = () => {
    // 頁面按鈕樣式
    const SecondButtonStyle = "flex items-center justify-center px-4 h-10 rounded-[6px] border-2 bg-[#FAFAFA]"

    return (
        <div className="w-full p-4 bg-white rounded-[6px] border border-[#111111]/15">
            <h3 className="flex items-center justify-start text-[20px] mr-4 mb-2.5 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                <span>補述B表紀錄</span>
            </h3>
            <div className="text-[#71717A] text-[14px] w-fit">評估日期</div>
            {/* 新增 */}
            <div className="flex items-center ">
                <button
                    className="flex items-center"
                >
                    <div className="py-[10px] h-10 mr-1">
                        <img className="w-full h-full" src={addIcon} alt="addIcon"/>
                    </div>
                    <p className="text-center text-[#38A169]">新增</p>
                </button>
                <div className="w-[1px] h-[20px] bg-[#D4D4D8] ml-2"/>
                <button className={`${SecondButtonStyle} ml-2`}>上頁</button>
                <button className={`${SecondButtonStyle} ml-2 bg-gray-200}`}>2024/06/28</button>
                <button className={`${SecondButtonStyle} ml-2`}>下頁</button>
            </div>
            <div className="flex justify-between items-center pt-2 mt-2.5 mb-2.5 w-full border-t border-[#111111]/15">
                {/*功能操作*/}
                <div className="w-full h-[60px] py-[10px]">
                    <button className="flex flex-row justify-start items-center w-[112px] h-10">
                        <div className="w-5 h-5 mr-1">
                            <img className="w-full h-full" src={deleteCircle} alt="addIcon"/>
                        </div>
                        <p className="text-center text-[#E53E3E]">{t('general.delete')}</p>
                    </button>
                </div>
                <div className="flex flex-row flex-shrink-0">
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        Ditto
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center h-10 px-4 mr-2 border-[2px] bg-[#FAFAFA] text-black border-[#D4D4D8] rounded-[6px]">
                        列印
                    </button>
                    <button
                        className="flex items-center flex-shrink-0 justify-center w-[128px] h-10 px-4 mr-2 border-[2px] bg-[#2B6CB0] text-white border-[#2B6CB0] rounded-[6px]">
                        存檔
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mb-6">
                {/* 評估日期輸入框 */}
                <label className="mr-2">評估日期:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border-[1px] border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Date}
                />

                {/* 評估人員輸入框 */}
                <label className="mr-2">評估人員:</label>
                <BaseInput
                    className="w-[140px] h-10 px-4 border border-[#D4D4D8] rounded-[6px] mr-4"
                    type={InputTypeEnum.Text}
                />
            </div>
            <div className="flex flex-row items-start justify-start w-full">
                <Field label={"護理紀錄"}>
                    <TextArea inputSize={SizeEnum.Fill}/>
                </Field>
            </div>
        </div>
    )
}
export default Supplement

import React, {useEffect, useState} from 'react'
import {formatTime} from '../utils';
import ChangeRecordQuery from './QueryChangeRecord';
import {ApiErrorStatusEnum, arrayIsEmpty, stringIsEmpty, time2String} from 'edah_utils/dist';
import {rsisCancelMaster, rsisQueryCodeValue} from "../../../api/v1/RSIS";
import {checkDoctorLicence} from "../../../api/v1/Menu";
import {usePopup} from "../PopupProvider";
import {getCurrentFormattedDateTime} from "../../CVIS/utils";
import {
    Dialog,
    DialogVariant,
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Field,
    Select,
    SizeEnum,
    TextField,
    SelectWidthEnum
} from "edah-component/dist";
import {t} from "i18next"

/**
 * 報備支援申請註銷作業-modal
 * @param {Object} data 資料
 * @param {boolean} isOwnUser 是否為承辦人員
 * @param {function} handleOnClose 關閉事件
 * @param {function} handleOnUpdate 更新事件
 * @returns {JSX.Element}
 */
const LogOffModal = ({
                         data,
                         isOwnUser,
                         handleOnClose,
                         handleOnUpdate
                     }) => {
    // 員工/醫師代碼
    const [supportUserNo, setSupportUserNo] = useState(data.supportUserNo ? data.supportUserNo : '')
    // 員工/醫師名稱
    const [supportUserName, setSupportUserName] = useState(data.supportUserName ? data.supportUserName : '')
    // 支援目的
    const [purposeCode, setPurposeCode] = useState(data.purposeCode ? data.purposeCode : '')
    // 支援目的門柱
    const [subPurposeCode, setSubPurposeCode] = useState(data.purposeCodeExt ? data.purposeCodeExt : '')
    // 變更原因
    const [modifyReason, setModifyReason] = useState(data.supportModifyReasonCode ? data.supportModifyReasonCode : '')
    // 變更原因說明
    const [reasonDoc, setReasonDoc] = useState(data.supportModifyReasonDoc ? data.supportModifyReasonDoc : '')
    // 執登科別編號
    const [divNo, setDivNo] = useState('')
    // 執登科別名稱
    const [divName, setDivName] = useState('')
    // 證書字號
    const [licenceId, setLicenceId] = useState('')
    // 職類別
    const [licenceKind, setLicenceKind] = useState('')
    // 職類別名稱
    const [licenceKindName, setLicenceKindName] = useState('')
    // 執登機構編號
    const [hospNo, setHospNo] = useState('')
    // 執登機構名稱
    const [hospName, setHospName] = useState('')

    // 支援目的選項
    const [purposeOption, setPurposeOption] = useState([])
    // 支援目的門柱選項
    const [subPurposeOptions, setSubPurposeOptions] = useState([])
    // 變更原因選項
    const [changeReason, setChangeReason] = useState([])


    // 使用上下文
    const {
        showChangeRecordModal,
        setShowChangeRecordModal,
        showToast
    } = usePopup()

    /**
     * 提交註銷表單
     * @return {void}
     */
    const handleOnLogOffSubmit = () => {
        // 註銷內容
        logOffContent()
    }

    /**
     * 取得醫師執登資料
     * @return {void}
     */
    const getDoctorData = (userNo) => {
        // 根據醫師代碼查詢醫師資料
        checkDoctorLicence({
            doctorNo: userNo,
            divNo: ''
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDivNo(data.divNo)
                setDivName(data.divName)
                setLicenceId(data.licenceId)
                setLicenceKind(data.licenceKind)
                setLicenceKindName(data.licenceKindName)
                setHospNo(data.hospNo)
                setHospName(data.hospName)
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 渲染支援目的選項
     * @return {JSX.Element}
     */
    const renderPurposeOptions = () => {
        const purposeCodes = purposeCode.split(',').filter(Boolean)
        const subPurpose = () => {
            if (subPurposeCode === '1') {
                return '門診'
            } else if (subPurposeCode === '2') {
                return '值班'
            } else if (subPurposeCode === '3') {
                return '會診'
            }
        }
        const purposeTexts = purposeCodes.map(codeNo => {
            const purpose = purposeOption.find(item => item.codeNo === codeNo)
            if (purpose) {
                if (codeNo === '10') {
                    return `${purpose.codeValue1} ${data.purposeCodeExt ? `${subPurpose()}` : ''}`;
                }
                return purpose.codeValue1;
            }
            return null;
        }).filter(Boolean)

        return purposeTexts.map((text, index) => (
            <p key={index} className='flex justify-start py-[9px]'>{text}</p>
        ))
    }


    /**
     * 取得支援目的選項
     * @return {void}
     */
    const getPurposeOptions = () => {
        // 查詢支援目的選項
        rsisQueryCodeValue({effFlag: 'ALL'}).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setPurposeOption(data.filter(item => item.codeType === 'PURPOSE'))
                //取得變更原因清單
                const changeReasonList = isOwnUser
                    ? data.filter(item => item.codeType === 'CHANGE') // 若為承辦人員則取得所有變更原因
                    : data.filter(item => item.codeType === 'CHANGE' && item.codeValue2 === 'N') // 若不為承辦人員則取得非承辦人員變更原因
                // 設定變更原因
                setChangeReason(changeReasonList)
                // 變更原因不為空，且變更原因為空時，將第一筆設定給變更原因
                if (!arrayIsEmpty(changeReasonList) && data.supportModifyReasonCode === '') {
                    //將第一筆設定給變更原因
                    setModifyReason(changeReasonList[0].codeNo)
                }
            } else {
                setPurposeOption([])
                setSubPurposeOptions([])
                setChangeReason([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得送出資料
     * @return {void}
     */
    const getSendData = () => {
        return {
            supportNo: data.supportNo,
            itemVersion: data.itemVersion,
            supportUserNo: data.supportUserNo,
            supportUserName: data.supportUserName,
            supportOrgNo: data.supportOrgNo,
            supportOrgName: data.supportOrgName,
            supportDivNo: data.supportDivNo,
            supportDivName: data.supportDivName,
            purposeCode: data.purposeCode,
            purposeCodeExt: data.purposeCodeExt,
            itemStartDate: data.itemStartDate,
            itemEndDate: data.itemEndDate,
            itemStartTime: data.itemStartTime,
            itemEndTime: data.itemEndTime,
            supportProperties: data.supportProperties,
            executeContent: data.executeContent,
            memo: data.memo,
            agentUserNo: data.agentUserNo,
            agentUserName: data.agentUserName,
            stepStatus: data.stepStatus,
            supportModifyStatus: data.supportModifyStatus,
            supportModifyDatetime: getCurrentFormattedDateTime(),
            supportModifyReasonCode: modifyReason,
            supportModifyReasonDoc: reasonDoc,
            sendFileName: data.sendFileName,
            sendCaseNo: data.sendCaseNo,
            sendDatetime: data.sendDatetime,
            applyType: data.applyType,
            zone: data.zone,
            effFlag: data.effFlag,
            ownerDeptNo: data.ownerDeptNo,
            changeFieldsDoc: data.changeFieldsDoc,
            confirmUserNo: data.confirmUserNo,
            confirmDatetime: data.confirmDatetime
        }
    }

    /**
     * 註銷內容
     * @return {void}
     */
    const logOffContent = () => {
        const sendData = getSendData()

        // 檢查變更原因是否為其他，若是則檢查是否有輸入其他說明
        if (sendData.supportModifyReasonCode === 'other' && sendData.supportModifyReasonDoc === '') {
            showToast({message: '請輸入其他原因之說明', type: AlertTypeEnum.Warning})
            return
        }

        rsisCancelMaster([sendData]).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                handleOnUpdate()
                handleOnClose()
                showToast({message: '註銷成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 理由選擇變更
     * @param value {String}
     */
    const handleReasonSelectOnChange = (value) => {
        setModifyReason(value)
    }

// 第一次執行時
    useEffect(() => {
        // 取得執登資料
        getDoctorData(supportUserNo.split(' ')[0])
        // 取得支援目的選項
        getPurposeOptions()
    }, []);

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.LogOffModal')}
            content={
                <div className='flex flex-col px-6 gap-4 mb-6'>
                    {/* 第一列員工搜尋、查詢變更紀錄、狀態 */}
                    <Field label='員工/醫師代碼'>
                        {`${supportUserNo} ${supportUserName}`}
                    </Field>
                    {/* 第二列資訊 */}
                    <div className='flex flex-row'>
                        {/* 執登機構 */}
                        <Field label='執登機構'>
                            {!stringIsEmpty(hospNo) && !stringIsEmpty(hospName) ? `${hospNo} ${hospName}` : ''}
                        </Field>
                        {/* 職類別 */}
                        <Field label='職類別'>
                            {!stringIsEmpty(licenceKind) && !stringIsEmpty(licenceKindName) ? `${licenceKind} ${licenceKindName}` : ''}
                        </Field>
                        {/* 執登科別 */}
                        <Field label='執登科別'>
                            {!stringIsEmpty(divNo) && !stringIsEmpty(divName) ? `${divNo} ${divName}` : ''}
                        </Field>
                        {/* 證書字號 */}
                        <Field label='證書字號'>
                            {!stringIsEmpty(licenceId) ? licenceId : ''}
                        </Field>
                    </div>
                    {/* 註銷 */}
                    <div className='flex flex-row gap-4'>
                        <Card title={"註銷"}>
                            <div className="flex flex-row w-full py-2 justify-between">
                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                        size={ButtonSizeEnum.Medium}
                                        onClick={handleOnLogOffSubmit} text={t('RSIS.optionStatus.logoff')}/>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Field label="申請單號">
                                    {data.supportNo}
                                </Field>
                                <Field label='機構代碼/名稱'>
                                    {`${data.supportOrgNo} ${data.supportOrgName}`}
                                </Field>
                                <Field label='支援科別'>
                                    {`${data.supportDivNo} ${data.supportDivName}`}
                                </Field>
                                <Field label='支援目的'>
                                    {renderPurposeOptions()}
                                </Field>
                                <Field label="支援日期區間">
                                    {`${time2String(data.itemStartDate, 'YYYY-MM-DD')}~${time2String(data.itemEndDate, 'YYYY-MM-DD')} ${formatTime(data.itemStartTime)}~${formatTime(data.itemEndTime)}`}
                                </Field>
                                <Field label="備註">
                                    {data.memo}
                                </Field>
                                <Field label="變更原因">
                                    <div className='flex flex-row items-center px-3 gap-2'>
                                        <Select
                                            data={{
                                                label: '',
                                                options:
                                                    changeReason.map((item) => ({
                                                        label: item.codeValue1,
                                                        value: item.codeNo,
                                                    })),
                                            }}
                                            value={modifyReason}
                                            onChange={handleReasonSelectOnChange}
                                            width={SelectWidthEnum.Medium}
                                        />
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.XLarge}
                                            value={reasonDoc}
                                            onChange={(e) => setReasonDoc(e.target.value)}
                                        />
                                    </div>
                                </Field>
                            </div>
                        </Card>
                    </div>
                    {/* 查詢變更紀錄 */}
                    {showChangeRecordModal &&
                        <ChangeRecordQuery handleOnClose={() => setShowChangeRecordModal(false)}/>}
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '1736px'}}
            onClose={handleOnClose}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default LogOffModal

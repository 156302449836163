import React, {useEffect, useState} from 'react'
import ExtendBtnTextArea from '../../Input/ExtendBtnTextArea'
import ReferralTurnInModal from './ReferralTurnInModal'
import ReferralTurnOutModal from './ReferralTurnOutModal'
import {
    queryTranCodeList,
    queryTranTypeByEncounterId,
    saveTranTypeByEncounterId,
    trancaseQueryBasicData
} from '../../../api/v1/Rmis'
import {ApiErrorStatusEnum, stringIsEmpty, time2String} from 'edah_utils/dist'
import {DATETIME_FORMAT} from '../../../constants/common'
import useToast from '../../../hooks/useToast'
import {t} from 'i18next'
import store from '../../../redux/store'
import {completePrintMode, updatePrintContent,} from '../../../redux/Slice/printSlice'
import {FORM_TYPE} from '../../../constants/referralSystem/print'
import {useSelector} from 'react-redux'
import {getBirthDayAndDetailedAge} from "../utils";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    Field,
    RadioGroup,
    Search,
    SizeEnum,
    TextField
} from "edah-component/dist"

const InputStyle =
    'w-[200px] h-10  px-2 text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const Layout = ({children}) => {
    return (
        <div className="p-5 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({children, extendClass}) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}
const Divider = () => {
    return <div className="border-t-[1px] border-[rgba(0,0,0,0.15)] my-8"></div>
}
/**
 * 轉診開單作業
 * @return {JSX.Element}
 */
const ReferralTicketModalContent = ({selectedVisitInfo, onClose}) => {
    // 開單預設資料
    const defaultInfo = {
        // 開單內容
        tranType: '',
        // 病歷號
        patientId: '',
        // 姓名
        patientName: '',
        // 性別代號
        gender: '',
        // 性別
        genderName: '',
        // 出生日期
        birthDate: '',
        // 身分證號
        idNo: '',
        // 連絡電話
        tel: '',
        // 聯絡人
        lianluoren: '',
        // 聯絡地址
        lianluoAddress: '',
        // 開立醫師代號
        doctorNo: '',
        // 開立醫師
        doctorName: '',
        // 病情摘要
        subject: '',
        // 診斷
        treatmentCode: '',
        treatmentCode1: '',
        treatmentCode2: '',
        treatmentName: '',
        treatmentName1: '',
        treatmentName2: '',
        // 檢查及治療摘要
        clinicInfo: '',
        tranIn: {},
        tranOut: {},
    }
    // 開單資料
    const [ticketInfo, setTicketInfo] = useState(defaultInfo)
    // 基礎資料
    const [baseDropdownData, setBaseDropdownData] = useState({
        // 轉診原因
        tranPurposeList: [],
        // 轉出原因
        tranReasonList: [],
    })
    //基礎資料
    const [baseData, setBaseData] = useState({
        // 醫師清單
        doctorList: [],
        // 科別清單
        divList: [],
        // 開單內容選項
        tranTypeList: [],
        // 轉診結果下拉選單
        tranFindingList: [],
    })

    // 轉診單資料
    const [turnOutData, setTurnOutData] = useState(defaultInfo.tranOut)

    // 回覆單資料
    const [turnInData, setTurnInData] = useState(defaultInfo.tranIn)

    // 取得列印模式
    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const showToast = useToast()

    // 更新開單資料
    const updateTicketInfo = (e, field) => {
        setTicketInfo((prev) => {
            return {
                ...prev,
                [field]: e.target.value,
            }
        })
    }

    /**
     * 更新開單內容
     * @param e {Event} 事件
     * @return {void}
     */
    const handleTranTypeChange = (e) => setTicketInfo({
        ...ticketInfo,
        tranType: e.target.value
    })

    /**
     * 轉診回診證明單
     * @param value
     */
    const handleReturnTicket = (value) => {
        const printData = ticketInfo
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.returnTicket,
                printData,
            })
        )
    }

    /**
     * 轉入單列印
     * @param value
     */
    const handleTurnInTicket = (value) => {
        const printData = ticketInfo
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranInForm,
                printData,
            })
        )
    }

    /**
     * 轉診單列印
     * @param value
     */
    const handleTurnOutTicket = (value) => {
        const tranReason = baseDropdownData.tranReasonList.find(
            (item) => turnOutData.tranReason === item.codeNo
        )?.codeTypeName
        const printData = {...ticketInfo, tranReason: tranReason}
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.tranOutForm,
                printData,
            })
        )
    }
    /**
     * Subject
     * @param value
     */
    const handleSubjectClick = (value) => {
        // TODO
    }
    /**
     * Object
     * @param value
     */
    const handleObjectClick = (value) => {
        // TODO
    }
    /**
     * ER
     * @param value
     */
    const handleErClick = (value) => {
        // TODO
    }
    /**
     * S/O
     * @param value
     */
    const handleSOClick = (value) => {
        // TODO
    }
    /**
     * 存檔
     */
    const handleSave = async () => {
        // [轉診原因-本院無該檢查]代碼
        const TRAN_REASON_NO_REVELANT_TEST = '46'
        // [轉診原因-本院無該治療]代碼
        const TRAN_REASON_NO_REVELANT_TREATMENT = '47'
        console.log(turnOutData.tranReason, stringIsEmpty(turnOutData.tranReason))
        if (
            (turnOutData.tranReason === TRAN_REASON_NO_REVELANT_TEST ||
                turnOutData.tranReason === TRAN_REASON_NO_REVELANT_TREATMENT) && stringIsEmpty(turnOutData.tranReasonRemark2)
        ) {
            return
        }
        const value = {
            encounterDate: ticketInfo.encounterDate,
            encounterId: ticketInfo.encounterId,
            patientId: ticketInfo.patientId,
            tranType: ticketInfo.tranType,
            divNo: ticketInfo?.divNo,
            inpOpd: ticketInfo?.inpOpd,
            doctorNo: ticketInfo.doctorNo,
            treatmentCode: ticketInfo.treatmentCode,
            treatmentCode1: ticketInfo.treatmentCode1,
            treatmentCode2: ticketInfo.treatmentCode2,
            lianluoren: ticketInfo.lianluoren,
            lianluoAddress: ticketInfo.lianluoAddress,
            idNo: ticketInfo.idNo,
            subject: ticketInfo.subject,
            clinicInfo: ticketInfo.clinicInfo,
            tranIn: turnInData
                ? {
                    ...turnInData,
                    tranDate: turnInData.tranDate
                        ? time2String(turnInData.tranDate, DATETIME_FORMAT)
                        : '',
                }
                : {},
            tranOut: turnOutData || {},
        }

        const res = await saveTranTypeByEncounterId(value)

        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            onClose()
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 選擇科別
     * @param {object} division 科別
     */
    const selectDivision = (division) => {
        setTicketInfo((prev) => {
            return {
                ...prev,
                divNo: division?.divNo ? division.divNo : '',
                divName: division?.divName ? division.divName : '',
            }
        })
    }

    /**
     * 選擇開單醫師
     * @param {object} doctor 開單醫師
     */
    const selectDoctor = (doctor) => {
        if (!doctor) {
            return
        }
        setTicketInfo((prev) => {
            return {
                ...prev,
                doctorNo: doctor.doctorNo ? doctor.doctorNo : '',
                doctorName: doctor.doctorName ? doctor.doctorName : '',
            }
        })
    }

    /**
     * 取得轉診單資料
     * @return {void}
     */
    const getReferralInfo = () => {
        queryTranTypeByEncounterId({
            encounterId: selectedVisitInfo.patient.encounterId,
            encounterDate: selectedVisitInfo.patient.encounterDate,
            inpOpd: selectedVisitInfo.patient.inpOpd,
            patientId: selectedVisitInfo.patient.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = {}
                Object.keys(res.data).forEach((key) => {
                    if (key === 'tranIn' || key === 'tranOut') {
                        const newTranInfo = {}
                        const tranInfo = res.data[key]
                        Object.keys(tranInfo).forEach((field) => {
                            newTranInfo[field] = tranInfo[field] || ''
                        })
                        data[key] = newTranInfo
                    } else {
                        data[key] = res.data[key] || ''
                    }
                })
                setTicketInfo(() => ({...ticketInfo, ...data}))
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得基礎選單資料
     * @return {void}
     */
    const getBaseData = () => {
        trancaseQueryBasicData({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData({
                    ...res.data,
                    divList: res.data.divList || [],
                    doctorList: res.data.doctorList || [],
                })
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 處理轉診單資料
     * @param data {object} 轉診單資料
     */
    const handleTurnOutData = (data) => {
        setTurnOutData((prev) => ({...prev, ...data}))
    }

    /**
     * 處理轉入單資料
     * @param data {object} 轉入單資料
     */
    const handleTurnInData = (data) => {
        setTurnInData((prev) => ({...prev, ...data}))
    }

    useEffect(() => {
        getReferralInfo()
        getBaseData()
    }, [])

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    useEffect(() => {
        Promise.all([
            queryTranCodeList({codeType: 'TRAN_REASON'}),
            queryTranCodeList({codeType: 'TRAN_PURPOSE'}),
        ]).then((res) => {
            const [tranReasonRes, tranPurposeRes] = res
            if (
                tranReasonRes.err === ApiErrorStatusEnum.Success &&
                tranPurposeRes.err === ApiErrorStatusEnum.Success
            ) {
                setBaseDropdownData((prev) => ({
                    ...prev,
                    tranPurposeList: tranPurposeRes.data || [],
                    tranReasonList: tranReasonRes.data || [],
                }))
            } else {
                showToast({
                    message: tranReasonRes.msg,
                    type: AlertTypeEnum.Error,
                })

                showToast({
                    message: tranPurposeRes.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }, [])

    return (
        <Dialog
            open={true}
            title={'轉診開單作業'}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: '90%'}}
            onClose={onClose}
            onSave={handleSave}
            muiDialogContentProps={{dividers: true}}
            content={
                <>
                    {/* 開單內容 */}
                    <div className="w-full flex justify-between">
                        <div>
                            <Field label="開單內容">
                                <RadioGroup size={SizeEnum.Medium}
                                            value={ticketInfo.tranType}
                                            optionProps={{
                                                options: baseData.tranTypeList.map(option => ({
                                                    label: option.tranTypeName,
                                                    value: option.tranType
                                                })),
                                            }}
                                            onChange={handleTranTypeChange}/>
                            </Field>
                        </div>
                        <div className="flex items-center justify-end gap-2">
                            <Button
                                text={'轉診回診證明單'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={handleReturnTicket}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'回覆單列印'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={() => handleTurnInTicket()}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'轉診單列印'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={() => handleTurnOutTicket()}
                                sx={{fontWeight: 'bold'}}
                            />
                        </div>
                    </div>
                    {/* 病歷號 */}
                    <div className="mt-2 grid grid-cols-4">
                        <Field label={t('general.patientNo')}>
                            {ticketInfo.patientId}
                        </Field>
                        {/*姓名*/}
                        <Field label={t('general.username')}>
                            {ticketInfo.patientName}
                        </Field>
                        {/*性別*/}
                        <Field label={t('general.gender.name')}>
                            {ticketInfo.genderName}
                        </Field>
                        {/*出生日期*/}
                        <Field label={t('general.dateOfBirth')}>
                            {getBirthDayAndDetailedAge(ticketInfo.birthDate)}
                        </Field>
                    </div>
                    <div className="mt-2 grid grid-cols-4">
                        {/*身分證號*/}
                        <Field label={t('general.idNumber')}>
                            {ticketInfo.idNo}
                        </Field>
                        {/*電話*/}
                        <Field label={t('general.telephone')}>
                            {ticketInfo.tel}
                        </Field>
                        {/*聯絡人*/}
                        <Field label="聯絡人">
                            {ticketInfo.lianluoren}
                        </Field>
                    </div>
                    <div>
                        {/* 聯絡地址 */}
                        <Field label={t('general.contactAddress')}>
                            {ticketInfo.lianluoAddress}
                        </Field>
                    </div>
                    <Divider/>
                    <div className="grid grid-cols-4 mb-2">
                        {/*開單醫師*/}
                        <Field
                            labelWidth={155}
                            label="開單醫師">
                            <Search
                                options={baseData.doctorList}
                                getOptionLabel={(option) => `${option.doctorNo} ${option.doctorName}`}
                                value={{
                                    doctorNo: ticketInfo.doctorNo || '',
                                    doctorName: ticketInfo.doctorName || '',
                                }}
                                onChange={(_event, newValue) => selectDoctor(newValue)}
                            />
                        </Field>
                        {/*科別*/}
                        <Field label={t('general.department')}>
                            <Search
                                options={baseData.divList}
                                getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                                value={{
                                    divNo: ticketInfo?.divNo || '',
                                    divName: ticketInfo.divName || '',
                                }}
                                onChange={(_event, newValue) => selectDivision(newValue)}
                            />
                        </Field>
                        <div className="flex items-center ml-2 gap-2">
                            <Button
                                text={'Subject'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={handleSubjectClick}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'Object'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={handleObjectClick}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'S/O'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={handleSOClick}
                                sx={{fontWeight: 'bold'}}
                            />
                            <Button
                                text={'ER'}
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                onClick={handleErClick}
                                sx={{fontWeight: 'bold'}}
                            />
                        </div>
                    </div>
                    {/* 病情摘要 */}
                    <Field
                        sx={{alignItems: 'start'}}
                        labelWidth={155}
                        label={
                            <div>
                                A. 病情摘要
                                <div>(主訴及簡短病史)</div>
                            </div>
                        }
                        labelSx={{display: 'flex'}}>
                        <ExtendBtnTextArea
                            defaultHeight={4}
                            maxHeight={400}
                            handleOnChange={(e) => updateTicketInfo(e, 'subject')}
                            value={ticketInfo.subject}
                        ></ExtendBtnTextArea>
                    </Field>
                    {/* 診斷(ICD10) */}
                    <Field label="B. 診斷(ICD10)" labelWidth={155}>
                        {/*空行*/}
                        <div></div>
                    </Field>
                    {/* 診斷1 */}
                    <Field label="診斷1" sx={{mb: 2}} labelWidth={155}>
                        <div className="grid grid-cols-5 gap-2">
                            <TextField
                                className="col-span-1"
                                sx={{gridColumn: 1}}
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentCode}
                                onChange={(e) => updateTicketInfo(e, 'treatmentCode')}
                            />
                            <TextField
                                className="col-span-4"
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentName}
                                disabled
                            />
                        </div>
                    </Field>
                    {/* 診斷2 */}
                    <Field label="診斷2" sx={{mb: 2}} labelWidth={155}>
                        <div className="grid grid-cols-5 gap-2">
                            <TextField
                                className="col-span-1"
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentCode1}
                                onChange={(e) => updateTicketInfo(e, 'treatmentCode1')}
                            />
                            <TextField
                                className="col-span-4"
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentName1}
                                disabled
                            />
                        </div>
                    </Field>
                    {/* 診斷3 */}
                    <Field label="診斷3" labelWidth={155} sx={{mb: 2}}>
                        <div className="grid grid-cols-5 gap-2">
                            <TextField
                                className="col-span-1"
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentCode2}
                                onChange={(e) => updateTicketInfo(e, 'treatmentCode2')}
                            />
                            <TextField
                                className="col-span-4"
                                size={SizeEnum.Small}
                                inputWidth={SizeEnum.Fill}
                                value={ticketInfo.treatmentName2}
                                disabled
                            />
                        </div>
                    </Field>
                    {/* 檢查與治療摘要 */}
                    <Field label="C. 檢查及治療摘要" labelWidth={155}>
                        <ExtendBtnTextArea
                            defaultHeight={4}
                            maxHeight={400}
                            handleOnChange={(e) => updateTicketInfo(e, 'clinicInfo')}
                            value={ticketInfo.clinicInfo}
                        ></ExtendBtnTextArea>
                    </Field>
                    <Divider/>
                    {/* 回覆單 轉診單 */}
                    <div className="flex">
                        <ReferralTurnInModal
                            onUpdateData={handleTurnInData}
                            tranInInfo={ticketInfo?.tranIn}
                            tranFindingList={baseData?.tranFindingList}
                            divisionList={baseData?.divList}
                            zipCodeList={baseData?.zipCodeList}
                        />
                        <ReferralTurnOutModal
                            onUpdateData={handleTurnOutData}
                            tranOutInfo={ticketInfo?.tranOut}
                            divisionList={baseData?.divList}
                            vpnReasonList={baseData?.vpnReasonList}
                            zipCodeList={baseData?.zipCodeList}
                        />
                    </div>
                </>
            }
        />
    )
}

/**
 * 轉出轉入開立作業-轉診開單作業
 * @return {JSX.Element}
 */
const ReferralTicketModal = ({onClose, selectedVisitInfo}) => {
    return (
        <ReferralTicketModalContent
            selectedVisitInfo={selectedVisitInfo}
            onClose={onClose}
        />
    )
}
export default ReferralTicketModal

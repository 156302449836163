import Button from '../../components/Button/Button'
import {ApiErrorStatusEnum, arrayIsEmpty, downloadFile, exportExcelAsBlob, FileExtensionsEnum,} from 'edah_utils/dist'
import React, {useEffect, useMemo, useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {STRATEGIC_ALLIANCE_MAINTAIN} from '../../constants/referralSystem/maintainAreaHeader'
import {ReactComponent as EditIcon} from '../../assets/images/icons/edit.svg'
import {ReactComponent as ExportText} from '../../assets/images/icons/text.svg'
import {Pagination} from '../../components/Pagination/Pagination'
import {ReactComponent as AddBtn} from '../../assets/images/icons/add_circle.svg'
import {BasePopup} from '../../components/Popup/BasePopup'
import StrategicAllianceMaintainForm, {
    ModalStatus,
} from '../../components/ReferralSystem/StrategicAllianceMaintain/StrategicAllianceMaintainForm'
import {queryTranFederal} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {completePrintMode, updatePrintContent,} from '../../redux/Slice/printSlice'
import {useSelector} from 'react-redux'
import store from '../../redux/store'
import {FORM_TYPE} from '../../constants/referralSystem/print'
import {AlertTypeEnum, TextField} from "edah-component/dist";

const Layout = ({children}) => {
    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            {children}
        </div>
    )
}
const Title = ({children, extendClass}) => {
    return (
        <div
            className={`date flex flex-row items-center justify-start ${extendClass}`}
        >
            <div>{children}</div>
        </div>
    )
}

const InputStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'

/**
 * 策略聯盟維護
 * @returns {JSX.Element}
 */
const StrategicAllianceMaintain = () => {
    // 表格相關選項
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    const [keyword, setKeyword] = useState('')

    /**
     * 策略聯盟表單狀態
     */
    const [modalInfo, setModalInfo] = useState({
        show: false,
        modalStatus: ModalStatus.ADD,
    })
    const [activeRow, setActiveRow] = useState({})

    const isPrintMode = useSelector((state) => state.print.isPrintMode)

    const showToast = useToast()

    const handleKeywordChange = (e) => setKeyword(e.target.value)

    /**
     * 查詢按鈕
     */
    const handleClickQuery = () => {
        fetchTranFerderalList(1, filter.pageSize)
    }

    /**
     * 取得策略聯盟清單
     * @param {number} pageNum 頁碼
     * @param {number} pageSize 每頁筆數
     */
    const fetchTranFerderalList = (pageNum, pageSize) => {
        queryTranFederal({
            search: keyword,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                if (arrayIsEmpty(list)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        fetchTranFerderalList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        fetchTranFerderalList(filter.pageNum, pageSize)
    }
    /**
     * 點選上一頁
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        fetchTranFerderalList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        fetchTranFerderalList(page, filter.pageSize)
    }

    /**
     * 表格中的編輯按鈕
     * @param {object} tableRow 要編輯的單筆資料
     * @returns {JSX.Element}
     */
    const Edit = (tableRow) => (
        <div className="flex items-center justify-center">
            <button
                className="text-[#007CDB] flex"
                onClick={() => {
                    setActiveRow(tableRow)
                    setModalInfo({
                        show: true,
                        modalStatus: ModalStatus.EDIT,
                    })
                }}
            >
                <EditIcon/>
                <div className="ml-1">編輯</div>
            </button>
        </div>
    )

    /**
     * boolean 表格呈現
     * @param {object} tableRow 單筆資料
     * @param {object} field
     * @returns {JSX.Element}
     */
    const BooleanDisplay = (tableRow, field) => (
        <div className="flex items-center justify-center">
            <div className="ml-1">{tableRow[field.key] ? '是' : '否'}</div>
        </div>
    )

    const exportText = () => {
        if (dataList.length === 0) {
            return
        }
        const dataArray = []
        dataList.forEach((item) => {
            const data = []
            renderFields.forEach((field) => {
                switch (field.key) {
                    case 'effectDate':
                    case 'endDate':
                        data.push(field.format(item[field.key])) // 時間格式
                        break
                    case 'federalFlag':
                    case 'communityFlag':
                    case 'supportFlag':
                        data.push(item[field.key] ? '是' : '否')
                        break
                    default:
                        data.push(item[field.key])
                }
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: renderFields.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '策略聯盟維護',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     */
    const ExportExcelBtn = () => {
        return (
            <button
                className="flex items-center text-green-600"
                onClick={exportText}
            >
                <ExportText className="mr-1"/>
                匯出
            </button>
        )
    }

    const handleReset = () => {
        setModalInfo({
            modalStatus: ModalStatus.ADD,
            show: false,
        })
        setActiveRow({})
        handleClickQuery()
    }

    /**
     * 列印按鈕
     */
    const handlePrint = () => {
        // 列印單頁資料
        store.dispatch(
            updatePrintContent({
                reportType: FORM_TYPE.federalList,
                printData: dataList,
            })
        )
    }

    /**
     * 開啟列印模式則開啟瀏覽器列印視窗
     * 結束列印則關閉列印模式
     */
    useEffect(() => {
        if (isPrintMode) {
            window.print()
            store.dispatch(completePrintMode())
        }
    }, [isPrintMode])

    /**
     * 轉出清單 table header
     */
    const renderFields = useMemo(() => {
        STRATEGIC_ALLIANCE_MAINTAIN[0] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[0],
            render: Edit,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[5] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[5],
            render: BooleanDisplay,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[6] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[6],
            render: BooleanDisplay,
        }
        STRATEGIC_ALLIANCE_MAINTAIN[7] = {
            ...STRATEGIC_ALLIANCE_MAINTAIN[7],
            render: BooleanDisplay,
        }

        return STRATEGIC_ALLIANCE_MAINTAIN
    }, [])

    return (
        <Layout>
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center">
                    <TextField
                        label="查詢條件"
                        value={keyword}
                        onChange={handleKeywordChange}
                    />
                    <Button
                        classNames={`ml-4 ${ButtonStyle}`}
                        text={'查詢'}
                        onClickFn={handleClickQuery}
                    />
                </div>
                <div>
                    <Button
                        classNames={SecondButtonStyle}
                        text={'列印'}
                        onClickFn={handlePrint}
                    />
                </div>
            </div>
            <div className="flex px-2 mt-2 mb-4 justify-between">
                <button
                    className="flex text-green-600 items-center"
                    onClick={() => {
                        setModalInfo({
                            modalStatus: ModalStatus.ADD,
                            show: true,
                        })
                    }}
                >
                    <AddBtn/>
                    <div className="ml-1">新增內容</div>
                </button>
            </div>
            <CustomTable
                isSelectable={false}
                fields={renderFields}
                dataList={dataList}
                slotButton={<ExportExcelBtn/>}
            />
            <div className="flex justify-end">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={handlePageOnChange}
                    onPageSizeChange={handlePageSizeChange}
                    onPrevPageOnClick={handlePrevPageOnClick}
                    onNextPageOnClick={handleNextPageOnClick}
                />
            </div>
            {
                modalInfo.show && (
                    <BasePopup
                        title={
                            modalInfo.modalStatus === ModalStatus.ADD
                                ? '策略聯盟新增'
                                : '策略聯盟編輯'
                        }
                        width="80%"
                        content={
                            <StrategicAllianceMaintainForm
                                data={activeRow}
                                onClose={() => {
                                    handleReset()
                                }}
                                status={modalInfo.modalStatus}
                            />
                        }
                        closePopupButtonOnClick={() => {
                            handleReset()
                        }}
                    />
                )
            }
        </Layout>
    )
}

export default StrategicAllianceMaintain

import React, {useState} from 'react'
import {
    Card,
    SizeEnum,
    DatePicker,
    Checkbox,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 取得次要按鈕
 * @param text
 * @param onClick
 * @return {Element}
 */
const SecondaryButton = ({text, onClick}) => {
    return (
        <Button
            color={ButtonColorEnum.Secondary}
            size={ButtonSizeEnum.Large}
            variant={ButtonVariantEnum.Outlined}
            onClick={onClick}
            text={text}
            sx={{fontWeight: 'bold'}}
        />
    )
}


/**
 * 轉診外掛單機上傳程式
 * @returns {JSX.Element}
 */
const ReferralPluginUpload = () => {
    const [searchParams, setSearchParams] = useState({
        throwDate: '',
        encounterEndDate: '',
        patientId: '',
    })

    /**
     * 更新查詢欄位value
     * @param {object} e input event
     */

    const updateSearchParams = (e, field) => {
        const value = e.target.value
        setSearchParams({
            ...searchParams,
            [field]: value,
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className=" p-2">
                    {/* 就醫年月選取 */}
                    <DatePicker
                        size={SizeEnum.Small}
                        required
                        value={searchParams.throwDate ? dayjs(searchParams.throwDate) : null}
                        label="拋轉日期"
                        onChange={(newValue) => {
                            updateSearchParams({
                                target: {value: newValue ? newValue.format('YYYY-MM-DD') : ''}
                            }, 'throwDate')
                        }}
                    />
                    <div className="flex space-x-2 mt-2">
                        <SecondaryButton text="0.清除舊資料" onClick={() => {
                        }}/>
                        <SecondaryButton text={"1.拋轉頭檔"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"2.OR"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"3.出院病摘"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"4.PATH"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"8.LAB"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"9.SOAP"} onClick={() => {
                        }}/>
                        <SecondaryButton text={"手動批次"} onClick={() => {
                        }}/>

                        <div className="flex flex-row space-x-3 items-center">
                            <Checkbox
                                label="2-9重拋180天資料"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full h-full flex gap-2">
                    <Card></Card>
                    <Card></Card>
                </div>
            </div>
        </>
    )
}
export default ReferralPluginUpload

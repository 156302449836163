import React, {useEffect, useMemo, useRef, useState} from "react";
import {t} from "i18next";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
} from "edah_utils/dist";
import useToast from "../../hooks/useToast";
import {regsQueryReg} from "../../api/v1/Regs";
import {queryAllDivision, queryAllDoctor, queryJustNameByCategories,} from "../../api/v1/Menu";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    DateRangePicker,
    RadioGroup,
    Search,
    Select,
    SizeEnum,
    TextField,
    Pagination,
    AdvancedDataGrid
} from 'edah-component/dist'
import dayjs from 'dayjs';

/**
 * 掛號查詢頁面
 * @return {JSX.Element}
 */
const RegistrationSearch = () => {
    //時段列表
    const apnList = [
        {
            justnameNo: "1,2,3",
            justnameName: "全部",
        },
        {
            justnameNo: "1",
            justnameName: "早",
        },
        {
            justnameNo: "2",
            justnameName: "午",
        },
        {
            justnameNo: "3",
            justnameName: "晚",
        },
    ];

    // 預設建立時間起迄:起-今日
    const today = new Date();
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, "yyyy-MM-DD"),
        //終止時間迄
        dateEnd: time2String(today, "yyyy-MM-DD"),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
        //醫師代碼
        doctorCode: "",
        // 科別代碼
        departmentCode: "",
        //病歷號
        patientId: "",
        //診間類別
        clinicType: "",
    };
    // 所有醫生選項
    const [allDoctor, setAllDoctor] = useState(null);
    // 所有科別選項
    const [allDepartment, setAllDepartment] = useState(null);
    // 診間類別選項
    const [allClinicType, setAllClinicType] = useState(null);
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null);
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null);
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(
        defaultQueryInputData
    );
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData);
    // 目前院區掛號資料
    const [currentCampusRegistrationArray, setCurrentCampusRegistrationArray] =
        useState(null);
    // 其他院區掛號資料
    const [otherCampusRegistrationArray, setOtherCampusRegistrationArray] =
        useState(null);
    // 目前院區 pagination控制變數
    const [currentCampusPaginationProps, setCurrentCampusPaginationProps] =
        useState({
            //當前頁碼
            currentPage: 1,
            //每頁資料筆數
            pageSize: 10,
            //總資料筆數
            totalItemSize: 0,
            //總頁碼
            totalPageSize: 0,
        });
    // 其他院區 pagination控制變數
    const [otherCampusPaginationProps, setOtherCampusPaginationProps] =
        useState({
            //當前頁碼
            currentPage: 1,
            //每頁資料筆數
            pageSize: 10,
            //總資料筆數
            totalItemSize: 0,
            //總頁碼
            totalPageSize: 0,
        });
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false);
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false);
    // 預設選中的 value
    const [selectedValue, setSelectedValue] = useState('option1');
    //Toast Message Hooks
    const showToast = useToast();
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null);
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null);
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });

    /**
     * 取得所有醫生
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then((res) => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data;
                //設定所有醫生選項
                setAllDoctor(data);
            }
        });
    };

    /**
     * 取得所有科別
     */
    const getQueryAllDepartment = () => {
        queryAllDivision().then((res) => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data;
                //設定所有科別選項
                setAllDepartment(data);
            }
        });
    };

    /**
     * 取得診間類別陣列
     */
    const getClinicTypeArray = () => {
        queryJustNameByCategories({
            // 診間類別
            categories: "CLINIC_TYPE",
        }).then((res) => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res;
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定診間類別資料
                const allObj = [{justnamedNo: "ALL", justnamedName: "全部"}];
                const updateData = !arrayIsEmpty(data)
                    ? [...allObj, ...data]
                    : [...allObj];
                setAllClinicType(updateData);
                setDisplayQueryInputData({
                    ...displayQueryInputData,
                    clinicType: "ALL",
                });
            } else {
                // 取得資料失敗
                setAllClinicType(null);
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 取得醫師下拉選項
     * @returns
     */
    const getDoctorOptionList = () => {
        const splitArray = displayQueryInputData.doctorCode
            ? displayQueryInputData.doctorCode.split(" ")
            : [];
        if (splitArray.length > 2) {
            return [];
        }

        if (splitArray.length === 2) {
            return allDoctor?.filter(
                (doctor) =>
                    doctor.userNo.includes(splitArray[0]) &&
                    doctor.userName.includes(splitArray[1])
            );
        } else {
            return fuzzySearchObjects(
                allDoctor,
                displayQueryInputData.doctorCode
            );
        }
    };

    /**
     * 取得科別代碼下拉選項
     * @returns
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode
            ? displayQueryInputData.departmentCode.split(" ")
            : [];
        if (splitArray.length > 2) {
            return [];
        }

        if (splitArray.length === 2) {
            return allDepartment?.filter(
                (doctor) =>
                    doctor.divNo.includes(splitArray[0]) &&
                    doctor.divName.includes(splitArray[1])
            );
        } else {
            return fuzzySearchObjects(
                allDepartment,
                displayQueryInputData.departmentCode
            );
        }
    };

    /**
     * 日期範圍變更事件
     * @param  newValue {Array} 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeOnChange = (newValue) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: newValue[0] ? dayjs(newValue[0]).format('YYYY-MM-DD') : null,
            dateEnd: newValue[1] ? dayjs(newValue[1]).format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 時段模式改變時
     * @param e {Event} 事件
     */
    const handleApnModeOnChange = (e) => {
        const selectedApn = e.target.value;
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: selectedApn
        });
    };

    /**
     * 醫師代號方框的值變動時
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: value,
        });
    };

    /**
     * 醫師代號方框取得焦點時
     * @return {void}
     */
    const handleInputDoctorNoOnFocus = () => {
        setDoctorOptionList(getDoctorOptionList());
        setShowDoctorDropDown(true);
    };

    /**
     * 選取醫師下拉選項時
     * @param doctor {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (doctor) => {
        const value = doctor?.userNo && doctor?.userName ? `${doctor?.userNo} ${doctor?.userName}` : null
        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: value,
        });

        setShowDoctorDropDown(false);
    };

    /**
     * 科別代碼方框的值變動時
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: value,
        });
    };

    /**
     * 科別代碼方框取得焦點時
     * @return {void}
     */
    const handleInputDepartmentNoOnFocus = () => {
        setDepartmentOptionList(getDepartmentOptionList());
        setShowDepartmentDropDown(true);
    };

    /**
     * 選取科別下拉選項時
     * @param department {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (department) => {
        const value = department?.divNo && department?.divName ? `${department?.divNo} ${department?.divName}` : null

        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: value,
        });

        setShowDepartmentDropDown(false);
    };

    /**
     * 病歷號輸入框值變更事件
     * @param e {Event} 事件
     */
    const handleInputPatientIdOnChange = (e) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            patientId: e.target.value,
        });
    };

    /**
     * 診別輸入框值變更事件
     * @param value {String} 值
     */
    const handleInputClinicTypeOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            clinicType: value,
        });
    };

    /**
     * 診別輸入框取得焦點時
     * @param e {Event} 事件
     */
    const handleInputClinicTypeOnFocus = (e) => {
        setShowClinicTypeDropDown(true);
    };

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        // 查詢最大天數
        const maxDays = 30
        //把日期從 string 轉回 date object
        const dateStart = dayjs(displayQueryInputData.dateStart);
        const dateEnd = dayjs(displayQueryInputData.dateEnd);
        // 計算兩日期相差天數
        const diffIndays = dateEnd.diff(dateStart, "day");

        if (
            displayQueryInputData.dateEnd &&
            displayQueryInputData.dateStart > displayQueryInputData.dateEnd
        ) {
            showToast({
                message: "起始日期不可大於結束日期",
                type: AlertTypeEnum.Error,
            });
            return;
        }

        // 查詢區間不可超過30天，跳出提示
        if( diffIndays > (maxDays - 1) ) {
            // 當結束日期大於開始日期 30 天自動填入結束日期
            const autofillDateEnd = dateStart.add((maxDays - 1), "day").format("YYYY-MM-DD");
            setDisplayQueryInputData(prevData => ({...prevData, dateEnd: autofillDateEnd}));
            showToast({
                message: "查詢區間不可超過30天",
                type: AlertTypeEnum.Error,
            });
            return;
        }

        //取得當前院區掛號資料
        queryCurrentCampusReg(
            displayQueryInputData,
            1,
            currentCampusPaginationProps.pageSize
        );
    };

    const queryCurrentCampusReg = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);

        regsQueryReg({
            //起始日期
            startDate: time2String(
                queryInputObj.dateStart,
                "YYYY-MM-DD 00:00:00"
            ),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            // 醫師代碼
            doctorNo:
                queryInputObj?.doctorCode?.indexOf(" ") > 0
                    ? queryInputObj?.doctorCode?.split(" ")[0]
                    : queryInputObj?.doctorCode,
            // 科別代碼
            divNo:
                queryInputObj?.departmentCode?.indexOf(" ") > 0
                    ? queryInputObj?.departmentCode?.split(" ")[0]
                    : queryInputObj?.departmentCode,
            //病歷號
            patientId: queryInputObj.patientId,
            //診室號
            clinicType:
                queryInputObj.clinicType === "ALL"
                    ? ""
                    : queryInputObj.clinicType,
            //是否排除退掛
            excludeCancel: true,
            //時段
            apn:
                queryInputObj.apn === "1" ||
                queryInputObj.apn === "2" ||
                queryInputObj.apn === "3"
                    ? parseInt(queryInputObj.apn)
                    : null,
            pageNum: page, //第幾頁
            pageSize: pageSize, //一頁幾筆資料
        }).then((res) => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res;
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定標語類別資料
                setCurrentCampusRegistrationArray(data.dataList);

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
            } else {
                // 取得資料失敗
                setCurrentCampusRegistrationArray([]);

                setCurrentCampusPaginationProps({
                    ...currentCampusPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 目前院區班表頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onCurrentCampusPaginationPageOnChange = (page, pageSize) => {
        queryCurrentCampusReg(
            queryInputData,
            page,
            pageSize
        );
    };

    /**
     * 其他院區班表頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onOtherCampusPaginationPageOnChange = (page, pageSize) => {
    };


    /**
     * 取得table醫師欄位內容
     * @param scheduleData {Object} 掛號資料
     * @returns
     */
    const getTableDoctorFieldContent = (scheduleData) => {
        let doctorContent = "";
        if (!stringIsEmpty(scheduleData.locumDoctorName)) {
            doctorContent = `${scheduleData.locumDoctorName}[代]`;
        } else {
            if (!stringIsEmpty(scheduleData.doctorName)) {
                doctorContent = scheduleData.doctorName;
            }
        }
        return doctorContent;
    };

    /**
     * 取得給診號欄位內容
     * @param registrationItem {Object} 掛號資料
     * @returns
     */
    const getViewNoFieldContent = (registrationItem) => {
        if (objectIsEmpty(registrationItem)) {
            return "";
        }

        if (registrationItem.giveNumType === "1") {
            //依診號
            return `${registrationItem.viewNo}`;
        } else if (registrationItem.giveNumType === "2") {
            //依時間
            if (
                !stringIsEmpty(registrationItem.startViewDatetime) &&
                !stringIsEmpty(registrationItem.endViewDatetime)
            ) {
                const start = time2String(
                    registrationItem.startViewDatetime,
                    "HH:mm"
                );
                const end = time2String(
                    registrationItem.endViewDatetime,
                    "HH:mm"
                );

                return `${start} ~ ${end}`;
            }
        }

        return "";
    };

    // 表頭資料
    const systemColumns = [
        {
            field: 'encounterId',
            headerName: '就醫號',
            width: 100,
        },
        {
            field: 'patientId',
            headerName: t("general.patientNo"),
            width: 120,
        },
        {
            field: 'patientName',
            headerName: t("general.username"),
            width: 120,
        },
        {
            field: 'idNo',
            headerName: t("general.idNumber"),
            width: 150,
        },
        {
            field: 'birthDate',
            headerName: t("general.birthday"),
            width: 120,
            renderCell: (params) => time2String(params.value, "YYYY-MM-DD"),
        },
        {
            field: 'clinicTypeName',
            headerName: '診別',
            width: 100,
        },
        {
            field: 'encounterDate',
            headerName: t("Regs.general.clinicDate"),
            width: 120,
            renderCell: (params) => time2String(params.value, "YYYY-MM-DD"),
        },
        {
            field: 'apnName',
            headerName: t("Regs.general.timeSlot"),
            width: 100,
        },
        {
            field: 'doctorName',
            headerName: t("general.doctor"),
            width: 120,
        },
        {
            field: 'divName',
            headerName: t("general.department"),
            width: 120,
        },
        {
            field: 'clinicName',
            headerName: t("Regs.general.clinicName"),
            width: 180,
        },
        {
            field: 'viewNo',
            headerName: '就診號',
            width: 100,
        },
    ];


    /**
     * 第一次執行時
     */
    useMemo(() => {
        if (arrayIsEmpty(allDoctor)) {
            getQueryAllDoctor();
        }
        if (arrayIsEmpty(allDepartment)) {
            getQueryAllDepartment();
        }
        if (arrayIsEmpty(allClinicType)) {
            getClinicTypeArray();
        }

        handleSearchOnClick();
    }, []);

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList());
    }, [displayQueryInputData.doctorCode, displayQueryInputData.departmentCode, allDoctor]);

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList());
    }, [displayQueryInputData.departmentCode, displayQueryInputData.doctorCode, allDepartment]);

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="space-y-2.5">
                <div className="flex flex-row flex-wrap items-center">
                    <div className="date flex flex-row items-center justify-start space-x-2.5 mr-3.5">
                        <div className="flex flex-row items-center">
                            <DateRangePicker
                                size="small"
                                value={[
                                    displayQueryInputData.dateStart ? dayjs(displayQueryInputData.dateStart) : null,
                                    displayQueryInputData.dateEnd ? dayjs(displayQueryInputData.dateEnd) : null
                                ]}
                                required
                                onChange={handleDateRangeOnChange}
                            />
                        </div>
                    </div>
                    {/*醫師*/}
                    <div className="flex items-center mr-2">
                        <Search
                            inputLabel={t('general.doctor')}
                            required
                            onChange={(_e, value) => {
                                handleDoctorOptionOnClick(value)
                            }}
                            onInputChange={(_e, value) =>
                                handleInputDoctorNoOnChange(value)
                            }
                            disablePortal={false}
                            options={doctorOptionList}
                            showLabel
                            getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                        />
                    </div>
                    {/*科別*/}
                    <div className="flex items-center mr-2">
                        <Search
                            required
                            inputLabel={t('general.department')}
                            onChange={(_e, value) => {
                                handleDepartmentOptionOnClick(value)
                            }}
                            onInputChange={(_e, value) => {
                                handleInputDepartmentNoOnChange(value)
                            }
                            }
                            disablePortal={false}
                            options={departmentOptionList}
                            showLabel
                            getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                        />
                    </div>
                    {/*時段*/}
                    <div className="flex flex-row justify-center items-center gap-[12px] text-base font-normal mr-5">
                        <RadioGroup
                            row
                            labelProps={{text: t('Regs.general.timeSlot')}}
                            size={SizeEnum.Medium}
                            value={displayQueryInputData.apn}
                            optionProps={{
                                options: apnList.map(item => ({
                                    label: item.justnameName,
                                    value: item.justnameNo
                                }))
                            }}
                            onChange={handleApnModeOnChange}/>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap items-center">
                    <div className="flex items-center mr-2">
                        <TextField
                            label={t('Regs.general.medicalRecordNumber')}
                            value={displayQueryInputData.patientId}
                            onChange={handleInputPatientIdOnChange}
                        />
                    </div>
                    <div className="flex items-center mr-2 text-left">
                        <Select
                            data={{
                                label: "診別",
                                options: allClinicType?.map((item) => ({
                                    value: item.justnamedNo,
                                    label: item.justnamedName,
                                })) || [],
                            }}
                            value={displayQueryInputData.clinicType}
                            onChange={handleInputClinicTypeOnChange}
                            notched={true}
                            displayEmpty={true}
                        />
                    </div>
                    {/*查詢*/}
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                        {t("general.query")}
                    </Button>
                </div>
            </div>
            {/*查詢table*/}
            <div className="flex flex-row space-x-3">
                {/*系統登錄院區*/}
                <div className='w-1/2 relative'>
                    <Card title='系統登錄院區'>
                        <div className="text-xl absolute right-5 top-2.5">目前院區：屏東義大醫院</div>
                        {/*系統登錄院區 Table*/}
                        <AdvancedDataGrid
                            rows={currentCampusRegistrationArray || []}
                            columns={systemColumns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick={false}
                            disableColumnMenu={true}
                            height={'700px'}
                            getRowHeight={(params) => 56}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <div className="flex justify-end w-[770px] mt-1.5">
                                <Pagination
                                    totalPageSize={
                                        currentCampusPaginationProps.totalPageSize
                                    }
                                    page={
                                        currentCampusPaginationProps.currentPage
                                    }
                                    pageSize={currentCampusPaginationProps.pageSize}
                                    totalSize={
                                        currentCampusPaginationProps.totalItemSize
                                    }
                                    onPageOnChange={(page, pageSize) =>
                                        onCurrentCampusPaginationPageOnChange(page, pageSize)
                                    }
                                    showFirstButton
                                    showLastButton
                                />
                            </div>

                        </div>
                    </Card>
                </div>
                {/*其他院區*/}
                <div className="w-1/2 relative">
                    <Card title='其他院區'>
                        <div className='absolute right-2 top-1 text-left'>
                            <Select
                                data={{
                                    label: "其他院區",
                                    options: [
                                        {
                                            value: "義大醫院",
                                            label: "義大醫院",
                                        },
                                    ],
                                }}
                                value="義大醫院"
                                notched={true}
                            />
                        </div>
                        {/*其他院區 Table*/}
                        <AdvancedDataGrid
                            rows={otherCampusRegistrationArray}
                            columns={systemColumns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick={false}
                            disableColumnMenu={true}
                            height={'700px'}
                            getRowHeight={(params) => 56}
                        />
                        {/*分頁*/}
                        <div className="flex justify-end w-full mt-1.5">
                            <div className="flex justify-end w-[770px] mt-1.5">
                                <Pagination
                                    totalPageSize={
                                        otherCampusPaginationProps.totalPageSize
                                    }
                                    currentPage={otherCampusPaginationProps.currentPage}
                                    pageSize={otherCampusPaginationProps.pageSize}
                                    totalSize={otherCampusPaginationProps.totalItemSize}
                                    onPageOnChange={(page, pageSize) =>
                                        onOtherCampusPaginationPageOnChange(page, pageSize)
                                    }
                                    showFirstButton
                                    showLastButton
                                />
                            </div>

                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default RegistrationSearch;

import React, {useEffect, useMemo, useState} from 'react'
import {
    ApiErrorStatusEnum,
    objectIsEmpty,
    time2String,
    validDateFormat,
    downloadFile,
    exportExcelAsBlob,
    FileExtensionsEnum,
} from 'edah_utils/dist'
import {REPLY_CASE_FIELDS} from '../../constants/referralSystem/tranInCase'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import {ReactComponent as EditIcon} from '../../assets/images/icons/edit.svg'
import {
    trancaseEditTranIn,
    trancaseQueryBasicData,
    trancaseQueryTranInBySearch,
} from '../../api/v1/Rmis'
import EditTranInModal from '../../components/ReferralSystem/TanInCaseList/EditTranInModal'
import QueryToolsModal from '../../components/ReferralSystem/QueryToolsModal'
import {BasePopup} from '../../components/Popup/BasePopup'
import {Pagination} from '../../components/Pagination/Pagination'
import TranBackCaseListModal from '../../components/ReferralSystem/TanInCaseList/TranBackCaseListModal'
import useToast from '../../hooks/useToast'
import {DATETIME_FORMAT} from '../../constants/common'
import {ReactComponent as ExcelIcon} from '../../assets/images/icons/excel_icon.svg'
import {
    Checkbox,
    TextField,
    AlertTypeEnum,
    SizeEnum,
    DateRangePicker,
    Select,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from 'edah-component/dist'
import dayjs from "dayjs"
import {t} from 'i18next'
import {IconEnum} from "edah-component/dist/index";


/**
 * 轉入單個管作業_清單
 * @return {JSX.Element}
 */
const TranInCaseList = () => {
    //預設資料
    const defaultFormParams = {
        //開始時間
        encounterStartDate: '',
        //結束時間
        encounterEndDate: '',
        //病歷號碼
        patientId: '',
        //醫療層級
        hospGrade: 'ALL',
        //急救責任照顧醫院, 打勾=1, 不打勾=0
        aidGradeFlag: 0,
        //個管顯示
        acceptFlag: 'ALL',
        //電子轉診單
        etranNoFlag: 'ALL',
        // 偏鄉離島
        ruralType: 'ALL',
    }
    //基礎資料
    const [baseData, setBaseData] = useState([])
    //表單參數
    const [formParams, setFormParams] = useState(defaultFormParams)
    //資料清單
    const [dataList, setDataList] = useState([])
    //是否顯示編輯彈窗
    const [isShowEditModal, setIsShowEditModal] = useState(false)
    //
    const [isShowNoReplyLetterModal, setIsShowNoReplyLetterModal] =
        useState(false)
    // 查詢工具
    const [isShowQueryToolsModal, setIsShowQueryToolsModal] = useState(false)
    // pagination
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })
    //編輯模式使用的資料
    const [info, setInfo] = useState({})
    // 就醫日期欄位檢核狀態
    const [encounterDateInputState, setEncounterDateInputState] = useState({
        encounterStartDate: null,
        encounterEndDate: null,
    })
    // toast message
    const showToast = useToast()

    /**
     * 編輯模塊
     * @param {object} data
     * @return {JSX.Element}
     */
    const Editor = (data) => (
        <Button
            color={ButtonColorEnum.Primary}
            icon={IconEnum.Edit}
            size={ButtonSizeEnum.Large}
            text={t('general.edit')}
            variant={ButtonVariantEnum.Text}
            onClick={() => editModalOnClick(data)}
        />
    )

    /**
     * 匯出EXCEL
     * @returns {void}
     */
    const exportExcel = () => {
        const selectedList = dataList.filter((item) => item.isSelected)
        if (selectedList.length === 0) {
            return
        }
        const dataArray = []
        const fields = REPLY_CASE_FIELDS.slice(1)
        selectedList.forEach((item) => {
            const data = []
            fields.forEach((field) => {
                const value = field.format
                    ? field.format(item[field.key])
                    : item[field.key]

                data.push(value)
            })
            dataArray.push(data)
        })
        const sheets = [
            {
                titles: fields.map((item) => item.name),
                data: dataArray,
            },
        ]
        downloadFile(
            exportExcelAsBlob(sheets),
            '轉入單個管作業',
            FileExtensionsEnum.XLSX
        )
    }

    /**
     * 匯出Excel
     * @return {JSX.Element}
     */
    const ExportExcelBtn = () => {
        return (
            <Button
                color={ButtonColorEnum.Success}
                icon={IconEnum.Export}
                size={ButtonSizeEnum.Large}
                text={t('general.export')}
                variant={ButtonVariantEnum.Text}
                onClick={exportExcel}
            />
        )
    }

    /**
     * 表格顯示編輯按鈕
     */
    const renderFields = useMemo(() => {
        REPLY_CASE_FIELDS[0] = {
            ...REPLY_CASE_FIELDS[0],
            render: Editor,
        }
        return REPLY_CASE_FIELDS
    })

    /**
     * 就醫日期起始日期更新時
     * @param {array} newDates
     * @return {void}
     */
    const handleEncounterDateRangeOnChange = (newDates) => {
        const [startDate, endDate] = newDates;

        setFormParams({
            ...formParams,
            encounterStartDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            encounterEndDate: endDate ? endDate.format('YYYY-MM-DD') : '',
        });
    };

    /**
     * 病歷號更新時
     * @param e {event} input event
     * @return {void}
     */
    const handlePatientIdOnChange = (e) =>
        setFormParams({...formParams, patientId: e.target.value})

    /**
     * 醫療層級更新時
     * @param value {String} input string
     * @return {void}
     */
    const handleHospGradeOnChange = (value) =>
        setFormParams({...formParams, hospGrade: value})

    /**
     * 個管顯示更新時
     * @param value {String} input string
     * @return {void}
     */
    const handleAcceptFlagOnChange = (value) =>
        setFormParams({...formParams, acceptFlag: value})

    /**
     * 電子轉診單更新時
     * @param value {String} input string
     * @return {void}
     */
    const handleTranNoFlagOnChange = (value) =>
        setFormParams({...formParams, etranNoFlag: value})

    /**
     * 急救責任照顧醫院改變時
     * @return {void}
     */
    const handleAidGradeFlagOnChange = () => {
        // 0:全部、1: 是急救責任照顧醫院
        const IS_ALL = 0
        const IS_AID_GRADE = 1
        setFormParams({
            ...formParams,
            aidGradeFlag:
                formParams.aidGradeFlag === IS_AID_GRADE
                    ? IS_ALL
                    : IS_AID_GRADE,
        })
    }

    /**
     * 偏鄉離島更新
     * @param value {String} input value
     * @return {void}
     */
    const handleRuralTypeOnChange = (value) => {
        setFormParams({
            ...formParams,
            ruralType: value,
        })
    }

    /**
     * 查詢動作
     * @return {void}
     */
    const handleSubmit = () => {
        getTranCaseQueryTranInBySearch(1, filter.pageSize)
    }

    /**
     * 點選 pagination 頁碼
     * @param {string} page 目前頁碼
     * @return {void}
     */
    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        getTranCaseQueryTranInBySearch(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param {object} event
     * @return {void}
     */
    const onPageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getTranCaseQueryTranInBySearch(filter.pageNum, pageSize)
    }

    /**
     * 點選上一頁
     */
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        getTranCaseQueryTranInBySearch(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     */
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        getTranCaseQueryTranInBySearch(page, filter.pageSize)
    }

    /**
     * 開啟轉入單個管作業_維護modal-點擊事件
     * @param data {object} 點選編輯的單筆資料
     * @return {void}
     */
    const editModalOnClick = (data) => {
        //設定編輯模式使用的資料
        setInfo({
            //就醫號
            encounterId: data.encounterId,
            //就醫日期
            encounterDate: data.encounterDate,
            //門住類別(O門診/E急診/I住院)
            inpOpd: data.inpOpd,
            //病歷號
            patientId: data.patientId,
        })
        //顯示編輯modal
        setIsShowEditModal(true)
    }

    /**
     * api 傳送日期格式需轉換成 YYYY-MM-DD 00:00:00
     * @param {string|null} value
     * @returns 日期 YYYY-MM-DD 00:00:00
     */
    const generateDate = (datetime) => {
        return datetime ? time2String(datetime, DATETIME_FORMAT) : ''
    }

    /**
     * 轉入個管作業_維護modal 存檔
     * @param caseDetail {Object}
     * @return {void}
     */
    const handleEditTranInModalOnSave = (caseDetail) => {
        const params = {
            //版本鎖定
            lockVersion: caseDetail.lockVersion,
            //就醫號
            encounterId: caseDetail.encounterId,
            //就醫日期
            encounterDate: caseDetail.encounterDate,
            //門住類別(O門診/E急診/I住院)
            inpOpd: caseDetail.inpOpd,
            //病歷號
            patientId: caseDetail.patientId,
            //聯絡方式
            contact: caseDetail.contact,
            //連絡電話
            contactTel: caseDetail.contactTel,
            //聯絡地址
            contactAddress: caseDetail.contactAddress,
            //科別
            divNo: caseDetail.divNo,
            //醫師代碼
            doctorNo: caseDetail.doctorNo,
            //主診斷
            treatmentCode: caseDetail.treatmentCode,
            //次診斷1
            treatmentCode1: caseDetail.treatmentCode1,
            //次診斷2
            treatmentCode2: caseDetail.treatmentCode2,
            //病情摘要
            subject: caseDetail.subject,
            //建議事項
            advise: caseDetail.advise,
            //藥物過敏
            allergy: caseDetail.allergy,
            //檢查及治療摘要
            clinicInfo: caseDetail.clinicInfo,
            //轉診結果:1.痊癒、2.死亡、3.確定可出院、4.其他、5.須轉院，若為5.須轉院  需打REASON_OTHER
            tranFinding: caseDetail.tranFinding,
            //轉出日期
            tranDate: caseDetail.tranDate,
            //轉院原因
            reasonOther: caseDetail.reasonOther,
            //緊急傷病回復日期時間(mars_date)，若是急診才顯示要填資料
            urgentReplyDatetime: caseDetail.urgentReplyDatetime,
            //開單日期時間
            orderDatetime: caseDetail.orderDatetime,
            //填寫狀態，0.暫存、3.完成
            opdStatus: caseDetail.opdStatus,
            //手工轉診單單號
            tranNo: caseDetail.tranNo,
            //電子轉診單單號
            etranNo: caseDetail.etranNo,
            //轉入院所
            hospNoIn: caseDetail.hospNoIn,
            //轉入轉診目的代碼
            tranPurposeIn: caseDetail.tranPurposeIn,
            //轉入轉診目的備註
            tranPurposeRemarkIn: caseDetail.tranPurposeRemarkIn,
            //回覆日期
            replyDate: generateDate(caseDetail.replyDate),
            //回覆單填寫
            inTraninCompleteFlag: caseDetail.inTraninCompleteFlag,
            //病摘完成
            inSummaryCompleteFlag: caseDetail.inSummaryCompleteFlag,
            //轉診資料同意書  同意/拒絕需傳boolean
            inTrandataAgreeFlag: caseDetail.inTrandataAgreeFlag === '1',
            //轉介回函回覆日期
            inReceiptDate: generateDate(caseDetail.inReceiptDate),
            //病摘回覆日期
            inSummaryReplyDate: generateDate(caseDetail.inSummaryReplyDate),
            //回覆單回收日期
            inRecoveryDate: generateDate(caseDetail.inRecoveryDate),
            //無法列印病摘原因、需搭配設定檔點選原因,顯示原因名稱.
            inUnprintSummaryReason: caseDetail.inUnprintSummaryReason,
            // 對方醫院開單日期
            inOrderDate: generateDate(caseDetail.inOrderDate),
            //收到外院回覆單日期
            inReplyDate: generateDate(caseDetail.inReplyDate),
            //外院回覆狀態，需搭配設定檔點選原因,顯示原因名稱.
            inReplyStatus: caseDetail.inReplyStatus,
            //有無轉診單
            inTranPaperFlag: caseDetail.inTranPaperFlag,
            //無轉診單的原因、需搭配設定檔點選原因,顯示原因名稱
            inTranPaperReason: caseDetail.inTranPaperReason,
            //特殊報告回覆日期
            inSpecialReplyDate: generateDate(caseDetail.inSpecialReplyDate),
            //癌症篩檢
            inCancerFlag: caseDetail.inCancerFlag,
            //備註
            inRemark: caseDetail.inRemark,
            //抽單註記(預住院/住院取消後,紙本單完成註記)
            inPickOut: caseDetail.inPickOut,
            //抽單日期
            inPickDate: caseDetail.inPickDate,
            //註銷否
            cancelFlag: caseDetail.cancelFlag,
            //回覆單上傳日期時間
            uploadDatetime: caseDetail.uploadDatetime,
            // 院所層級
            hospGrade: caseDetail.hospGrade,
            // 預住院單對應的就醫號
            inpEncounterId: caseDetail.inpEncounterId,
            // 出院日期
            dischargeDate: generateDate(caseDetail.dischargeDate),
            // 收到轉診單日期
            tranInReceiveDate: generateDate(caseDetail.tranInReceiveDate),
            // 床號
            bedNo: caseDetail.bedNo,
        }
        //修改轉入單
        trancaseEditTranIn(params).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                setIsShowEditModal(false)
                getTranCaseQueryTranInBySearch(filter.pageNum, filter.pageSize)
                handleEditTranInModalOnClose()
            } else {
                //顯示Toast Message
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 開啟未收到轉回元醫療院所名單modal
     * @return {void}
     */
    const showNoReplyLetterModal = () => {
        setIsShowNoReplyLetterModal(true)
    }

    /**
     * 開啟查詢工具
     * @return {void}
     */
    const showQueryToolModal = () => {
        setIsShowQueryToolsModal(true)
    }

    /**
     * 全選資料
     * @param {boolean} isAllSelected
     * @return {void}
     */
    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     * @return {void}
     */
    const toggleItem = (isChecked, selectedItem) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.encounterId === item.encounterId) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 編輯表單關閉時
     * @return {void}
     */
    const handleEditTranInModalOnClose = () => {
        //清除編輯模式使用的資料
        setInfo({})
        //關閉編輯modal
        setIsShowEditModal(false)
    }

    /**
     * 取得轉診基礎資料
     * @return {void}
     */
    const getTranCaseQueryBasicData = () => {
        trancaseQueryBasicData({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData(res.data)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得查詢轉入單清單
     * @return {void}
     */
    const getTranCaseQueryTranInBySearch = (pageNum, pageSize) => {
        const encounterStartDateIsValid = validDateFormat(
            formParams.encounterStartDate
        )
        const encounterEndDateIsValid = validDateFormat(
            formParams.encounterEndDate
        )
        setEncounterDateInputState({
            encounterStartDate: encounterStartDateIsValid,
            encounterEndDate: encounterEndDateIsValid,
        })
        if (!encounterStartDateIsValid || !encounterEndDateIsValid) {
            return
        }
        trancaseQueryTranInBySearch({
            //開始時間
            encounterStartDate: `${formParams.encounterStartDate} 00:00:00`,
            //結束時間
            encounterEndDate: `${formParams.encounterEndDate} 00:00:00`,
            //醫院層級
            hospGrade: formParams.hospGrade,
            //急救責任照顧醫院，0:全部、1: 是急救責任照顧醫院
            aidGradeFlag: formParams.aidGradeFlag,
            //偏鄉地區註記 0:一般醫院、1:偏鄉醫院、2:離島醫院、ALL:全部
            ruralType: formParams.ruralType,
            //電子轉診單
            etranNoFlag: formParams.etranNoFlag,
            //收案註記
            acceptFlag: formParams.acceptFlag,
            //病歷號
            patientId: formParams.patientId,
            // 頁碼
            pageNum,
            // 每頁筆數
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list =
                    res.data.dataList?.map((item) => {
                        return {
                            ...item,
                            isSelected: false,
                        }
                    }) || []
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                setDataList(list)
                setFilter({
                    ...filter,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageSize,
                    pageNum,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 第一次執行時取得轉診基礎資料
     */
    useEffect(() => getTranCaseQueryBasicData(), [])

    return (
        <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
            <div>
                <div className="flex flex-wrap p-2">
                    <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                        <DateRangePicker
                            size={SizeEnum.Small}
                            required
                            localeText={{
                                start: '就醫開始日期',
                                end: '就醫結束日期',
                            }}
                            value={[
                                formParams.encounterStartDate ? dayjs(formParams.encounterStartDate) : null,
                                formParams.encounterEndDate ? dayjs(formParams.encounterEndDate) : null,
                            ]}
                            onChange={handleEncounterDateRangeOnChange}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4">
                        <TextField
                            label="病歷號"
                            value={formParams.patientId}
                            onChange={(e) => handlePatientIdOnChange(e)}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                        {!objectIsEmpty(baseData) &&
                            <Select
                                data={{
                                    label: '醫療層級',
                                    options: baseData.hospGradeList.map((item) => ({
                                        value: item.hospGrade,
                                        label: item.hospGradeName
                                    })) || []
                                }}
                                notched={true}
                                displayEmpty={true}
                                value={formParams.hospGrade}
                                onChange={handleHospGradeOnChange}
                                width='Small'
                            />} 
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                        {!objectIsEmpty(baseData) &&
                            <Select
                                data={{
                                    label: '個管顯示',
                                    options:baseData.acceptFlagList.map((item) => ({
                                        value: item.acceptFlag,
                                        label: item.acceptFlagName
                                    })) || []
                                }}
                                value={formParams.acceptFlag}
                                onChange={handleAcceptFlagOnChange}
                                notched={true}
                                width='Small'
                            />}
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                        {!objectIsEmpty(baseData) &&
                            <Select
                                data={{
                                    label: '電子轉診單',
                                    options: baseData.etranNoFlagList.map((item) => ({
                                        value: item.etranNoFlag,
                                        label: item.etranNoFlagName
                                    })) || []
                                }}
                                value={formParams.etranNoFlag}
                                onChange={handleTranNoFlagOnChange}
                                notched={true}
                                width='Medium'
                            />}
                    </div>
                    <div className="flex flex-row items-center justify-start mb-4 mr-4 text-left">
                        {!objectIsEmpty(baseData) && 
                            <Select
                                data={{
                                    label: '偏鄉離島',
                                    options: baseData.ruralTypeList.map((item) => ({
                                        value: item.ruralType,
                                        label: item.ruralTypeName
                                    }))
                                }}
                                value={formParams.ruralType}
                                onChange={handleRuralTypeOnChange}
                                notched={true}
                                width='Small'
                        />}
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-between p-2 mb-2">
                    <div className="flex flex-wrap items-center">
                        <div className="mr-2">
                            <Checkbox
                                label="急救責任照顧醫院"
                                id="aidGrade"
                                name="aidGrade"
                                checked={formParams.aidGradeFlag === 1}
                                onChange={handleAidGradeFlagOnChange}
                            />
                        </div>
                        <Button
                            color={ButtonColorEnum.Primary}
                            size={ButtonSizeEnum.Medium}
                            text="查詢"
                            onClick={handleSubmit}
                            sx={{fontWeight: 'bold'}}
                        />
                    </div>
                    <div className="flex gap-2">
                        <Button
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            text="床號、入院科別、入出院日、轉入單確認"
                            onClick={()=>{}}
                            sx={{fontWeight: 'bold'}}
                        />
                        <Button
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            text="列印轉回原醫療院所表單"
                            onClick={showNoReplyLetterModal}
                            sx={{fontWeight: 'bold'}}
                        />
                        <Button
                            color={ButtonColorEnum.Secondary}
                            size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Outlined}
                            text="查詢工具"
                            onClick={showQueryToolModal}
                            sx={{fontWeight: 'bold'}}
                        />
                    </div>
                </div>
            </div>
            <CustomTable
                isSelectable={true}
                fields={renderFields}
                dataList={dataList}
                toggleAll={toggleAll}
                toggleItem={toggleItem}
                slotButton={<ExportExcelBtn/>}
            />
            <div className="flex justify-end mt-2">
                <Pagination
                    pageSize={filter.pageSize}
                    totalSize={filter.totalItemSize}
                    currentPage={filter.pageNum}
                    totalPageSize={filter.totalPageSize}
                    onPageOnChange={onPageOnChange}
                    onPageSizeChange={onPageSizeChange}
                    onPrevPageOnClick={onPrevPageOnClick}
                    onNextPageOnClick={onNextPageOnClick}
                />
            </div>
            {/*編輯視窗*/}
            {isShowEditModal && (
                <EditTranInModal
                    info={info}
                    inpOpdList={baseData.inpOpdList}
                    tranPurposeInList={baseData.tranPurposeInList}
                    doctorList={baseData.doctorList}
                    divisionList={baseData.divList}
                    inTrandataAgreeFlagList={baseData.inTrandataAgreeFlagList}
                    inReplyStatusList={baseData.inReplyStatusList}
                    inUnprintSummaryReasonList={
                        baseData.inUnprintSummaryReasonList
                    }
                    zipCodeList={baseData.zipCodeList}
                    inTranPaperReasonList={baseData.inTranPaperReasonList}
                    onClose={handleEditTranInModalOnClose}
                    onConfirm={handleEditTranInModalOnSave}
                />
            )}
            {/*轉診查詢工具*/}
            {isShowQueryToolsModal && (
                <Dialog
                    open={true}
                    title={"轉診查詢工具"}
                    variant={DialogVariant.CONFIRM}
                    paperStyleProps={{width: '90%'}}
                    content={
                        <QueryToolsModal/>
                    }
                    onClose={() => setIsShowQueryToolsModal(false)}
                    muiDialogActionsProps={{sx:{display:'none'}}}
                    muiDialogContentProps={{dividers: true}}
                />
            )}
            {/*列印轉回原醫療院所表單*/}
            {isShowNoReplyLetterModal && (
                <Dialog
                    open={true}
                    title={"列印轉回原醫療院所名單"}
                    variant={DialogVariant.CONFIRM}
                    paperStyleProps={{width: '80%'}}
                    content={
                        <TranBackCaseListModal
                            inReplyStatusList={baseData.inReplyStatusList}
                            zipCodeList={baseData?.zipCodeList}
                        />
                    }
                    onClose={() => setIsShowNoReplyLetterModal(false)}
                    muiDialogActionsProps={{sx:{display:'none'}}}
                    muiDialogContentProps={{dividers: true}}
                />
            )}
        </div>
    )
}

export default TranInCaseList

import React, {useEffect, useState} from 'react'
import CustomTable from '../RSISCustomTable';
import {RSISSupportOrgQueryTable} from '../Constant';
import {rsisQueryOrganization} from '../../../api/v1/RSIS';
import {ApiErrorStatusEnum, getLocalStorage,} from "edah_utils/dist";
import {t} from 'i18next'
import {Dialog, DialogVariant, DialogSizeEnums, AlertTypeEnum} from "edah-component/dist";
import {usePopup} from "../PopupProvider";

/**
 * 列表組件
 * @param {Object} item 列表項目
 * @param {number} index 列表索引
 * @param {boolean} isSelectAll 是否全選
 * @param {function} handleOnClick 點擊事件
 * @returns {JSX.Element}
 */
const ListItem = ({
                      item,
                      index,
                      isSelectAll = null,
                      handleOnClick
                  }) => {

    /**
     * 點擊列表項目事件，帶回支援機構資料
     * @return {void}
     */
    const handleItemOnClick = () => {
        handleOnClick(item)
    }

    return (
        <li className='hover:cursor-pointer' key={index} onClick={handleItemOnClick}>
            <div>{item.orgNo}</div>
            <div>{item.orgType == 1 ? '非醫療' : '醫療'}</div>
            <div>{item.orgName}</div>
            <div>{`${item.orgAddrCity} ${item.orgAddrLocal} ${item.orgAddrInfo}`}</div>
        </li>
    )
}

/**
 * 支援機構查詢
 * @param {boolean} filterOrg 是否過濾機構，預設為false
 * @param {function} handleOnClose 關閉彈窗
 * @param {function} handleOnSelectOrg 選擇機構時的事件
 * @return {JSX.Element}
 * */
const QuerySupportOrg = ({
                             filterOrg = false,
                             handleOnClose,
                             handleOnUpdate
                         }) => {
    const {showToast} = usePopup()
    // 資料列表
    const [dataList, setDataList] = useState([])
    // 分頁屬性
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
        fn: () => {
        }
    });

    /**
     * 取得排除本院後的機構清單
     * @param orgList
     * @return {Array}
     */
    const getFilteredOrgList = (orgList) => {
        // 取得本院院區ID
        const campusId = getLocalStorage('campusId')
        // 過濾並且排除本院
        return orgList.filter((org) => {
            return org.orgNo !== campusId
        })
    }

    /**
     * 取得所有支援機構清單
     * @return {void}
     */
    const getAllSupportOrg = () => {
        rsisQueryOrganization({}).then((res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 如果資料長度為0
                if (data.length === 0) {
                    showToast({message: '查無資料', type: AlertTypeEnum.Info})
                }
                // 是否需要過濾本院
                filterOrg ? setDataList(getFilteredOrgList(data)) : setDataList(data)
            } else {
                setDataList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }

        }))
    }

    // 第一次執行時
    useEffect(() => {
        getAllSupportOrg()
    }, [])

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.QuerySupportOrg')}
            content={
                <div className='px-6 pb-4'>
                    <CustomTable
                        fields={RSISSupportOrgQueryTable.key}
                        dataList={dataList}
                        ListItem={(props) => (
                            <ListItem
                                {...props}
                                handleOnClick={handleOnUpdate}
                            />
                        )}
                        advancedSearch={true}
                        type={'SupportOrgQueryTable'}
                        paginationProps={paginationProps}
                    />

                </div>
            }
            onClose={handleOnClose}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.LG}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{dividers: true}}
        />
    )
}


export default QuerySupportOrg

import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, InputTypeEnum} from "edah_utils/dist"
import {getCurrentFormattedDateTime} from "../../components/CVIS/utils";
import {cvnotify, queryCvisNotifyUnit} from "../../api/v1/Critical";
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {AlertTypeEnum, Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, TextField, Field, SizeEnum, Select} from "edah-component/dist"

/** SA說這個要做成彈窗，是所有系統的共用元件，但目前只有CVIS有用到
 * 危急值通報頁面
 * @param popUpOnClose {Function} 關閉彈窗
 * @return {JSX.Element}
 */
const CriticalValueAlert = ({popUpOnClose}) => {
    const showToast = useToast()
    // 危急值通報單位
    const [unit, setUnit] = useState([])
    // 危急值通報輸入資料
    const [cvisQueryData, setCvisQueryData] = useState({
        // 通報單位
        cvisNotifyUnit: '',
        // 就醫號碼
        encounterId: "I-240529",
        // 通報項目
        cvisNotifyItem: '',
        // 通報等級
        cvisNotifyClass: '',
        // 通報類型
        cvisNotifyType: "A",
        // 病歷號
        patientId: '',
        // 病患名稱
        patientName: '',
        // 門/住/急 O/I/E
        inpOpdNo: '',
        // 通報日期(發送日期時間)
        cvisNotifyDatetime: getCurrentFormattedDateTime(), //直接帶入當下時間
        // 通報人員
        cvisNotifyUser: '',
        // 通報對象(主治醫師)
        cvisNotifyObject: '',
        // 通報內容
        cvisNotifyContent: '',
        // 通報電話
        cvisNotifyPhone: '',
        // 科別
        divName: ''
    })

    /**
     * 送出危急值通報
     * @return {void}
     */
    const handleCvisNotifySubmit = () => {
        console.log(cvisQueryData.encounterId)
        try {
            cvnotify({
                // 就醫日期
                encounterDate: getCurrentFormattedDateTime(),
                // 就醫號
                encounterId: cvisQueryData.encounterId,
                // 門/住/急 > O/I/E
                inpOpdNo: cvisQueryData.inpOpdNo,
                // 病歷號
                patientId: cvisQueryData.patientId,
                // 通報日期(發送日期時間)
                cvisNotifyDatetime: getCurrentFormattedDateTime(),
                // 通報人員
                cvisNotifyUser: cvisQueryData.cvisNotifyUser,
                // 通報類別 > A/B
                cvisNotifyType: cvisQueryData.cvisNotifyType,
                // 通報單位
                cvisNotifyUnit: cvisQueryData.cvisNotifyUnit,
                // 通報等級 > A/B/C
                cvisNotifyClass: cvisQueryData.cvisNotifyClass,
                // 通報項目
                cvisNotifyItem: cvisQueryData.cvisNotifyItem,
                // 通報內容
                cvisNotifyContent: cvisQueryData.cvisNotifyContent,
                // 通報對象
                cvisNotifyObject: cvisQueryData.cvisNotifyObject,
                // 是否虛感控通報
                isInfection: 'N'
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    // 關閉視窗
                    // popUpOnClose()
                    showToast({message: '送出成功', type: AlertTypeEnum.Success})
                } else {
                    showToast({message: `送出失敗: ${res.msg}`, type: AlertTypeEnum.Error})
                }
            })
        } catch (e) {
        }
    }

    /**
     * 輸入框變動時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleInputChange = (e) => {
        const {name, value} = e.target
        setCvisQueryData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

     /**
     * 通報單位變動事件
     * @param value {String} 通報單位
     * @returns {void}
     */
     const handleCvisNotifyUnitOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: value
        })
    }

    /**
     * 第一次載入時取得通報單位清單
     * @return {void}
     */
    useEffect(() => {
        queryCvisNotifyUnit({})
            .then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    setUnit(res.data);
                    if (res.data.length > 0) {
                        setCvisQueryData(prevData => ({
                            ...prevData,
                            cvisNotifyUnit: res.data[0].cvisNotifyUnitNo
                        }));
                    }
                } else {
                    showToast({message: '通報單位清單取得失敗', type: AlertTypeEnum.Error});
                }
            });
    }, []);

    return (
        <>
            <div className=" flex items-center justify-center bg-black/50 w-full h-full top-0 left-0">
                <div className="w-[910px] rounded-[8px] bg-white">
                    <div
                        className="h-[60px] flex flex-row items-center justify-between px-6 border-b-[1px] border-[#111111]/15">
                        <h3 className="text-[20px] font-bold">危急值通報</h3>
                        <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Large} text={t('general.close')}/>
                    </div>
                    <div className="px-6 py-4 space-y-3">
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="通報單位">
                                <Select
                                    data={{
                                        label: '通報單位',
                                        options: unit?.map((item) => ({
                                            value: item.cvisNotifyUnitNo,
                                            label: item.cvisNotifyUnitName
                                        }))
                                    }}
                                    value={cvisQueryData.cvisNotifyUnit}
                                    displayEmpty={true}
                                    notched={true}
                                    onChange={handleCvisNotifyUnitOnChange}
                                    showLabel = {false}
                                />
                               
                            </Field>
                            <Field label="就醫號">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='encounterId'
                                    value={cvisQueryData.encounterId}
                                    onChange={handleInputChange}
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="通報項目" isRequired>
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='cvisNotifyItem'
                                    value={cvisQueryData.cvisNotifyItem}
                                    onChange={handleInputChange}
                                    placeholder="通報項目"
                                    required
                                    error={cvisQueryData.cvisNotifyItem === ''}
                                />
                            </Field>
                            <Field label="事件等級">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='cvisNotifyClass'
                                    value={cvisQueryData.cvisNotifyClass}
                                    onChange={handleInputChange}
                                    placeholder="A/B/C"
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="病歷號">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    value={cvisQueryData.patientId}
                                    name='patientId'
                                    onChange={handleInputChange}
                                    placeholder="病歷號"
                                />
                            </Field>
                            <Field label="姓名">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    value={cvisQueryData.patientName}
                                    name='patientName'
                                    onChange={handleInputChange}
                                    placeholder="姓名"
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="門住">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='inpOpdNo'
                                    value={cvisQueryData.inpOpdNo}
                                    onChange={handleInputChange}
                                    placeholder="O/I/E"
                                />
                            </Field>
                            <Field label="科別">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='divName'
                                    value={cvisQueryData.divName}
                                    onChange={handleInputChange}
                                    placeholder="科別"
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="通報日期時間">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    value={getCurrentFormattedDateTime()}
                                    readOnly
                                />
                            </Field>
                            <Field label="通報人員">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='cvisNotifyUser'
                                    value={cvisQueryData.cvisNotifyUser}
                                    onChange={handleInputChange}
                                    placeholder="通報人員"
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Field label="通報對象">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small}
                                    name='cvisNotifyObject'
                                    value={cvisQueryData.cvisNotifyObject}
                                    onChange={handleInputChange}
                                    placeholder="通報對象"
                                />
                            </Field>
                            <Field label="通報電話">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small} 
                                    name='cvisNotifyPhone'
                                    value={cvisQueryData.cvisNotifyPhone}
                                    type="tel"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    onChange={handleInputChange}
                                    placeholder="通報電話"
                                />
                            </Field>
                        </div>
                        <div>
                            <Field label="通報內容">
                                <TextField
                                    inputWidth={SizeEnum.Fill}
                                    size={SizeEnum.Small} 
                                    name='cvisNotifyContent'
                                    value={cvisQueryData.cvisNotifyContent}
                                    type={InputTypeEnum.Text}
                                    onChange={handleInputChange}
                                    placeholder="通報內容"
                                />
                            </Field>
                        </div>
                    </div>
                    <div
                        className="h-[60px] flex flex-row items-center justify-end px-6 border-t-[1px] border-[#111111]/15">
                        <Button sx={{marginRight:'0.5rem'}} color={ButtonColorEnum.Primary}
                                variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                                text={t('general.submit')} onClick={handleCvisNotifySubmit} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default CriticalValueAlert

import {useEffect, useState} from 'react'
import {BasePopup} from '../Popup/BasePopup'
import {ApiErrorStatusEnum, enum2Array, generateEnums, stringIsEmpty,} from 'edah_utils/dist'
import {trancaseEditTranIn, trancaseQueryTranInByEncounterId,} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import Button from '../Button/Button'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    TextArea,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from 'edah-component/dist'

const NoReferralReasonContent = ({patientInfo, onClose, goReferralTicket}) => {
    // 轉診開立作業: 0, 不轉診原因: 1 列舉
    const ReferralFlagEnums = generateEnums({
        "ReferralCreationProcess": {label: '轉診開立作業', value: '0'},
        "NonReferralReasons": {label: '不轉診原因', value: '1'}
    })

    const [referralFlag, setReferralFlag] = useState(ReferralFlagEnums.NonReferralReasons.value)
    const [noReferralReason, setNoReferralReason] = useState('')
    const [referralData, setReferralData] = useState({})
    const [reasonInputState, setReasonInputState] = useState(null)

    const showToast = useToast()

    const handleNoReferralReasonOnChange = (e) => {
        setNoReferralReason(e.target.value)
    }

    const saveReferralReason = () => {
        if (referralFlag === ReferralFlagEnums.ReferralCreationProcess.value) {
            goReferralTicket()
            onClose()
        } else {
            const isValid = !stringIsEmpty(noReferralReason)
            setReasonInputState(isValid)
            if (!isValid) {
                return
            }
            trancaseEditTranIn({
                ...referralData,
                notTranBackReason: noReferralReason,
                // 需傳入此次的就醫號碼和就醫日期
                opdEncounterId: patientInfo?.encounterId,
                opdEncounterDate: patientInfo?.encounterDate,
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    showToast({
                        message: '儲存成功',
                        type: AlertTypeEnum.Success,
                    })
                    onClose()
                } else {
                    //顯示Toast Message
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * RadioGroup onChange
     * @param e {Event}
     * @return {void}
     */
    const handleRadioGroupOnChange = (e) => setReferralFlag(e.target.value)

    useEffect(() => {
        trancaseQueryTranInByEncounterId({
            encounterId: patientInfo?.encounterId,
            encounterDate: patientInfo?.encounterDate,
            inpOpd: patientInfo?.inpOpd,
            patientId: patientInfo?.patientId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setReferralData(res.data)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }, [patientInfo])

    return (
        <Dialog
            open={true}
            title={'不轉診原因'}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={onClose}
            onConfirm={saveReferralReason}
            content={
                <div className="p-5 flex flex-col items-center">
                    <div className="w-[450px]">
                        <p className="text-left">
                            此轉入個案已超過45天，請填寫不轉出原因
                        </p>
                        <div className="mb-3 w-full flex flex-col items-start justify-center">
                            <RadioGroup
                                size={SizeEnum.Medium} row={false} value={referralFlag}
                                optionProps={{
                                    options: enum2Array(ReferralFlagEnums).map(({value: {label, value}}) => ({
                                        label,
                                        value,
                                    })),
                                    container: (<div className='flex flex-row space-x-4 items-center my-2'></div>)
                                }}
                                onChange={handleRadioGroupOnChange}/>
                            <div className="flex flex-col items-start w-full mt-2 mb-2">
                                <TextArea
                                    value={noReferralReason}
                                    onChange={handleNoReferralReasonOnChange}
                                    disabled={referralFlag === ReferralFlagEnums.ReferralCreationProcess.value}
                                    sx={{width: '100%'}}
                                    error={!reasonInputState}
                                />
                                {reasonInputState === false && referralFlag === ReferralFlagEnums.NonReferralReasons.value && (
                                    <span className="text-danger pl-[14px]">必填</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            muiDialogContentProps={{dividers: true}}
        />
    )
}

/**
 * 轉出轉入開立作業-不轉診原因Modal
 * @return {JSX.Element}
 */
const NoReferralReasonModal = ({onClose, patientInfo, goReferralTicket}) => {
    return (
        <NoReferralReasonContent
            patientInfo={patientInfo}
            goReferralTicket={goReferralTicket}
            onClose={onClose}
        />
    )
}
export default NoReferralReasonModal

import React, {useEffect, useState} from 'react'
import {APRV_STATUS_LIST} from '../../../constants/referralSystem/dischargeSummary'
import {t} from 'i18next'
import {validDateFormat} from 'edah_utils'
import {
    TextField,
    Select,
    DateRangePicker,
    SizeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from 'edah-component/dist'
import dayjs from "dayjs";

const DischargeSummarySearchBar = ({
                                       handleQuery,
                                       isApplyPage,
                                       searchParamsOnChange,
                                   }) => {
    // 起始日期
    const [startDate, setStartDate] = useState('')

    // 結束日期
    const [endDate, setEndDate] = useState('')

    // 出院病摘狀態: 預設[申請中]
    const [aprvStatus, setAprvStatus] = useState(
        isApplyPage ? '' : APRV_STATUS_LIST[0].value
    )

    // 身分證號
    const [idNo, setIdNo] = useState('')

    // 病歷號
    const [patientId, setPatientId] = useState('')

    // 起始日期輸入框檢核狀態
    const [startDateInputState, setStartDateInputState] = useState(null)

    // 結束日期輸入框檢核狀態
    const [endDateInputState, setEndDateInputState] = useState(null)

    /**
     * 日期範圍變動事件
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates;

        // 更新起始和結束日期
        setStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : '');
        setEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : '');

        // 檢查輸入狀態並設置輸入框的檢核狀態
        setStartDateInputState(!!newStartDate);
        setEndDateInputState(!!newEndDate);
    }
    /**
     * 更新狀態
     * @param value {string}
     * @return {void}
     */
    const updateAprvStatus = (value) => {
        setAprvStatus(value)
    };
    const updateIdNo = (e) => {
        setIdNo(e.target.value)
    }
    const updatePatientId = (e) => {
        setPatientId(e.target.value)
    }

    // 查詢
    const handleQuerOnClick = () => {
        const startDateIsValid = validDateFormat(startDate)
        const endDateIsValid = validDateFormat(endDate)
        setStartDateInputState(startDateIsValid)
        setEndDateInputState(endDateIsValid)

        if (!startDateIsValid || !endDateIsValid) {
            return
        }
        handleQuery()
    }

    useEffect(() => {
        searchParamsOnChange({
            startDate,
            endDate,
            patientId,
            idNo,
            aprvStatus,
        })
    }, [startDate, endDate, patientId, idNo, aprvStatus])

    return (
        <div className="flex items-center justify-start gap-2 flex-shrink-0 text-left">
            {/*出院起始日期*/}
            <DateRangePicker
                size={SizeEnum.Small}
                required
                localeText={{
                    start: '出院開始日期',
                    end: '出院結束日期',
                }}
                value={[
                    startDate ? dayjs(startDate) : null,
                    endDate ? dayjs(endDate) : null,
                ]}
                onChange={handleDateRangeChange}
            />
            {/*核准選項*/}
            <Select
                data={{
                    label: "選項",
                    options: APRV_STATUS_LIST.map((item) => ({
                        value: item.value,
                        label: item.name,
                    })),
                }}
                value={aprvStatus || APRV_STATUS_LIST[0].value} // 確保有預設值
                onChange={(value) => updateAprvStatus(value)}
                size={SizeEnum.Small}
                notched={true}
                displayEmpty={false}
                width = "Small"
            />
            {/*身分證號*/}
            <TextField
                label={t('general.idNumber')}
                value={idNo}
                onChange={(e) => updateIdNo(e)}
            />
            {/*病歷號*/}
            <TextField
                label={t('general.patientNo')}
                value={patientId}
                onChange={(e) => updatePatientId(e)}
            />
            <Button
                text={t('general.query')}
                color={ButtonColorEnum.Primary}
                size={ButtonSizeEnum.Large}
                variant={ButtonVariantEnum.Contained}
                onClick={() => handleQuerOnClick({
                    startDate,
                    endDate,
                    aprvStatus,
                    idNo,
                    patientId,
                })}
                sx={{fontWeight: 'bold'}}
            />
        </div>
    )
}

export default DischargeSummarySearchBar

import React, {useState} from 'react'
import CustomTable from '../../components/ReferralSystem/CustomTable'
import DischargeSummarySearchBar from '../../components/ReferralSystem/DischargeSummary/DischargeSummarySearchBar'
import {DISCHARGE_SUMMARY_FIELDS} from '../../constants/referralSystem/dischargeSummary'
import {Pagination} from '../../components/Pagination/Pagination'
import {
    editApplyDischargenote,
    queryApplyDischargenoteByArpv,
} from '../../api/v1/Rmis'
import {
    ApiErrorStatusEnum,
    time2String,
    arrayIsEmpty,
    getLocalStorage,
} from 'edah_utils/dist'
import {DATETIME_FORMAT} from '../../constants/common'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum
} from "edah-component/dist"

/**
 * 出院病摘_核准
 * @returns {JSX.Element}
 */
const ApproveDischargeSummary = () => {
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        totalPageSize: 1,
        totalItemSize: 0,
        pageNum: 1,
        pageSize: 10,
    })

    // 查詢條件
    const [searchParams, setSearchParams] = useState({
        // 出院日期(起)
        encounterStartDate: '',
        // 出院日期(迄)
        encounterEndDate: '',
        // 身分證號
        idNo: '',
        // 病歷號
        patientId: '',
        // 選項: 核准/未核准
        aprvStatus: '',
    })

    // toast message
    const showToast = useToast()

    const handleQueryOnClick = () => {
        fetchDischargeSummaryList(1, filter.pageSize)
    }

    /**
     * 取得出院病摘清單
     * @param {number} pageNum
     * @param {number} pageSize
     */
    const fetchDischargeSummaryList = (pageNum, pageSize) => {
        queryApplyDischargenoteByArpv({
            ...searchParams,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (arrayIsEmpty(res.data)) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                const list = arrayIsEmpty(res.data)
                    ? []
                    : res.data.map((item, index) => {
                        return {
                            ...item,
                            isSelected: false,
                            originIndex: index,
                        }
                    })
                setDataList(list)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    const onPageOnChange = (page) => {
        const currentPage = Number(page)
        fetchDischargeSummaryList(currentPage, filter.pageSize)
    }

    const onPageSizeChange = (event) => {
        console.log(event.target.value)
        const pageSize = Number(event.target.value)
        fetchDischargeSummaryList(filter.pageNum, pageSize)
    }
    const onPrevPageOnClick = () => {
        const prevPage = filter.currentPage - 1
        const page = prevPage < 1 ? filter.totalPage : prevPage
        fetchDischargeSummaryList(page, filter.pageSize)
    }
    const onNextPageOnClick = () => {
        const nextPage = filter.currentPage + 1
        const firstPage = 1
        const page = nextPage > filter.totalPage ? firstPage : nextPage
        fetchDischargeSummaryList(page, filter.pageSize)
    }

    const toggleAll = (isAllSelected) => {
        const modifiedList = dataList.map((item) => ({
            ...item,
            isSelected: isAllSelected,
        }))
        setDataList(modifiedList)
    }

    const toggleItem = (isChecked, selectedItem, index) => {
        const modifiedList = dataList.map((item) => {
            if (selectedItem.originIndex === item.originIndex) {
                return {
                    ...item,
                    isSelected: isChecked,
                }
            }
            return item
        })
        setDataList(modifiedList)
    }

    /**
     * 核准確認
     */
    const handleApprove = () => {
        const userNo = getLocalStorage('userno')
        const selectedDataList = dataList.filter((item) => item.isSelected)
        if (arrayIsEmpty(selectedDataList)) {
            return
        }
        const rmisApplyDischargenoteData = selectedDataList.map((item) => {
            return {
                //就醫號
                encounterId: item.encounterID,
                //核准日期
                applyDate: item.applyDate,
                //核准人員
                aprvUserNo: userNo,
                //核准狀態 Y:核准
                aprvStatus: 'Y',
                lockVersion: item.lockVersion,
                recCount: item.recCount,
            }
        })

        editApplyDischargenote({
            rmisApplyDischargenoteData,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: '申請成功', type: AlertTypeEnum.Success})
                fetchDischargeSummaryList(filter.pageNum, filter.pageSize)
            } else {
                showToast({
                    message: `申請失敗: ${res.msg}`,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 更新查詢條件
     * @param {object} searchParams
     */
    const searchParamsOnChange = (searchParams) => {
        setSearchParams({
            encounterStartDate: time2String(
                searchParams.startDate,
                DATETIME_FORMAT
            ),
            encounterEndDate: time2String(
                searchParams.endDate,
                DATETIME_FORMAT
            ),
            idNo: searchParams.idNo,
            patientId: searchParams.patientId,
            aprvStatus: searchParams.aprvStatus,
        })
    }

    return (
        <>
            <div className="p-2 w-full h-[calc(100vh-98px)] overflow-auto">
                <div className="flex justify-between items-center p-2">
                    <DischargeSummarySearchBar
                        isApplyPage={false}
                        searchParamsOnChange={searchParamsOnChange}
                        handleQuery={handleQueryOnClick}
                    />
                    <Button
                        text={'核准確認'}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Outlined}
                        onClick={handleApprove}
                        sx={{fontWeight: 'bold'}}
                    />
                </div>
                <CustomTable
                    isSelectable={true}
                    fields={DISCHARGE_SUMMARY_FIELDS}
                    dataList={dataList}
                    toggleAll={toggleAll}
                    toggleItem={toggleItem}
                ></CustomTable>
                <div className="flex justify-end">
                    <Pagination
                        pageSize={filter.pageSize}
                        totalSize={filter.totalItemSize}
                        currentPage={filter.pageNum}
                        totalPageSize={filter.totalPageSize}
                        onPageOnChange={onPageOnChange}
                        onPageSizeChange={onPageSizeChange}
                        onPrevPageOnClick={onPrevPageOnClick}
                        onNextPageOnClick={onNextPageOnClick}
                    />
                </div>
            </div>
        </>
    )
}

export default ApproveDischargeSummary

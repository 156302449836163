import {ApiErrorStatusEnum, getLocalStorage, InputTypeEnum,} from 'edah_utils/dist'
import React, {useMemo, useState} from 'react'
import CustomTable from './CustomTable'
import {RETURN_HOSPITAL} from '../../constants/referralSystem/maintainAreaHeader'
import {Pagination} from '../Pagination/Pagination'
import {BasePopup} from '../Popup/BasePopup'
import {addHospitalinfoSelf, delHospitalinfoSelf, queryHospitalInfoList,} from '../../api/v1/Rmis'
import useToast from '../../hooks/useToast'
import {
    AlertTypeEnum,
    RadioGroup,
    SizeEnum,
    TextField,
    Dialog,
    DialogVariant,
    Button,
    ButtonVariantEnum,
    ButtonColorEnum,
    ButtonSizeEnum,
    Select,
    Checkbox,
} from "edah-component/dist"
import {t} from 'i18next'

/**
 * Layout
 * @param children {JSX.Element}
 * @return {JSX.Element}
 */
const Layout = ({children}) =>
    <div className="p-2 w-full  max-h-[calc(100%-140px)] overflow-auto">
        {children}
    </div>

/**
 * 標題
 * @param children {string}
 * @param extendClass {string}
 * @return {JSX.Element}
 */
const Title = ({children, extendClass}) =>
    <div className={`date flex flex-row items-center justify-start ${extendClass}`}>
        <div>{children}</div>
    </div>

/**
 * 策略聯盟、社區醫療、支援院所
 * @param {Boolean} value 對應欄位的值
 * @return {JSX.Element}
 */
const CustomCheckbox = (value) =>
    <div className="w-full text-center">
        <input type="checkbox" checked={value} disabled/>
    </div>

const InputStyle =
    'w-[120px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'
const ButtonStyle =
    'flex items-center justify-center font-bold px-4 h-10 rounded-[6px] bg-[#2B6CB0] text-white'
const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[160px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const HealthCareOptions = [
    {
        label: '台北',
        value: '1',
    },
    {
        label: '北區',
        value: '2',
    },
    {
        label: '中區',
        value: '3',
    },
    {
        label: '南區',
        value: '4',
    },
    {
        label: '高屏',
        value: '5',
    },
    {
        label: '東區',
        value: '6',
    },
    {
        label: '全部',
        value: '',
    },
]

const HospitalTypeOptions = [
    {
        label: '醫學中心',
        value: '1',
    },
    {
        label: '區域醫院',
        value: '2',
    },
    {
        label: '地區醫院',
        value: '3',
    },
    {
        label: '診所',
        value: '4',
    },
    {
        label: '全部',
        value: '',
    },
]

const QuickSelector = [
    {
        label: '病人居住地區',
        value: '1',
    },
    {
        label: '醫師自訂',
        value: '2',
    },
    {
        label: '義大體系',
        value: '3',
    },
    {
        label: '高屏聯盟',
        value: '4',
    },
    {
        label: '全部',
        value: '',
    },
]


/**
 * 轉診院所查詢
 * @return {JSX.Element}
 */
const ReferralHospital = ({onConfirm, onClose, zipCodeList = []}) => {
    // 院所清單
    const [dataList, setDataList] = useState([])

    // pagination
    const [filter, setFilter] = useState({
        // 總頁數
        totalPageSize: 1,
        // 總筆數
        totalItemSize: 0,
        // 當前頁碼
        pageNum: 1,
        // 每頁筆數
        pageSize: 10,
    })

    // 健保分局
    const [healthCare, setHealthCare] = useState(HealthCareOptions[4].value)

    // 特約類別
    const [hospitalType, setHospitalType] = useState(
        HospitalTypeOptions[4].value
    )

    // 快速查詢
    const [quickSelector, setQuickSelector] = useState(QuickSelector[4].value)

    // 高雄地區
    const [kaohsiungArea, setKaohsiungArea] = useState('')

    // 院所代碼
    const [hospNo, setHospNo] = useState('')

    // 院所名稱
    const [hospitalName, setHospitalName] = useState('')

    // 院所地址
    const [hospitalAddress, setHospitalAddress] = useState('')

    // 選擇院所
    const [selectedHospital, setSelectedHospital] = useState({})

    // toast message
    const showToast = useToast()

    /**
     * 更新院所代碼
     * @param e {event}
     * @return {void}
     */
    const handleHospNoChange = (e) => setHospNo(e.target.value)

    /**
     * 更新院所名稱
     * @param e {event}
     * @return {void}
     */
    const handleHospitalNameChange = (e) => setHospitalName(e.target.value)

    /**
     * 更新院所地址
     * @param e {event}
     * @return {void}
     */
    const handleHospitalAddressChange = (e) =>
        setHospitalAddress(e.target.value)

    /**
     * 查詢按鈕
     * @return {void}
     */
    const handleClickQuery = () => {
        getHospitalInfoList(1, filter.pageSize)
    }

    /**
     * 自訂按鈕設定
     * @param hospNo {string} 院所代碼
     * @return {Promise<void>}
     */
    const handleCustomButtonSetting = async ({hospNo}) => {
        const userNo = getLocalStorage('userno')
        const res = await addHospitalinfoSelf({
            userNo,
            hospNo,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            getHospitalInfoList(filter.pageNum, filter.pageSize)
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 自訂按鈕取消
     * @param hospNo {string} 院所代碼
     * @return {Promise<void>}
     */
    const handleCustomButtonCancel = async ({hospNo}) => {
        const userNo = getLocalStorage('userno')
        const res = await delHospitalinfoSelf({
            userNo,
            hospNo,
        })
        if (res.err === ApiErrorStatusEnum.Success) {
            showToast({message: '儲存成功', type: AlertTypeEnum.Success})
            getHospitalInfoList(filter.pageNum, filter.pageSize)
        } else {
            const msg = `儲存失敗: ${res.msg}`
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 自訂按鈕
     * @param row {object}
     * @returns {Element}
     */
    const CustomButton = (row) => {
        return (
            <div className="flex">
                {!row.hospitalinfoSelfFlag && (
                    <Button
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={'自訂'}
                        onClick={() => handleCustomButtonSetting({hospNo: row.hospNo})}
                        sx={{fontWeight: 'bold'}}
                    />
                )}
                {row.hospitalinfoSelfFlag && (
                    <Button
                        color={ButtonColorEnum.Primary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained}
                        text={'取消'}
                        onClick={() => handleCustomButtonCancel({hospNo: row.hospNo})}
                        sx={{fontWeight: 'bold'}}
                    />
                )}
            </div>
        )
    }

    /**
     * 查詢院所清單
     * @param pageNum {Number} 當前頁數
     * @param pageSize {Number} 每頁筆數
     * @return {void}
     */
    const getHospitalInfoList = (pageNum, pageSize) => {
        const userNo = getLocalStorage('userno')
        queryHospitalInfoList({
            hospBranch: healthCare, // 健保分局
            hospGrade: hospitalType, // 特約類別
            quickSearch: quickSelector, //TODO: 待後端處理 1:病人居住地,2醫師自訂,3義大體系,4高屏聯盟, ALL
            hospNo: hospNo, // 院所代碼
            hospName: hospitalName, // 院所名稱
            hospAddress: hospitalAddress, // 院所地址
            hospZipCode: kaohsiungArea, // 高雄地區
            userNo,
            pageNum,
            pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data.dataList
                if (list.length === 0) {
                    showToast({
                        message: '查無資料',
                        type: AlertTypeEnum.Warning,
                    })
                }
                const modifiedList = list.map((item) => ({
                    ...item,
                    isSelected: false,
                }))
                setDataList(modifiedList)
                setFilter({
                    ...filter,
                    pageNum,
                    pageSize,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                })
            } else {
                showToast({
                    message: res.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }

    /**
     * 院所清單 table header
     * 策略聯盟、社區醫療、支援院所插入checkbox
     * @return {Array<Object>}
     */
    const renderFields = () => useMemo(() => {
        return RETURN_HOSPITAL.map((item) => {
            switch (item.key) {
                case 'communityFlag':
                    return {
                        ...item,
                        render: (row) => <Checkbox checked={row.communityFlag} disabled/>,
                    }
                case 'federalFlag':
                    return {
                        ...item,
                        render: (row) => <Checkbox checked={row.federalFlag} disabled/>,
                    }
                case 'supportFlag':
                    return {
                        ...item,
                        render: (row) => <Checkbox checked={row.supportFlag} disabled/>,
                    }
                case 'custom':
                    return {
                        ...item,
                        render: (row) => CustomButton(row),
                    }
                default:
                    return item
            }
        })
    }, [])

    /**
     * 點選 pagination 頁碼
     * @param page {string} 目前頁碼
     * @return {void}
     */
    const handlePageOnChange = (page) => {
        const currentPage = Number(page)
        getHospitalInfoList(currentPage, filter.pageSize)
    }

    /**
     * 變更每頁筆數
     * @param event {Event}
     * @return {void}
     */
    const handlePageSizeChange = (event) => {
        const pageSize = Number(event.target.value)
        getHospitalInfoList(filter.pageNum, pageSize)
    }

    /**
     * 點選上一頁
     * @return {void}
     */
    const handlePrevPageOnClick = () => {
        const prevPage = filter.pageNum - 1
        const page = prevPage < 1 ? filter.totalPageSize : prevPage
        getHospitalInfoList(page, filter.pageSize)
    }

    /**
     * 點選下一頁
     * @return {void}
     */
    const handleNextPageOnClick = () => {
        const nextPage = filter.pageNum + 1
        const firstPage = 1
        const page = nextPage > filter.totalPageSize ? firstPage : nextPage
        getHospitalInfoList(page, filter.pageSize)
    }
    /**
     * 更新健保分局選項
     * @param e {Event}
     * @return {void}
     */
    const handleHealthCareChange = (e) => setHealthCare(e.target.value)

    /**
     * 更新特約類別選項
     * @param e {Event}
     * @return {void}
     */
    const handleHospitalTypeChange = (e) => setHospitalType(e.target.value)

    /**
     * 更新快速選擇選項
     * @param e {Event}
     * @return {void}
     */
    const handleQuickSelectorChange = (e) => setQuickSelector(e.target.value)

    /**
     * 更新高雄地區選項
     * @param value {String}
     * @return {void}
     */
    const handleKaohsiungAreaChange = (value) => setKaohsiungArea(value)
    /**
     * 健保署地圖式查詢網頁
     * @return {void}
     */
    const handleOpenHealthCare = () => window.open('https://info.nhi.gov.tw/INAE1000/INAE1000S00', '_blank')
    /**
     * 選擇單筆資料
     * @param {boolean} isChecked 是否勾選
     * @param {object} selectedItem 勾選的資料
     * @param {number} index 勾選項目的index
     */
    const toggleItem = (isChecked, selectedItem, index) => {
        if (selectedHospital && !isChecked) {
            setSelectedHospital({})
        } else {
            setSelectedHospital({...selectedItem, isSelected: true})
        }
        const modifiedList = dataList.map((item) => {
            return {
                ...item,
                isSelected:
                    selectedItem.hospNo === item.hospNo ? isChecked : false,
            }
        })

        setDataList(modifiedList)
    }

    return (
        <Dialog
            open={true}
            title={'轉診院所查詢'}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: '80%'}}
            muiDialogContentProps={{dividers: true}}
            onClose={onClose}
            onConfirm={() => onConfirm(selectedHospital)}
            content={
                <Layout>
                    {/* 健保分局 */}

                    <div className="flex justify-between p-2 items-center">
                        <div className="flex items-center">
                            <Title>健保分局：</Title>
                            <div className="flex items-center ml-4">
                                {/*健保分局RadioGroup*/}
                                <RadioGroup size={SizeEnum.Medium} value={healthCare}
                                            optionProps={{options: HealthCareOptions}}
                                            onChange={handleHealthCareChange}/>
                            </div>
                        </div>
                        <div>
                            <Button
                                color={ButtonColorEnum.Secondary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Outlined}
                                text={'健保署地圖式查詢網頁'}
                                onClick={handleOpenHealthCare}
                                sx={{fontWeight: 'bold'}}
                            />
                            {/*<Button*/}
                            {/*    classNames={SecondButtonStyle}*/}
                            {/*    text={'健保署地圖式查詢網頁'}*/}
                            {/*    onClickFn={handleOpenHealthCare}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    {/* 特約類別 */}
                    <div className="flex justify-between p-2 items-center">
                        <div className="flex items-center">
                            <Title>特約類別：</Title>
                            <div className="flex items-center ml-4">
                                {/*特約類別RadioGroup*/}
                                <RadioGroup size={SizeEnum.Medium} value={hospitalType}
                                            optionProps={{options: HospitalTypeOptions}}
                                            onChange={handleHospitalTypeChange}/>
                            </div>
                        </div>
                    </div>
                    {/* 快速選擇 */}
                    <div className="flex justify-between p-2 items-center">
                        <div className="flex items-center">
                            <Title>快速選擇：</Title>
                            <div className="flex items-center ml-4">
                                {/*特約類別RadioGroup*/}
                                <RadioGroup size={SizeEnum.Medium} value={quickSelector}
                                            optionProps={{options: QuickSelector}}
                                            onChange={handleQuickSelectorChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex p-2 items-center w-full">
                        <div className="flex items-center space-x-2">
                            <TextField
                                label='院所名稱'
                                value={hospitalName}
                                onChange={handleHospitalNameChange}
                            />
                            <TextField
                                label='院所代碼'
                                className={InputStyle}
                                type={InputTypeEnum.Text}
                                value={hospNo}
                                onChange={handleHospNoChange}
                            />
                            <TextField
                                label='院所地址'
                                value={hospitalAddress}
                                onChange={handleHospitalAddressChange}
                            />
                            <Title extendClass={'ml-4'}>高雄地區：</Title>
                            <Select
                                data={{
                                    label: '高雄地區',
                                    options: zipCodeList.map((item) => ({
                                        value: item.no,
                                        label: item.name
                                    }))
                                }}
                                value={kaohsiungArea}
                                onChange={handleKaohsiungAreaChange}
                                showLabel={false}
                            />
                            <Button
                                color={ButtonColorEnum.Primary}
                                size={ButtonSizeEnum.Medium}
                                variant={ButtonVariantEnum.Contained}
                                text={t('general.query')}
                                onClick={handleClickQuery}
                                sx={{fontWeight: 'bold'}}
                            />
                        </div>
                    </div>
                    <CustomTable
                        isSelectable={true}
                        fields={renderFields()}
                        dataList={dataList}
                        isSingleSelect
                        toggleItem={toggleItem}
                    />
                    <div className="flex justify-end">
                        <Pagination
                            pageSize={filter.pageSize}
                            totalSize={filter.totalItemSize}
                            currentPage={filter.pageNum}
                            totalPageSize={filter.totalPageSize}
                            onPageOnChange={handlePageOnChange}
                            onPageSizeChange={handlePageSizeChange}
                            onPrevPageOnClick={handlePrevPageOnClick}
                            onNextPageOnClick={handleNextPageOnClick}
                        />
                    </div>
                </Layout>
            }
        />

    )
}

/**
 * 院所查詢彈窗
 * @param onConfirm {Function}
 * @param closePopupButtonOnClick {Function}
 * @param zipCodeList {Array} 高雄地區
 * @return {JSX.Element}
 */
const ReferralHospitalModal = ({onConfirm, closePopupButtonOnClick, zipCodeList}) =>
    <ReferralHospital onConfirm={onConfirm} onClose={closePopupButtonOnClick} zipCodeList={zipCodeList}/>

export default ReferralHospitalModal

import {Checkbox, Dialog, DialogSizeEnums, DialogVariant, Divider, Field, RedColorEnum} from 'edah-component/dist'
import {stringIsEmpty, time2String} from 'edah_utils/dist'
import {t} from 'i18next'

/**
 * 顯示掛號完成的彈窗
 * @param data {Object} 掛號資料
 * @param showPopup {Boolean} 是否顯示彈窗
 * @param handleClosePopup {Function} 關閉彈窗的函數
 */
export const ShowGiveNumberPopup = ({data, showPopup, handleClosePopup}) => {
    /**
     * 是否是今天的日期
     * @param dateString{String} 日期時間字串
     * @returns
     */
    const isToday = (dateString) => {
        const givenDate = new Date(dateString)
        const today = new Date()

        return (
            givenDate.getFullYear() === today.getFullYear() &&
            givenDate.getMonth() === today.getMonth() &&
            givenDate.getDate() === today.getDate()
        )
    }

    /**
     * 取得Title
     * @returns {string}
     */
    const getTitleContent = () => {
        const startViewTime = time2String(data.startViewDatetime, 'HH:mm')

        if (data.viewNo === null) {
            return `預約時間：${startViewTime}`
        }
        return `${data.viewNo}號 ${startViewTime}`
    }

    /**
     * 顯示的內容
     * @returns {JSX.Element}
     */
    const getContent = () => {
        return (
            <>
                <div className="text-center text-2xl font-bold">
                    {getTitleContent()}
                </div>
                <div className="w-[55%] mx-auto space-y-4">
                    <Field label="掛號院區">{data.zoneName}</Field>
                    <Divider/>
                    <Field label="看診日期">
                        {data.encounterDate?.split(' ')[0]}，{data.apnName}
                    </Field>
                    <Divider/>
                    <Field label={t('Regs.general.medicalRecordNumber')}>
                        {data.patientId}
                    </Field>
                    <Divider/>
                    <Field label={t('general.department')}>
                        {data.divName}
                    </Field>
                    <Divider/>
                    <Field label={t('general.doctor')}>
                        {stringIsEmpty(data.locumDoctorName)
                            ? data.doctorName
                            : data.locumDoctorName}
                    </Field>
                    <Divider/>
                    <Field label={t('Regs.general.clinicName')}>
                        {data.clinicName}
                    </Field>
                    <Divider/>
                    <Field label="列印">
                        <Checkbox
                            label={
                                isToday(data.encounterDate)
                                    ? '就診單'
                                    : '預約單'
                            }
                            labelSx={{
                                color: RedColorEnum.Red600,
                            }}
                            checked
                        />
                    </Field>
                    {/*<div className="flex flex-row space-x-3">
                                <input
                                    type="checkbox"
                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                    style={{accentColor: "#38A169"}}
                                    checked
                                />
                                <label className="text-[#E53E3E]">病歷首頁</label>
                            </div>
                            <div className="flex flex-row space-x-3">
                                <input
                                    type="checkbox"
                                    className="bg-green-400 checked:bg-green-600 text-green-600 w-5"
                                    style={{accentColor: "#38A169"}}
                                    checked
                                />
                                <label className="text-[#E53E3E]">借閱單</label>
                            </div>*/}
                </div>
            </>
        )
    }

    return (
        <Dialog
            open={showPopup}
            title={isToday(data.encounterDate) ? '掛號完成' : '預約掛號完成'}
            content={getContent()}
            varient={DialogVariant.CONFIRM}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={handleClosePopup}
        />
    )
}

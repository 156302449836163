import {t} from "i18next"
import React, {useEffect, useState} from "react"
import { PopupModeEnum } from "edah_utils/dist"
import { saveCdss } from "../../api/v1/CDSS";
import { ApiErrorStatusEnum, ToastTypeEnum, stringIsEmpty } from "edah_utils/dist"
import ToastMessage from "../Toasts/ToastMessage";
import { Field, SizeEnum, TextArea } from "edah-component/dist/index";

/**
 * @param mode {Number} 新增或編輯
 * @param data {Object} 資料
 * @param closePopup {Function} 關閉彈出視窗
 * @param handleSuccessCreateRule {Function} 建立或編輯成功事件
 */
const RulePopup = ({
    mode,
    data,
    closePopup,
    handleSuccessCreateRule
}) => {
    //預設設定
    const baseSettings = {
        //規則代碼
        cdssId: data.cdssId ? data.cdssId : "",
        //分類
        category: data.category ? data.category : "",
        //目的說明
        purpose: data.purpose ? data.purpose : "",
        //規則說明
        description: data.description ? data.description : "",
        //規則出處
        ruleSource: data.ruleSource ? data.ruleSource : "",
        //服務伺服器類型
        serverType: data.serverType ? data.serverType : "",
        //服務中
        effectiveFlag: data.effectiveFlag ? data.effectiveFlag : true,
        //參考規範
        parameterConstraints: data.parameterConstraints ? data.parameterConstraints : "",
        //回傳結果
        returnResult: data.returnResult ? data.returnResult : ""
    }
    //當前設定
    const [record, setRecord] = useState(baseSettings)
    /**
     * Toast資料
     */
    const [toast, setToast] = useState(null)

    /**
     * 儲存或編輯按鈕被點擊時
     */
    const handleSaveOrEditOnClick = () =>{
        
        let fieldEmpty = false;
        let warningMsg = "";
        do {
            if(stringIsEmpty(record.cdssId)) {
                fieldEmpty = true;
                warningMsg = "規則代碼欄位為空"
                break
            }
            if(stringIsEmpty(record.category)) {
                fieldEmpty = true;
                warningMsg = "分類欄位為空"
                break
            }
            if(stringIsEmpty(record.purpose)) {
                fieldEmpty = true;
                warningMsg = "目的說明欄位為空"
                break
            }
            if(stringIsEmpty(record.description)) {
                fieldEmpty = true;
                warningMsg = "規則說明欄位為空"
                break
            }
            if(stringIsEmpty(record.ruleSource)) {
                fieldEmpty = true;
                warningMsg = "規則來源欄位為空"
                break
            }
            if(stringIsEmpty(record.serverType)) {
                fieldEmpty = true;
                warningMsg = "服務伺服器類型沒有選擇"
                break
            }
            if(stringIsEmpty(record.parameterConstraints)) {
                fieldEmpty = true;
                warningMsg = "參考規範為空"
                break
            }
            if(stringIsEmpty(record.returnResult)) {
                fieldEmpty = true;
                warningMsg = "回傳結果為空"
                break
            }
        } while(false)
        if(fieldEmpty === true) {
            setToast({
                show:true,
                type:ToastTypeEnum.Error,
                message: warningMsg
            })

            return
        }
        
        //是否為新增模式
        const isAdd = mode === PopupModeEnum.Add;
        if(isAdd) {
            //新增規則
            saveCdss({
                // CDSS ID
                cdssId: record.cdssId,
                // 分類
                category: record.category,
                // 目的說明
                purpose: record.purpose,
                // 規則說明
                description: record.description,
                // 規則來源
                ruleSource: record.ruleSource,
                // 服務伺服器類型
                serverType: record.serverType,
                // effective Flag
                effectiveFlag: record.effectiveFlag,
                // 參考規範
                parameterConstraints: record.parameterConstraints,
                // 回傳結果
                returnResult: record.returnResult,
                lockVersion: 0
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    handleSuccessCreateRule();
                }
                else
                {
                    setToast({
                        show:true,
                        type:ToastTypeEnum.Error,
                        message: res.msg
                    })
                }
            })
        }
        else { //修改模式
            //新增規則
            saveCdss({
                // CDSS ID
                cdssId: record.cdssId,
                // 分類
                category: record.category,
                // 目的說明
                purpose: record.purpose,
                // 規則說明
                description: record.description,
                // 規則來源
                ruleSource: record.ruleSource,
                // 服務伺服器類型
                serverType: record.serverType,
                // effective Flag
                effectiveFlag: record.effectiveFlag,
                // 參考規範
                parameterConstraints: record.parameterConstraints,
                // 回傳結果
                returnResult: record.returnResult,
                lockVersion: 0
            }).then(res => {
                //新增成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    handleSuccessCreateRule();
                }
                else {
                    setToast({
                        show:true,
                        type:ToastTypeEnum.Error,
                        message: res.msg
                    })
                }
            })
        }
    }

    /**
     * 規則代碼文字欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRuleTextOnChange = (e) => {
        setRecord({...record, cdssId: e.target.value})
    }

    /**
     * 分類文字欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleCategoryTextOnChange = (e) => {
        setRecord({...record, category: e.target.value})
    }

    /**
     * 目的說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handlePurposeOnChange = (e) => {
        setRecord({...record, purpose: e.target.value})
    }

    /**
     * 規則說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleDescriptionOnChange = (e) => {
        setRecord({...record, description: e.target.value})
    }

    /**
     * 規則出處欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRuleSourceOnChange = (e) => {
        setRecord({...record, ruleSource: e.target.value})
    }

    /**
     * 服務伺服器類型欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleServerTypeOnChange = (e) => {
        setRecord({...record, serverType: e.target.value})
    }

    /**
     * 服務中欄位改變時
     * @param v {Boolean}
     * @return {void}
     */
    const handleEffectiveFlagOnChange = (v) => {
        setRecord({...record, effectiveFlag: v})
    }

    /**
     * 參考規範欄位改變時
     * @param v {Boolean}
     * @return {void}
     */
    const handleParameterConstraintsOnChange = (e) => {
        setRecord({...record, parameterConstraints: e.target.value})
    }

    /**
     * 回傳結果欄位改變時
     * @param v {Boolean}
     * @return {void}
     */
    const handleReturnResultOnChange = (e) => {
        setRecord({...record, returnResult: e.target.value})
    }

    return (
        <>
        <div
            className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex py-12 px-[100px] text-globalFont"
            onClick={closePopup}
        >
            <div
                className="flex w-[930px] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                onClick={(e) => e.stopPropagation()}>
                {/**Title */}
                <div
                    className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                    <div className="text-black text-xl font-normal">
                        {mode === PopupModeEnum.Add ? "新增" : "編輯"}
                    </div>
                    <div className="text-sky-700 text-lg font-normal cursor-pointer"
                            onClick={closePopup}>
                        {t('general.close')}
                    </div>
                </div>
                {/**Container */}
                <div className="flex flex-row p-[9px] space-x-[20px] text-[16px]">
                    <div className="w-1/2 space-y-[4px]">
                        {/**規則代碼 */}
                        <div className="h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">規則代碼</p>
                            <input 
                                className={`px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}
                                type="text"
                                disabled={mode !== PopupModeEnum.Add}
                                value={record.cdssId}
                                onChange={(e) => handleRuleTextOnChange(e)}
                            />
                        </div>
                        {/**分類 */}
                        <div className="h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">分類</p>
                            <input 
                                className="px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                type="text"
                                value={record.category}
                                onChange={(e) => handleCategoryTextOnChange(e)}
                            />
                        </div>
                        {/**目的說明 */}
                        <div className="space-x-[32px] flex flex-row items-center">
                            <Field label={"目的說明"}>
                                <TextArea
                                    inputSize={SizeEnum.Fill}
                                    value={record.purpose}
                                    onChange={(e) => handlePurposeOnChange(e)}
                                />
                            </Field>
                        </div>
                        {/**規則說明 */}
                        <div className="space-x-[32px] flex flex-row items-center">
                            <Field label={"目的說明"}>
                                <TextArea
                                    inputSize={SizeEnum.Fill}
                                    value={record.description}
                                    onChange={(e) => handleDescriptionOnChange(e)}
                                />
                            </Field>
                        </div>
                        {/**規則出處 */}
                        <div className="h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">規則出處</p>
                            <input 
                                className="px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]"
                                type="text"
                                value={record.ruleSource}
                                onChange={(e) => handleRuleSourceOnChange(e)}
                            />
                        </div>
                        {/**服務伺服器類型 */}
                        <div className="h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">服務伺服器類型</p>
                            <select
                                className="px-[14px] w-[60%] border border-[#D4D4D8] h-[40px] rounded-[6px]"
                                value={data.serverType}
                                onChange={(e) => handleServerTypeOnChange(e)}
                                defaultValue={null}
                            >
                                <option value="" disabled selected>請選擇</option>
                                <option value="Rule-Server">Rule-Server</option>
                                <option value="KIE-Server">KIE-Server</option>
                            </select>
                        </div>
                        {/**服務中 */}
                        <div className="h-[59px] space-x-[32px] flex flex-row items-center">
                            <p className="w-[40%] text-right">服務中</p>
                            <div className="w-[60%] space-x-[12px] flex flex-row items-center">
                                <div className="flex flex-row space-x-[8px]">
                                    <input
                                        type="radio"
                                        checked={record.effectiveFlag}
                                        onChange={() => handleEffectiveFlagOnChange(true)}
                                    />
                                    <label>
                                        是
                                    </label>
                                </div>
                                <div className="flex flex-row space-x-[8px]">
                                    <input
                                        type="radio"
                                        checked={!record.effectiveFlag}
                                        onChange={() => handleEffectiveFlagOnChange(false)}
                                    />
                                    <label>
                                        否
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 space-y-[4px]">
                        {/**參考規範 */}
                        <div className="min-h-[59px] space-x-[32px] flex flex-row items-center">
                            <Field label={"參考規範"}>
                                <TextArea
                                    inputSize={SizeEnum.Fill}
                                    value={record.parameterConstraints}
                                    onChange={(e) => handleParameterConstraintsOnChange(e)}
                                />
                            </Field>
                        </div>
                        {/**回傳結果 */}
                        <div className="min-h-[59px] space-x-[32px] flex flex-row items-center">
                            <Field label={"回傳結果"}>
                                <TextArea
                                    inputSize={SizeEnum.Fill}
                                    value={record.returnResult}
                                    onChange={(e) => handleReturnResultOnChange(e)}
                                />
                            </Field>
                        </div>
                    </div>
                </div>
                {/**下方區塊(存檔) */}
                <div
                    className="h-[60px] p-4 bg-white rounded-bl-lg rounded-br-lg border-t border-neutral-900 border-opacity-20 flex-col justify-center items-end inline-flex">
                    <button
                        className="bg-[#2B6CB0] p-2 text-white rounded-[6px] text-[16px] min-w-[64px]"
                        onClick={handleSaveOrEditOnClick}
                    >
                        {t('general.saveFile')}
                    </button>
                </div>
            </div>
        </div>
        {
                toast && 
                <ToastMessage 
                    message={toast.message}
                    type={toast.type}
                    show={toast.show}
                    onClickClose={() => setToast(null)}/>
            }
        </>
    )
}

export default RulePopup;
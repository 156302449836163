import React, {useEffect, useState} from 'react'
import Button from '../../Button/Button'
import {BaseInput} from '../../Input/BaseInput'
import {
    InputTypeEnum,
    objectIsEmpty,
    ApiErrorStatusEnum,
    stringIsEmpty,
} from 'edah_utils/dist'
import ReferralHospitalModal from '../ReferralHospitalModal'
import {queryTranCodeList} from '../../../api/v1/Rmis'
import useToast from '../../../hooks/useToast'
import {t} from 'i18next'
import {AlertTypeEnum, Checkbox, Field, Select, SizeEnum, TextField, Search} from "edah-component/dist"

const SecondButtonStyle =
    'bg-gray-50 flex items-center justify-center font-bold px-4 h-10 rounded-[6px] border-2'
const SelectStyle =
    'w-[200px] h-10  px-2 bg-white text-black rounded-[6px] border border-gray-300'

const Layout = ({children}) => {
    return (
        <div
            className="ml-4 p-8 w-full h-[calc(100vh-98px)] overflow-auto border-[1px] border-[rgba(0,0,0,0.15)] rounded-md	">
            {children}
        </div>
    )
}
/**
 * [轉診原因-本院無該檢查]代碼
 */
const TRAN_REASON_NO_REVELANT_TEST = '46'
/**
 * [轉診原因-本院無該治療]代碼
 */
const TRAN_REASON_NO_REVELANT_TREATMENT = '47'
/**
 * [轉診原因-其他]代碼
 */
const TRAN_REASON_OTHER = '6'
/**
 * [VPN原因-其他]代碼
 */
const VPN_REASON_OTHER = '9'
/**
 * 轉診單
 * @param tranOutInfo {object} 轉診單資料
 * @param divisionList {array} 科別下拉選單
 * @param vpnReasonList {array} vpn原因下拉選單
 * @param onUpdateData {function} 更新轉診單資料
 * @param zipCodeList {array} 郵遞區號下拉選單
 * @return {Element}
 */
const ReferralTurnOutModal = ({
                                  tranOutInfo,
                                  divisionList = [],
                                  vpnReasonList = [],
                                  onUpdateData,
                                  zipCodeList = [],
                              }) => {
    // 轉診單號
    const [tranNo, setTranNo] = useState('')

    // 註銷
    const [cancelFlag, setCancelFlag] = useState(true)

    // 轉出院所
    const [tranOutHospNo, setTranOutHospNo] = useState('')
    const [tranOutHospName, setTranOutHospName] = useState('')

    // 建議轉診科別
    const [tranOutDivNo, setTranOutDivNo] = useState('')
    const [tranOutDivName, setTranDivOutName] = useState('')

    // 建議轉診醫師
    const [tranOutDoctorName, setTranOutDoctorName] = useState('')

    // 轉出原因
    const [tranReason, setTranReason] = useState('')

    // 轉出原因(其他)
    const [tranReasonRemark, setTranReasonRemark] = useState('')

    // 本院無治療或檢查原因
    const [tranReasonRemark2, setTranReasonRemark2] = useState('')

    // 轉出目的
    const [tranPurpose, setTranPurpose] = useState('')

    // 轉出目的(其他)
    const [tranPurposeRemark, setTranPurposeRemark] = useState('')

    // VPN原因-其他原因
    const [otherReason, setOtherReason] = useState('')

    // 基礎資料
    const [baseDropdownData, setBaseDropdownData] = useState({
        // 轉診原因
        tranPurposeList: [],
        // 轉出原因
        tranReasonList: [],
    })

    // VPN原因
    const [vpnReason, setVpnReason] = useState(vpnReasonList[0]?.vpnReason)

    const [tranReasonRemark2InputState, setTranReasonRemark2InputState] =
        useState(null)

    // toast message
    const showToast = useToast()

    // 是否開啟轉診院所查詢視窗
    const [isShowReferralHospitalModal, setIsShowReferralHospitalModal] =
        useState(false)

    // 更新註銷
    const handleCancelFlagChange = (e) => setCancelFlag(e.target.checked)

    // 更新轉出院所
    const handleTranOutHospNoChange = (e) => setTranOutHospNo(e.target.value)

    // 更新轉出院所名稱
    const handleTranOutHospNameChange = (e) =>
        setTranOutHospName(e.target.value)

    // 更新建議轉診醫師
    const handleTranOutDoctorNameChange = (e) =>
        setTranOutDoctorName(e.target.value)

    // 更新轉出原因(其他)
    const handleTranReasonRemarkChange = (e) =>
        setTranReasonRemark(e.target.value)

    // 更新本院無治療或檢查原因
    const handleTranReasonRemark2Change = (e) =>
        setTranReasonRemark2(e.target.value)

    // 更新轉出目的(其他)
    const handleTranPurposeRemarkChange = (e) =>
        setTranPurposeRemark(e.target.value)

    // 更新vpn原因 - 其他原因
    const handleOtherReasonChange = (e) => setOtherReason(e.target.value)

    /**
     * 更新轉出原因
     * @param value {Stirng}
     */
    const handleTranReasonChange = (value) => {
        // const value = e.target.value
        const uploadCodeNo =
            baseDropdownData.tranReasonList.find(
                (item) => value === item.codeNo
            )?.uploadCodeNo || ''
        const tranPurposeCode = baseDropdownData.tranPurposeList.find(
            (item) => item.codeNo === uploadCodeNo
        )?.codeNo
        setTranReason(value)
        setTranPurpose(tranPurposeCode)
    }

    /**
     * 更新轉診目的
     * @param value {Stirng}
     * return {void}
     */
    const handleTranPurposeChange = (value) => setTranPurpose(value)

    /**
     * 更新vpn原因
     * @param value {String}
     * return {void}
     */
    const handleVpnReasonChange = (value) => setVpnReason(value)

    /**
     * 開啟院所查詢視窗
     */
    const showReferralHospital = () => {
        setIsShowReferralHospitalModal(true)
    }

    /**
     * 選擇院所
     */
    const onSelectHospital = (hospital) => {
        if (objectIsEmpty(hospital)) {
            setIsShowReferralHospitalModal(false)
            return
        }
        setTranOutHospNo(hospital.hospNo)
        setTranOutHospName(hospital.hospName)
        setIsShowReferralHospitalModal(false)
    }

    /**
     * 選擇科別
     * @param {object} division 選擇的科別
     * @returns
     */
    const selectDivision = (division) => {
        if (!division) {
            return
        }
        setTranOutDivNo(division?.divNo)
        setTranDivOutName(division.divName)
    }

    // 轉出原因(本院無治療或檢查原因)欄位狀態: 無狀態:null/檢核通過:true/檢核失敗:false
    useEffect(() => {
        if (
            tranReason !== TRAN_REASON_NO_REVELANT_TEST &&
            tranReason !== TRAN_REASON_NO_REVELANT_TREATMENT
        ) {
            setTranReasonRemark2InputState(null)
        } else {
            setTranReasonRemark2InputState(!stringIsEmpty(tranReasonRemark2))
        }
    }, [tranReason, tranReasonRemark2])

    useEffect(() => {
        Promise.all([
            queryTranCodeList({codeType: 'TRAN_REASON'}),
            queryTranCodeList({codeType: 'TRAN_PURPOSE'}),
        ]).then((res) => {
            const [tranReasonRes, tranPurposeRes] = res
            if (
                tranReasonRes.err === ApiErrorStatusEnum.Success &&
                tranPurposeRes.err === ApiErrorStatusEnum.Success
            ) {
                setBaseDropdownData((prev) => ({
                    ...prev,
                    tranPurposeList: tranPurposeRes.data || [],
                    tranReasonList: tranReasonRes.data || [],
                }))
            } else {
                showToast({
                    message: tranReasonRes.msg,
                    type: AlertTypeEnum.Error,
                })

                showToast({
                    message: tranPurposeRes.msg,
                    type: AlertTypeEnum.Error,
                })
            }
        })
    }, [])

    useEffect(() => {
        setTranNo(tranOutInfo?.tranNo || '')
        setCancelFlag(!!tranOutInfo?.cancelFlag)
        setTranOutHospNo(tranOutInfo?.tranOutHospNo || '')
        setTranOutHospName(tranOutInfo?.tranOutHospName || '')
        setTranOutDivNo(tranOutInfo?.tranOutDivNo || '')
        setTranDivOutName(tranOutInfo?.tranOutDivName || '')
        setTranOutDoctorName(tranOutInfo?.tranOutDoctorName || '')
        setTranReason(tranOutInfo?.tranReason || '')
        setTranReasonRemark(tranOutInfo?.tranReasonRemark || '')
        setTranReasonRemark2(tranOutInfo?.tranReasonRemark2 || '')
        setTranPurpose(tranOutInfo?.tranPurpose || '')
        setTranPurposeRemark(tranOutInfo?.tranPurposeRemark || '')
        setTranOutDoctorName(tranOutInfo?.tranOutDoctorName || '')
        setVpnReason(tranOutInfo?.vpnReason || vpnReasonList[0]?.vpnReason)
        setOtherReason(tranOutInfo?.otherReason)
    }, [tranOutInfo])

    // 更新資料傳到父層
    useEffect(() => {
        onUpdateData &&
        onUpdateData({
            lockVersion: tranOutInfo.lockVersion,
            encounterId: tranOutInfo.encounterId,
            encounterDate: tranOutInfo.encounterDate,
            inpOpd: tranOutInfo.inpOpd,
            patientId: tranOutInfo.patientId,
            contact: tranOutInfo.contact,
            contactTel: tranOutInfo.contactTel,
            contactAddress: tranOutInfo.contactAddress,
            divNo: tranOutInfo.divNo,
            doctorNo: tranOutInfo.doctorNo,
            treatmentCode: tranOutInfo.treatmentCode,
            treatmentCode1: tranOutInfo.treatmentCode1,
            treatmentCode2: tranOutInfo.treatmentCode2,
            subject: tranOutInfo.subject,
            clinicInfo: tranOutInfo.clinicInfo,
            etranNo: tranOutInfo.etranNo,
            tranDate: tranOutInfo.tranDate,
            tranDoctorNo: tranOutInfo.tranDoctorNo,
            tranOutAidBranchNo: tranOutInfo.tranOutAidBranchNo,
            condition: tranOutInfo.condition,
            otherChoice: tranOutInfo.otherChoice,
            urgentType: tranOutInfo.urgentType,
            urgentReason: tranOutInfo.urgentReason,
            urgentReasonRemark: tranOutInfo.urgentReasonRemark,
            urgentOrderDatetime: tranOutInfo.urgentOrderDatetime,
            orderDatetime: tranOutInfo.orderDatetime,
            uploadDatetime: tranOutInfo.uploadDatetime,
            outRealOutHospNo: tranOutInfo.outRealOutHospNo,
            outRealOutDivNo: tranOutInfo.outRealOutDivNo,
            outReturnDate: tranOutInfo.outReturnDate,
            outReplyDate: tranOutInfo.outReplyDate,
            outReplyDate2: tranOutInfo.outReplyDate2,
            admissionDate: tranOutInfo.admissionDate,
            dischargeDate: tranOutInfo.admissionDate,
            outStatus: tranOutInfo.outStatus,
            outCancerFlag: tranOutInfo.outCancerFlag,
            outRemark: tranOutInfo.outRemark,
            outRemark2: tranOutInfo.outRemark2,
            tranOutAcceptFlag: tranOutInfo.tranOutAcceptFlag,
            idNo: tranOutInfo.idNo,
            outNoteFinishFlag: tranOutInfo.outNoteFinishFlag,
            tranInFinishFlag: tranOutInfo.tranInFinishFlag,

            tranNo: tranNo,
            tranOutHospNo: tranOutHospNo,
            tranOutDivNo: tranOutDivNo,
            tranOutDoctorName: tranOutDoctorName,
            tranReason: tranReason,
            tranReasonRemark: tranReasonRemark,
            tranReasonRemark2: tranReasonRemark2,
            tranPurpose: tranPurpose,
            tranPurposeRemark: tranPurposeRemark,
            otherReason: otherReason,
            cancelFlag: cancelFlag,
            vpnReason: vpnReason,
        })
    }, [
        tranNo,
        tranOutHospNo,
        cancelFlag,
        tranOutHospName,
        tranOutDivNo,
        tranOutDoctorName,
        tranReason,
        tranReasonRemark,
        tranReasonRemark2,
        tranPurpose,
        tranPurposeRemark,
        otherReason,
    ])

    return (
        <Layout>
            {/* 標題 */}
            <div className="flex items-center">
                <div className="w-[8px] h-[20px] bg-[#2B6CB0]"></div>
                <div className="ml-4 text-[#2B6CB0] text-[20px]">轉診單</div>
            </div>
            {/* 轉診單號 */}
            <Field label="轉診單號" sx={{mb: 2}}>
                <div className="flex justify-between items-center">
                    {tranNo}
                    <Checkbox
                        label={t('general.logoff')}
                        checked={cancelFlag}
                        onChange={(e) => handleCancelFlagChange(e)}
                    />
                </div>
            </Field>
            {/* 轉出院所 */}
            <Field label="轉出院所" sx={{mb: 2}}>
                <div className="flex items-center space-x-2">
                    <Button
                        classNames={SecondButtonStyle}
                        text={'院所查詢'}
                        onClickFn={showReferralHospital}
                    />
                    <TextField
                        size={SizeEnum.Small}
                        inputWidth={SizeEnum.Small}
                        value={tranOutHospNo}
                        onChange={handleTranOutHospNoChange}
                        disabled
                    />
                    <TextField
                        size={SizeEnum.Small}
                        inputWidth={SizeEnum.Large}
                        value={tranOutHospName}
                        onChange={handleTranOutHospNameChange}
                        disabled
                    />
                </div>
            </Field>
            {/* 建議轉診科別 */}
            <Field label="建議轉診科別" sx={{mb: 2}}>
                <Search 
                    options = {divisionList}
                    getOptionLabel = {(option) => `${option.divNo} ${option.divName}`}
                    value={{
                        divNo: tranOutDivNo || '',
                        divName: tranOutDivName || '',
                    }}
                    onChange={(_event, newValue)=>selectDivision(newValue)}
                    inputWidth= {SizeEnum.XLarge}
                />
            </Field>
            {/* 建議轉診醫師 */}
            <Field label="建議轉診醫師" sx={{mb: 2}}>
                <TextField
                    size={SizeEnum.Small}
                    value={tranOutDoctorName}
                    onChange={handleTranOutDoctorNameChange}
                />
            </Field>
            {/* 轉出原因 */}
            <Field label="轉出原因" sx={{mb: 2}}>
                {baseDropdownData && <Select
                    data = {{
                        label: '轉出原因',
                        options: [
                            {value: '', label: ''},
                            ...baseDropdownData?.tranReasonList?.map((item) => ({
                                value: item.codeNo, 
                                label: item.codeNoName
                            })),
                        ]
                    }}
                    value = {tranReason}
                    onChange = {handleTranReasonChange}
                    showLabel = {false}
                    displayEmpty = {true}
                    width={SizeEnum.XLarge.charAt(0).toUpperCase() + SizeEnum.XLarge.slice(1)} // xLarge => XLarge
                    />}
            </Field>
            {/* 轉出原因(其他) */}
            {baseDropdownData.tranReasonList.find(
                (item) => tranReason === item.codeNo
            )?.codeNo === TRAN_REASON_OTHER && (
                <Field label="轉出原因(其他)" sx={{mb: 2}}>
                    <TextField
                        size={SizeEnum.Small}
                        value={tranReasonRemark}
                        onChange={handleTranReasonRemarkChange}
                    />
                </Field>
            )}
            {/* 轉出原因(本院無治療或檢查原因) */}
            {(tranReason === TRAN_REASON_NO_REVELANT_TEST ||
                tranReason === TRAN_REASON_NO_REVELANT_TREATMENT) && (
                <Field label="本院無治療或檢查原因"
                       sx={{mb: 2}}
                       labelSx={{display: 'flex', alignItems: 'center'}}
                       isRequired>
                    <TextField
                        size={SizeEnum.Small}
                        value={tranReasonRemark2}
                        onChange={handleTranReasonRemark2Change}
                        error={!tranReasonRemark2InputState}
                    />
                </Field>
            )}
            {/* 轉出目的 */}
            <Field label="轉出目的" sx={{mb: 2}}>
                {baseDropdownData && <Select 
                    data = {{
                        label: '轉出目的',
                        options: [
                            {value: '', label: ''},
                            ...baseDropdownData?.tranPurposeList?.map((item) => ({
                                value: item.codeNo,
                                label: item.codeNoName
                            })),
                        ]
                    }}
                    value={tranPurpose}
                    onChange={handleTranPurposeChange}
                    showLabel={false}
                    width={SizeEnum.XLarge.charAt(0).toUpperCase() + SizeEnum.XLarge.slice(1)}
                    displayEmpty = {true}
                />}
            </Field>
            {/* 轉出目的(其他) */}
            {baseDropdownData.tranPurposeList.find(
                (item) => tranPurpose === item.codeNo
            )?.codeNoName === '其他' && (
                <Field label="轉出目的(其他)" sx={{mb: 2}}>
                    <TextField
                        size={SizeEnum.Small}
                        value={tranPurposeRemark}
                        onChange={handleTranPurposeRemarkChange}
                    />
                </Field>
            )}
            {/* VPN原因 */}
            <Field label="VPN原因" sx={{mb: 2}}>
               {vpnReasonList &&  <Select
                    data = {{
                        label: 'VPN原因',
                        options: [
                            {value: '', label: ''},
                            ...vpnReasonList?.map((item) => ({
                                value: item.vpnReason,
                                label: item.vpnReasonName
                            })),
                        ]
                    }}
                    value={vpnReason ||''}
                    onChange={handleVpnReasonChange}
                    showLabel={false}
                    displayEmpty = {true}
                />}
            </Field>
            {/* 其他原因 */}
            {vpnReasonList.find((item) => vpnReason === item.vpnReason)
                ?.vpnReason === VPN_REASON_OTHER && (
                <Field label="其他原因" sx={{mb: 2}}>
                    <TextField
                        size={SizeEnum.Small}
                        value={otherReason}
                        onChange={handleOtherReasonChange}
                    />
                </Field>
            )}
            {isShowReferralHospitalModal && (
                <ReferralHospitalModal
                    onConfirm={onSelectHospital}
                    zipCodeList={zipCodeList}
                    closePopupButtonOnClick={() =>
                        setIsShowReferralHospitalModal(false)
                    }
                />
            )}
        </Layout>
    )
}

export default ReferralTurnOutModal

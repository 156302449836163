import {time2String} from "edah_utils/dist";
import {useState} from "react";
import {usePopup} from "../PopupProvider";
import {AlertTypeEnum, Dialog, DialogSizeEnums, DialogVariant, Field, SizeEnum, TextField} from "edah-component/dist";
import {t} from "i18next";

/**
 * 匯出檔案名稱彈窗
 * @param handleOnClose
 * @param handleOnExport
 * @return {JSX.Element}
 */
const ExportFIleNameModal = ({handleOnClose, handleOnExport}) => {

    const {showToast} = usePopup()

    // 取得日期時間
    const current = time2String(new Date(), 'YYYY-MM-DD HH-mm-ss')

    // 檔案名稱
    const [fileName, setFileName] = useState('')

    // 匯出檔案名稱
    const [exportFileName, setExportFileName] = useState('')

    // 匯出按鈕點擊事件
    const handleExportOnClick = () => {
        if (exportFileName === '') {
            showToast({message: '請輸入檔案名稱', type: AlertTypeEnum.Warning})
            return
        }
        handleOnExport(exportFileName)
    }

    /**
     * 格式話匯出日期名稱
     * @param value {string} 日期時間
     * @return {string}
     */
    const formatExportDateName = (value) => {
        const result = ''
        const date = value.split(' ')[0]
        const time = value.split(' ')[1]

        const newDateString = date.split('-').join('')
        // 時間只取split後的前兩個
        const newTimeString = time.split('-').slice(0, 2).join('')

        return result + `${newDateString}${newTimeString}`
    }

    return (
        <Dialog
            open={true}
            title={t('RSIS.Popup.ExportFileNameModal')}
            content={
                <div className='flex flex-col gap-4 p-4'>
                    <Field label="當前日期時間">
                        {current}
                    </Field>
                    <Field label="備註說明">
                        <TextField
                            size={SizeEnum.Small}
                            placeholder={`請輸入匯出檔案名稱`}
                            value={`${fileName}`}
                            onChange={(e) => {
                                setFileName(e.target.value)
                                setExportFileName(`${formatExportDateName(current)}_${e.target.value}`)
                            }}
                        />
                    </Field>
                    <Field label="匯出檔案名稱">
                        {exportFileName}
                    </Field>
                </div>
            }
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            onClose={handleOnClose}
            onSave={handleExportOnClick}
            muiDialogContentProps={{dividers: true}}
        />
    )
}

export default ExportFIleNameModal

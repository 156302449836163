import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist";
import {queryPatientByMultiField} from "../../../api/v1/Mris";
import useToast from "../../../hooks/useToast";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant, Field,
    Select,
    SizeEnum,
    TextField,
    SelectWidthEnum,
    Card,
    Pagination,
    AdvancedDataGrid
} from "edah-component/dist";
import dayjs from "dayjs"
import {SearchTextField} from "../../SearchTextField/SearchTextField";

/**
 * 多因素搜尋彈窗
 * @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 * @param show {Boolean} 是否顯示
 * @param icCardData {Object} IC卡資料
 * @param skipAuthReasonList {Array} 跳過稽核原因Array
 * @param confirmPatientData {Function} 確認病人病歷
 * @param handleMultifactorSearchSkipOnClick {Function} 跳過稽核事件
 * @param searchText {String} 帶入搜尋的字串
 */
export const MultifactorSearchPopup = ({
                                           closePopupButtonOnClick,
                                           show = false,
                                           icCardData,
                                           skipAuthReasonList,
                                           confirmPatientData,
                                           handleMultifactorSearchSkipOnClick,
                                           searchText,
                                       }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);
    /**
     * 病歷名單
     */
    const [arrayData, setArrayData] = useState([]);
    // 病歷名單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([]);
    // 進階搜尋文字狀態
    const [advancedSearchText, setAdvancedSearchText] = useState("");
    //查詢欄位資料(已查詢後)
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: "",
        //生日
        birthDate: "",
        //身分證號
        idNo: "",
        //電話號碼
        phoneNo: "",
        //地址
        address: "",
    });
    //查詢欄位資料(顯示在查詢爛位上的)
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: "",
        birthDate: "",
        idNo: "",
        phoneNo: "",
        address: "",
    });
    /**
     * 選擇的病歷號
     */
    const [selectedPatientData, setSelectedPatientData] = useState(null);
    /**
     * 跳過稽核的原因
     */
    const [skipReason, setSkipReason] = useState("");
    /**
     * 查詢按鈕disable狀態
     */
    const [disableSearchButton, setDisableSearchButton] = useState(true);
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });
    //Toast Message Hooks
    const showToast = useToast();

    // 表頭資料
    const columns = [
        {
            field: 'radioSelect',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <input
                    className="text-black"
                    type="radio"
                    checked={!objectIsEmpty(selectedPatientData) && selectedPatientData.patientId === params.row.patientId}
                    onChange={() => handlePatientOnChange(params.row)}
                />
            )
        },
        {
            field: 'sameIdNo',
            headerName: t("page.Appointment.popup.MultifactorSearch.sameIDNumber"),
            width: 120,
        },
        {
            field: 'samePatientName',
            headerName: t("page.Appointment.popup.MultifactorSearch.sameName"),
            width: 100,
        },
        {
            field: 'sameBirthDate',
            headerName: t("page.Appointment.popup.MultifactorSearch.sameBirthday"),
            width: 120,
        },
        {
            field: 'sameTelephone',
            headerName: t("page.Appointment.popup.MultifactorSearch.sameTelephone"),
            width: 120,
        },
        {
            field: 'sameAddress',
            headerName: t("page.Appointment.popup.MultifactorSearch.sameAddress"),
            width: 120,
        },
        {
            field: 'patientId',
            headerName: t("Regs.general.medicalRecordNumber"),
            width: 120
        },
        {
            field: 'patientName',
            headerName: t("general.username"),
            width: 120
        },
        {
            field: 'idNo',
            headerName: t("general.idNumber"),
            width: 130
        },
        {
            field: 'birthDate',
            headerName: t("general.dateOfBirth"),
            width: 125,
        },
        {
            field: 'genderName',
            headerName: t("general.gender.name"),
            width: 80,
        },
        {
            field: 'homephonenumber',
            headerName: '電話(市話)',
            width: 120
        },
        {
            field: 'phonenumber',
            headerName: t("general.mobilePhone"),
            width: 120
        },
        {
            field: 'address',
            headerName: t("general.address"),
            width: 400
        }
    ];

    /**
     * 點擊跳過稽核按鈕事件
     */
    const handleSkipCheckOnClick = () => {
        if (objectIsEmpty(icCardData)) {
            handleMultifactorSearchSkipOnClick(
                queryInputTmpData.text,
                queryInputTmpData.birthDate,
                queryInputTmpData.idNo,
                queryInputTmpData.phoneNo,
                queryInputTmpData.address
            );
        } else {
            //有ic卡資料就帶入ic卡資料
            handleMultifactorSearchSkipOnClick(
                icCardData.patientName,
                icCardData.birthDate,
                icCardData.idNo,
                "",
                ""
            );
        }
    };

    /**
     * 點擊查詢按鈕事件
     */
    const handleSearchOnClick = () => {
        //搜尋第一頁
        searchPageData(queryInputTmpData, 1, paginationProps.pageSize);
        //清除選擇的病歷號
        setSelectedPatientData(null);
    };

    /**
     * 查詢分頁資料
     * @param queryInputObj {Object} 查詢的資料
     * @param page {Number} 第幾頁
     * @param pageSize {Number} 每頁筆數
     */
    const searchPageData = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);

        queryPatientByMultiField({
            idNo: queryInputObj.idNo,
            birthDate: stringIsEmpty(queryInputObj.birthDate)
                ? ""
                : `${queryInputObj.birthDate} 00:00:00`,
            patientName: queryInputObj.text,
            pageNum: page, //第幾頁
            pageSize: pageSize, //一頁幾筆資料
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setArrayData(res.data.dataList);

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
                if (arrayIsEmpty(res.data.dataList)) {
                    //顯示toast
                    showToast({
                        message: "沒有找到符合的資料",
                        type: AlertTypeEnum.Warning,
                    });
                }
            } else {
                setArrayData([]);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
                let errMsg = "搜尋出現錯誤";
                if (!stringIsEmpty(res.msg)) errMsg = res.msg;
                else if (res.statusCode !== null)
                    errMsg = `Error(${res.statusCode})`;
                //顯示toast
                showToast({message: errMsg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 搜尋姓名輸入框文字變更時
     * @param e {Event}
     * @returns
     */
    const handleSearchNameTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            text: e.target.value,
        });
    };

    /**
     * 搜尋出生日期輸入框文字變更時
     * @param date {Dayjs | null} 選定的日期
     * @returns {void}
     */
    const handleSearchBirthDateTextOnChange = (date) => {
        if (!date || !date.isValid()) {
            return
        }
        setQueryInputTmpData({
            ...queryInputTmpData,
            birthDate: date.format('YYYY-MM-DD')
        })
    }

    /**
     * 搜尋身份證號輸入框文字變更時
     * @param e {Event}
     * @returns
     */
    const handleSearchIdNoTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            idNo: e.target.value,
        })
    }

    /**
     * 搜尋電話輸入框文字變更時
     * @param e {Event}
     * @returns
     */
    const handleSearchPhoneNoTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            phoneNo: e.target.value
        })
    }

    /**
     * 搜尋地址輸入框文字變更時
     * @param e {Event}
     * @returns
     */
    const handleSearchAddressTextOnChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            address: e.target.value
        })
    }

    /**
     * 病人選擇事件
     * @param patientData {String} 病人病歷資料
     * @returns
     */
    const handlePatientOnChange = (patientData) => {
        setSelectedPatientData(patientData)
    }

    /**
     * 跳過稽核原因下拉選單改變時
     * @param value {String}
     * @returns
     */
    const handleSkipReasonOnChange = (value) => setSkipReason(value);

    /**
     * 按下確認帶回按鈕事件
     * @returns
     */
    const handleConfirmOnClick = () => {
        if (objectIsEmpty(selectedPatientData)) return;
        //將patient data帶回
        confirmPatientData(selectedPatientData);
    };

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        searchPageData(queryInputData, page, pageSize);
    };

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        searchPageData(queryInputData, 1, e.target.value);
        //清除選擇的病歷號
        setSelectedPatientData(null);
    };

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page =
            paginationProps.currentPage - 1 > 1
                ? paginationProps.currentPage - 1
                : 1;
        searchPageData(queryInputData, page, paginationProps.pageSize);
    };

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page =
            paginationProps.currentPage + 1 < paginationProps.totalPageSize
                ? paginationProps.currentPage + 1
                : paginationProps.totalPageSize;
        searchPageData(queryInputData, page, paginationProps.pageSize);
    };

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) =>
        setAdvancedSearchText(e.target.value);

    /**
     * 取得病人進階搜尋後的結果
     * @returns
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(advancedSearchText)) {
            return arrayData;
        } else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = advancedSearchText.toLowerCase();
            return arrayData.filter((data) => {
                return (
                    (data.sameIdNo != null &&
                        data.sameIdNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.samePatientName != null &&
                        data.samePatientName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.sameBirthDate != null &&
                        data.sameBirthDate
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.patientId !== null &&
                        data.patientId
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.patientName !== null &&
                        data.patientName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.idNo !== null &&
                        data.idNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.genderName !== null &&
                        data.genderName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.birthDate !== null &&
                        time2String(data.birthDate, "YYYY-MM-DD").includes(
                            lowerCaseSearchText
                        ))
                );
            });
        }
    };

    /**
     * 取得彈窗元件
     * @return {JSX.Element} 彈窗元件
     */
    const getContent = () => (
        <div className="px-4">
            {icCardData && (
                <div>
                    <p className="text-xl font-normal text-left">
                        {`${icCardData.patientName}，${
                            icCardData.idNo
                        }，${time2String(icCardData.birthDate, "YYYY-MM-DD")}`}
                    </p>
                </div>
            )}
            <div className="flex my-1 text-sm leading-6">
                {t("page.Appointment.popup.MultifactorSearch.tip1")}
            </div>
            <div className="flex flex-row justify-start items-center py-1 space-x-2">
                {/*姓名*/}
                <TextField label={t("general.username")}
                           value={queryInputTmpData.text}
                           onChange={handleSearchNameTextOnChange}
                />
                {/*出生日期*/}
                <DatePicker
                    size="small"
                    label="出生日期"
                    disableFuture
                    value={dayjs(queryInputTmpData.birthDate)}
                    onChange={handleSearchBirthDateTextOnChange}
                />
                {/*身份證號*/}
                <TextField
                    label={t("general.idNumber")}
                    value={queryInputTmpData.idNo}
                    onChange={handleSearchIdNoTextOnChange}
                />
                {/*查詢按鈕*/}
                <Button
                    color={ColorEnum.Primary}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    disabled={disableSearchButton}
                    onClick={handleSearchOnClick}>
                    {t("general.query")}
                </Button>
            </div>
            <div className="flex my-1 text-sm leading-6">
                {t("page.Appointment.popup.MultifactorSearch.tip2")}
            </div>
            <div className="flex py-1 space-x-2 w-1/2 mb-2">
                {/*市話/手機*/}
                <TextField
                    label="市話/手機" value={queryInputTmpData.phoneNo}
                    onChange={handleSearchPhoneNoTextOnChange}
                />
                {/*地址*/}
                <TextField inputWidth={SizeEnum.Fill}
                           label={t("general.address")}
                           value={queryInputTmpData.address}
                           onChange={handleSearchAddressTextOnChange}/>
            </div>
            {/**中間 Data Grid */}
            <Card title="病歷名單">
                <div className="flex flex-row items-center mb-4">
                    {/*進階搜尋*/}
                    <SearchTextField
                        sx={{mr: 2}}
                        placeholder={t("general.advancedSearch")}
                        value={advancedSearchText}
                        onChange={(e) =>
                            onAdvancedSearchTextOnInputChange(e)
                        }
                    />
                    <Button
                        color="secondary"
                        variant="outlined"
                        size={ButtonSizeEnum.Medium}
                        onClick={handleConfirmOnClick}>{t("general.confirm")}</Button>
                </div>
                {/**Data Grid */}
                <div
                    className="border-collapse w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[22vh] xl:max-h-[40vh]">
                    <AdvancedDataGrid
                        rows={advSearchArrayData}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={false}
                        disableColumnMenu={true}
                        height={'100%'}
                        getRowId={(row) => row.patientId}
                        getRowHeight={(params) => 56}
                    />

                </div>
                <div className="flex justify-end w-full">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        page={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page, pageSize) =>
                            onPaginationPageOnChange(page, pageSize)
                        }
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>

            {/** 跳過稽核區塊 */}
            <Field label={'跳過稽核原因'}>
                <div className="flex flex-row items-center justify-start space-x-2">
                    <Select
                        data={{
                            label: "跳過稽核原因",
                            options:
                                skipAuthReasonList?.map((reason) => ({
                                    label: reason.justnameName,
                                    value: reason.justnameNo,
                                })) || [],
                        }}
                        value={skipReason}
                        onChange={handleSkipReasonOnChange}
                        showLabel={false}
                        width={SelectWidthEnum.Medium}
                    />
                    {/* 跳過稽核按鈕 */}
                    <Button color={ColorEnum.Secondary}
                            variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            disabled={disableSearchButton}
                            onClick={handleSkipCheckOnClick}>
                        跳過稽核
                    </Button>
                </div>
            </Field>
        </div>
    );

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        if (!show) {
            //重置搜尋欄位、Table
            setSelectedPatientData(null);
            setArrayData([]);

            setQueryInputTmpData({
                text: "",
                birthDate: "",
                idNo: "",
                phoneNo: "",
                address: "",
            });

            setPaginationProps({
                //當前頁碼
                currentPage: 0,
                //每頁資料筆數
                pageSize: 10,
                //總資料筆數
                totalItemSize: 0,
                //總頁碼
                totalPageSize: 0,
            })

            setAdvancedSearchText('')
        } else {
            if (!objectIsEmpty(icCardData)) {
                setQueryInputTmpData({
                    ...queryInputTmpData,
                    text: stringIsEmpty(icCardData.patientName)
                        ? ""
                        : icCardData.patientName,
                    idNo: stringIsEmpty(icCardData.idNo) ? "" : icCardData.idNo,
                    birthDate: stringIsEmpty(icCardData.birthDate)
                        ? ""
                        : time2String(icCardData.birthDate, "YYYY-MM-DD"),
                });
            } else if (!stringIsEmpty(searchText)) {
                //手機號碼:09開頭後面8個數字
                const regxMobile = /^09\d{8}$/;
                //生日: yyyyMMdd 8碼數字
                const regxBirthdate = /^\d{8}$/;
                //身分證規則:英文開頭 1碼 + 英文或數字 9碼
                const regxIdNo = /^[A-Za-z][A-Za-z0-9]{9}$/;

                if (regxIdNo.test(searchText)) {
                    //是身分證號格式
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        idNo: searchText,
                    });
                } else if (regxBirthdate.test(searchText)) {
                    //是生日格式
                    //將8碼數字 轉成 YYYY-MM-DD
                    const year = searchText.substring(0, 4);
                    const month = searchText.substring(4, 6);
                    const day = searchText.substring(6, 8);
                    const date = `${year}-${month}-${day}`;
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        birthDate: date,
                    });
                } else if (regxMobile.test(searchText)) {
                    //是手機號碼格式
                    setQueryInputTmpData({
                        ...queryInputTmpData,
                        phoneNo: searchText,
                    });
                }
            }
        }
        setShowPopup(show);
    }, [show]);

    /**
     * 監聽arrayData & advancedSearchText變化
     */
    useEffect(() => {
        if (arrayData !== null) {
            const list = getAdvSearchPatientList();
            setAdvSearchArrayData(list);
        } else {
            setAdvSearchArrayData(null);
        }
    }, [advancedSearchText, arrayData]);

    /**
     * 監聽skipAuthReasonList變化
     */
    useEffect(() => {
        if (!arrayIsEmpty(skipAuthReasonList)) {
            setSkipReason(skipAuthReasonList[0].justnameNo);
        }
    }, [skipAuthReasonList]);

    /**
     * 監聽查詢欄位變化
     */
    useEffect(() => {
        let cnt = 0;
        cnt = stringIsEmpty(queryInputTmpData.text) ? cnt : cnt + 1;
        cnt = stringIsEmpty(queryInputTmpData.birthDate) ? cnt : cnt + 1;
        cnt = stringIsEmpty(queryInputTmpData.idNo) ? cnt : cnt + 1;
        if (cnt < 1) {
            setDisableSearchButton(true);
        } else {
            if (cnt > 1) {
                setDisableSearchButton(false);
            } else {
                setDisableSearchButton(
                    stringIsEmpty(queryInputTmpData.phoneNo) &&
                    stringIsEmpty(queryInputTmpData.address)
                );
            }
        }
    }, [queryInputTmpData]);

    return (
        <Dialog
            open={showPopup}
            title={t("page.Appointment.popup.MultifactorSearch.title")}
            content={getContent()}
            onClose={closePopupButtonOnClick}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            muiDialogActionsProps={{sx: {display: 'none'}}}
            muiDialogContentProps={{sx: {overflowY: 'hidden'}}}
        />
    )
};
